























































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { LinkDto } from "@/application/dtos/master/v3/links/LinkDto";
import services from "@/services";
import store from "@/store";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import LinkConfirmInvitation from "./LinkConfirmInvitation.vue";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import { AppUsageType } from "../../../../application/enums/app/usages/AppUsageType";
import DateUtils from "@/utils/shared/DateUtils";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  components: {
    ConfirmModal,
    LinkConfirmInvitation,
    EmptyState,
    ErrorModal,
    Loading
  }
})
export default class LinksPendingList extends Vue {
  $refs!: {
    errorModal: ErrorModal;
    modalReject: ConfirmModal;
    modalAccept: ConfirmModal;
    linkConfirmInvitation: LinkConfirmInvitation;
  };
  items: LinkDto[] = [];
  loading = false;
  error = "";
  mounted() {
    this.reload();
    tinyEventBus().emitter.on("reload-links", () => {
      this.reload();
    });
  }
  beforeDestroy() {
    tinyEventBus().emitter.off("reload-links");
  }
  asProvider(link: LinkDto) {
    return store.state.tenant.currentWorkspace?.id === link.providerWorkspaceId;
  }
  reload() {
    this.items = [];
    this.loading = true;
    services.links
      .getAllPending()
      .then(response => {
        this.items = response;
      })
      .catch(error => {
        this.error = error;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  reject(item: LinkDto) {
    const whoAmIName =
      this.whoAmI(item) === 0
        ? this.$t("models.provider.object")
        : this.$t("models.client.object");
    this.$refs.modalReject.value = item;
    this.$refs.modalReject.show(
      "Rechazar invitación",
      "Rechazar",
      "Atrás",
      `Rechazarás ser ${whoAmIName} de ${this.inviterWorkspace(item).taxId}`
    );
  }
  view(item: LinkDto) {
    this.$refs.linkConfirmInvitation.show(item);
  }
  accept(item: LinkDto) {
    const whoAmIName =
      this.whoAmI(item) === 0
        ? this.$t("models.provider.object")
        : this.$t("models.client.object");
    this.$refs.modalAccept.value = item;
    this.$refs.modalAccept.show(
      "Aceptar invitación",
      "Aceptar",
      "Atrás",
      `Aceptarás ser ${whoAmIName} de ${this.inviterWorkspace(item).taxId}`
    );
  }
  accepted(item: LinkDto) {
    this.loading = true;
    services.links
      .acceptOrReject(item.id, {
        accepted: true
      })
      .then(() => {
        item.status = 1;
        services.tenants.getCurrentUsage(AppUsageType.PENDING_INVITATIONS);
      })
      .catch(error => {
        this.$refs.errorModal.show("Error", this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  rejected(item: LinkDto) {
    this.loading = true;
    services.links
      .acceptOrReject(item.id, {
        accepted: false
      })
      .then(() => {
        item.status = 2;
        services.tenants.getCurrentUsage(AppUsageType.PENDING_INVITATIONS);
      })
      .catch(error => {
        this.$refs.errorModal.show("Error", this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  whoAmI(item: LinkDto) {
    const currentWorkspaceId = store.state.tenant.currentWorkspace?.id ?? "";
    if (currentWorkspaceId === item.providerWorkspaceId) {
      return 0;
    }
    return 1;
  }
  inviterWorkspace(item: LinkDto): WorkspaceDto {
    if (item.createdByWorkspaceId === item.providerWorkspaceId) {
      return item.providerWorkspace;
    }
    return item.clientWorkspace;
  }
  invite() {
    tinyEventBus().emitter.emit("new-link");
  }
  dateAgo(value: Date) {
    return DateUtils.dateAgo(value);
  }
  get currentWorkspaceId() {
    return store.state.tenant.currentWorkspace?.id ?? "";
  }
}
