

















































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { DocumentTypeDto } from "@/application/dtos/app/v3/documentation/DocumentTypeDto";
import { DocumentationStatusDto } from "@/application/dtos/app/v3/documentation/DocumentationStatusDto";
import { DocumentStatusDto } from "@/application/dtos/app/v3/documentation/DocumentStatusDto";
import months from "@/application/dtos/shared/Months";
import { Month } from "@/application/dtos/shared/Month";
import DocumentTableRow from "./DocumentTableRow.vue";
import DocumentView from "./DocumentView.vue";
import DocumentUpload from "./DocumentUpload.vue";
import { PeriodStatusDto } from "@/application/dtos/app/v3/documentation/PeriodStatusDto";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import { ComplianceStatus } from "@/application/enums/app/v3/documentation/ComplianceStatus";
import { Periodicity } from "@/application/enums/app/common/Periodicity";
import store from "@/store";
import { WorkspaceType } from "@/application/enums/master/tenants/WorkspaceType";
import services from "@/services";

@Component({
  components: {
    DocumentTableRow,
    DocumentView,
    DocumentUpload,
    ErrorModal
  }
})
export default class DocumentsTable extends Vue {
  @Prop({}) year!: number;
  @Prop({}) title!: string;
  @Prop({}) documentTypes!: DocumentTypeDto[];
  @Prop({}) documentation!: DocumentationStatusDto;
  @Prop({}) canUpload!: boolean;
  @Prop({ default: false }) specialized!: boolean;
  @Prop({ default: false }) showValidatePreviousPeriods!: boolean;
  validatePreviousPeriods = false;
  months: Month[] = months;
  loading = false;
  $refs!: {
    uploadDocument: DocumentUpload;
    viewDocument: DocumentView;
    errorModal: ErrorModal;
  };
  //   periods(documentType: DocumentTypeDto) {
  //       return this.findDocumentPeriods(documentType);
  //   }
  clickWithinDeadline(document: DocumentStatusDto, period: PeriodStatusDto) {
    if (this.canUpload) {
      this.$refs.uploadDocument.upload(document, period);
    } else {
      this.$refs.errorModal.show(
        this.$t(
          "app.documentation.status." + ComplianceStatus[document.status]
        ),
        document.statusMessage
      );
    }
  }
  clickCompliant(document: DocumentStatusDto, period: PeriodStatusDto) {
    this.$refs.uploadDocument.upload(document, period);
    // this.$refs.viewDocument.show(document, period);
  }
  dropped(
    document: DocumentStatusDto,
    period: PeriodStatusDto,
    base64: string
  ) {
    this.$refs.uploadDocument.upload(document, period, base64);
  }
  clickNonCompliant(document: DocumentStatusDto, period: PeriodStatusDto) {
    if (this.canUpload) {
      this.$refs.uploadDocument.upload(document, period);
    } else {
      this.$refs.errorModal.show(
        this.$t(
          "app.documentation.status." + ComplianceStatus[document.status]
        ),
        document.statusMessage
      );
    }
  }
  currentMonth(month: Month) {
    const currentMonthlyPeriods = this.documentation.periods.filter(
      f => f.periodicity === Periodicity.MONTHLY && f.isCurrentPeriod
    );
    if (
      currentMonthlyPeriods.length > 0 &&
      currentMonthlyPeriods[0].period === month.value
    ) {
      return true;
    }
  }
  toggleWorkspaceType() {
    this.loading = true;
    const newType =
      this.workspaceType === WorkspaceType.REGULAR
        ? WorkspaceType.SPECIALIZED_PROVIDER
        : WorkspaceType.REGULAR;
    services.workspaces
      .updateType(store.state.tenant.currentWorkspace?.id, newType)
      .then(() => {
        this.$emit("updated");
      })
      .finally(() => {
        this.loading = false;
      });
  }
  toggleValidatePreviousPeriods() {
    this.validatePreviousPeriods = !this.validatePreviousPeriods;
    this.$emit("validatePreviousPeriodsChanged", this.validatePreviousPeriods);
  }
  get currentPeriod(): PeriodStatusDto | undefined {
    return this.documentation?.periods.find(
      f => f.periodicity === Periodicity.MONTHLY && f.isCurrentPeriod
    );
  }
  get workspaceType() {
    return store.state.tenant.currentWorkspace?.type ?? WorkspaceType.REGULAR;
  }
}
