










































import Vue from "vue";
import Component from "vue-class-component";
import services from "@/services";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import NewModal from "@/components/shared/modals/NewModal.vue";
import CfdisListAndTable from "./CfdisListAndTable.vue";
import { CfdiDto } from "@/application/dtos/app/v3/cfdis/CfdiDto";
import Loading from "@/components/shared/loaders/Loading.vue";
import { Prop } from "vue-property-decorator";
import { CfdiType } from "@/application/enums/app/v3/cfdis/CfdiType";

@Component({
  components: {
    TableRowSkeleton,
    NewModal,
    CfdisListAndTable,
    Loading
  }
})
export default class CfdisList extends Vue {
  @Prop({}) type!: CfdiType;
  loading = false;
  items: CfdiDto[] = [];

  searchInput = "";

  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    services.cfdis
      .getAllCfdis(this.type)
      .then(response => {
        this.items = response;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  get filteredItems(): CfdiDto[] {
    // return this.items;
    const items = this.items.filter(
      f =>
        f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) ||
        f.emisorRfc
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.emisorNombre
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.receptorRfc
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.receptorNombre
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.receptorUsoCfdi
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase())
    );

    return items;
  }
  get sortedItems(): CfdiDto[] {
    this.$forceUpdate();
    return this.filteredItems;
  }
}
