




















import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Toggle extends Vue {
  @Prop()
  options: any;
  @Prop()
  defaultSelected: any;
  selected: any = {};
  mounted() {
    this.selected = { value: this.defaultSelected };
    if (this.selected === "") {
      this.selected = this.options[0].value;
    }
  }
  select(option) {
    this.selected = option.value;
    this.$emit("selected", option);
  }
}
