








import Vue from "vue";
import { Component } from "vue-property-decorator";
import AppLayout from "@/components/layouts/sidebar/SidebarLayout.vue";
import store from "@/store";

@Component({
  components: { AppLayout }
})
export default class App extends Vue {
  get currentWorkspaceId(): string {
    return store.state.tenant.currentWorkspace?.id ?? "";
  }
}
