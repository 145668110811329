





































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import DropDocument from "@/components/app/v3/contracts/uploads/DropDocument.vue";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import { FileBase64 } from "@/application/dtos/shared/FileBase64";
import pdf from "vue-pdf";
import store from "@/store";
import { ContractMemberRole } from "@/application/enums/app/v3/contracts/ContractMemberRole";
import services from "@/services";
import { ContractDto } from "@/application/dtos/app/v3/contracts/ContractDto";
import { VueCsvImport } from "vue-csv-import";
import Contract from "@/assets/contracts/Contract.vue";
import Sign from "@/assets/contracts/Sign.vue";
import Workers from "@/assets/contracts/Workers.vue";
import FakePdfBase64 from "@/services/api/app/v3/contracts/FakePdfBase64";
import { EmployeeDto } from "@/application/dtos/app/v3/employees/EmployeeDto";
import LinkSelector from "@/components/app/v3/contacts/selectors/LinkSelector.vue";
import { LinkDto } from "@/application/dtos/master/v3/links/LinkDto";
import WarningBanner from "@/components/shared/banners/WarningBanner.vue";
import SelectEmployees from "../employees/SelectEmployees.vue";
import SelectContractMembers from "./SelectContractMembers.vue";
import { AddContractMemberDto } from "@/application/contracts/app/v3/contracts/AddContractMemberDto";
import { AppUsageType } from "@/application/enums/app/usages/AppUsageType";
import { AppUsageSummaryDto } from "@/application/contracts/app/tenants/AppUsageSummaryDto";
import { TenantFeaturesDto } from "@/application/contracts/master/tenants/TenantFeaturesDto";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  metaInfo: {
    title: "Nuevo contrato",
    titleTemplate: "%s | Délega"
  },
  components: {
    SuccessModal,
    ErrorModal,
    ConfirmModal,
    Breadcrumb,
    DropDocument,
    pdf,
    VueCsvImport,
    Contract,
    Sign,
    Workers,
    LinkSelector,
    WarningBanner,
    SelectEmployees,
    SelectContractMembers,
    Loading
  }
})
export default class NewContract extends Vue {
  $refs!: {
    errorModal: ErrorModal;
    successModal: SuccessModal;
    confirmCreate: ConfirmModal;
    inputName: HTMLInputElement;
    inputProviderEmail: HTMLInputElement;
    inputProviderTaxId: HTMLInputElement;
    inputClientEmail: HTMLInputElement;
    inputClientTaxId: HTMLInputElement;
    selectEmployees: SelectEmployees;
    selectContractMembers: SelectContractMembers;
    linkSelector: LinkSelector;
  };
  name = "";
  link: LinkDto | null = null;
  linkId = "";
  description = "";
  contractFile = "";
  employeesFile = "";
  currentPage = 1;
  pageCount = 0;
  members: AddContractMemberDto[] = [];
  employees: EmployeeDto[] = [];
  loading = false;
  importingEmployees = false;
  asProvider = true;
  preselectLinkId = "";
  mounted() {
    this.preselectLinkId = this.$route.query?.l?.toString() ?? "";
    if (this.preselectLinkId) {
      services.links.get(this.preselectLinkId).then(link => {
        this.$refs.linkSelector.select(link);
      });
    }
    this.loadFeatures();
    this.$nextTick(() => {
      this.changedAsProvider();
    });
  }
  loadSandboxInfo() {
    if (process.env.VUE_APP_SERVICE === "sandbox") {
      this.name = "Contract name";
      this.description =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";
      this.contractFile = "data:application/pdf;base64," + FakePdfBase64;
    }
  }
  loadFeatures() {
    services.tenants.getCurrentUsage(AppUsageType.CONTRACTS);
    services.tenants.getFeatures();
  }
  addMember() {
    if (!this.link || !this.link.id) {
      this.$refs.errorModal.show(
        "Faltan campos",
        "Selecciona un enlace (cliente o proveedor)."
      );
    } else {
      this.$refs.selectContractMembers.show(
        this.link,
        this.link?.id,
        this.members.map(f => f.userId)
      );
    }
  }
  addEmployee() {
    this.$refs.selectEmployees.show(this.employees.map(f => f.id));
  }
  removeFile() {
    this.contractFile = "";
  }
  nextPage(pages) {
    this.currentPage += pages;
  }
  removeMember(index) {
    this.members = this.members.filter((x, i) => i !== index);
  }
  removeEmployee(index) {
    this.employees = this.employees.filter((x, i) => i !== index);
    if (this.employees.length === 0) {
      this.employeesFile = "";
    }
  }
  save() {
    if (this.maxContractsReached) {
      this.$refs.errorModal.show(
        "Límite excedido",
        `Ya no puedes crear contratos este mes. Tienes un límite de ${this.features.monthlyContracts} contratos mensualmente.`
      );
    } else if (!this.link || !this.link.id) {
      this.$refs.errorModal.show(
        "Faltan campos",
        "Selecciona un enlace (cliente o proveedor)."
      );
      return;
    } else if (!this.name) {
      this.$refs.errorModal.show("Faltan campos", "Especifica un nombre.");
      return;
    } else if (!this.description) {
      this.$refs.errorModal.show("Especifica una descripción.");
      return;
    } else if (!this.contractFile) {
      this.$refs.errorModal.show(
        "Faltan campos",
        "No has agregado un contrato en formato PDF."
      );
    } else if (
      !this.members ||
      this.members.filter(f => f.role === ContractMemberRole.SIGNATORY).length <
      2
    ) {
      this.$refs.errorModal.show(
        "Faltan campos",
        "Agrega al menos 2 firmantes."
      );
      return;
    } else {
      this.$refs.confirmCreate.show(
        "¿Crear contrato?",
        "Crear y solicitar firmas",
        "No",
        this.maxContracts === 1
          ? `ADVERTENCIA: Te quedan ${this.maxContracts} contratos este mes. Se enviará por correo a los participantes para que firmen con su FIEL.`
          : "Se enviará por correo a los participantes para que firmen con su FIEL."
      );
    }
  }
  saveContract() {
    this.loading = true;
    services.contracts
      .create({
        linkId: this.linkId,
        // clientWorkspaceId: this.clientWorkspaceId,
        name: this.name,
        description: this.description,
        file: this.contractFile,
        members: this.members,
        employees: this.employees
      })
      .then((response: ContractDto) => {
        this.$router.push({
          name: "app.contracts.view",
          params: { id: response.id }
        });
      })
      .catch(error => {
        this.$refs.errorModal.show("Error creando", this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  droppedContractFile(files: FileBase64[]) {
    if (files.length > 0) {
      const mb = files[0].file.size / 1048576;
      console.log("mb", mb);
      if (mb >= 40) {
        this.$refs.errorModal.show(
          "Límite excedido",
          this.$t("app.contracts.errors.maxFileReached")
        );
      } else {
        this.contractFile = files[0].base64;
      }
    }
  }
  droppedEmployeesFile(filesBase64: FileBase64[], files: File[]) {
    if (files.length > 0) {
      this.employeesFile = filesBase64[0].base64;
      const employeesFile = files[0];
      const reader = new FileReader();
      reader.onload = e => {
        if (e.target?.result) {
          const employees = this.csvToArray(e.target.result.toString());
          employees.forEach(employee => {
            this.employees.push({
              id: "",
              firstName: employee[0],
              lastName1: employee[1],
              lastName2: employee[2],
              taxId: employee[3],
              socialSecurityId: employee[4],
              populationRegistryId: employee[5],
              email: employee[6],
              cfdis: []
            });
          });
        }
        this.clearEmptyEmployees();
      };
      reader.readAsText(employeesFile);
    }
  }
  clearEmptyEmployees() {
    this.employees.forEach((employee, index) => {
      if (!employee.firstName || employee.firstName.trim() === "") {
        this.employees = this.employees.filter((x, i) => i !== index);
      }
    });
  }
  csvToArray(str: string, delimiter = ",") {
    const headers: string[] = str.slice(0, str.indexOf("\n")).split(delimiter);

    let rows = str.slice(str.indexOf("\n") + 1).split("\n");
    if (headers[0].trim().toUpperCase() !== "NOMBRE") {
      rows = str.split("\n");
    }
    const arr = rows.map(row => {
      const values = row.split(delimiter);
      const el = headers.reduce((object, header, index) => {
        object[index] = values[index];
        return object;
      }, {});
      return el;
    });
    return arr;
  }
  downloadEmployeesFileTemplate() {
    const rows = [["NOMBRE", "RFC", "NSS", "CURP"]];

    const csvContent =
      "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "plantilla.csv");
    document.body.appendChild(link);

    link.click();
  }
  changedAsProvider() {
    // if (this.asProvider) {
    //   this.providerEmail = store.state.account.user?.email ?? "";
    //   this.providerTaxId = store.state.tenant.currentWorkspace?.taxId ?? "";
    //   this.providerLegalName =
    //     store.state.tenant.currentWorkspace?.legalName ?? "";
    //   this.clientEmail = "";
    //   this.clientTaxId = "";
    //   this.clientLegalName = "";
    //   this.$nextTick(() => {
    //     this.$refs.inputProviderEmail.focus();
    //   });
    // } else {
    //   this.clientEmail = store.state.account.user?.email ?? "";
    //   this.clientTaxId = store.state.tenant.currentWorkspace?.taxId ?? "";
    //   this.clientLegalName = store.state.tenant.currentWorkspace?.taxId ?? "";
    //   this.providerEmail = "";
    //   this.providerTaxId = "";
    //   this.providerLegalName = "";
    //   this.$nextTick(() => {
    //     this.$refs.inputClientEmail.focus();
    //   });
    // }
    this.loadSandboxInfo();
  }
  selectedLink(id: string, link: LinkDto) {
    this.linkId = id;
    this.link = link;
    this.$nextTick(() => {
      this.$refs.inputName.focus();
      this.$refs.inputName.select();
    });
  }
  selectedEmployees(items: EmployeeDto[]) {
    this.employees = items;
  }
  selectedContractMembers(items: AddContractMemberDto[]) {
    this.members = items;
  }
  get currentTaxId() {
    return store.state.tenant.currentWorkspace?.taxId;
  }
  get imProvider() {
    return (
      store.state.tenant.currentWorkspace?.id === this.link?.providerWorkspaceId
    );
  }
  get features(): TenantFeaturesDto {
    return store.state.app.features;
  }
  get usage(): AppUsageSummaryDto {
    return store.state.app.usage;
  }
  get maxContracts() {
    return this.features.monthlyContracts;
  }
  get maxContractsReached() {
    if (!this.features) {
      return true;
    } else {
      if (
        this.features.monthlyContracts > 0 &&
        this.usage.contracts >= this.features.monthlyContracts
      ) {
        return true;
      }
      return false;
    }
  }
}
