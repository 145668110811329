/* eslint-disable */
import { UserLoggedResponse } from "@/application/contracts/master/users/UserLoggedResponse";
import { UserLoginRequest } from "@/application/contracts/master/users/UserLoginRequest";
import { UserRegisterRequest } from "@/application/contracts/master/users/UserRegisterRequest";
import { UserVerifyRequest } from "@/application/contracts/master/users/UserVerifyRequest";
import store from "@/store";
import { FakeWorkspaceService } from "../../app/workspaces/FakeWorkspaceService";
import { FakeTenantService } from "../tenants/FakeTenantService";
import { FakeUserService } from "./FakeUserService";
import { IAuthenticationService } from "./IAuthenticationService";

const fakeUserService = new FakeUserService();
const fakeWorkspaceService = new FakeWorkspaceService();
const fakeTenantService = new FakeTenantService();

const defaultWorkspace = fakeWorkspaceService.workspaces[0];
defaultWorkspace.tenant = fakeTenantService.tenants[0];

const userLoggedResponse: UserLoggedResponse = {
  user: fakeUserService.users[0],
  token: "",
  defaultWorkspace,
};

export class FakeAuthenticationService implements IAuthenticationService {
  login(payload: UserLoginRequest): Promise<UserLoggedResponse> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        store.commit("auth/login", userLoggedResponse);
        resolve(userLoggedResponse);
      }, 1000);
    });
  }
  impersonate(userId: string): Promise<UserLoggedResponse> {
    throw new Error("Method not implemented.");
  }
  register(payload: UserRegisterRequest): Promise<UserLoggedResponse> {
    return this.login({} as UserLoginRequest);
  }
  verify(payload: UserVerifyRequest): Promise<UserLoggedResponse> {
    throw new Error("Method not implemented.");
  }
  reset(email: string): Promise<any> {
    throw new Error("Method not implemented.");
  }
}
