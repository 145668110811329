

































































































































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import store from "@/store";
import AllDocumentation from "@/components/app/v3/documentation/AllDocumentation.vue";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";

@Component({
  metaInfo: {
    title: "Mi documentación",
    titleTemplate: "%s | Délega"
  },
  components: {
    Breadcrumb,
    AllDocumentation
  }
})
export default class MyDocumentation extends Vue {
  $refs!: {
    allDocumentation: AllDocumentation;
  };
  year = 0;
  open = false;
  // linkId: string = "";
  years: number[] = []
  created() {
    for (let year = 2021; year <= new Date().getFullYear(); year++) {
      this.years.push(year);
    }
    const currentYear = new Date().getFullYear();
    this.year =
      Number(this.$route.query.ejercicio) ?? Number(this.$route.query.year);
    if (isNaN(this.year) || this.year === 0) {
      this.year = currentYear;
    }
  }
  closeOptions() {
    this.open = false;
  }
  toggleOptions() {
    this.open = !this.open;
  }
  sendDocumentationStatus() {
    this.closeOptions();
    this.$refs.allDocumentation.sendDocumentationStatus();
  }
  reload() {
    this.closeOptions();
    this.$refs.allDocumentation.reload();
  }
  downloadAll() {
    this.closeOptions();
    this.$refs.allDocumentation.downloadAll();
  }
  uploadDocument() {
    this.closeOptions();
    this.$refs.allDocumentation.uploadDocument();
  }
  get currentWorkspace(): WorkspaceDto | null {
    return store.state.tenant.currentWorkspace;
  }
}
