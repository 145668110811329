



















import Vue from "vue";

export default class PageTitle extends Vue {}
