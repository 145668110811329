/* eslint-disable */
import store from "@/store";
import { SubscriptionGetCurrentResponse } from "@/application/contracts/master/subscriptions/SubscriptionGetCurrentResponse";
import { SubscriptionInvoiceDto } from "@/application/dtos/master/subscriptions/SubscriptionInvoiceDto";
import { SubscriptionCouponDto } from "@/application/dtos/master/subscriptions/SubscriptionCouponDto";
import { SelectedSubscriptionRequest } from "@/application/contracts/master/subscriptions/SelectedSubscriptionRequest";
import { SubscriptionCreateCardTokenRequest } from "@/application/contracts/master/subscriptions/SubscriptionCreateCardTokenRequest";
import { ISubscriptionManagerService } from "./ISubscriptionManagerService";
import { TenantProductDto } from "@/application/dtos/master/tenants/TenantProductDto";
import { SubscriptionCustomerDto } from "@/application/dtos/master/subscriptions/SubscriptionCustomerDto";
import { SubscriptionCardDto } from "@/application/dtos/master/subscriptions/SubscriptionCardDto";
import { TenantDto } from "@/application/dtos/master/tenants/TenantDto";
import { defaultProducts } from "@/store/modules/pricing/default-pricing";
import { SubscriptionPaymentMethodDto } from "@/application/dtos/master/subscriptions/SubscriptionPaymentMethodDto";

const subscriptions: SubscriptionGetCurrentResponse[] = [];
for (const product of defaultProducts) {
  const customer: SubscriptionCustomerDto = {
    id: "",
    email: "",
    name: "",
    description: "",
    phone: "",
    currency: "",
    created: new Date(),
  };
  const invoices: SubscriptionInvoiceDto[] = [
    {
      created: new Date(),
      invoicePdf: "",
      lines: [
        {
          description: product.title,
          planName: product.title,
          planInterval: "month",
          planCurrency: "mxn",
          priceUnitAmount: product.prices[0].price,
          priceType: "",
        },
      ],
    },
  ];
  const cards: SubscriptionCardDto[] = [
    {
      brand: "visa",
      expiryMonth: 12,
      expiryYear: 2030,
      lastFourDigits: "1234",
      expiryDate: new Date(2030, 12, 1),
    },
  ];
  const tenantProducts: TenantProductDto[] = [
    {
      id: "",
      tenantId: "",
      tenant: {} as TenantDto,
      subscriptionPriceId: product.prices[0].id,
      subscriptionPrice: product.prices[0],
      active: true,
      startDate: new Date(),
      endDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
      subscriptionServiceId: "",
      subscriptionProduct: product,
      maxWorkspaces: product.maxWorkspaces,
      maxUsers: product.maxUsers,
      maxLinks: product.maxLinks,
      maxStorage: product.maxStorage,
      monthlyContracts: product.monthlyContracts,
    },
  ];
  const paymentMethods: SubscriptionPaymentMethodDto[] = [
    {
      card: cards[0],
    },
  ];
  const subscription: SubscriptionGetCurrentResponse = {
    activeProduct: tenantProducts,
    myProducts: tenantProducts,
    customer,
    invoices,
    cards,
    paymentMethods,
  };
  subscriptions.push(subscription);
}
export class FakeSubscriptionManagerService implements ISubscriptionManagerService {
  subscriptions = subscriptions;
  currentSubscription = subscriptions[1];
  getCurrentSubscription(): Promise<SubscriptionGetCurrentResponse> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        store.commit("tenant/subscription", this.currentSubscription);
        resolve(this.currentSubscription);
      }, 1000);
    });
  }
  getUpcomingInvoice(): Promise<SubscriptionInvoiceDto> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.currentSubscription.invoices[0]);
      }, 1000);
    });
  }
  getCoupon(couponId: string, currency: string): Promise<SubscriptionCouponDto> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject("not implemented");
      }, 1000);
    });
  }
  updateSubscription(subscription: SelectedSubscriptionRequest): Promise<SubscriptionGetCurrentResponse> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        defaultProducts.forEach((product) => {
          product.prices.forEach((price) => {
            if (price.id === subscription.subscriptionPriceId) {
              const subscription = this.subscriptions.find((f) => f.activeProduct[0].subscriptionPriceId === price.id);
              if (subscription) {
                this.currentSubscription = subscription;
                store.commit("tenant/subscription", this.currentSubscription);
                resolve(this.currentSubscription);
              }
            }
          });
        });
        // tslint:disable-next-line: max-line-length
        const newSuscription = subscriptions.find((f) => f.activeProduct.find((x) => x.subscriptionPriceId === subscription.subscriptionPriceId));
        if (newSuscription) {
          resolve(newSuscription);
        }
        reject("subscription not found");
      }, 1000);
    });
  }
  cancelSubscription(): Promise<SubscriptionGetCurrentResponse> {
    throw new Error("Method not implemented");
  }
  updateCardToken(cardToken: string): Promise<SubscriptionGetCurrentResponse> {
    throw new Error("Method not implemented");
  }
  createCardToken(cardTokenRequest: SubscriptionCreateCardTokenRequest): Promise<SubscriptionGetCurrentResponse> {
    throw new Error("Method not implemented");
  }
  updateCard(cardTokenRequest: SubscriptionCreateCardTokenRequest): Promise<SubscriptionGetCurrentResponse> {
    throw new Error("Method not implemented");
  }
  createCustomerPortalSession(): Promise<SubscriptionGetCurrentResponse> {
    throw new Error("Method not implemented");
  }
}
