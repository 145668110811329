import { Module } from "vuex";
import { Theme } from "@/application/enums/shared/Theme";
import { RootState, ThemeState } from "@/store/types";

export const defaultState: ThemeState = {
  theme: Theme.Light,
};
export const state: ThemeState = defaultState;
export const theme: Module<ThemeState, RootState> = {
  namespaced: true,
  state,
  getters: {
    theme: (state: ThemeState) => {
      return state.theme;
    },
  },
  mutations: {
    reset(state: ThemeState) {
      state.theme = defaultState.theme;
      const htmlClasses = document.querySelector("html")?.classList;
      htmlClasses?.remove("dark");
    },
    theme(state: ThemeState, payload: number) {
      state.theme = payload;
      const htmlClasses = document.querySelector("html")?.classList;
      if (payload === 0) {
        htmlClasses?.remove("dark");
      } else {
        htmlClasses?.add("dark");
      }
    },
  },
};
