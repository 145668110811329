import { SendDocumentationStatusRequest } from "@/application/contracts/app/v3/documentation/SendDocumentationStatusRequest";
import { DocumentationStatusDto } from "@/application/dtos/app/v3/documentation/DocumentationStatusDto";
import { ApiService } from "../../../ApiService";
import { IDocumentationService } from "./IDocumentationService";
import { UpdateDocumentPeriodRequest } from "@/application/contracts/app/v3/documentation/UpdateDocumentPeriodRequest";
import { CreateDocumentRequest } from "@/application/contracts/app/v3/documentation/CreateDocumentRequest";

export class DocumentationService extends ApiService implements IDocumentationService {
  constructor() {
    super("Documentation");
  }
  // tslint:disable-next-line: max-line-length
  getMyDocumentationStatus(year: number, validatePreivousPeriods: boolean, date?: Date): Promise<DocumentationStatusDto> {
    const byDate = date ? `?byDate=${date}` : "";
    return super.getAll(`GetMyDocumentationStatus/${year}/${validatePreivousPeriods}${byDate}`);
  }
  // tslint:disable-next-line: max-line-length
  getDocumentationStatus(linkId: string, year: number, validatePreivousPeriods: boolean, date?: Date): Promise<DocumentationStatusDto> {
    const byDate = date ? `?byDate=${date}` : "";
    return super.getAll(`GetDocumentationStatus/${linkId}/${year}/${validatePreivousPeriods}${byDate}`);
  }
  // tslint:disable-next-line: max-line-length
  sendDocumentationStatus(linkId: string, year: number, validatePreivousPeriods: boolean, payload: SendDocumentationStatusRequest, date?: Date): Promise<void> {
    const byDate = date ? `?byDate=${date}` : "";
    return super.post(payload, `SendDocumentationStatus/${linkId}/${year}/${validatePreivousPeriods}${byDate}`);
  }
  // get(id: string): Promise<DocumentationDto> {
  //   return super.get(`Get/${id}`);
  // }
  //  downloadDocument(workspaceId: string, year: number, period: number, type: DocumentationType): Promise<any> {
  //    return super.download(`DownloadDocument/${workspaceId}/${year}/${period}/${type}`);
  //  }
  downloadDocument(linkId: string, id: string): Promise<string> {
    return super.post(undefined, `Download/${linkId}/${id}`);
  }
  downloadMyDocument(id: string): Promise<string> {
    return super.post(undefined, `DownloadMyDocument/${id}`);
  }
  downloadAll(linkId: string, year: number): Promise<any> {
    return super.download(undefined, `DownloadAll/${linkId}/${year}`);
  }
  downloadAllMyDocuments(year: number): Promise<any> {
    return super.download(undefined, `DownloadAllMyDocuments/${year}`);
  }
  downloadAllMyProvidersDocuments(year: number): Promise<any> {
    return super.download(undefined, `DownloadAllMyProvidersDocuments/${year}`);
  }
  create(payload: CreateDocumentRequest): Promise<void> {
    return super.post(payload, "Create");
  }
  updateDocumentPeriod(id: string, payload: UpdateDocumentPeriodRequest): Promise<void> {
    return super.put(id, payload, "Update");
  }
  delete(id: string): Promise<void> {
    return super.delete(id, "Delete");
  }
}
