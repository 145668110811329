/* eslint-disable */
import { UserLoggedResponse } from "@/application/contracts/master/users/UserLoggedResponse";
import { UserUpdateAvatarRequest } from "@/application/contracts/master/users/UserUpdateAvatarRequest";
import { UserUpdateLocaleRequest } from "@/application/contracts/master/users/UserUpdateLocaleRequest";
import { UserUpdatePasswordRequest } from "@/application/contracts/master/users/UserUpdatePasswordRequest";
import { UserUpdateRequest } from "@/application/contracts/master/users/UserUpdateRequest";
import { UserDto } from "@/application/dtos/master/users/UserDto";
import { UserLoginType } from "@/application/enums/master/users/UserLoginType";
import { UserType } from "@/application/enums/master/users/UserType";
import { i18n } from "@/plugins/vue-i18n";
import store from "@/store";
import fakeNamesAndEmails from "../tenants/FakeNamesAndEmails";
import { FakeTenantService } from "../tenants/FakeTenantService";
import { IUserService } from "./IUserService";

const users: UserDto[] = [];
const fakeTenantService = new FakeTenantService();

for (let index = 0; index < 20; index++) {
  const user: any = {
    id: index,
    type: UserType.Tenant,
    username: fakeNamesAndEmails[index].email.split("@")[0],
    email: fakeNamesAndEmails[index].email,
    firstName: fakeNamesAndEmails[index].firstName,
    lastName: fakeNamesAndEmails[index].lastName,
    phone: "",
    loginType: UserLoginType.PASSWORD,
    avatar: "",
    token: "",
    defaultTenant: fakeTenantService.tenants[0],
    tenants: fakeTenantService.tenants,
    currentTenant: fakeTenantService.tenants[0],
    timezone: "",
    locale: "",
  };
  users.push(user);
}

export class FakeUserService implements IUserService {
  users = users;
  adminGetAll(): Promise<UserDto[]> {
    return Promise.resolve(users);
  }
  get(id: string): Promise<UserDto> {
    const user = this.users.find((f) => f.id === id);
    if (user) {
      return Promise.resolve(user);
    } else {
      return Promise.reject(i18n.t("shared.notFound"));
    }
  }
  getUserAvatar(id: string): Promise<string> {
    const user = this.users.find((f) => f.id === id);
    return Promise.resolve(user?.avatar ?? "");
  }
  getClaims(): Promise<any[]> {
    throw new Error("Method not implemented.");
  }
  getCurrent(): Promise<UserDto[]> {
    throw new Error("Method not implemented.");
  }
  updateAvatar(avatar: UserUpdateAvatarRequest): Promise<UserDto> {
    throw new Error("Method not implemented." + avatar);
  }
  updateLocale(payload: UserUpdateLocaleRequest): Promise<any> {
    return Promise.resolve(payload);
  }
  update(id: string, payload: UserUpdateRequest): Promise<UserDto> {
    throw new Error("Method not implemented." + { id, payload });
  }
  updatePassword(payload: UserUpdatePasswordRequest): Promise<any> {
    throw new Error("Method not implemented." + payload);
  }
  adminUpdatePassword(userId: string, password: string): Promise<any> {
    throw new Error("Method not implemented." + { userId, password });
  }
  updateDefaultTenant(tenantId?: string): Promise<UserLoggedResponse> {
    throw new Error("Method not implemented." + tenantId);
  }
  deleteMe(): Promise<void> {
    store.dispatch("auth/logout");
    return Promise.resolve();
  }
  adminDelete(id: string): Promise<void> {
    throw new Error("Method not implemented.");
  }
}
