













































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ContractMemberDto } from "@/application/dtos/app/v3/contracts/ContractMemberDto";
import store from "@/store";
import Sign from "@/assets/contracts/Sign.vue";
import Clock from "@/assets/contracts/Clock.vue";

@Component({
  components: {
    Sign,
    Clock
  }
})
export default class ContractMembers extends Vue {
  @Prop({}) items!: ContractMemberDto[];
  selectedMember: ContractMemberDto | null = null;

  showOptions(member: ContractMemberDto) {
    this.selectedMember = member;
  }
  isCurrentMember(member: ContractMemberDto) {
    return store.state.account.user?.email === member.user.email;
  }
  get currentEmail() {
    return store.state.account.user?.email ?? "";
  }
  get signers(): ContractMemberDto[] {
    return this.items.filter(f => f.role === 0);
    // return [];
  }
  get sortedItems() {
    return this.items.sort((x, y) => {
      return x.role > y.role ? 1 : -1;
    });
  }
}
