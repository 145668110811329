































































































































import Component from "vue-class-component";
import Vue from "vue";
import GoogleSignInButton from "vue-google-signin-button-directive";
import Logo from "@/components/marketing/Logo.vue";
import { UserVerifyRequest } from "../../application/contracts/master/users/UserVerifyRequest";
import LoadingButton from "@/components/shared/buttons/LoadingButton.vue";
import ErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import services from "@/services";

@Component({
  components: {
    Logo,
    LoadingButton
  },
  directives: {
    GoogleSignInButton
  }
})
export default class Verify extends Vue {
  $refs!: {
    loadingButton: LoadingButton;
    errorModal: ErrorModal;
  };
  requireName = false;
  requirePassword = true;
  droppedDown = false;
  user = {} as UserVerifyRequest;
  created() {
    this.user.email = this.$route.query.e ? this.$route.query.e.toString() : "";
    this.user.token = this.$route.query.t ? this.$route.query.t.toString() : "";
    // console.log(this.user);
  }
  verify() {
    if (this.user.password !== this.user.passwordConfirm) {
      this.$refs.errorModal.show(
        this.$t("account.login.errors.passwordMissmatch")
      );
      return;
    }

    this.$refs.loadingButton.start();
    services.authentication
      .verify(this.user)
      .catch(error => {
        this.$refs.errorModal.show(error);
      })
      .finally(() => {
        if (this.$refs.loadingButton) {
          this.$refs.loadingButton.stop();
        }
      });
  }
  closeDropdown() {
    this.droppedDown = false;
  }
}
