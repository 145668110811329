













































































import Vue from "vue";
import Component from "vue-class-component";
import Modal from "../../../shared/modals/Modal.vue";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import { TenantUserDto } from "@/application/dtos/master/tenants/TenantUserDto";
import WorkspaceItem from "@/components/app/workspaces/WorkspaceItem.vue";
import { Prop } from "vue-property-decorator";
import { Period } from "@/application/enums/app/common/Period";
import services from "@/services";

@Component({
  components: {
    Modal,
    WorkspaceItem
  }
})
export default class WorkspacesWidget extends Vue {
  @Prop({ default: false }) summary!: boolean;
  @Prop({ default: Period.ALL }) initialPeriod!: Period;
  $refs!: {
    uploadTicketsModal: Modal;
    workspaceItem: WorkspaceItem[];
  };
  loading = false;
  items: WorkspaceDto[] = [];
  workspaceId = "";
  users: TenantUserDto[] = [];
  period: Period = Period.ALL;
  mounted() {
    this.period = this.initialPeriod;
    this.reload();
  }
  reloadPeriod(period: Period) {
    this.period = period;
    // for (let index = 0; index < this.workspaces.length; index++) {
    //   this.$refs.workspaceItem[index].loadSummary(period);
    // }
  }
  async reload() {
    this.loading = true;
    this.items = [];
    services.workspaces
      .getAllWorkspaces(false)
      .then((response: WorkspaceDto[]) => {
        this.items = response;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  get workspaces() {
    if (!this.items) {
      return [];
    }
    return this.items;
  }
}
