













































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { i18n } from "@/plugins/vue-i18n";
import store from "@/store";
import { LinkDto } from "@/application/dtos/master/v3/links/LinkDto";
import tinyEventBus from "@/plugins/tiny-eventbus";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";
import Sign from "@/assets/contracts/Sign.vue";

@Component({
  components: {
    // Icon
    EmptyState,
    Sign
  }
})
export default class LinksListAndTable extends Vue {
  @Prop({}) items!: LinkDto[];
  sortByColumn = "";
  sortDirection = -1;
  headers = [
    {
      name: "legalName",
      title: i18n.t("models.client.legalName")
    },
    {
      title: i18n.t("models.link.type")
    },
    {
      title: i18n.t("shared.actions")
    }
    // {
    //   name: "createdAt",
    //   title: i18n.t("shared.created")
    // }
    // {
    //   title: i18n.t("app.clients.visits")
    // }
  ];

  sortBy(column) {
    if (column) {
      this.sortDirection = this.sortDirection === -1 ? 1 : -1;
      this.sortByColumn = column;
    }
  }
  currentWorkspaceTaxIdIs(taxId) {
    return store.state.tenant.currentWorkspace?.taxId === taxId;
  }
  newClient() {
    tinyEventBus().emitter.emit("new-client");
  }
  getWorkspace(item: LinkDto) {
    if (this.whoAmI(item) === 0) {
      return item.clientWorkspace;
    } else {
      return item.providerWorkspace;
    }
  }
  whoAmI(item: LinkDto) {
    const currentWorkspaceId = store.state.tenant.currentWorkspace?.id ?? "";
    if (currentWorkspaceId === item.providerWorkspaceId) {
      return 0;
    }
    return 1;
  }
  get sortedItems(): LinkDto[] {
    const column = this.sortByColumn;
    const sortDirection = this.sortDirection;
    if (!column || column === "") {
      return this.items;
    }
    return this.items.sort((x, y) => {
      if (x[column] && y[column]) {
        if (sortDirection === -1) {
          return (x[column] > y[column] ? 1 : -1) ?? 1;
        } else {
          return (x[column] < y[column] ? 1 : -1) ?? 1;
        }
      }
      return 1;
    });
  }
}
