import { Module } from "vuex";
import { PricingState, RootState } from "@/store/types";
import { SubscriptionProductDto } from "@/application/dtos/master/subscriptions/SubscriptionProductDto";
import { SubscriptionBillingPeriod } from "@/application/enums/master/subscriptions/SubscriptionBillingPeriod";
import { SubscriptionPriceType } from "@/application/enums/master/subscriptions/SubscriptionPriceType";
import { TenantProductDto } from "@/application/dtos/master/tenants/TenantProductDto";

export const state: PricingState = {
  products: [],
  selectedProduct: null,
  billingPeriod: SubscriptionBillingPeriod.MONTHLY,
  currency: process.env.VUE_APP_CURRENCY?.toString() ?? "usd",
};
export const pricing: Module<PricingState, RootState> = {
  namespaced: true,
  state,
  actions: {
    myProducts({ commit }, products: TenantProductDto[]) {
      if (products?.length > 0) {
        commit("select", {
          product: products[0].subscriptionProduct,
          billingPeriod: products[0].subscriptionPrice.billingPeriod,
        });
      }
    },
  },
  mutations: {
    reset: (state) => {
      state.products = [];
      state.selectedProduct = null;
      state.billingPeriod = SubscriptionBillingPeriod.MONTHLY;
      state.currency = "mxn";
    },
    products: (state: PricingState, payload: SubscriptionProductDto[]) => {
      if (payload) {
        payload = payload.sort((x, y) => {
          return x.tier > y.tier ? 1 : -1;
        });
      }
      state.products = payload;
      if (payload.length > 0) {
        payload.forEach((product) => {
          product.prices.forEach((price) => {
            if (price.type === SubscriptionPriceType.RECURRING) {
              if (!state.currency) {
                state.currency = price.currency;
              }
            }
          });
        });
      }
    },
    select: (state: PricingState, payload) => {
      state.billingPeriod = payload.billingPeriod;
      state.selectedProduct = payload.product;
    },
    billingPeriod: (state: PricingState, payload: SubscriptionBillingPeriod) => {
      state.billingPeriod = payload;
    },
    currency: (state: PricingState, payload: string) => {
      state.currency = payload;
    },
  },
};
