






import { Component, Vue } from "vue-property-decorator";
import store from "./store";
import { Theme } from "./application/enums/shared/Theme";

@Component({
  metaInfo: {
    title: "Gestión de cumplimiento de proveedores especializados",
    titleTemplate: "%s | Délega"
  },
  components: {}
})
export default class App extends Vue {
  mounted() {
    const htmlClasses = document.querySelector("html")?.classList;
    if (this.currentTheme === 0) {
      store.commit("theme/theme", 0);
      htmlClasses?.remove("dark");
    } else {
      store.commit("theme/theme", 1);
      htmlClasses?.add("dark");
    }
  }
  get currentTheme(): Theme {
    return store.state?.theme?.theme ?? Theme.Light;
  }
}
