<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    style=" fill:#000000;"
  >
    <rect width="12" height="2" x="1" y="14" rx="1"></rect>
    <path
      d="M8,10H8v3a1,1,0,0,1-2,0V10H6a4,4,0,0,0-4,4v1H12V14A4,4,0,0,0,8,10Z"
    ></path>
    <rect width="12" height="2" x="12" y="6" rx="1"></rect>
    <path
      d="M19,2h0V5a1,1,0,0,1-2,0V2h0a4,4,0,0,0-4,4V7H23V6A4,4,0,0,0,19,2Z"
    ></path>
    <path
      d="M12,16a.93487.93487,0,0,0-.10339.02087A4.99982,4.99982,0,0,0,7.98,12.09875.92709.92709,0,0,0,8,12V10a1,1,0,0,0-2,0v2a.92709.92709,0,0,0,.02.09875,4.99982,4.99982,0,0,0-3.91657,3.92212A.93487.93487,0,0,0,2,16a1,1,0,0,0,0,2,.92709.92709,0,0,0,.09875-.02,4.99785,4.99785,0,0,0,9.8025,0A.92709.92709,0,0,0,12,18a1,1,0,0,0,0-2Z"
      opacity=".35"
    ></path>
    <path
      d="M23,7.98914a.93845.93845,0,0,0-.10614.02142A4.99935,4.99935,0,0,0,18.98,4.09875.92709.92709,0,0,0,19,4V2a1,1,0,0,0-2,0V4a.92709.92709,0,0,0,.02.09875,4.99935,4.99935,0,0,0-3.91382,3.91181A.93845.93845,0,0,0,13,7.98914a1,1,0,1,0,0,2,.93845.93845,0,0,0,.09772-.01972,4.99654,4.99654,0,0,0,9.80456,0A.93845.93845,0,0,0,23,9.98914a1,1,0,0,0,0-2Z"
      opacity=".35"
    ></path>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>