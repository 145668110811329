










































import Vue from "vue";
import Component from "vue-class-component";
import services from "@/services";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import NewModal from "@/components/shared/modals/NewModal.vue";
import LinksListAndTable from "./LinksListAndTable.vue";
import { LinkDto } from "@/application/dtos/master/v3/links/LinkDto";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  components: {
    TableRowSkeleton,
    NewModal,
    LinksListAndTable,
    Loading
  }
})
export default class LinksList extends Vue {
  loading = false;
  items: LinkDto[] = [];
  // pending: LinkDto[] = [];

  searchInput = "";

  mounted() {
    this.reload();
  }
  reload() {
    this.items = [];
    if (this.$route.name === "app.links.linked") {
      this.loading = true;
      services.links
        .getAllLinked()
        .then(response => {
          response.forEach(element => {
            this.items.push(element);
          });
        })
        .finally(() => {
          this.loading = false;
        });
    } else if (this.$route.name === "app.links.pending") {
      this.loading = true;
      services.links
        .getAllPending()
        .then(response => {
          response.forEach(element => {
            this.items.push(element);
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }

    // services.clients.getAllPending().then(response => {
    //   this.pending = response;
    // });
  }
  get filteredItems(): LinkDto[] {
    if (!this.items) {
      return [];
    }
    const items = this.items.filter(
      f =>
        f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) ||
        f.clientWorkspace.taxId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.clientWorkspace.legalName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.providerWorkspace.taxId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.providerWorkspace.legalName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase())
    );

    return items;
  }
  get sortedItems(): LinkDto[] {
    this.$forceUpdate();
    return this.filteredItems;
  }
}
