






































































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import LinksList from "@/components/app/v3/contacts/links/LinksList.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import Tabs, { TabItem } from "@/components/shared/tabs/Tabs.vue";

@Component({
  metaInfo: {
    title: "Mis enlaces",
    titleTemplate: "%s | Délega"
  },
  components: {
    Breadcrumb,
    LinksList,
    Tabs
  }
})
export default class Links extends Vue {
  $refs!: {
    list: LinksList;
  };
  tabs: TabItem[] = [];
  mounted() {
    this.tabs = [
      {
        name: this.$t("app.links.pending.title"),
        routeName: "app.links.pending"
      },
      {
        name: this.$t("app.links.linked.title"),
        routeName: "app.links.linked"
      }
    ];
  }
  reload() {
    this.$refs.list.reload();
  }
  newClient() {
    tinyEventBus().emitter.emit("new-client");
  }
  newProvider() {
    tinyEventBus().emitter.emit("new-provider");
  }
  newLink() {
    tinyEventBus().emitter.emit("new-link");
  }
}
