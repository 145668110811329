import { SubscriptionProductDto } from "@/application/dtos/master/subscriptions/SubscriptionProductDto";
import { SubscriptionBillingPeriod } from "@/application/enums/master/subscriptions/SubscriptionBillingPeriod";
import { SubscriptionPriceType } from "@/application/enums/master/subscriptions/SubscriptionPriceType";

const currency = "mxn";
const products: SubscriptionProductDto[] = [
  {
    serviceId: "",
    id: undefined,
    tier: 1,
    title: "Gratis",
    image: "",
    description: "Prueba compartir documentación con tu primer cliente o proveedor.",
    custom: false,
    prices: [
      {
        serviceId: "",
        id: undefined,
        type: SubscriptionPriceType.RECURRING,
        billingPeriod: SubscriptionBillingPeriod.MONTHLY,
        price: 0,
        currency,
        trialDays: 0,
        active: true,
      },
      {
        serviceId: "",
        id: undefined,
        type: SubscriptionPriceType.RECURRING,
        billingPeriod: SubscriptionBillingPeriod.YEARLY,
        price: 0,
        currency,
        trialDays: 0,
        active: true,
      },
    ],
    features: [
      {
        id: undefined,
        order: 1,
        key: "oneLink",
        value: "1",
        included: true,
        translateInFrontend: true,
      },
      {
        id: undefined,
        order: 2,
        key: "oneDocument",
        value: "1",
        included: true,
        translateInFrontend: true,
      },
      {
        id: undefined,
        order: 3,
        key: "maxUsers",
        value: "2",
        included: true,
        translateInFrontend: true,
      },
      {
        id: undefined,
        order: 4,
        key: "oneWorkspace",
        value: "1",
        included: true,
        translateInFrontend: true,
      },
      {
        id: undefined,
        order: 5,
        key: "maxStorage",
        value: "1 GB",
        included: true,
        translateInFrontend: true,
      },
    ],
    badge: "",
    active: true,
    maxUsers: 2,
    maxWorkspaces: 1,
    maxLinks: 1,
    maxStorage: 1 * 1024,
    monthlyContracts: 1,
  },
  {
    serviceId: "",
    id: undefined,
    tier: 2,
    title: "Estándar",
    image: "",
    description: "Comparte con tus clientes tu documentación de cumplimiento.",
    custom: false,
    prices: [
      {
        serviceId: "",
        id: undefined,
        type: SubscriptionPriceType.RECURRING,
        billingPeriod: SubscriptionBillingPeriod.MONTHLY,
        price: 1999,
        currency,
        trialDays: 30,
        active: true,
      },
      {
        serviceId: "",
        id: undefined,
        type: SubscriptionPriceType.RECURRING,
        billingPeriod: SubscriptionBillingPeriod.YEARLY,
        price: 19199,
        currency,
        trialDays: 0,
        active: true,
      },
    ],
    features: [
      {
        id: undefined,
        order: 1,
        key: "links",
        value: "45",
        included: true,
        translateInFrontend: true,
      },
      {
        id: undefined,
        order: 2,
        key: "monthlyContracts",
        value: "45",
        included: true,
        translateInFrontend: true,
      },
      {
        id: undefined,
        order: 3,
        key: "maxUsers",
        value: "5",
        included: true,
        translateInFrontend: true,
      },
      {
        id: undefined,
        order: 4,
        key: "maxWorkspaces",
        value: "2",
        included: true,
        translateInFrontend: true,
      },
      {
        id: undefined,
        order: 5,
        key: "maxStorage",
        value: "100 GB",
        included: true,
        translateInFrontend: true,
      },
    ],
    badge: "recommended",
    active: true,
    maxUsers: 5,
    maxWorkspaces: 2,
    maxLinks: 45,
    maxStorage: 100 * 1024,
    monthlyContracts: 45,
  },
  {
    serviceId: "",
    id: undefined,
    tier: 3,
    title: "Empresarial",
    image: "",
    description: "Gestiona la documentación de cumplimiento de todos tus proveedores.",
    custom: false,
    prices: [
      {
        serviceId: "",
        id: undefined,
        type: SubscriptionPriceType.RECURRING,
        billingPeriod: SubscriptionBillingPeriod.MONTHLY,
        price: 3999,
        currency,
        trialDays: 30,
        active: true,
      },
      {
        serviceId: "",
        id: undefined,
        type: SubscriptionPriceType.RECURRING,
        billingPeriod: SubscriptionBillingPeriod.YEARLY,
        price: 38399,
        currency,
        trialDays: 0,
        active: true,
      },
    ],
    features: [
      {
        id: undefined,
        order: 1,
        key: "links",
        value: "100",
        included: true,
        translateInFrontend: true,
      },
      {
        id: undefined,
        order: 2,
        key: "monthlyContracts",
        value: "90",
        included: true,
        translateInFrontend: true,
      },
      {
        id: undefined,
        order: 3,
        key: "maxUsers",
        value: "12",
        included: true,
        translateInFrontend: true,
      },
      {
        id: undefined,
        order: 4,
        key: "maxWorkspaces",
        value: "5",
        included: true,
        translateInFrontend: true,
      },
      {
        id: undefined,
        order: 5,
        key: "maxStorage",
        value: "500 GB",
        included: true,
        translateInFrontend: true,
      },
    ],
    badge: "",
    active: true,
    maxUsers: 12,
    maxWorkspaces: 5,
    maxLinks: 100,
    maxStorage: 500 * 1024,
    monthlyContracts: 90,
  },
  {
    serviceId: "",
    id: undefined,
    tier: 4,
    title: "Corporativo",
    image: "",
    description: "Para empresas con muchos colaboradores.",
    custom: true,
    prices: [
      {
        serviceId: "",
        id: undefined,
        type: SubscriptionPriceType.RECURRING,
        billingPeriod: SubscriptionBillingPeriod.MONTHLY,
        price: 0,
        currency,
        trialDays: 0,
        active: true,
      },
      {
        serviceId: "",
        id: undefined,
        type: SubscriptionPriceType.RECURRING,
        billingPeriod: SubscriptionBillingPeriod.YEARLY,
        price: 0,
        currency,
        trialDays: 0,
        active: true,
      },
    ],
    features: [
      {
        id: undefined,
        order: 1,
        key: "links",
        value: "100+",
        included: true,
        translateInFrontend: true,
      },
      {
        id: undefined,
        order: 2,
        key: "monthlyContracts",
        value: "90+",
        included: true,
        translateInFrontend: true,
      },
      {
        id: undefined,
        order: 3,
        key: "maxUsers",
        value: "12+",
        included: true,
        translateInFrontend: true,
      },
      {
        id: undefined,
        order: 4,
        key: "maxWorkspaces",
        value: "5+",
        included: true,
        translateInFrontend: true,
      },
      {
        id: undefined,
        order: 5,
        key: "maxStorage",
        value: "500+ GB",
        included: true,
        translateInFrontend: true,
      },
    ],
    badge: "",
    active: true,
    maxUsers: 12,
    maxWorkspaces: 5,
    maxLinks: 100,
    maxStorage: 500 * 1024,
    monthlyContracts: 90,
  },
];

// const periods = [SubscriptionBillingPeriod.MONTHLY, SubscriptionBillingPeriod.YEARLY];
// const tenantType = [TenantType.TENANT, TenantType.TenantA];
// const currencies = [defaultCurrency, "usd"];

// // TODO
// const basePrice = 99; // on MXN
// const factorSuperiorPlan = 2; // x2 each plan increase
// const factorEachSuperiorPlan = 2.5; // +250% plan increase
// const factorTenantType = 3.02; // +302%
// const factorYearly = 8.4; // -30% yearly
// const factorUsd = 0.05; // 20 USD -> MXN

// products.forEach(product => {
//   product.prices = [];
//   periods.forEach((billingPeriod: SubscriptionBillingPeriod) => {
//     tenantType.forEach((mode: TenantType) => {
//       currencies.forEach((currency: string) => {
//         let currentPrice: number = basePrice;
//         if (product.tier > 1) {
//           currentPrice = currentPrice
//             * (product.tier - 1) * factorSuperiorPlan
//             * (product.tier - 1) * factorEachSuperiorPlan;
//         }
//         if (mode !== TenantType.TENANT) {
//           currentPrice = currentPrice * (mode * factorTenantType);
//         }
//         if (billingPeriod === SubscriptionBillingPeriod.YEARLY) {
//           currentPrice = currentPrice * factorYearly;
//         }
//         if (currency === "usd") {
//           currentPrice = currentPrice * factorUsd;
//         } else {
//           currentPrice = Math.round(currentPrice);
//           let strPrice = currentPrice.toString();
//           while (strPrice.substr(strPrice.length - 2, strPrice.length) !== '99') {
//             currentPrice += 1;
//             strPrice = currentPrice.toString();
//           }
//         }
//         currentPrice = Math.round(currentPrice);
//         product.prices.push({
//           serviceId: "15",
//           id: undefined,
//           type: SubscriptionPriceType.RECURRING,
//           billingPeriod,
//           price: currentPrice,
//           currency,
//           trialDays: mode === TenantType.TENANT && product.tier === 1 ? 7 : 0,
//           active: true,
//           mode
//         });
//       });
//     });
//   });
// });

export const defaultProducts = products;
