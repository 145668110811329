

































































import Vue from "vue";
import Component from "vue-class-component";
import CountryFlag from "vue-country-flag";
import supportedLocales from "../../../locale/supportedLocales";
import { Prop } from "vue-property-decorator";
import store from "@/store";
import { i18n } from "@/plugins/vue-i18n";

@Component({
  components: {
    CountryFlag
  }
})
export default class FlagSelector extends Vue {
  @Prop() open!: boolean;
  @Prop({ default: true }) showFlagInsteadOfLocale;
  dropDownFlags = false;
  supportedLocales = supportedLocales;
  mounted() {
    this.dropDownFlags = this.open;
  }
  closeDropDownFlags() {
    this.dropDownFlags = false;
  }
  changedLocale(value) {
    this.closeDropDownFlags();
    store.commit("locale/updateLocale", value);
    i18n.locale = value;
  }
  getCurrentLocale(flag: boolean) {
    const current = this.supportedLocales.find(f => f.lang === i18n.locale);
    if (current) {
      return flag ? current.flag : current.lang_short;
    }
    return flag ? "us" : "en";
  }
}
