
































































































































































































































































































































































import Component from "vue-class-component";
import Vue from "vue";
import Selector from "@/components/shared/forms/Selector.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import supportedLocales from "../../../../locale/supportedLocales";
import UploadImage from "@/components/shared/forms/UploadImage.vue";
import { UserUpdatePasswordRequest } from "../../../../application/contracts/master/users/UserUpdatePasswordRequest";
import { UserUpdateAvatarRequest } from "../../../../application/contracts/master/users/UserUpdateAvatarRequest";
import { UserDto } from "../../../../application/dtos/master/users/UserDto";
import { UserUpdateRequest } from "../../../../application/contracts/master/users/UserUpdateRequest";
import { UserLoginType } from "@/application/enums/master/users/UserLoginType";
import store from "@/store";
import { i18n } from "@/plugins/vue-i18n";
import { UserType } from "@/application/enums/master/users/UserType";
import services from "@/services";
import * as TimezonesUtils from "@/utils/shared/TimezonesUtils";

@Component({
  metaInfo: {
    title: "Perfil",
    titleTemplate: "%s | Délega"
  },
  components: {
    Selector,
    SuccessModal,
    ErrorModal,
    UploadImage,
    ConfirmModal
  }
})
export default class Profile extends Vue {
  $refs!: {
    errorModal: ErrorModal;
    successModal: SuccessModal;
    confirmModal: ConfirmModal;
  };
  profile = {} as UserDto;
  updatePasswordRequest = {} as UserUpdatePasswordRequest;
  selectedLocale = "";
  locales: string[] = [];
  showUploadImage = false;
  timezonesOptions: any[] = [];
  uploadingImage = false;
  created() {
    this.timezonesOptions = TimezonesUtils.timezones;
    this.profile.id = store.state.account.user?.id ?? "";
    this.profile.email = store.state.account.user?.email ?? "";
    this.profile.firstName = store.state.account.user?.firstName ?? "";
    this.profile.lastName = store.state.account.user?.lastName ?? "";
    this.profile.phone = store.state.account.user?.phone ?? "";
    this.profile.loginType =
      store.state.account.user?.loginType ?? UserLoginType.PASSWORD;
    this.selectedLocale = i18n.locale;
  }
  mounted() {
    supportedLocales.forEach(f => {
      this.locales.push(f.lang);
    });
  }
  canChangePassword() {
    if (this.profile.loginType === UserLoginType.PASSWORD) {
      return true;
    }
    return false;
  }
  changedLocale(locale: string) {
    services.users
      .updateLocale({ locale })
      .then(() => {
        this.$router.go(0);
      })
      .finally(() => {
        store.commit("locale/updateLocale", locale);
        i18n.locale = locale;
      });
  }
  updateProfile() {
    const updateRequest: UserUpdateRequest = {
      firstName: this.profile.firstName,
      lastName: this.profile.lastName,
      phone: this.profile.phone
    };
    services.users
      .update(this.profile.id, updateRequest)
      .then(() => {
        this.$refs.successModal.show(
          this.$t("settings.profile.profileUpdated")
        );
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      });
  }
  logout() {
    store.dispatch("auth/logout");
  }
  updatePassword() {
    services.users
      .updatePassword(this.updatePasswordRequest)
      .then(() => {
        this.$refs.successModal.show(
          this.$t("settings.profile.passwordUpdated")
        );
        this.updatePasswordRequest.passwordCurrent = "";
        this.updatePasswordRequest.passwordNew = "";
        this.updatePasswordRequest.passwordConfirm = "";
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      });
  }
  deleteAccount() {
    if (store.state.account.user?.type === UserType.Admin) {
      this.$refs.errorModal.show(
        this.$t("settings.profile.errors.cannotDeleteAdmin")
      );
    } else {
      this.$refs.confirmModal.show(this.$t("settings.danger.confirmDelete"));
    }
  }
  confirmDelete() {
    services.users
      .deleteMe()
      .then(() => {
        // ignore
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      });
  }
  loadedImage(image) {
    const updateAvatar: UserUpdateAvatarRequest = {
      avatar: image
    };
    this.uploadingImage = true;
    services.users
      .updateAvatar(updateAvatar)
      .then(() => {
        this.showUploadImage = false;
      })
      .catch(error => {
        console.error("Error: " + JSON.stringify(error));
      })
      .finally(() => {
        this.uploadingImage = false;
      });
  }

  get avatar() {
    return store.state.account.user?.avatar ?? "";
  }
}
