































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { i18n } from "@/plugins/vue-i18n";

@Component({})
export default class Info extends Vue {
  @Prop({ default: i18n.t("shared.note") }) title!: string;
  @Prop({ default: "" }) text!: string;
}
