













































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import { LinkDto } from "@/application/dtos/master/v3/links/LinkDto";
import services from "@/services";
import { ContractDto } from "@/application/dtos/app/v3/contracts/ContractDto";
import ContractClock from "@/assets/contracts/ContractClock.vue";
import ContractCheck from "@/assets/contracts/ContractCheck.vue";
import ContractArchived from "@/assets/contracts/ContractArchived.vue";
import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";
import store from "@/store";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import { AppUsageType } from "../../../../application/enums/app/usages/AppUsageType";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import Sign from "@/assets/contracts/Sign.vue";
import DateUtils from "@/utils/shared/DateUtils";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  components: {
    Breadcrumb,
    // YearlyDocumentation,
    ContractClock,
    ContractCheck,
    ContractArchived,
    ConfirmModal,
    SuccessModal,
    ErrorModal,
    Sign,
    Loading
  }
})
export default class LinkProfile extends Vue {
  $refs!: {
    confirmDelete: ConfirmModal;
    successModalDeleted: SuccessModal;
    errorModal: ErrorModal;
  };
  id = "";
  year = 0;
  loading = false;
  loadingContracts = false;
  openOptions = false;

  provider: LinkDto = {} as LinkDto;
  contracts: ContractDto[] = [];
  created() {
    this.year = new Date().getFullYear();
    this.id = this.$route.params.id;
  }
  mounted() {
    this.reload();
    this.reloadContracts();
  }
  reload() {
    this.closeOptions();
    this.loading = true;
    services.links
      .get(this.id)
      .then(response => {
        this.provider = response;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  reloadContracts() {
    this.loadingContracts = true;
    services.contracts
      .getAllByLink(this.provider.id)
      .then(response => {
        this.contracts = response;
      })
      .finally(() => {
        this.loadingContracts = false;
      });
  }
  deleteLink() {
    this.closeOptions();
    this.$refs.confirmDelete.show(
      "¿Eliminar enlace?",
      "Eliminar",
      "Cancelar",
      "ADVERTENCIA: Se eliminarán todos los contratos y CFDIs que tengan relacionados. No se puede recuperar un enlace eliminado"
    );
  }
  confirmedDelete() {
    this.loading = true;
    services.links
      .delete(this.id)
      .then(() => {
        services.tenants.getCurrentUsage(AppUsageType.ALL);
        this.$refs.successModalDeleted.show("Enlace eliminado");
      })
      .catch(error => {
        this.$refs.errorModal.show("Error eliminando", this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  successModalDeletedClosed() {
    if (
      this.provider.providerWorkspaceId ===
      store.state.tenant.currentWorkspace?.id
    ) {
      this.$router.push({ name: "app.clients" });
    } else {
      this.$router.push({ name: "app.providers" });
    }
  }
  closeOptions() {
    this.openOptions = false;
  }
  toggleOptions() {
    this.openOptions = !this.openOptions;
  }
  dateMonthDayYear(value: Date | undefined) {
    return DateUtils.dateMonthDayYear(value);
  }
  dateDM(value) {
    return DateUtils.dateDM(value);
  }
  get isOwnerOrAdmin(): boolean {
    return (
      this.currentRole === TenantUserRole.OWNER ||
      this.currentRole === TenantUserRole.ADMIN
    );
  }
  get currentRole(): TenantUserRole {
    return store.state.tenant.current?.currentUser.role ?? TenantUserRole.GUEST;
  }
}
