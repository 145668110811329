export enum AppUsageType {
  ALL,
  PROVIDERS,
  CLIENTS,
  EMPLOYEES,
  CFDIS,
  CONTRACTS,
  STORAGE,
  PENDING_INVITATIONS,
}
