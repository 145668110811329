import { appSettingsRoutes } from "./appSettingsRoutes";
import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";

export const appRoutes = {
  name: "app",
  path: "/app",
  component: require("@/views/app/Index.vue").default,
  meta: {
    requiresAuth: true,
  },
  redirect: "/app/dashboard",
  children: [
    {
      name: "app.dashboard",
      path: "dashboard",
      component: require("@/views/app/dashboard/Dashboard.vue").default,
      meta: {
        roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
      },
    },
    {
      name: "app.cfdis.upload",
      alias: "upload/cfdis",
      path: "subir/cfdis",
      component: require("@/views/app/v3/cfdis/CfdisUpload.vue").default,
    },
    {
      name: "app.providers",
      path: "proveedores/:status?",
      alias: "providers/:status?",
      component: require("@/views/app/v3/contacts/Providers.vue").default,
      meta: {
        roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
      },
    },
    {
      name: "app.provider",
      path: "proveedor",
      alias: "provider",
      component: require("@/views/app/v3/contacts/Provider.vue").default,
      meta: {
        roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
      },
      children: [
        {
          name: "app.providers.profile",
          path: "perfil/:id",
          alias: "profile/:id",
          component: require("@/components/app/v3/contacts/LinkProfile.vue").default,
        },
        {
          name: "app.providers.documentation",
          path: "documentacion/:id",
          alias: "documentation/:id",
          component: require("@/components/app/v3/contacts/LinkDocumentation.vue").default,
        },
      ],
    },
    {
      name: "app.employees.new",
      path: "colaboradores/nuevo",
      alias: "employees/new",
      component: require("@/views/app/v3/employees/NewEmployee.vue").default,
      meta: {
        roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
      },
    },
    {
      name: "app.employee",
      path: "colaborador",
      alias: "employee",
      component: require("@/views/app/v3/employees/Employee.vue").default,
      meta: {
        roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
      },
      children: [
        {
          name: "app.employees.profile",
          path: "perfil/:id",
          alias: "profile/:id",
          component: require("@/components/app/v3/employees/profile/EmployeeProfile.vue").default,
        },
        {
          name: "app.employees.cfdis",
          path: "cfdis/:id",
          component: require("@/components/app/v3/employees/profile/EmployeeCFDIs.vue").default,
        },
      ],
    },
    {
      name: "app.clients",
      path: "clientes",
      alias: "clients",
      component: require("@/views/app/v3/contacts/Clients.vue").default,
      meta: {
        roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
      },
    },
    {
      name: "app.links",
      path: "enlaces",
      alias: "links",
      component: require("@/views/app/v3/contacts/Links.vue").default,
      meta: {
        roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
      },
      children: [
        {
          name: "app.links.pending",
          path: "pendientes",
          alias: "pending",
          component: require("@/components/app/v3/contacts/LinksPendingList.vue").default,
        },
        {
          name: "app.links.linked",
          path: "enlazados",
          alias: "linked",
          component: require("@/components/app/v3/contacts/links/LinksList.vue").default,
        },
      ],
    },
    {
      name: "app.employees",
      path: "colaboradores",
      alias: "employees",
      component: require("@/views/app/v3/employees/Employees.vue").default,
      meta: {
        roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
      },
    },
    {
      name: "app.cfdis",
      path: "cfdis",
      alias: "invoices",
      component: require("@/views/app/v3/cfdis/Cfdis.vue").default,
      redirect: "/app/cfdis/nomina",
      meta: {
        roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
      },
      children: [
        {
          name: "app.cfdis.payroll",
          path: "nomina",
          alias: "payroll",
          component: require("@/views/app/v3/cfdis/CfdisPayroll.vue").default,
        },
        {
          name: "app.cfdis.sync",
          path: "extracciones",
          alias: "extractions",
          component: require("@/views/app/v3/cfdis/CfdisSync.vue").default,
        },
      ],
    },
    {
      name: "app.contracts",
      path: "contratos/:status?",
      alias: "contracts/:status?",
      component: require("@/views/app/v3/contracts/Contracts.vue").default,
      meta: {
        roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
      },
    },
    {
      name: "app.contracts.new",
      path: "contrato/nuevo",
      alias: "contract/new",
      component: require("@/views/app/v3/contracts/NewContract.vue").default,
      meta: {
        roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
      },
    },
    {
      name: "app.contracts.details",
      path: "contrato",
      alias: "contract",
      component: require("@/views/app/v3/contracts/Contract.vue").default,
      meta: {
        roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
      },
      children: [
        {
          name: "app.contracts.view",
          path: ":id",
          alias: ":id",
          component: require("@/views/app/v3/contracts/ContractDetails.vue").default,
          children: [
            {
              name: "app.contracts.sign",
              path: "firmar",
              alias: "sign",
              component: require("@/views/app/v3/contracts/ContractSign.vue").default,
            },
          ],
        },
        {
          name: "app.contracts.employees",
          path: ":id/colaboradores",
          alias: ":id/employees",
          component: require("@/views/app/v3/contracts/ContractEmployees.vue").default,
        },
        {
          name: "app.contracts.cfdis",
          path: ":id/cfdis",
          alias: ":id/cfdis",
          component: require("@/views/app/v3/contracts/ContractCfdis.vue").default,
        },
      ],
    },
    {
      name: "app.visits",
      path: "visitas",
      alias: "visits",
      component: require("@/views/app/v3/visits/Visits.vue").default,
      roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER, TenantUserRole.GUEST],
    },
    {
      name: "app.visits.list",
      path: "visitas/lista/:id",
      alias: "visits/lista/:id",
      component: require("@/views/app/v3/visits/VisitsList.vue").default,
      roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER, TenantUserRole.GUEST],
    },
    {
      name: "app.visits.new",
      path: "visitas/nueva/:id",
      alias: "visits/new/:id",
      component: require("@/views/app/v3/visits/NewVisit.vue").default,
      roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER, TenantUserRole.GUEST],
    },
    //  {
    //    name: "app.projects",
    //    alias: "projects",
    //    path: "proyectos",
    //    component: require("@/views/app/projects/ProjectsList.vue").default,
    //    meta: {
    //      roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
    //    },
    //    children: [
    //      {
    //        name: "app.projects.new",
    //        alias: "new",
    //        path: "nuevo",
    //        component: require("@/views/app/projects/ProjectsForm.vue").default,
    //        meta: {
    //          roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
    //        },
    //      },
    //      {
    //        name: "app.projects.edit",
    //        alias: "edit/:id",
    //        path: "editar/:id",
    //        component: require("@/views/app/projects/ProjectsForm.vue").default,
    //        meta: {
    //          roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
    //        },
    //      },
    //    ],
    //  },
    {
      name: "app.documentation",
      path: "documentacion",
      alias: "documentation",
      component: require("@/views/app/v3/documentation/MyDocumentation.vue").default,
      meta: {
        roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
      },
    },
    // {
    //   name: "app.resources",
    //   alias: "resources",
    //   component: require("@/views/app/resources/MyResources.vue").default,
    // },
    {
      name: "app.unauthorized",
      path: "denegado",
      alias: "unauthorized",
      component: require("@/views/app/common/Unauthorized.vue").default,
    },
    appSettingsRoutes,
  ],
};
