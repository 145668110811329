

























import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import Tabs, { TabItem } from "@/components/shared/tabs/Tabs.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";

@Component({
  components: {
    Breadcrumb,
    Tabs
  }
})
export default class Contracts extends Vue {
  tabs: TabItem[] = [];
  mounted() {
    this.tabs = [
      {
        name: this.$t("app.contracts.details.title"),
        routeName: "app.contracts.view"
        // current: false
      },
      {
        name: this.$t("app.contracts.employees.title"),
        routeName: "app.contracts.employees"
        // current: false
      },
      // {
      //   name: this.$t("app.contracts.visits.title"),
      //   routeName: "app.contracts.visits",
      //   current: false
      // },
      {
        name: this.$t("app.contracts.cfdis.title"),
        routeName: "app.contracts.cfdis"
        // current: false
      }
    ];
    // this.tabs.forEach(tab => {
    //   tab.current = tab.routeName === this.$route.name;
    // });
    // this.changeContractsStatus();
  }
  reload() {
    tinyEventBus().emitter.emit("contracts-reload");
  }
  // @Watch("$route.path")
  // changeContractsStatus() {
  //   const tab = this.tabs.find(f => f.routeName === this.$route.name);
  //   if (tab) {
  //     switch (tab.routeName) {
  //       case "app.contracts.pending":
  //         this.currentStatus = ContractStatus.PENDING;
  //         break;
  //       case "app.contracts.signed":
  //         this.currentStatus = ContractStatus.SIGNED;
  //         break;
  //       case "app.contracts.archived":
  //         this.currentStatus = ContractStatus.ARCHIVED;
  //         break;
  //       case "app.contracts.all":
  //         this.currentStatus = null;
  //         break;
  //     }
  //   }
  // }
}
