






























































































































































import Vue from "vue";
import Component from "vue-class-component";
import BillingPeriodToggle from "../toggles/BillingPeriodToggle.vue";
import { SubscriptionProductDto } from "../../../application/dtos/master/subscriptions/SubscriptionProductDto";
import { SubscriptionPriceDto } from "../../../application/dtos/master/subscriptions/SubscriptionPriceDto";
import CurrencyToggle from "@/components/marketing/toggles/CurrencyToggle.vue";
import { TenantProductDto } from "@/application/dtos/master/tenants/TenantProductDto";
import { SubscriptionBillingPeriod } from "@/application/enums/master/subscriptions/SubscriptionBillingPeriod";
import { Prop } from "vue-property-decorator";
import { SubscriptionFeatureDto } from "@/application/dtos/master/subscriptions/SubscriptionFeatureDto";
import store from "@/store";
import services from "@/services";
import NumberUtils from "@/utils/shared/NumberUtils";
import TenantUtils from "@/utils/store/TenantUtils";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  components: {
    BillingPeriodToggle,
    CurrencyToggle,
    Loading
  }
})
export default class PlansRadioButtons extends Vue {
  @Prop({ default: true }) plansLabel!: boolean;
  @Prop({ default: false }) showCurrent!: boolean;
  @Prop({ default: "theme-bg-light theme-indigo" }) mainTheme!: string;

  loading = false;
  droppedDown = false;
  showOptions = false;
  created() {
    if (store.state.pricing.products.length === 0) {
      services.subscriptionProducts.getProducts(false, true).then(() => {
        this.loadCurrent();
      });
    }
  }
  mounted() {
    this.loading = true;
    services.subscriptionProducts
      .getProducts(true, true)
      .then(() => {
        this.loadCurrent();
        if (!store.state.auth.authenticated) {
          this.selectProductIfNotSelected();
          this.selectMonthly();
        }
      })
      .finally(() => {
        this.loading = false;
      });
    // if (
    //   this.myProducts &&
    //   this.myProducts.length > 0 &&
    //   this.myProducts[0].subscriptionProduct
    // ) {
    //   store.commit(
    //     "pricing/currency",
    //     this.myProducts[0].subscriptionPrice.currency
    //   );
    //   store.commit(
    //     "pricing/billingPeriod",
    //     this.myProducts[0].subscriptionPrice.billingPeriod
    //   );
    //   this.changeProduct(this.myProducts[0].subscriptionProduct);
    // }
  }
  loadCurrent() {
    if (this.activeProduct) {
      store.commit("pricing/select", {
        billingPeriod: this.activeProduct.subscriptionPrice.billingPeriod,
        product: this.activeProduct.subscriptionProduct
      });
    }
  }
  // setFromCurrentSubscription() {
  //   if (this.subscription) {
  //   }
  // }
  open() {
    this.droppedDown = true;
  }
  closeDropdown() {
    this.droppedDown = false;
  }
  changeProduct(product: SubscriptionProductDto) {
    this.closeDropdown();
    store.commit("pricing/select", {
      product,
      billingPeriod: store.state.pricing.billingPeriod
    });
  }
  price(product: SubscriptionProductDto): SubscriptionPriceDto {
    return (
      product.prices.find(
        f =>
          f.billingPeriod === store.state.pricing.billingPeriod &&
          f.currency === store.state.pricing.currency
      ) ?? product.prices[0]
    );
  }
  billingPeriod(price: SubscriptionPriceDto): string {
    if (price.billingPeriod === SubscriptionBillingPeriod.ONCE) {
      return this.$t("marketing.pricing.once").toString();
    } else {
      return (
        "/" +
        this.$t(
          "marketing.pricing." +
            SubscriptionBillingPeriod[price.billingPeriod] +
            "Short"
        )
      );
    }
  }
  showShowOptions() {
    // if (!this.showOptions) {
    //   this.closeDropdown();
    // }
    this.showOptions = !this.showOptions;
  }
  closeShowOptions() {
    // if (!this.showOptions) {
    //   this.closeDropdown();
    // }
    this.showOptions = false;
  }
  toggleBilling() {
    if (
      this.selectedPrice?.billingPeriod === SubscriptionBillingPeriod.MONTHLY
    ) {
      store.commit("pricing/billingPeriod", SubscriptionBillingPeriod.MONTHLY);
    } else {
      store.commit("pricing/billingPeriod", SubscriptionBillingPeriod.YEARLY);
    }
  }
  minPrice(product) {
    const price = NumberUtils.intFormat(this.price(product).price);
    const billingPeriod = this.$t(
      "marketing.pricing." + store.state.pricing.billingPeriod
    );
    return `${price} ${this.price(product).currency} ${billingPeriod}`;
  }
  getPrice(product: SubscriptionProductDto): SubscriptionPriceDto | undefined {
    const prices = product.prices.find(
      f =>
        (f.billingPeriod === store.state.pricing.billingPeriod ||
          f.billingPeriod === SubscriptionBillingPeriod.ONCE) &&
        f.currency === store.state.pricing.currency &&
        f.active
    );
    return prices;
  }
  getPriceAmount(product): string {
    return NumberUtils.numberFormat(this.getPrice(product)?.price ?? 0);
  }
  getFeatureDescription(feature: SubscriptionFeatureDto) {
    if (!feature.translateInFrontend) {
      if (feature.value) {
        return `${feature.value}`;
      } else {
        return `${feature.key}`;
      }
    } else {
      return this.$t("marketing.pricing.features." + feature.key, [
        feature.value
      ]);
    }
  }
  select(product: SubscriptionProductDto) {
    store.commit("pricing/select", {
      product,
      billingPeriod: store.state.pricing.billingPeriod
    });
  }
  changedProduct(e) {
    const product = this.products.find(f => f.id === e.target.value);
    if (product) {
      store.commit("pricing/select", {
        product,
        billingPeriod: store.state.pricing.billingPeriod
      });
    }
  }
  selectMonthly() {
    store.commit("pricing/billingPeriod", SubscriptionBillingPeriod.MONTHLY);
  }
  selectYearly() {
    store.commit("pricing/billingPeriod", SubscriptionBillingPeriod.YEARLY);
  }
  toggleBillingPeriod() {
    if (
      store.state.pricing.billingPeriod === SubscriptionBillingPeriod.MONTHLY
    ) {
      this.selectYearly();
    } else {
      this.selectMonthly();
    }
  }
  selectProductIfNotSelected() {
    if (this.products.length > 0) {
      if (!this.products.find(f => f.id === this.selectedProduct?.id)) {
        store.commit("pricing/select", {
          billingPeriod: this.products[0].prices[0].billingPeriod,
          product: this.products[0]
        });
      }
    }
  }
  getBillingPeriodName(billingPeriod: SubscriptionBillingPeriod) {
    return this.$t(
      "marketing.pricing." + SubscriptionBillingPeriod[billingPeriod]
    );
  }
  getYearlyDiscount(): string | undefined {
    const priceYearly = this.getPriceWithInterval(
      SubscriptionBillingPeriod.YEARLY
    );
    const priceMonthly = this.getPriceWithInterval(
      SubscriptionBillingPeriod.MONTHLY
    );
    if (priceYearly && priceMonthly) {
      const discount =
        100 - (priceYearly.price * 100) / (priceMonthly.price * 12);
      if (discount !== 0) {
        return "-" + discount.toFixed(0) + "%";
      }
    }

    return undefined;
  }
  getPriceWithInterval(
    billingPeriod: SubscriptionBillingPeriod
  ): SubscriptionPriceDto | undefined {
    let price: SubscriptionPriceDto | undefined;
    if (this.products && this.products.length > 0) {
      this.products.forEach(product => {
        const prices = product.prices.find(
          f =>
            f.billingPeriod === billingPeriod &&
            f.currency === store.state.pricing.currency &&
            f.price > 0
        );
        if (prices) {
          price = prices;
        }
      });
    }
    return price;
  }
  get activeProduct() {
    return TenantUtils.activeProduct();
  }
  get selectedProduct(): SubscriptionProductDto | null {
    return store.state.pricing.selectedProduct;
  }
  get selectedPrice(): SubscriptionPriceDto | undefined {
    const prices = this.selectedProduct?.prices;
    if (prices && prices.length > 0) {
      return (
        // tslint:disable-next-line: max-line-length
        prices.find(
          e =>
            e.billingPeriod === store.state.pricing.billingPeriod &&
            e.currency === store.state.pricing.currency
        ) ?? prices.filter(f => f.currency === store.state.pricing.currency)[0]
      );
    }
    return undefined;
  }
  get myProducts(): TenantProductDto[] {
    return (store.state.tenant.subscription
      ?.myProducts as TenantProductDto[])?.filter(
      f => f.subscriptionPrice.billingPeriod !== SubscriptionBillingPeriod.ONCE
    );
  }
  get products() {
    const products = (store.state.pricing.products as SubscriptionProductDto[])
      ?.filter(f => f.active && !f.custom)
      .sort((x, y) => {
        return x.tier > y.tier ? 1 : -1;
      });
    console.log("products", products);
    return products;
  }
}
