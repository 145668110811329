






































































































































































import Vue from "vue";
import Component from "vue-class-component";
import TenantMembers from "@/components/app/settings/tenant/TenantMembers.vue";
import Modal from "@/components/shared/modals/Modal.vue";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import { TenantDto } from "../../../application/dtos/master/tenants/TenantDto";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";
import { i18n } from "@/plugins/vue-i18n";
import { SubscriptionBillingPeriod } from "@/application/enums/master/subscriptions/SubscriptionBillingPeriod";
import { SubscriptionPriceDto } from "@/application/dtos/master/subscriptions/SubscriptionPriceDto";
import NumberUtils from "@/utils/shared/NumberUtils";
import services from "@/services";
import Loading from "@/components/shared/loaders/Loading.vue";
import DateUtils from "@/utils/shared/DateUtils";

@Component({
  components: {
    TenantMembers,
    Modal,
    TableRowSkeleton,
    EmptyState,
    Loading
  }
})
export default class Tenants extends Vue {
  $refs!: {
    imageModal: Modal;
  };
  loading = false;
  items: TenantDto[] = [];
  selectedImage = "";
  selectedImageIsDarkMode = false;
  searchInput = "";
  headers = [
    {
      title: i18n.t("models.tenant.object")
    },
    {
      title: i18n.t("models.workspace.plural")
    },
    {
      title: i18n.t("shared.created")
    },
    {
      title: i18n.t("models.tenant.subscription")
    }
    // {
    //   title: i18n.t("shared.actions")
    // }
  ];
  mounted() {
    this.reload();
  }
  async reload() {
    this.loading = true;
    this.items = [];
    services.tenants
      .adminGetAll()
      .then((response: TenantDto[]) => {
        this.items = response;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  selectTenant(tenant: TenantDto) {
    this.$router.push({ name: "admin.users", params: { id: tenant.id } });
  }
  changeTenant(tenant: TenantDto) {
    services.users.updateDefaultTenant(tenant.id);
  }
  selectImage(image, isDarkmode) {
    this.selectedImage = image;
    this.selectedImageIsDarkMode = isDarkmode;
    this.$refs.imageModal.show();
  }
  avatarText(tenant: TenantDto) {
    if (tenant) {
      return tenant.name.substring(0, 2).toUpperCase();
    }
    return "";
  }
  stripeUrl() {
    return process.env.NODE_ENV === "production"
      ? "https://dashboard.stripe.com"
      : "https://dashboard.stripe.com/test";
  }
  customerLink(tenant: TenantDto) {
    return this.stripeUrl() + "/customers/" + tenant.subscriptionCustomerId;
  }
  subscriptionOrProductLink(tenant: TenantDto) {
    if (
      tenant.subscriptionPlanId &&
      tenant.subscriptionPlanId.includes("sub_")
    ) {
      return this.stripeUrl() + "/subscriptions/" + tenant.subscriptionPlanId;
    } else {
      return this.stripeUrl() + "/payments/" + tenant.subscriptionPlanId;
    }
  }
  getWorkspaces(item: TenantDto) {
    return item.workspaces?.map(f => `${f.taxId} - ${f.legalName}`).join(", ");
  }
  getUsers(item: TenantDto) {
    return item.users
      ?.map(f => `${f.firstName} ${f.lastName} (${f.email})`)
      .join(", ");
  }
  getProducts(item: TenantDto) {
    return item.products
      ?.map(
        f =>
          `${f.subscriptionProduct.tier} - ${
            f.subscriptionProduct.title
          } (${NumberUtils.decimalFormat(f.subscriptionPrice.price)} ${
            f.subscriptionPrice.currency
          }${this.billingPeriod(f.subscriptionPrice)})`
      )
      .join(", ");
  }
  billingPeriod(price: SubscriptionPriceDto): string {
    if (price.billingPeriod === SubscriptionBillingPeriod.ONCE) {
      return this.$t("marketing.pricing.once").toString();
    } else {
      return (
        "/" +
        this.$t(
          "marketing.pricing." +
            SubscriptionBillingPeriod[price.billingPeriod] +
            "Short"
        )
      );
    }
  }
  dateAgo(value) {
    return DateUtils.dateAgo(value)
  }
  get filteredItems(): TenantDto[] {
    if (!this.orderedItems) {
      return [];
    }
    const orderedItems = this.orderedItems.filter(
      f =>
        f.subdomain !== "admin" &&
        (f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) ||
          f.name
            ?.toString()
            .toUpperCase()
            .includes(this.searchInput.toUpperCase()))
    );

    return orderedItems;
  }
  get orderedItems(): TenantDto[] {
    if (!this.items) {
      return [];
    }
    return this.items.sort((x, y) => {
      if (x.createdAt && y.createdAt) {
        return (x.createdAt > y.createdAt ? -1 : 1) ?? -1;
      }
      return -1;
    });
  }
}
