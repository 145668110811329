









































import Vue from "vue";
import Component from "vue-class-component";
import store from "@/store";
import tinyEventBus from "@/plugins/tiny-eventbus";

@Component({
  components: {}
})
export default class LinksInformationButton extends Vue {
  showLinksInformation() {
    tinyEventBus().emitter.emit("view-links-information");
  }
  get linksPlan(): number {
    return store.state.app.features.maxLinks;
  }
  get linksLeft(): number {
    return (
      this.linksPlan -
      store.state.app.usage.providers +
      store.state.app.usage.clients
    );
  }
}
