

































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class LinksInformationModal extends Vue {
  @Prop({ default: true }) asProvider!: boolean;

  showing = false;
  show() {
    this.showing = true;
  }
  close() {
    this.showing = false;
  }
}
