
























































































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import services from "@/services";
import ContractClock from "@/assets/contracts/ContractClock.vue";
import ContractCheck from "@/assets/contracts/ContractCheck.vue";
import ContractArchived from "@/assets/contracts/ContractArchived.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import Sign from "@/assets/contracts/Sign.vue";
import { TenantDto } from "@/application/dtos/master/tenants/TenantDto";
import { TenantProductDto } from "@/application/dtos/master/tenants/TenantProductDto";
import { SubscriptionPriceDto } from "@/application/dtos/master/subscriptions/SubscriptionPriceDto";
import { SubscriptionBillingPeriod } from "@/application/enums/master/subscriptions/SubscriptionBillingPeriod";
import DateUtils from "@/utils/shared/DateUtils";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  metaInfo: {
    title: "Subscripciones de Organización",
    titleTemplate: "%s | Délega"
  },
  components: {
    Breadcrumb,
    // YearlyDocumentation,
    ContractClock,
    ContractCheck,
    ContractArchived,
    ConfirmModal,
    SuccessModal,
    ErrorModal,
    Sign,
    Loading
  }
})
export default class TenantSubscription extends Vue {
  $refs!: {
    confirmDelete: ConfirmModal;
    successModalDeleted: SuccessModal;
    errorModal: ErrorModal;
  };
  id = "";
  year = 0;
  loading = false;
  loadingContracts = false;
  openOptions = false;

  item: TenantDto = {} as TenantDto;
  products: TenantProductDto[] = [];

  headers = [
    {
      title: "Plan"
    },
    {
      title: "Precio"
    },
    {
      title: "Comienza"
    },
    {
      title: "Termina"
    },
    {
      title: "Prueba"
    },
    {
      title: "Estatus"
    },
    {
      title: "Empresas"
    },
    {
      title: "Usuarios"
    },
    {
      title: "Enlaces"
    },
    {
      title: "Contratos"
    },
    {
      title: "Almacenamiento"
    }
  ];

  created() {
    this.id = this.$route.params.id;
  }
  mounted() {
    this.reload();
  }
  reload() {
    this.closeOptions();
    const promises: any[] = [
      services.tenants.get(this.id).then(response => {
        this.item = response;
      }),
      services.tenants.adminGetProducts(this.id).then(response => {
        this.products = response;
      })
    ];

    this.loading = true;
    Promise.all(promises).finally(() => {
      this.loading = false;
    });
  }
  deleteTenant() {
    this.closeOptions();
    this.$refs.confirmDelete.show(
      "¿Eliminar organización?",
      "Eliminar",
      "Cancelar",
      "ADVERTENCIA: Se eliminarán todas sus empresas, usuarios e información que haya subido."
    );
  }
  confirmedDelete() {
    this.loading = true;
    services.tenants
      .adminDelete(this.id)
      .then(() => {
        this.$refs.successModalDeleted.show("Organización eliminada");
      })
      .catch(error => {
        this.$refs.errorModal.show("Error eliminando", this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  successModalDeletedClosed() {
    this.$router.push({ name: "admin.clients" });
  }
  closeOptions() {
    this.openOptions = false;
  }
  toggleOptions() {
    this.openOptions = !this.openOptions;
  }
  billingPeriod(price: SubscriptionPriceDto): string {
    if (price.billingPeriod === SubscriptionBillingPeriod.ONCE) {
      return this.$t("marketing.pricing.once").toString();
    } else {
      return (
        "/" +
        this.$t(
          "marketing.pricing." +
            SubscriptionBillingPeriod[price.billingPeriod] +
            "Short"
        )
      );
    }
  }
  dateAgo(value: Date) {
    return DateUtils.dateAgo(value);
  }
  dateYMD(value: Date | undefined) {
    return DateUtils.dateYMD(value);
  }
}
