































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import NewModal from "@/components/shared/modals/NewModal.vue";
import services from "@/services";
// import { LinkPermission } from "@/application/enums/app/v3/links/LinkPermission";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import SuccessModal from "@/components/shared/modals/SuccessModal2.vue";
import store from "@/store";
import { LinkDto } from "@/application/dtos/master/v3/links/LinkDto";
import WarningBanner from "@/components/shared/banners/WarningBanner.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import { AppUsageType } from "@/application/enums/app/usages/AppUsageType";
import Loading from "../../../shared/loaders/Loading.vue";

@Component({
  components: {
    NewModal,
    ErrorModal,
    ConfirmModal,
    SuccessModal,
    WarningBanner,
    Loading
  }
})
export default class LinkForm extends Vue {
  @Prop({ default: true }) selectType!: boolean;
  @Prop({ default: true }) asProvider!: boolean;
  imProvider = false;
  loading = false;
  $refs!: {
    inputEmail: HTMLInputElement;
    errorModal: ErrorModal;
    confirmCreateLinkModal: ConfirmModal;
    confirmInviteNewUserModal: ConfirmModal;
    successModal: SuccessModal;
    buttonCreate: HTMLButtonElement;
  };
  showing = false;
  loadingSearching = false;
  loadingLinking = false;
  email = "";
  taxId = "";
  workspace: WorkspaceDto | null = null;
  linkCreated: LinkDto | null = null;
  error = "";
  // permissions: LinkPermission[] = [];
  mounted() {
    this.imProvider = this.asProvider;
  }
  reload() {
    this.loading = true;
    const promises: any[] = []
    promises.push(services.tenants.getCurrentUsage(AppUsageType.ALL));
    promises.push(services.tenants.getFeatures());

    Promise.all(promises).finally(() => {
      this.loading = false;
      this.$nextTick(() => {
      if (this.$refs.inputEmail) {
        this.$refs.inputEmail.focus();
        this.$refs.inputEmail.select();
      }
    });
    })
  }
  show() {
    this.workspace = null;
    this.email = "";
    this.taxId = "";

    if (process.env.VUE_APP_SERVICE === "sandbox") {
      this.taxId = "ABS12345XYZ";
      this.email = "user@email.com";
    }

    this.showing = true;
    this.reload();
  }
  close() {
    this.showing = false;
  }
  linkWithUserAndTaxId() {
    if (!this.email || this.email.trim() === "") {
      this.$refs.errorModal.show("Faltan campos", "Ingresa el correo");
      return;
    }
    if (!this.taxId || this.taxId.trim() === "") {
      this.$refs.errorModal.show("Faltan campos", "Ingresa el RFC");
      return;
    }
    this.error = "";
    this.loadingSearching = true;
    this.workspace = null;
    services.links
      .searchUser(this.email)
      .then(() => {
        this.searchWorkspace();
      })
      .catch(() => {
        this.$refs.confirmInviteNewUserModal.show(
          "Usuario no registrado",
          "Invitar",
          "Cancelar",
          `El usuario ${this.email} no está registrado en nuestra plataforma. Envía una invitación para que se registre gratis en delega.com.mx.`
        );
        this.loadingSearching = false;
      });
  }
  searchWorkspace() {
    services.links
      .searchMember(this.email, this.taxId)
      .then(() => {
        this.createLink();
        // this.$nextTick(() => {
        //   this.$refs.buttonCreate.focus();
        // });
      })
      .catch(() => {
        this.$refs.errorModal.show(
          "Error",
          `El usuario ${this.email} no tiene una empresa con RFC ${this.taxId}. Pídele que la agregue dando clic en 'Empresas' en el menú lateral izquierdo.`
        );
      })
      .finally(() => {
        this.loadingSearching = false;
      });
  }
  createLink() {
    const confirmText = this.workspace?.id ? "Enlazar" : "Invitar";
    const inviteText = this.workspace?.id
      ? ""
      : "No se ha encontrado una cuenta con ese correo y RFC, se le enviará un correo electrónico para invitarlo a unirse gratis a delega.com.mx.";
    if (this.imProvider) {
      this.$refs.confirmCreateLinkModal.show(
        "¿Agregar cliente?",
        confirmText,
        "Cancelar",
        "El cliente podrá ver tu documentación. " + inviteText
      );
    } else {
      this.$refs.confirmCreateLinkModal.show(
        "¿Agregar proveedor?",
        confirmText,
        "Cancelar",
        "Podrás ver la documentación de tu proveedor. " + inviteText
      );
    }
  }
  confirmInviteNewUser() {
    this.loadingLinking = true;
    services.links
      .createInvitation({
        email: this.email,
        taxId: this.taxId,
        legalName: "",
        message: "",
        inviteeIsProvider: !this.imProvider
      })
      .then(() => {
        this.$refs.successModal.show(
          "Invitación enviada",
          `Se ha enviado un correo a ${this.email} para que cree su cuenta en delega.com.mx y acepte tu invitación.`
        );
      })
      .catch(error => {
        this.$refs.errorModal.show("Error creando invitación", this.$t(error));
      })
      .finally(() => {
        this.loadingLinking = false;
      });
  }
  confirmCreateLink() {
    // const providerWorkspaceId = this.imProvider
    //   ? this.currentWorkspace.id
    //   : this.workspace?.id;
    // const clientWorkspaceId = !this.imProvider
    //   ? this.currentWorkspace.id
    //   : this.workspace?.id;
    this.loadingLinking = true;
    services.links
      .create({
        email: this.email,
        taxId: this.taxId,
        asProvider: !this.imProvider
        // permissions: this.permissions
      })
      .then(response => {
        this.linkCreated = response;
        this.$refs.successModal.show(
          this.$t("app.links.pending.invitationSent"),
          this.$t("app.links.invited", [
            this.email,
            this.imProvider ? "cliente" : "proveedor",
            this.currentWorkspace.taxId,
            this.currentWorkspace.legalName
          ])
        );
        services.tenants.getCurrentUsage(AppUsageType.PENDING_INVITATIONS);
      })
      .catch(error => {
        this.$refs.errorModal.show("Error creando invitación", this.$t(error));
      })
      .finally(() => {
        this.loadingLinking = false;
      });
  }
  createdLink() {
    tinyEventBus().emitter.emit("reload-links");
    this.$emit("created", this.linkCreated);
    this.close();
  }
  get totalLinks(): number {
    return store.state.app.usage.providers + store.state.app.usage.clients;
  }
  get maxLinks(): number {
    return store.state.app.features.maxLinks;
  }
  get maxLinksReached() {
    if (
      this.maxLinks > 0 &&
      store.state.app.usage.providers + store.state.app.usage.clients >=
        this.maxLinks
    ) {
      return true;
    }
    return false;
  }
  get currentWorkspace(): WorkspaceDto {
    return store.state.tenant.currentWorkspace ?? ({} as WorkspaceDto);
  }
  get linkName() {
    return !this.imProvider
      ? this.$t("models.provider.object")
      : this.$t("models.client.object");
  }
}
