



























































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { i18n } from "@/plugins/vue-i18n";
import store from "@/store";
import Icon from "@/assets/contracts/WorkerMale.vue";
import { CfdiDto } from "@/application/dtos/app/v3/cfdis/CfdiDto";
import DateUtils from "@/utils/shared/DateUtils";
import NumberUtils from "@/utils/shared/NumberUtils";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";

@Component({
  components: {
    Icon,
    EmptyState,
    ConfirmModal
  }
})
export default class CfdisListAndTable extends Vue {
  @Prop({}) items!: CfdiDto[];
  @Prop({ default: true }) withContract!: boolean;
  sortByColumn = "";
  sortDirection = -1;
  headers: any[] = [];

  mounted() {
    this.headers = [
      // {
      //   name: "tipoDeComprobante",
      //   title: i18n.t("models.cfdi.type")
      // },
      {
        name: "receptorRfc",
        title: i18n.t("models.cfdi.receiver")
      },
      {
        name: "emisorRfc",
        title: i18n.t("models.cfdi.issuer")
      },
      {
        name: "fecha",
        title: i18n.t("shared.date")
      },
      {
        name: "total",
        title: i18n.t("models.cfdi.total")
      }
      // {
      //   title: i18n.t("models.cfdi.object")
      // }
    ];
    if (this.withContract) {
      this.headers.push({
        title: i18n.t("models.contract.object")
      });
    }
  }
  sortBy(column) {
    if (column) {
      this.sortDirection = this.sortDirection === -1 ? 1 : -1;
      this.sortByColumn = column;
    }
  }
  currentWorkspaceTaxIdIs(taxId) {
    return store.state.tenant.currentWorkspace?.taxId === taxId;
  }
  download(cfdi: CfdiDto | undefined, type: string) {
    if (cfdi) {
      const downloadLink = document.createElement("a");
      const fileName =
        `${cfdi.tipoDeComprobante} - ${cfdi.receptorRfc} - ${cfdi.fecha}.` +
        type;
      const file = type === "xml" ? cfdi.xml : cfdi.pdf;
      downloadLink.href = file;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }
  decimalFormat(value: number) {
    return NumberUtils.decimalFormat(value);
  }
  dateMonthName(value: Date | undefined) {
    return DateUtils.dateMonthName(value);
  }
  dateDM(value: Date | undefined) {
    return DateUtils.dateDM(value);
  }
  get sortedItems(): CfdiDto[] {
    const column = this.sortByColumn;
    const sortDirection = this.sortDirection;
    if (!column || column === "") {
      return this.items;
    }
    return this.items.sort((x, y) => {
      if (x[column] && y[column]) {
        if (sortDirection === -1) {
          return (x[column] > y[column] ? 1 : -1) ?? 1;
        } else {
          return (x[column] < y[column] ? 1 : -1) ?? 1;
        }
      }
      return 1;
    });
  }
}
