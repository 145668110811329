








































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { i18n } from "@/plugins/vue-i18n";
import store from "@/store";
import { EmployeeDto } from "@/application/dtos/app/v3/employees/EmployeeDto";
import Icon from "@/assets/contracts/WorkerMale.vue";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";
import DateUtils from "@/utils/shared/DateUtils";

@Component({
  components: {
    Icon,
    EmptyState
  }
})
export default class EmployeesListAndTable extends Vue {
  @Prop({ default: true }) canEdit!: boolean;
  @Prop({}) items!: EmployeeDto[];
  sortByColumn = "";
  sortDirection = -1;
  headers: any[] = [
    {
      name: "taxId",
      title: i18n.t("models.employee.taxId")
    },
    {
      name: "socialSecurityId",
      title: i18n.t("models.employee.socialSecurityId")
    },
    {
      name: "populationRegistryId",
      title: i18n.t("models.employee.populationRegistryId")
    }
    // {
    //   title: i18n.t("app.employees.visits")
    // }
  ];

  monuted() {
    if (this.canEdit) {
      this.headers.push({
        name: "email",
        title: i18n.t("models.employee.email")
      });
      this.headers.push({
        title: i18n.t("shared.actions")
      });
    }
  }
  sortBy(column) {
    if (column) {
      this.sortDirection = this.sortDirection === -1 ? 1 : -1;
      this.sortByColumn = column;
    }
  }
  currentWorkspaceTaxIdIs(taxId) {
    return store.state.tenant.currentWorkspace?.taxId === taxId;
  }
  downloadCfdis(employee: EmployeeDto | undefined) {
    if (employee?.cfdis && employee?.cfdis.length > 0) {
      const downloadLink = document.createElement("a");
      const cfdi = employee.cfdis[0];
      const fileName = `${employee.taxId} - ${cfdi.fecha}.pdf`;
      downloadLink.href = "data:application/pdf;base64" + cfdi.pdf;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }
  dateMonthName(value: Date | undefined) {
    return DateUtils.dateMonthName(value);
  }
  get sortedItems(): EmployeeDto[] {
    const column = this.sortByColumn;
    const sortDirection = this.sortDirection;
    if (!column || column === "") {
      return this.items;
    }
    return this.items.sort((x, y) => {
      if (x[column] && y[column]) {
        if (sortDirection === -1) {
          return (x[column] > y[column] ? 1 : -1) ?? 1;
        } else {
          return (x[column] < y[column] ? 1 : -1) ?? 1;
        }
      }
      return 1;
    });
  }
}
