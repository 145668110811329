import { Module } from "vuex";
import { TenantState, RootState } from "@/store/types";
import store from "@/store";
import { SubscriptionGetCurrentResponse } from "@/application/contracts/master/subscriptions/SubscriptionGetCurrentResponse";
import { SubscriptionCardDto } from "@/application/dtos/master/subscriptions/SubscriptionCardDto";
import { TenantProductDto } from "@/application/dtos/master/tenants/TenantProductDto";
import { SubscriptionPriceDto } from "@/application/dtos/master/subscriptions/SubscriptionPriceDto";
import { TenantDto } from "@/application/dtos/master/tenants/TenantDto";
import { TenantUserDto } from "@/application/dtos/master/tenants/TenantUserDto";
import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";
import { TenantFeaturesDto } from "../../../application/contracts/master/tenants/TenantFeaturesDto";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import { WorkspaceType } from "@/application/enums/master/tenants/WorkspaceType";

export const state: TenantState = {
  tenants: [],
  current: null,
  subscription: null,
  members: [],
  workspaces: [],
  currentWorkspace: null,
  features: null,
};
export const tenant: Module<TenantState, RootState> = {
  namespaced: true,
  state,
  getters: {
    defaultSubscriptionCard: (state: TenantState): SubscriptionCardDto | undefined => {
      if (state.subscription && state.subscription.paymentMethods?.length > 0) {
        return state.subscription.paymentMethods[0].card;
      }
      return undefined;
    },
    activeProduct: (state: TenantState): TenantProductDto | null => {
      if (state.subscription && state.subscription.myProducts?.length > 0) {
        return state.subscription.myProducts[0];
      }
      return null;
    },
    activePrice: (state: TenantState): SubscriptionPriceDto | null => {
      if (state.subscription && state.subscription.myProducts?.length > 0) {
        return state.subscription.myProducts[0].subscriptionPrice;
      }
      return null;
    },
    isOwnerOrAdmin: (state: TenantState): boolean => {
      if (!state.current?.currentUser) {
        return false;
      }
      // tslint:disable-next-line: max-line-length
      return state.current.currentUser?.role === TenantUserRole.OWNER || state.current.currentUser?.role === TenantUserRole.ADMIN;
    },
    memberCount: (state: TenantState) => {
      return state.members.length;
    },
    features: (state: TenantState) => {
      return state.features;
    },
    workspacesCount: (state: TenantState) => {
      return state.workspaces?.length ?? 0;
    },
  },
  mutations: {
    reset(state: TenantState) {
      state.tenants = [];
      state.current = null;
      state.subscription = null;
      state.members = [];
      state.workspaces = [];
      state.currentWorkspace = null;
      state.features = null;
    },
    myTenants: (state: TenantState, payload: TenantDto[]) => {
      state.tenants = payload;
    },
    current: (state: TenantState, payload: TenantDto) => {
      state.current = payload;
    },
    currentWorkspace: (state: TenantState, payload: WorkspaceDto) => {
      state.currentWorkspace = payload;
    },
    currentImage: (state: TenantState, { imageType, image }) => {
      if (state.current) {
        if (imageType === "icon") {
          state.current.icon = image;
        } else if (imageType === "logo") {
          state.current.logo = image;
        } else if (imageType === "logoDarkmode") {
          state.current.logoDarkmode = image;
        }
      }
    },
    features: (state: TenantState, payload: TenantFeaturesDto) => {
      state.features = payload;
    },
    subscription: (state: TenantState, payload: SubscriptionGetCurrentResponse) => {
      state.subscription = payload;

      if (state.tenants && payload.customer) {
        state.tenants.forEach((tenant) => {
          if (tenant.subscriptionCustomerId === payload.customer.id) {
            tenant.products = payload.myProducts;
          }
        });
      }

      store.dispatch("pricing/myProducts", payload.myProducts);
    },
    members(state: TenantState, payload: TenantUserDto[]) {
      state.members = payload;
    },
    workspaces(state: TenantState, payload: WorkspaceDto[]) {
      // state.currentWorkspace = null;
      state.workspaces = payload;
      if (state.currentWorkspace) {
        if (!payload.find((f) => f.id === state.currentWorkspace?.id)) {
          state.currentWorkspace = null;
        }
      }
      if (payload?.length > 0 && !state.currentWorkspace) {
        const defaultWorkspace = payload.find((f) => f.default);
        if (defaultWorkspace) {
          state.currentWorkspace = defaultWorkspace;
        } else {
          state.currentWorkspace = payload[0];
        }
      }
    },
    currentWorkspaceType(state: TenantState, payload: WorkspaceType) {
      if (state.currentWorkspace) {
        state.currentWorkspace.type = payload;
      }
    },
    settings(state: TenantState, payload: TenantDto) {
      if (state.current) {
        state.current.name = payload.name;
        state.current.subdomain = payload.subdomain;
      }
    },
  },
};
