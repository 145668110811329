



























































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { PeriodStatusDto } from "@/application/dtos/app/v3/documentation/PeriodStatusDto";
import { DocumentStatusDto } from "@/application/dtos/app/v3/documentation/DocumentStatusDto";
import { DocumentTypeDto } from "@/application/dtos/app/v3/documentation/DocumentTypeDto";
import { DocumentationType } from "@/application/enums/app/v3/documentation/DocumentationType";
import documentTypes from "@/application/dtos/app/v3/documentation/DocumentationTypes";
import { Periodicity } from "@/application/enums/app/common/Periodicity";
import pdf from "vue-pdf";
import DropDocument from "@/components/app/v3/contracts/uploads/DropDocument.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal2.vue";
import services from "@/services";
import { TranslateResult } from "vue-i18n";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  components: {
    pdf,
    DropDocument,
    ErrorModal,
    SuccessModal,
    Loading
  }
})
export default class DocumentView extends Vue {
  $refs!: {
    errorModalUploading: ErrorModal;
    successModalUploaded: SuccessModal;
  };
  @Prop({ default: false }) canDelete!: boolean;
  @Prop({ default: "sm:max-w-lg" }) maxSize!: string;
  period: PeriodStatusDto | null = null;
  document: DocumentStatusDto | null = null;
  documentTypes = documentTypes;
  title!: string;
  closeText!: TranslateResult | string;
  showing = false;
  errorDescription!: string;
  loadingUploading = false;

  //   uploadingDocumentPeriodicity: Periodicity = Periodicity.ONCE;
  uploadingDocumentType: DocumentationType | null = null;
  uploadingDocumentYear = 0;
  uploadingDocumentPeriod = 0;
  uploadingDocumentFileBase64 = "";
  uploadingDocumentFile: File | null = null;

  //   periodicities: Periodicity[] = [
  //     Periodicity.ONCE,
  //     Periodicity.MONTHLY,
  //     Periodicity.QUATERLY,
  //     Periodicity.YEARLY
  //   ];
  years: number[] = [];

  mounted() {
    for (let year = 2021; year <= new Date().getFullYear(); year++) {
      this.years.push(year);
    }
    this.closeText = this.$t("shared.close").toString();
  }
  show(document: DocumentStatusDto, period: PeriodStatusDto) {
    this.document = document;
    this.period = period;
    // this.uploadingDocumentPeriodicity = this.document.periodicity;
    this.uploadingDocumentType = this.document.documentType.type;
    this.uploadingDocumentYear = this.document.year;
    this.uploadingDocumentPeriod = this.document.period;
    this.uploadingDocumentFileBase64 = "";
    this.showing = true;

    console.log("document", this.document);
    if (this.document.documentation && this.document.documentation?.hasFile) {
      this.loadingUploading = true;
      services.documentation
        .downloadMyDocument(this.document.documentation.id)
        .then(file => {
          this.uploadingDocumentFileBase64 = file;
        })
        .catch(error => {
          this.$refs.errorModalUploading.show(this.$t(error));
        })
        .finally(() => {
          this.loadingUploading = false;
        });
    }
    this.$nextTick(() => {
      window.addEventListener("keyup", this.keyup);
    });
  }
  keyup(event: KeyboardEvent) {
    console.log(event.keyCode);
    if (event.keyCode === 27) {
      this.close();
    }
  }
  close() {
    this.$emit("closed");
    this.showing = false;
  }
  closeUpload() {
    this.close();
  }
  downloadCurrentDocument() {
    if (this.document?.documentation) {
      const downloadLink = document.createElement("a");
      downloadLink.href =
        "data:application/pdf;base64" + this.uploadingDocumentFileBase64;
      const fileName = `${this.document.year} - ${this.period?.periodName} - ${this.document.documentType.name}.pdf`;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }
  uploaded() {
    this.$emit("uploaded");
    this.close();
  }
  deleteDocument() {
    this.loadingUploading = true;
    services.documentation
      .delete(this.document?.documentation.id)
      .then(() => {
        this.$emit("deleted");
        this.close();
      })
      .catch(error => {
        this.$refs.errorModalUploading.show(this.$t(error));
      })
      .finally(() => {
        this.loadingUploading = false;
      });
  }
  get uploadingDocumentPeriodicity(): Periodicity {
    const doc = this.documentTypes.find(
      f => f.type === this.uploadingDocumentType
    );
    if (doc) {
      return doc.periodicity;
    }
    return Periodicity.ONCE;
  }
  get periodicities(): Periodicity[] {
    return [this.uploadingDocumentPeriodicity];
  }
  get existingDocument(): DocumentTypeDto | undefined {
    return this.document?.documentType;
  }
  get uploadingPeriodicityIsRecurring() {
    return this.uploadingDocumentPeriodicity !== Periodicity.ONCE;
  }
  get periods() {
    const periods: any[] = [];
    if (this.uploadingDocumentPeriodicity === Periodicity.MONTHLY) {
      for (let month = 1; month <= 12; month++) {
        periods.push({
          name: this.$t("app.shared.months." + month),
          value: month
        });
      }
    } else if (this.uploadingDocumentPeriodicity === Periodicity.BIMONTHLY) {
      for (let period = 1; period <= 6; period++) {
        periods.push({
          name: this.$t("app.shared.bimonthly." + period),
          value: period
        });
      }
    } else if (this.uploadingDocumentPeriodicity === Periodicity.QUATERLY) {
      for (let period = 1; period <= 3; period++) {
        periods.push({
          name: this.$t("app.shared.quarterly." + period),
          value: period
        });
      }
    } else if (this.uploadingDocumentPeriodicity === Periodicity.YEARLY) {
      for (let period = 1; period <= 1; period++) {
        periods.push({
          name: this.$t("app.shared.yearly." + period),
          value: period
        });
      }
    }
    return periods;
  }
}
