



































































































































import Vue from "vue";
import Component from "vue-class-component";
import NewModal from "@/components/shared/modals/NewModal.vue";
import services from "@/services";
import ErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import store from "@/store";
import { LinkDto } from "@/application/dtos/master/v3/links/LinkDto";
import { AppUsageType } from "../../../../application/enums/app/usages/AppUsageType";

@Component({
  components: {
    NewModal,
    ErrorModal,
    SuccessModal
  }
})
export default class LinkConfirmInvitation extends Vue {
  $refs!: {
    inputEmail: HTMLInputElement;
    errorModal: ErrorModal;
    successModal: SuccessModal;
    buttonCreate: HTMLButtonElement;
  };
  showing = false;
  loadingLinking = false;
  email = "";
  taxId = "";
  link: LinkDto | null = null;
  // permissions: LinkPermission[] = [];
  // arrPermissions: LinkPermission[];
  mounted() {
    // array.forEach(element => {
    // });
  }
  show(link: LinkDto) {
    this.link = link;

    this.email = link.createdByUser?.email ?? "";
    this.taxId = link.createdByWorkspace?.taxId ?? "";
    // this.permissions = link.permissions;

    if (process.env.VUE_APP_SERVICE === "sandbox") {
      this.taxId = "ABS12345XYZ";
      this.email = "user@email.com";
    }

    this.showing = true;
    // this.$nextTick(() => {
    //   this.$refs.inputEmail.focus();
    //   this.$refs.inputEmail.select();
    // });
  }
  close() {
    this.showing = false;
  }
  updateLink(accepted) {
    this.loadingLinking = true;
    services.links
      .acceptOrReject(this.link?.id, {
        accepted
        // permissions: []
      })
      .then(() => {
        this.close();
        services.tenants.getCurrentUsage(AppUsageType.PENDING_INVITATIONS);
        if (accepted) {
          // this.$refs.successModal.show(
          //   this.$t("app.links.created", [
          //     response.providerWorkspace.taxId,
          //     response.clientWorkspace.taxId
          //   ])
          // );
        } else {
          this.$refs.successModal.show(this.$t("app.links.rejected.rejected"));
        }
      })
      .catch(error => {
        this.$refs.errorModal.show(this.$t(error));
      })
      .finally(() => {
        this.loadingLinking = false;
      });
  }
  createdLink() {
    this.$emit("created");
    this.close();
  }
  get whoAmI() {
    const currentWorkspaceId = store.state.tenant.currentWorkspace?.id ?? "";
    if (currentWorkspaceId === this.link?.providerWorkspaceId) {
      return 0;
    }
    return 1;
  }
  get currentWorkspace(): WorkspaceDto {
    return store.state.tenant.currentWorkspace ?? ({} as WorkspaceDto);
  }
}
