
























































































































import Component from "vue-class-component";
import Vue from "vue";
import TenantSubscriptionPlan from "@/components/app/settings/tenant/subscription/TenantSubscriptionPlan.vue";
import TenantSubscriptionProducts from "@/components/app/settings/tenant/subscription/TenantSubscriptionProducts.vue";
import TenantSubscriptionPaymentDetails from "@/components/app/settings/tenant/subscription/TenantSubscriptionPaymentDetails.vue";
import TenantSubscriptionInvoices from "@/components/app/settings/tenant/subscription/TenantSubscriptionInvoices.vue";
import { SubscriptionPriceDto } from "@/application/dtos/master/subscriptions/SubscriptionPriceDto";
import { TenantProductDto } from "@/application/dtos/master/tenants/TenantProductDto";
import ErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import { SubscriptionBillingPeriod } from "@/application/enums/master/subscriptions/SubscriptionBillingPeriod";
import store from "@/store";
import { AppUsageType } from "../../../../../application/enums/app/usages/AppUsageType";
import services from "@/services";

@Component({
  metaInfo: {
    title: "Subscripción",
    titleTemplate: "%s | Délega"
  },
  components: {
    ConfirmModal,
    SuccessModal,
    ErrorModal,
    TenantSubscriptionPlan,
    TenantSubscriptionProducts,
    TenantSubscriptionPaymentDetails,
    TenantSubscriptionInvoices
  }
})
export default class TenantSubscription extends Vue {
  $refs!: {
    errorModal: ErrorModal;
    successModal: SuccessModal;
    confirmModal: ConfirmModal;
  };
  mounted() {
    services.subscriptionProducts.getProducts(true, true);
    services.tenants.getCurrentUsage(AppUsageType.ALL);
  }
  cancel() {
    this.$refs.confirmModal.show(
      this.$t("settings.tenant.subscription.confirmCancel")
    );
  }
  confirmCancel() {
    return services.subscriptionManager
      .cancelSubscription()
      .then(() => {
        this.$refs.successModal.show(
          this.$t("settings.tenant.subscription.canceled")
        );
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      });
  }
  get products(): TenantProductDto[] | undefined {
    return (
      (store.state.tenant?.subscription?.myProducts as TenantProductDto[]) ?? []
    );
  }
  get subscription(): SubscriptionPriceDto | undefined {
    const mySubscriptions = this.products?.filter(
      f => f.subscriptionPrice.billingPeriod !== SubscriptionBillingPeriod.ONCE
    );
    if (mySubscriptions && mySubscriptions.length > 0) {
      return mySubscriptions[0].subscriptionPrice;
    } else {
      return undefined;
    }
  }
}
