






























































































import Vue from "vue";
import Component from "vue-class-component";
import { i18n } from "@/plugins/vue-i18n";
import { TranslateResult } from "vue-i18n";

@Component
export default class ErrorModal extends Vue {
  $refs!: {
    closeButton: HTMLElement;
  };
  title!: string;
  closeText!: TranslateResult | string;
  showing = false;
  errorDescription!: string;
  errors: string[] = [];
  mounted() {
    this.title = this.$t("shared.error").toString();
    this.closeText = this.$t("shared.accept").toString();
  }
  show(error, closeText?: any) {
    console.log("showing error: " + error);
    if (error.length > 300 && process.env.NODE_ENV !== "production") {
      error = error.substring(0, 300);
    }
    if (
      error
        .toString()
        .trim()
        .toLowerCase() === "Network Error".toLowerCase()
    ) {
      error = i18n.t("shared.networkError").toString();
    }
    if (
      error
        .toString()
        .trim()
        .toLowerCase() === "System.TimeoutException".toLowerCase()
    ) {
      error = i18n.t("shared.timeout").toString();
    }

    if (closeText) {
      this.closeText = closeText;
    }
    if (error.toString().includes("<!DOCTYPE")) {
      error = "Tiempo de espera agotado";
    }
    this.errorDescription = error;
    this.showing = true;
    this.$nextTick(() => {
      if (this.$refs.closeButton) {
        this.$refs.closeButton.focus();
      }
    });
    // setTimeout(() => {
    //   window.addEventListener("keyup", this.keyup);
    // }, 1000);
  }
  close() {
    this.$emit("closed");
    this.showing = false;
  }
  showErrors(errors: string[]) {
    this.title = this.$t("shared.errors").toString();
    this.errors = errors;
    this.showing = true;
  }
  keyup(event: KeyboardEvent) {
    // console.log({ event, showing: this.showing });
    if (event.keyCode === 13) {
      this.close();
    }
  }
}
