












import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import EmptyStateSimple from "./EmptyStateSimple.vue";

@Component({
  components: {
    EmptyStateSimple
  }
})
export default class EmptyStates extends Vue {
  @Prop({ default: 0 }) type!: number;
  @Prop({}) icon!: string;
  @Prop({}) captions!: any;
  @Prop({}) to!: any;
}
