










import Vue from "vue";
import { Component } from "vue-property-decorator";
import AppLayout from "@/components/layouts/sidebar/SidebarLayout.vue";
@Component({
  components: { AppLayout }
})
export default class Admin extends Vue {}
