
<template>
  <div class="doodles-light">
    <div class="mx-auto max-w-screen-xl px-6 md:px-10">
      <div
        class="pt-6 lg:flex space-y-6 lg:space-y-0 lg:space-x-6 w-full text-center"
      >
        <div
          class="p-6 md:p-10 lg:w-1/2 rounded-xl bg-blueGray-100 dark:bg-blueGray-800 transform origin-center hover:scale-105 hover:bg-blueGray-200 dark:hover:bg-blueGray-900 transition duration-150 ease-in-out"
        >
          <div
            class="mx-auto flex items-center justify-center w-12 h-12 sm:w-16 sm:h-16 rounded-full text-6xl"
          >
            😞
          </div>
          <div class="flex justify-center">
            <div
              class="mt-3 bg-red-200 text-red-900 px-4 rounded-full font-medium text-sm sm:text-base leading-6 sm:leading-8"
            >
              Otras plataformas
            </div>
          </div>
          <h3
            class="pt-6 text-gray-500 dark:text-gray-400 text-lg md:text-2xl leading-6"
          >
            Aplicaciones incompletas que se enfocan sólo en cumplir con la
            NOM-151 y olvidan la colaboración Cliente-Proveedor.
          </h3>
        </div>
        <div
          class="p-6 md:p-10 lg:w-1/2 rounded-xl bg-blueGray-100 dark:bg-blueGray-800 transform origin-center hover:scale-105 hover:bg-blueGray-200 dark:hover:bg-blueGray-900 transition duration-150 ease-in-out"
        >
          <div
            class="mx-auto flex items-center justify-center w-12 h-12 sm:w-16 sm:h-16 rounded-full text-6xl"
          >
            😁
          </div>
          <div class="flex justify-center">
            <div
              class="mt-3 bg-teal-200 text-teal-900 px-4 rounded-full font-medium text-sm sm:text-base leading-6 sm:leading-8"
            >
              Usando Délega
            </div>
          </div>
          <h3
            class="pt-6 text-gray-500 dark:text-gray-400 text-lg md:text-2xl leading-6"
          >
            Gestión de cumplimiento completa de contratos desde la validación de
            proveedor hasta la verificación de colaboradores y CFDIs.
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>