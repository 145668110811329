






























































































































































































































































































































































































































































































































































































































































































import Component from "vue-class-component";
import Vue from "vue";
import WorkspacesWidget from "../../../components/app/widgets/workspaces/WorkspacesWidget.vue";
import store from "@/store";
import TenantSubscriptionProducts from "@/components/app/settings/tenant/subscription/TenantSubscriptionProducts.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import { ContractDto } from "@/application/dtos/app/v3/contracts/ContractDto";
import { AppUsageType } from "../../../application/enums/app/usages/AppUsageType";
import { AppUsageSummaryDto } from "@/application/contracts/app/tenants/AppUsageSummaryDto";
import ProvidersUsage from "@/components/app/usages/ProvidersUsage.vue";
import ClientsUsage from "@/components/app/usages/ClientsUsage.vue";
import EmployeesUsage from "@/components/app/usages/EmployeesUsage.vue";
import CfdisUsage from "@/components/app/usages/CfdisUsage.vue";
import services from "@/services";
import UserUtils from "@/utils/store/UserUtils";
import { UserDto } from "@/application/dtos/master/users/UserDto";
import Loading from "@/components/shared/loaders/Loading.vue";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import WarningBanner from "@/components/shared/banners/WarningBanner.vue";

@Component({
  metaInfo: {
    title: "Portal",
    titleTemplate: "%s | Délega"
  },
  components: {
    WorkspacesWidget,
    TenantSubscriptionProducts,
    ProvidersUsage,
    ClientsUsage,
    EmployeesUsage,
    CfdisUsage,
    Loading,
    WarningBanner
  }
})
export default class Dashboard extends Vue {
  loading = false;
  loadingSummary = false;
  pendingContracts: ContractDto[] = [];
  mounted() {
    this.load();
  }
  load() {
    services.tenants.getCurrentUsage(AppUsageType.CONTRACTS);
  }
  newProvider() {
    tinyEventBus().emitter.emit("new-provider");
  }
  newClient() {
    tinyEventBus().emitter.emit("new-client");
  }
  get showTips() {
    return store.state.app.showTips;
  }
  get workspacesWithoutFiscal(): WorkspaceDto[] {
    return store.state.tenant.workspaces.filter(f => !f.fiscalWorkspace);
  }
  get currentUser(): UserDto {
    return store.state.account.user ?? ({} as UserDto);
  }
  get avatarText() {
    return UserUtils.avatarText(this.currentUser);
  }
  get avatar() {
    return store.state.account.user?.avatar ?? "";
  }
  get usage(): AppUsageSummaryDto {
    return store.state.app.usage;
  }
}
