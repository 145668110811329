<template> </template>

<script>
import store from "@/store";
export default {
  mounted() {
    store.dispatch("auth/logout");
  }
};
</script>

<style>
</style>