

































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { PeriodStatusDto } from "@/application/dtos/app/v3/documentation/PeriodStatusDto";
import { DocumentStatusDto } from "@/application/dtos/app/v3/documentation/DocumentStatusDto";
import { DocumentTypeDto } from "@/application/dtos/app/v3/documentation/DocumentTypeDto";
import { DocumentationType } from "@/application/enums/app/v3/documentation/DocumentationType";
import documentTypes from "@/application/dtos/app/v3/documentation/DocumentationTypes";
import { Periodicity } from "@/application/enums/app/common/Periodicity";
import { FileBase64 } from "@/application/dtos/shared/FileBase64";
import pdf from "vue-pdf";
import DropDocument from "@/components/app/v3/contracts/uploads/DropDocument.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal2.vue";
import services from "@/services";
import { DocumentationInstitution } from "@/application/enums/app/v3/documentation/DocumentationInstitution";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import { TranslateResult } from "vue-i18n";
import Loading from "@/components/shared/loaders/Loading.vue";
import store from "@/store";

@Component({
  components: {
    pdf,
    DropDocument,
    ErrorModal,
    SuccessModal,
    ConfirmModal,
    Loading
  }
})
export default class DocumentUpload extends Vue {
  $refs!: {
    errorModalUploading: ErrorModal;
    // successModalUploaded: SuccessModal;
    confirmModal: ConfirmModal;
    confirmDeleteModal: ConfirmModal;
  };
  @Prop({ default: "sm:max-w-3xl" }) maxSize!: string;
  editing = false;
  period: PeriodStatusDto | null = null;
  document: DocumentStatusDto | null = null;
  documentTypes = documentTypes;
  title!: string;
  closeText!: TranslateResult | string;
  showing = false;
  errorDescription!: string;
  loadingUploading = false;
  year = 0;
  //   uploadingDocumentPeriodicity: Periodicity = Periodicity.ONCE;
  uploadingDocumentType: DocumentationType | null = null;
  uploadingDocumentYear = 0;
  uploadingDocumentPeriod = 0;
  uploadingDocumentFileBase64 = "";
  uploadingDocumentFile: File | null = null;

  //   periodicities: Periodicity[] = [
  //     Periodicity.ONCE,
  //     Periodicity.MONTHLY,
  //     Periodicity.QUATERLY,
  //     Periodicity.YEARLY
  //   ];
  // years: number[] = [];

  mounted() {
    // for (let year = 2021 - 1; year <= new Date().getFullYear(); year++) {
    //   this.years.push(year);
    // }
    this.closeText = this.$t("shared.close").toString();
  }
  upload(
    document?: DocumentStatusDto,
    period?: PeriodStatusDto,
    file?: string
  ) {
    this.editing = document?.documentation?.id;
    this.uploadingDocumentType = DocumentationType.SAT_COMPLIANCE;
    this.uploadingDocumentYear = new Date().getFullYear();
    this.uploadingDocumentPeriod = new Date().getMonth();
    this.uploadingDocumentFileBase64 = "";

    if (period) {
      this.uploadingDocumentYear = period.year;
      this.uploadingDocumentPeriod = period.period;
    }


    if (this.editing) {
      this.loadingUploading = true;
      services.documentation
        .downloadMyDocument(document?.documentation?.id)
        .then(file => {
          this.uploadingDocumentFileBase64 = file;
        })
        .catch(error => {
          this.$refs.errorModalUploading.show(this.$t(error));
        })
        .finally(() => {
          this.loadingUploading = false;
        });
    }

    this.year = new Date().getFullYear();
    if (document && period) {
      this.document = document;
      this.period = period;
      this.year = period.year;
      this.uploadingDocumentType =
        this.document?.documentType.type ?? DocumentationType.SAT_COMPLIANCE;
      this.uploadingDocumentPeriod = period.period;
      this.uploadingDocumentYear = period.year;
    }
    if (this.uploadingDocumentPeriodicity === Periodicity.YEARLY) {
      // this.uploadingDocumentYear = this.uploadingDocumentYear - 1;
    }

    console.log({
      year: this.uploadingDocumentYear,
      period,
      document
    })
    this.showing = true;
    this.$nextTick(() => {
      window.addEventListener("keyup", this.keyup);
    });
    if (file) {
      this.uploadingDocumentFileBase64 = file;
      this.$nextTick(() => {
        this.saveNewDocument();
      });
    }
  }
  keyup(event: KeyboardEvent) {
    console.log(event.keyCode);
    if (event.keyCode === 27) {
      this.close();
    }
  }
  close() {
    this.$emit("closed");
    this.showing = false;
  }
  deleteDocument() {
    this.$refs.confirmDeleteModal.show("¿Eliminar documento?", "Eliminar", "Cancelar", "ADVERTENCIA: No se podrá recuperar.");
  }
  confirmedDelete() {
    this.loadingUploading = true;
    services.documentation
      .delete(this.document?.documentation.id)
      .then(() => {
        this.$emit("deleted");
        this.close();
      })
      .catch(error => {
        this.$refs.errorModalUploading.show(this.$t(error));
      })
      .finally(() => {
        this.loadingUploading = false;
      });
  }
  saveNewDocument() {
    const documentType = this.documentTypes.find(
      f => f.type === this.uploadingDocumentType
    );
    if (documentType && this.uploadingDocumentFileBase64) {
      console.log("this.periods", this.periods)
      console.log("this.uploadingDocumentPeriod", this.uploadingDocumentPeriod)
      const periodName =
        this.uploadingDocumentYear +
        " de " +
        this.periods[this.uploadingDocumentPeriod - 1].name;
      const inPeriod =
        documentType.periodicity !== Periodicity.ONCE ? " " + periodName : "";
      this.$refs.confirmModal.show(
        this.editing ? "¿Actualizar documento?" : "¿Subir documento?",
        "Aceptar",
        "Cancelar",
        `Documento: ${documentType.name}${inPeriod}`
      );
    }
  }
  confirmedUpdate() {
    const documentType = this.documentTypes.find(
      f => f.type === this.uploadingDocumentType
    );
    if (documentType && this.uploadingDocumentFileBase64) {
      this.loadingUploading = true;
      services.documentation
        .updateDocumentPeriod(this.document?.documentation.id, {
          year: this.uploadingDocumentYear,
          period: this.uploadingDocumentPeriod,
        })
        .then(() => {
          this.$emit("updated")
          this.close();
          // this.$refs.successModalUploaded.show(
          //   "Documento subido",
          //   "Se ha subido correctamente el documento: " + documentType.name
          // );
        })
        .catch(error => {
          this.$refs.errorModalUploading.show("Error", this.$t(error));
        })
        .finally(() => {
          this.loadingUploading = false;
        });
    }
  }
  confirmedSaveOrUpdate() {
    if (this.editing) {
      this.confirmedUpdate();
    } else {
      this.confirmedSave();
    }
  }
  confirmedSave() {
    const documentType = this.documentTypes.find(
      f => f.type === this.uploadingDocumentType
    );
    if (documentType && this.uploadingDocumentFileBase64) {
      this.loadingUploading = true;
      services.documentation
        .create({
          year: this.uploadingDocumentYear,
          period: this.uploadingDocumentPeriod,
          type: documentType.type,
          file: this.uploadingDocumentFileBase64,
          isPublic: false
        })
        .then(() => {
          this.uploaded();
          // this.$refs.successModalUploaded.show(
          //   "Documento subido",
          //   "Se ha subido correctamente el documento: " + documentType.name
          // );
        })
        .catch(error => {
          this.$refs.errorModalUploading.show("Error", this.$t(error));
        })
        .finally(() => {
          this.loadingUploading = false;
        });
    }
  }
  droppedDocumentFile(files: FileBase64[]) {
    console.log("droppedfile");
    if (files.length > 0) {
      this.uploadingDocumentFileBase64 = files[0].base64;
      this.uploadingDocumentFile = files[0].file;
    }
  }
  closeUpload() {
    this.close();
  }
  downloadCurrentDocument() {
    if (this.document?.documentation) {
      const downloadLink = document.createElement("a");
      const extension = this.uploadingDocumentFileBase64.startsWith('data:application/zip') ? 'zip' : 'pdf';
      downloadLink.href =
        `data:application/${extension};base64${this.uploadingDocumentFileBase64}`;
      const fileName = `${this.document.year} - ${this.period?.periodName} - ${this.document.documentType.name}.${extension}`;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }
  uploaded() {
    this.$emit("uploaded");
    this.close();
  }
  institutionName(institution: DocumentationInstitution) {
    return DocumentationInstitution[institution];
  }
  get isCurrentWorkspace() {
    return store.state.tenant.currentWorkspace?.id === this.document?.documentation.workspaceId;
  }
  get years() {
    if (this.uploadingDocumentPeriodicity === Periodicity.ONCE) {
      return [0];
    }
    // else if (this.uploadingDocumentPeriodicity === Periodicity.YEARLY) {
    //   return [new Date().getFullYear(), new Date().getFullYear() - 1];
    // } else {
    //   return [new Date().getFullYear(), new Date().getFullYear() - 1];
    // }
    const allYears: number[] = [];
    for (let year = 2021 - 1; year <= new Date().getFullYear(); year++) {
      allYears.push(year);
    }
    return allYears;
  }
  // get uploadingDocumentYear() {
  //   const year = new Date().getFullYear();
  //   if (this.uploadingDocumentPeriodicity === Periodicity.YEARLY) {
  //     return year - 1;
  //   }
  //   return year;
  // }
  get uploadingDocumentPeriodicity(): Periodicity {
    let periodicity = Periodicity.ONCE;
    const doc = this.documentTypes.find(
      f => f.type === this.uploadingDocumentType
    );
    if (doc) {
      periodicity = doc.periodicity;
    }
    // this.uploadingDocumentYear = new Date().getFullYear();
    // if (this.document) {
    //   this.uploadingDocumentYear = this.document.year;
    // }
    // if (periodicity === Periodicity.YEARLY) {
    //   this.uploadingDocumentYear = new Date().getFullYear() - 1;
    // }
    return periodicity;
  }
  get periodicities(): Periodicity[] {
    return [this.uploadingDocumentPeriodicity];
  }
  get existingDocument(): DocumentTypeDto | undefined {
    return this.document?.documentType;
  }
  get uploadingPeriodicityIsRecurring() {
    return this.uploadingDocumentPeriodicity !== Periodicity.ONCE;
  }
  get uploadingPeriodicityIsYearly() {
    return this.uploadingDocumentPeriodicity === Periodicity.YEARLY;
  }
  get periods() {
    const periods: any[] = [];
    if (this.uploadingDocumentPeriodicity === Periodicity.MONTHLY) {
      for (let month = 1; month <= 12; month++) {
        periods.push({
          name: this.$t("app.shared.months." + month),
          value: month
        });
      }
    } else if (this.uploadingDocumentPeriodicity === Periodicity.BIMONTHLY) {
      for (let period = 1; period <= 6; period++) {
        periods.push({
          name: this.$t("app.shared.bimonthly." + period),
          value: period
        });
      }
    } else if (this.uploadingDocumentPeriodicity === Periodicity.QUATERLY) {
      for (let period = 1; period <= 3; period++) {
        periods.push({
          name: this.$t("app.shared.quarterly." + period),
          value: period
        });
      }
    } else if (this.uploadingDocumentPeriodicity === Periodicity.YEARLY) {
      for (let period = 1; period <= 1; period++) {
        periods.push({
          name: this.$t("app.shared.yearly." + period),
          value: period
        });
      }
    } else {
      return [{
        name: this.$t("app.shared.periodicity.ONCE"),
        value: 1
      }];
    }
    return periods;
  }
}
