

























































































import Vue from "vue";
import Component from "vue-class-component";
import { TranslateResult } from "vue-i18n";

@Component
export default class SuccessModal extends Vue {
  $refs!: {
    buttonClose: HTMLButtonElement;
  };
  title!: string;
  closeText!: TranslateResult | string;
  showing = false;
  successDescription!: string;
  subtitle!: string;
  warnings: string[] = [];
  mounted() {
    this.title = this.$t("shared.success").toString();
    this.closeText = this.$t("shared.close").toString();
  }
  show(description: any, subtitle?: any, warnings?: string[]) {
    this.successDescription = description;
    this.subtitle = subtitle;
    this.showing = true;
    if (warnings) {
      this.warnings = warnings;
    }
    // setTimeout(() => {
    //   window.addEventListener("keyup", this.keyup);
    // }, 1000);
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.$refs.buttonClose) {
          this.$refs.buttonClose.focus();
        }
      }, 1000);
    });
  }
  close() {
    this.$emit("closed");
    this.showing = false;
  }
  keyup(event: KeyboardEvent) {
    // console.log({ event, showing: this.showing });
    if (event.keyCode === 13) {
      this.close();
    }
  }
}
