














































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { TranslateResult } from "vue-i18n";

@Component
export default class NewModal extends Vue {
  @Prop({ default: "sm:max-w-lg" }) maxSize!: string;
  title!: string;
  closeText!: TranslateResult | string;
  showing = false;
  errorDescription!: string;
  mounted() {
    this.closeText = this.$t("shared.close").toString();
  }
  show(title = "", closeText = "") {
    this.title = title;
    if (closeText !== "") {
      this.closeText = closeText;
    }
    this.showing = true;

    this.$nextTick(() => {
      window.addEventListener("keyup", this.keyup);
    });
  }
  keyup(event: KeyboardEvent) {
    console.log(event.keyCode);
    if (event.keyCode === 27) {
      this.close();
    }
  }
  close() {
    this.$emit("closed");
    this.showing = false;
  }
}
