/* eslint-disable */
import { CreateEmployeesRequest } from "@/application/contracts/app/v3/employees/CreateEmployeesRequest";
import { UpdateEmployeeRequest } from "@/application/contracts/app/v3/employees/UpdateEmployeeRequest";
import { EmployeeDto } from "@/application/dtos/app/v3/employees/EmployeeDto";
import { i18n } from "@/plugins/vue-i18n";
import { FakeUserService } from "../../../master/users/FakeUserService";
import FakePdfBase64 from "../contracts/FakePdfBase64";
import FakeXmlBase64 from "../contracts/FakeXmlBase64";
import { IEmployeeService } from "./IEmployeeService";

const fakeUserService = new FakeUserService();

const fakeEmployees = [
  { fullName: "Gabriel Rodríguez Robles", rfc: "GRRD010190X23", curp: "GRR010190XYZXYZ0", nss: "5434567890", email: "grabiel@company.com" },
  { fullName: "María Guadalupe Ruíz", rfc: "MGRD010190X23", curp: "MGRD010190XYZXYZ0", nss: "6584567890", email: "maria@company.com" },
  { fullName: "Serratos Contreras García", rfc: "SCGD010190X23", curp: "SCGD010190XYZXYZ0", nss: "9804567890", email: "serratos@company.com" },
  { fullName: "David Castro Lopez", rfc: "DCLD010190X23", curp: "DCLD010190XYZXYZ0", nss: "3264567890", email: "david@company.com" },
  { fullName: "Gabriela Álvarez Aguilar", rfc: "GAAD010190X23", curp: "GAAD010190XYZXYZ0", nss: "6434567890", email: "grabiela@company.com" },
  { fullName: "Alejandra Gómez Carrasco", rfc: "AGCD010190X23", curp: "AGCD010190XYZXYZ0", nss: "2584567890", email: "alejandra@company.com" },
  { fullName: "Martha Contreras Martínez", rfc: "AGCD010190X23", curp: "AGCD010190XYZXYZ0", nss: "5284567890", email: "martha@company.com" },
  { fullName: "Rubén Chávez Contreras", rfc: "RCCD010190X23", curp: "RCCD010190XYZXYZ0", nss: "8544567890", email: "ruben@company.com" },
  { fullName: "Juan Aguilar Rodríguez", rfc: "JARD010190X23", curp: "JARD010190XYZXYZ0", nss: "8874567890", email: "juan@company.com" },
  { fullName: "Regina Guzmán Jimenez", rfc: "RGJD010190X23", curp: "RGJD010190XYZXYZ0", nss: "1214567890", email: "regina@company.com" },
];
const employees: EmployeeDto[] = [];
for (let index = 1; index <= 10; index++) {
  const employee: EmployeeDto = {
    createdByUserId: fakeUserService.users[0].id,
    createdByUser: fakeUserService.users[0],
    createdAt: new Date(),
    id: (index + 300).toString(),
    firstName: fakeEmployees[index - 1].fullName.split(" ")[0],
    lastName1: fakeEmployees[index - 1].fullName.split(" ")[1],
    lastName2: fakeEmployees[index - 1].fullName.split(" ")[2],
    email: fakeEmployees[index - 1].email,
    taxId: fakeEmployees[index - 1].rfc,
    socialSecurityId: fakeEmployees[index - 1].nss,
    populationRegistryId: fakeEmployees[index - 1].curp,
    cfdis: [],
  };
  employees.push(employee);
  employee.cfdis = [
    {
      id: index + 1 + "",
      version: "3.3",
      fecha: "2021-09-25T12:00:00",
      sello: "",
      noCertificado: "",
      certificado: "",
      subTotal: (index + 1) * (index + 1),
      moneda: "",
      total: (index + 1) * (index + 1) * 1.16,
      tipoDeComprobante: "N",
      lugarExpedicion: "",
      emisorRfc: "COMPANY 1 LEGAL NAME",
      emisorNombre: "ABS12345XYZ",
      emisorRegimenFiscal: "",
      receptorRfc: employee.taxId,
      receptorNombre: (employee.firstName + " " + employee.lastName1 + " " + employee.lastName2).trim(),
      receptorResidenciaFiscal: "",
      receptorNumRegIdTrib: "",
      receptorUsoCfdi: "P01",
      serie: "",
      folio: "",
      formaPago: "",
      condicionesDePago: "",
      descuento: 0,
      tipoCambio: "",
      metodoPago: "",
      confirmacion: "",
      xmlText: "",
      xml: "data:application/xml;base64," + FakeXmlBase64,
      pdf: "data:application/pdf;base64," + FakePdfBase64,
      fileName: "",
      cfdisRelacionados: [],
      conceptos: [],
      impuestos: "",
      complemento: "",
      addenda: "",
      hasXml: false,
      hasPdf: false,
      employeeId: employee.id,
      employee,
      paymentDate: new Date(),
      startPeriod: new Date(),
      endPeriod: new Date(),
      riskPrime: "",
    },
  ];
}

export class FakeEmployeeService implements IEmployeeService {
  employees: EmployeeDto[] = employees;
  getAll(): Promise<EmployeeDto[]> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.employees);
      }, 500);
    });
  }
  get(id: string): Promise<EmployeeDto> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const employee = this.employees.find((f) => f.id === id);
        if (employee) {
          resolve(employee);
        } else {
          reject(i18n.t("shared.notFound").toString());
        }
      }, 500);
    });
  }
  create(data: EmployeeDto): Promise<EmployeeDto> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.employees.push(data);
      }, 500);
    });
  }
  createMultiple(data: CreateEmployeesRequest): Promise<EmployeeDto[]> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        data.employees.forEach((element) => {
          this.employees.push(element);
        });
      }, 500);
    });
  }
  // download(id: string): Promise<any> {
  //   throw new Error("Method not implemented.");
  // }
  update(id: string, data: UpdateEmployeeRequest): Promise<EmployeeDto> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const item = this.employees.find((f) => f.id?.toString() === id);
        if (item) {
          item.firstName = data.firstName;
          item.lastName1 = data.lastName1;
          item.lastName2 = data.lastName2;
          item.taxId = data.taxId;
          item.socialSecurityId = data.socialSecurityId;
          item.populationRegistryId = data.populationRegistryId;
          item.email = data.email;
          resolve(item);
        } else {
          reject(i18n.t("shared.notFound").toString());
        }
      }, 500);
    });
  }
  delete(id: string): Promise<any> {
    throw new Error("Method not implemented.");
  }
}
