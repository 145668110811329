




















































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import services from "@/services";
import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal2.vue";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import SelectWorkspaces from "@/components/app/workspaces/SelectWorkspaces.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import store from "@/store";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import { TenantUserDto } from "@/application/dtos/master/tenants/TenantUserDto";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  components: {
    ErrorModal,
    SuccessModal,
    SelectWorkspaces,
    ConfirmModal,
    Loading
  }
})
export default class EditMember extends Vue {
  @Prop({ default: "sm:max-w-lg" }) maxSize!: string;
  $refs!: {
    errorModal: ErrorModal;
    successModal: SuccessModal;
    selectWorkspaces: SelectWorkspaces;
    confirmRemove: ConfirmModal;
  };
  id = "";
  item: TenantUserDto | null = null;
  loading = false;
  email = "";
  firstName = "";
  lastName = "";
  phone = "";
  companyRole = "";
  role: TenantUserRole = TenantUserRole.MEMBER;
  workspaces: WorkspaceDto[] = [];
  created() {
    this.id = this.$route.params.id;
  }
  mounted() {
    this.loading = true;
    services.tenantUsers
      .get(this.id)
      .then(response => {
        this.item = response;
        this.email = response.email;
        this.firstName = response.firstName;
        this.lastName = response.lastName;
        this.role = response.role;
        this.phone = response.phone;
        this.companyRole = response.companyRole;
        this.workspaces = [];
        response.user?.workspaces?.forEach(element => {
          if (element.workspace) {
            this.workspaces.push(element.workspace);
          }
        });
      })
      .catch(error => {
        this.$refs.errorModal.show("Error", this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  keyup(event: KeyboardEvent) {
    console.log(event.keyCode);
    if (event.keyCode === 27) {
      this.close();
    }
  }
  close() {
    this.$router.push({ name: "settings.tenant.members" });
  }
  save() {
    if (this.workspaces.length === 0) {
      this.$refs.errorModal.show(
        "Error",
        this.$t("account.tenant.members.errors.atLeastOneWorkspace")
      );
      return;
    }
    this.loading = true;
    services.tenantUsers
      .update(this.id, {
        phone: this.phone,
        role: this.role,
        workspaces: this.workspaces,
        companyRole: this.companyRole
      })
      .then(() => {
        tinyEventBus().emitter.emit("user-saved", this.item);
        this.$refs.successModal.show(
          "Usuario actualizado",
          "Se ha actualizado correctamente."
        );
      })
      .catch(error => {
        this.$refs.errorModal.show("Error", this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  remove() {
    this.$refs.confirmRemove.show(
      this.$t("shared.delete?"),
      "Eliminar",
      "Cancelar",
      "ADVERTENCIA: El usuario ya no podrá acceder a la empresa."
    );
  }
  yesRemove() {
    if (
      this.currentRole === TenantUserRole.MEMBER ||
      this.currentRole === TenantUserRole.GUEST
    ) {
      this.$refs.errorModal.show(this.$t("account.tenant.onlyAdmin"));
    } else {
      this.loading = true;
      services.tenantUsers
        .delete(this.id)
        .then(() => {
          tinyEventBus().emitter.emit("user-deleted", this.item);
          this.$router.push({ name: "settings.tenant.members" });
        })
        .catch(error => {
          this.$refs.errorModal.show(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  selectWorkspaces() {
    this.$refs.selectWorkspaces.show(this.workspaces.map(f => f.id));
  }
  selectedWorkspaces(items: WorkspaceDto[]) {
    this.workspaces = items;
  }
  changedRole(e) {
    const role: TenantUserRole = Number(e.target.value);
    this.role = role;
  }
  get currentRole(): TenantUserRole {
    return store.state.tenant.current?.currentUser.role ?? TenantUserRole.GUEST;
  }
  get currentWorkspacesDescription() {
    if (this.workspaces.length === 0) {
      return "No has seleccionado empresas del usuario";
    }
    return this.workspaces.map(f => f.taxId).join(", ");
  }
  get maxUsers(): number {
    if (store.state.account.user?.type === 0) {
      return 0;
    }
    return store.state.app.features.maxUsers;
  }
  get maxUsersReached() {
    if (
      this.maxUsers > 0 &&
      store.state.tenant.members.length >= this.maxUsers
    ) {
      return true;
    }
    return false;
  }
}
