
































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { i18n } from "@/plugins/vue-i18n";
import store from "@/store";
import { DocumentTypeDto } from "@/application/dtos/app/v3/documentation/DocumentTypeDto";
import documentTypes from "@/application/dtos/app/v3/documentation/DocumentationTypes";
import { DocumentationDto } from "@/application/dtos/app/v3/documentation/DocumentationDto";
import Modal from "@/components/shared/modals/NewModal.vue";
import { LinkDto } from "@/application/dtos/master/v3/links/LinkDto";
import months from "@/application/dtos/shared/Months";
import { Month } from "@/application/dtos/shared/Month";
import DropdownSimple from "@/components/shared/dropdowns/DropdownSimple.vue";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import { Periodicity } from "@/application/enums/app/common/Periodicity";
import { ComplianceStatus } from "@/application/enums/app/v3/documentation/ComplianceStatus";
import { PeriodStatusDto } from "@/application/dtos/app/v3/documentation/PeriodStatusDto";
import { LinkStatus } from "@/application/enums/app/v3/links/LinkStatus";
import { DocumentationStatusFilter } from "@/application/contracts/app/v3/documentation/DocumentationStatusFilter";
import DateUtils from "@/utils/shared/DateUtils";
import { DocumentationType } from "@/application/enums/app/v3/documentation/DocumentationType";
import { DocumentStatusDto } from "@/application/dtos/app/v3/documentation/DocumentStatusDto";
import { link } from "fs";
import { DocumentationInstitution } from "@/application/enums/app/v3/documentation/DocumentationInstitution";
import DocumentUpload from "../../../documentation/DocumentUpload.vue";
import SideDocumentUpload from "../../../documentation/SideDocumentUpload.vue";
import services from "@/services";

@Component({
  components: {
    // Icon,
    Modal,
    DropdownSimple,
    EmptyState,
    DocumentUpload,
    SideDocumentUpload
  }
})
export default class ProvidersListAndTable extends Vue {
  @Prop({}) year!: number;
  @Prop({}) items!: LinkDto[];
  sortByColumn = "status";
  sortDirection = -1;
  headers: any[] = [
    // {
    //   title: i18n.t("app.providers.visits")
    // }
  ];
  $refs!: {
    sideUploadDocument: SideDocumentUpload;
    uploadDocument: DocumentUpload;
    modalDocuments: Modal;
    dropdownOptionsDocs: DropdownSimple;
  };
  documentTypes: DocumentTypeDto[] = [];
  selectedProvider: LinkDto | null = null;
  showingDocuments = false;
  months: Month[] = [];
  showingDocument: DocumentStatusDto | undefined = undefined;
  mounted() {
    this.months = months;
    this.documentTypes = documentTypes;
    this.headers.push({
      // name: "legalName",
      title: i18n.t("models.provider.legalName")
    });
    this.headers.push({
      title: i18n.t("models.contract.plural")
    });
    this.headers.push({
      title: i18n.t("models.documentation.object")
    });
    this.headers.push({
      title: "Ene"
    });
    this.headers.push({
      title: "Feb"
    });
    this.headers.push({
      title: "Mar"
    });
    this.headers.push({
      title: "Abr"
    });
    this.headers.push({
      title: "May"
    });
    this.headers.push({
      title: "Jun"
    });
    this.headers.push({
      title: "Jul"
    });
    this.headers.push({
      title: "Ago"
    });
    this.headers.push({
      title: "Sep"
    });
    this.headers.push({
      title: "Oct"
    });
    this.headers.push({
      title: "Nov"
    });
    this.headers.push({
      title: "Dic"
    });
  }
  sortBy(column) {
    if (column) {
      this.sortDirection = this.sortDirection === -1 ? 1 : -1;
      this.sortByColumn = column;
    }
  }
  currentWorkspaceTaxIdIs(taxId) {
    return store.state.tenant.currentWorkspace?.taxId === taxId;
  }
  viewDocuments(provider: LinkDto) {
    this.selectedProvider = provider;
    this.documentTypes = documentTypes;
    this.documentTypes.forEach(element => {
      element.document = this.selectedProvider?.providerWorkspace.documentation.find(
        f => f.type === element.type
      );
    });
    // this.documentTypes = this.documentTypes.sort((x, y) => {
    //   if (x.document?.file?.length && y.document?.file?.length) {
    //     return (
    //       (x.document?.file?.length > y.document?.file?.length ? 1 : -1) ?? 1
    //     );
    //   }
    //   return 1;
    // });
    this.$refs.modalDocuments.show();
  }
  download(provider: LinkDto, documentation: DocumentationDto | undefined) {
    alert("not implemented");
    if (documentation) {
      // const downloadLink = document.createElement("a");
      // const documentName = this.$t(
      //   "models.documentation." + DocumentationType[documentation.type]
      // );
      // tslint:disable-next-line: max-line-length
      // const fileName = `${provider.providerWorkspace.taxId} - ${documentation.year}-${documentation.period} - ${documentName}.pdf`;
      // downloadLink.href = "data:application/pdf;base64" + documentation.file;
      // downloadLink.download = fileName;
      // downloadLink.click();
    }
  }
  activeMonth(month: Month) {
    const today = new Date();
    if (this.year === 2021 && month.value < 9) {
      return false;
    }
    if (today.getMonth() + 1 < month.value) {
      return false;
    }
    return true;
  }
  getDocuments(provider: LinkDto, month: Month): PeriodStatusDto {
    return (
      provider?.documentation?.periods.find(
        f => f.periodicity === 1 && f.period === month.value
      ) ?? ({} as PeriodStatusDto)
    );
  }
  getTotalDocuments(provider: LinkDto, month: Month): DocumentStatusDto[] {
    const period: PeriodStatusDto = (
      provider?.documentation?.periods.find(
        f => f.periodicity === 1 && f.period === month.value
      ) ?? ({} as PeriodStatusDto)
    );
    if (provider.providerWorkspace.taxId === 'FTE9905255Y1') {
      if (month.value === 12) {
        console.log('FTE9905255Y1', period)
      }
    }
    return period.documents.filter(f => f.documentation?.hasFile)
  }

  monthStatus(provider: LinkDto, month: Month) {
    const period = provider.documentation?.periods.find(
      f => f.periodicity === Periodicity.MONTHLY && f.period === month.value
    );
    return period?.status ?? ComplianceStatus.NotRequired;
  }
  getStatus(provider: LinkDto) {
    if (provider.documentation) {
      return this.$t("app.documentation.status." + ComplianceStatus[provider.documentation.status]);
    }
  }
  newProvider() {
    tinyEventBus().emitter.emit("new-provider");
  }
  currentMonth(month: Month) {
    if (this.items.length > 0 && this.items[0].documentation?.periods) {
      const currentMonthlyPeriods = this.items[0].documentation?.periods.filter(
        f => f.periodicity === Periodicity.MONTHLY && f.isCurrentPeriod
      );
      if (
        currentMonthlyPeriods.length > 0 &&
        currentMonthlyPeriods[0].period === month.value
      ) {
        return true;
      }
    }
  }
  dateAgo(value) {
    return DateUtils.dateAgo(value);
  }
  dateDM(value) {
    return DateUtils.dateCustom(value, "D MMMM, yyyy");
  }
  getDocumentName(document: DocumentationDto) {
    return this.$t("models.documentation." + DocumentationType[document.type])
  }
  getPeriodName(document: DocumentationDto) {
    if (document.periodicity === Periodicity.ONCE) {
      return "";
    } else if (document.periodicity === Periodicity.YEARLY) {
      return document.year;
    } else {
      return document.year + "-" + document.period;
    }
  }
  institutionName(document: DocumentStatusDto) {
    return DocumentationInstitution[document.documentType.institution];
  }
  viewDocument(document: DocumentStatusDto) {
    // this.showingDocument = document;
    this.$refs.uploadDocument.upload(document)
  }
  downloadAll(link: LinkDto) {
    services.documentation
      .downloadAll(link.id, this.year)
      .then(response => {
        this.downloadData(
          `${this.year} - ${link.providerWorkspace.legalName}.zip`,
          response
        );
      })
  }
  downloadData(fileName, response) {
    const type = "application/zip";
    const fileUrl = window.URL.createObjectURL(
      new Blob([response.data], { type })
    );
    const fileLink = document.createElement("a");
    fileLink.href = fileUrl;
    fileLink.setAttribute("download", fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
  }
  get currentWorkspaceId(): string {
    return store.state.tenant.currentWorkspace?.id ?? "";
  }
  get sortedItems(): LinkDto[] {
    // const column = this.sortByColumn;
    const sortDirection = this.sortDirection;
    // if (!column || column === "") {
    //   return this.items;
    // }
    return this.items.sort((x, y) => {
      if (x.lastDocument?.createdAt && y.lastDocument?.createdAt) {
        return (x.lastDocument?.createdAt > y.lastDocument?.createdAt ? -1 : 1) ?? -1;
      }
      return -1;
    });
    // return this.items.sort((x, y) => {
    //   if (x.providerWorkspace.taxId && y.providerWorkspace.taxId) {
    //     if (sortDirection === -1) {
    //       return (x.providerWorkspace.taxId > y.providerWorkspace.taxId ? 1 : -1) ?? 1;
    //     } else {
    //       return (x.providerWorkspace.taxId < y.providerWorkspace.taxId ? 1 : -1) ?? 1;
    //     }
    //   }
    //   return 1;
    // });
  }
}
