



















































































































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import Tabs, { TabItem } from "@/components/shared/tabs/Tabs.vue";
import CfdisList from "@/components/app/v3/cfdis/list/CfdisList.vue";
import ButtonSecondary from "@/components/shared/buttons/ButtonSecondary.vue";
import store from "@/store";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import services from "@/services";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  metaInfo: {
    title: "CFDIs",
    titleTemplate: "%s | Délega"
  },
  components: {
    Breadcrumb,
    Tabs,
    CfdisList,
    ButtonSecondary,
    ConfirmModal,
    Loading
  }
})
export default class Cfdis extends Vue {
  $refs!: {
    confirmModalError: ConfirmModal;
    cfdiList: CfdisList;
  };
  tabs: TabItem[] = [];
  loading = false;
  mounted() {
    this.tabs = [
      {
        name: this.$t("app.cfdis.types.payroll"),
        routeName: "app.cfdis.payroll"
      },
      {
        name: this.$t("app.cfdis.sync.title"),
        routeName: "app.cfdis.sync"
      }
    ];
  }
  syncCfdis() {
    if (!store.state.tenant.currentWorkspace?.fiscalWorkspace) {
      this.$refs.confirmModalError.show(
        "Error",
        "Definir ahora",
        "Atrás",
        "No tienes credenciales del SAT definidas."
      );
    } else {
      this.sync();
    }
  }
  sync() {
    this.loading = true;
    services.cfdis
      .sync()
      .then(() => {
        this.$refs.cfdiList.reload();
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  defineCredentials() {
    this.$router.push({
      name: "settings.tenant.workspaces.credentials.new",
      params: { id: store.state.tenant.currentWorkspace?.id }
    });
  }
}
