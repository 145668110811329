import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import createLogger from "vuex/dist/logger";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

// Modules
import { locale } from "./modules/locale";
import { account } from "./modules/account";
import { auth } from "./modules/auth";
import { tenant } from "./modules/tenant";
import { pricing } from "./modules/pricing";
import { theme } from "./modules/theme";
import { app } from "./modules/app";
import { RootState } from "@/store/types";

const debug = process.env.NODE_ENV !== "production";

const store: StoreOptions<RootState> = {
  modules: {
    locale,
    account,
    auth,
    tenant,
    pricing,
    theme,
    app,
  },
  strict: debug,
  plugins: debug ? [createPersistedState(), createLogger()] : [createPersistedState()],
};

export default new Vuex.Store<RootState>(store);
