





















import Vue from "vue";
import Component from "vue-class-component";
import SideModal from "../modals/SideModal.vue";
import { Prop } from "vue-property-decorator";
// @ts-ignore
import PictureInput from "vue-picture-input";

@Component({
  components: {
    PictureInput,
    SideModal
  }
})
export default class UploadImage extends Vue {
  @Prop()
  title!: string;
  @Prop()
  image!: string;
  customStrings = {} as any;
  mounted() {
    // @ts-ignore
    this.$refs.pictureInput.image = this.image;
    this.customStrings = {
      tap: "Clic aquí para seleleccionar de tu galería",
      change: "Clic aquí para seleleccionar de tu galería",
      remove: "Quitar foto",
      select: "Selecciona una foto",
      selected: "<p>Foto agregada</p>",
      fileSize: "El tamaño de la foto excede el límite",
      fileType: "Este tipo de archivo no es soportado.",
      aspect: "Horizontal/Vertical",
      upload: "<h1>Imágenes</h1>",
      drag:
        "<p class='text-gray-700 text-base'>Arrastra una imagen</p><p class='text-sm'>o clic aquí para seleccionar</p>"
    };
  }
  get getStrings() {
    return {
      tap: "Clic aquí para seleleccionar de tu galería",
      change: "Clic aquí para seleleccionar de tu galería",
      remove: "Quitar foto",
      select: "Selecciona una foto",
      selected: "<p>Foto agregada</p>",
      fileSize: "El tamaño de la foto excede el límite",
      fileType: "Este tipo de archivo no es soportado.",
      aspect: "Horizontal/Vertical",
      upload: "<h1>Imágenes</h1>",
      drag:
        "<p class='text-gray-700 text-base'>Arrastra una imagen</p><p class='text-sm'>o clic aquí para seleccionar</p>"
    };
  }
  onChange() {
    if (this.loadedImage) {
      this.$emit("loaded", this.loadedImage);
    }
  }
  get loadedImage() {
    // @ts-ignore
    return this.$refs.pictureInput.image;
  }
  close() {
    this.$emit("close");
  }
  get getTitle() {
    return this.$t("shared.upload") + " " + this.title;
  }
}
