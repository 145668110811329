











































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { TabItem } from "./Tabs.vue";

@Component({})
export default class TabsInPillsWithBrandColor extends Vue {
  @Prop({ default: "sm" }) breakpoint!: string;
  @Prop({ default: "" }) paramName!: string;
  @Prop({}) tabs!: TabItem[];
  selectTab(tabName) {
    const tab = this.tabs.find(f => f.name === tabName);
    if (tab) {
      this.$router.push({ name: tab.routeName, params: tab.routeParams });
    }
    this.$emit("select", tabName);
  }
  isCurrent(tab: TabItem) {
    return this.currentTab === tab;
  }
  get currentTab() {
    if (this.paramName) {
      return this.tabs.find(
        element =>
          this.$route.name === element.routeName &&
          element.routeParams[this.paramName] ===
            this.$route.params[this.paramName]
      );
    }
    return this.tabs.find(element => this.$route.name === element.routeName);
  }
}
