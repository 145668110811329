<template>
  <span>
    <button
      v-if="!to"
      :type="type"
      @click="$emit('click')"
      class="inline-flex items-center space-x-2 px-4 py-2 border shadow-sm text-sm font-medium rounded-md text-theme-900 bg-white border-theme-300 hover:bg-theme-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
    >
      <slot />
    </button>
    <router-link
      :to="to"
      v-else
      class="inline-flex items-center space-x-2 px-4 py-2 border shadow-sm text-sm font-medium rounded-md text-theme-900 bg-white border-theme-300 hover:bg-theme-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
    >
      <slot />
    </router-link>
  </span>
</template>

<script>
export default {
  props: ["type", "click", "to"]
};
</script>

<style>
</style>