



















































































































































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import ErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import { ContractDto } from "@/application/dtos/app/v3/contracts/ContractDto";
import services from "@/services";
import pdf from "vue-pdf";
import Sign from "@/assets/contracts/Sign.vue";
import store from "@/store";
import SideModal from "@/components/shared/modals/SideModal.vue";
import DropDocument from "@/components/app/v3/contracts/uploads/DropDocument.vue";
import CfdisListAndTable from "@/components/app/v3/cfdis/list/CfdisListAndTable.vue";
import VisitsListAndTable from "@/components/app/v3/employees/visits/VisitsListAndTable.vue";
import DropCfdis from "@/components/app/uploads/DropCfdis.vue";
import { ContractEmployeeVisitDto } from "@/application/dtos/app/v3/contracts/ContractEmployeeVisitDto";
import { Prop } from "vue-property-decorator";
import DateUtils from "@/utils/shared/DateUtils";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  components: {
    Breadcrumb,
    pdf,
    Sign,
    SideModal,
    DropDocument,
    CfdisListAndTable,
    ErrorModal,
    SuccessModal,
    ConfirmModal,
    DropCfdis,
    VisitsListAndTable,
    Loading
  }
})
export default class NewContractVisit extends Vue {
  @Prop({}) id!: string;
  $refs!: {
    errorModal: ErrorModal;
    successModal: SuccessModal;
    confirmCreate: ConfirmModal;
    modalSign: SideModal;
    buttonSave: HTMLButtonElement;
    dropCfdis: DropCfdis;
  };
  contractId = "";

  item?: ContractDto | null = null;
  error = "";
  loading = false;
  employeesVisiting: ContractEmployeeVisitDto[] = [];
  comments = "";

  searchInput = "";

  created() {
    this.contractId = this.$route.params.id;
    if (this.id) {
      this.contractId = this.id;
    }
  }
  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    services.contracts
      .getContract(this.contractId, true)
      .then(response => {
        this.item = response;
        if (this.item.employeeLists.length > 0) {
          this.item.employeeLists = this.item.employeeLists.sort((x, y) => {
            if (x.number && y.number) {
              return x.number > y.number ? -1 : 1;
            }
            return -1;
          });
          this.item.currentEmployeeList = this.item.employeeLists[0];
        }
        this.item?.currentEmployeeList?.employees.forEach(element => {
          this.employeesVisiting.push({
            id: undefined,
            visitId: undefined,
            visit: {} as any,
            employeeListId: this.item?.currentEmployeeList?.id,
            employeeList: {} as any,
            employeeId: element.id,
            employee: element,
            attended: undefined,
            checkIn: undefined,
            checkOut: undefined
          });
        });
        // if (this.item.visits.length === 0) {
        //   this.visiting = true;
        // }
      })
      .finally(() => {
        this.loading = false;
      });
  }
  notAttended(employeeVisit: ContractEmployeeVisitDto) {
    employeeVisit.attended = false;
  }
  checkIn(employeeVisit: ContractEmployeeVisitDto) {
    employeeVisit.checkIn = new Date();
    employeeVisit.attended = true;
  }
  checkOut(employeeVisit: ContractEmployeeVisitDto) {
    employeeVisit.checkOut = new Date();
  }
  saveVisit() {
    const undefinedAttendance = this.employeesVisiting.filter(
      f => f.attended === undefined
    );
    if (undefinedAttendance.length > 0) {
      this.$refs.errorModal.show(
        `Falta por especificar si atendieron ${undefinedAttendance.length}/${this.employeesVisiting.length} colaboradores`
      );
      return;
    }
    this.$refs.confirmCreate.show(
      "¿Guardar registro?",
      "Confirmar",
      "Atrás",
      `Guardarás el registro de ${
        this.employeesVisiting.filter(f => f.attended).length
      }/${this.employeesVisiting.length} asistencias`
    );
  }
  confirmSaveVisit() {
    const undefinedAttendance = this.employeesVisiting.filter(
      f => f.attended === undefined
    );
    this.loading = true;
    if (this.item) {
      services.contracts
        .createVisit(this.item.id, {
          comments: this.comments,
          employees: this.employeesVisiting
        })
        .then(() => {
          this.$refs.successModal.show(
            "Visita registrada",
            `Se ha registrado la visita de ${
              this.employeesVisiting.length
            } colaboradores con ${this.employeesVisiting.length -
              undefinedAttendance.length} asistencias y ${
              undefinedAttendance.length
            } inasistencias`
          );
        })
        .catch(error => {
          this.$refs.errorModal.show(this.$t(error));
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  goToVisits() {
    this.$router.push({
      name: "app.visits.list",
      params: { id: this.contractId }
    });
  }
  dateHMS(value: Date | undefined) {
    return DateUtils.dateHMS(value);
  }
  get canEdit() {
    return this.item?.createdByUserId === store.state.account.user?.id || true;
  }
  get filteredItems(): ContractEmployeeVisitDto[] {
    if (!this.employeesVisiting) {
      return [];
    }
    const items = this.employeesVisiting.filter(
      f =>
        f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) ||
        f.employee?.employee?.firstName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.employee?.employee?.lastName1
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.employee?.employee?.lastName2
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.employee?.employee?.email
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.employee?.employee?.taxId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.employee?.employee?.socialSecurityId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.employee?.employee?.populationRegistryId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase())
    );

    return items;
  }
  get sortedItems(): ContractEmployeeVisitDto[] {
    this.$forceUpdate();
    return this.filteredItems;
  }
}
