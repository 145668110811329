
























































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import Tabs from "@/components/shared/tabs/Tabs.vue";
import ProvidersList from "@/components/app/v3/contacts/providers/list/ProvidersList.vue";
import { DocumentationStatusFilter } from "@/application/contracts/app/v3/documentation/DocumentationStatusFilter";
import tinyEventBus from "@/plugins/tiny-eventbus";
import services from "@/services";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import ButtonPrimary from "@/components/shared/buttons/ButtonPrimary.vue";
import ButtonSecondary from "@/components/shared/buttons/ButtonSecondary.vue";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  metaInfo: {
    title: "Mis proveedores",
    titleTemplate: "%s | Délega"
  },
  components: {
    Breadcrumb,
    Tabs,
    ProvidersList,
    ErrorModal,
    ButtonPrimary,
    ButtonSecondary,
    Loading
  }
})
export default class Providers extends Vue {
  $refs!: {
    list: ProvidersList;
    errorModal: ErrorModal;
  };
  filter: DocumentationStatusFilter = DocumentationStatusFilter.All;
  loading = false;
  newProvider() {
    tinyEventBus().emitter.emit("new-provider");
  }
  reload() {
    this.$refs.list.reload();
  }
  downloadAll() {
    const year = new Date().getFullYear();
    this.loading = true;
    services.documentation
      .downloadAllMyProvidersDocuments(year)
      .then(response => {
        this.downloadData(
          `${year} - Documentación de mis proveedores.zip`,
          response
        );
      })
      .catch(error => {
        this.$refs.errorModal.show("Error descargando", this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  downloadData(fileName, response) {
    const type = "application/zip";

    const fileUrl = window.URL.createObjectURL(
      new Blob([response.data], { type })
    );
    const fileLink = document.createElement("a");
    fileLink.href = fileUrl;
    fileLink.setAttribute("download", fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
  }
}
