


























import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import Tabs, { TabItem } from "@/components/shared/tabs/Tabs.vue";
import DarkModeToggle from "@/components/marketing/toggles/DarkModeToggle.vue";

@Component({
  metaInfo: {
    title: "Ajustes",
    titleTemplate: "%s | Délega"
  },
  components: {
    Breadcrumb,
    Tabs,
    DarkModeToggle
  }
})
export default class Contracts extends Vue {
  tabs: TabItem[] = [];
  mounted() {
    if (this.$route.name?.toString() === "settings") {
      this.$router.push({ name: "settings.profile" });
    }
    this.tabs = [
      {
        name: this.$t("settings.profile.profileTitle"),
        routeName: "settings.profile"
      },
      {
        name: this.$t("settings.tenant.workspaces.title"),
        routeName: "settings.tenant.workspaces"
      },
      {
        name: this.$t("settings.tenant.members.title"),
        routeName: "settings.tenant.members"
      },
      {
        name: this.$t("settings.tenant.subscription.title"),
        routeName: "settings.tenant.subscription"
      },
      {
        name: this.$t("settings.tenant.title"),
        routeName: "settings.tenant.general"
      }
    ];
  }
}
