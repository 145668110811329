<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      d="M20.5,11c-0.386,0-16.614,0-17,0C2.672,11,2,11.672,2,12.5S2.672,14,3.5,14c0.386,0,16.614,0,17,0	c0.828,0,1.5-0.672,1.5-1.5S21.328,11,20.5,11z"
    ></path>
    <path
      d="M19.969,14.582C19.983,14.389,20,14.197,20,14v-4c0-1.862-1.278-3.413-3-3.859V4.249C15.57,3.417,13.851,3,12,3 S8.43,3.417,7,4.249v1.892C5.278,6.587,4,8.138,4,10v3.726c0,0.287,0.017,0.571,0.046,0.852C3.441,14.771,3,15.331,3,16 c0,0.828,0.672,1.5,1.5,1.5c0.127,0,0.248-0.02,0.365-0.05c1.232,2.514,3.67,4.343,6.602,4.533 c3.397,0.22,6.368-1.686,7.733-4.513c0.097,0.02,0.197,0.03,0.3,0.03c0.828,0,1.5-0.672,1.5-1.5 C21,15.337,20.566,14.78,19.969,14.582z"
      opacity=".35"
    ></path>
    <path
      d="M13,3c0-0.552-0.448-1-1-1s-1,0.448-1,1c0,0.087,0,3.413,0,3.5c0,0.552,0.448,1,1,1s1-0.448,1-1C13,6.413,13,3.087,13,3z"
    ></path>
    <path
      d="M15,3.413V5c0,0.552-0.448,1-1,1s-1-0.448-1-1V3.045C12.671,3.017,12.338,3,12,3s-0.671,0.017-1,0.045V5	c0,0.552-0.448,1-1,1S9,5.552,9,5V3.413C5.507,4.415,3,7.272,3,12h18C21,7.272,18.493,4.415,15,3.413z"
    ></path>
    <path
      d="M3,12v2c0,0.552,0.448,1,1,1s1-0.293,1-1c0-0.767,0.302-1.466,0.784-2H3z"
    ></path>
    <path
      d="M18.221,12C18.7,12.532,19,13.228,19,14c0,0.584,0.448,1,1,1s1-0.448,1-1v-2H18.221z"
    ></path>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>