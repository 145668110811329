var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.sortedItems.length === 0)?_c('div',[_c('EmptyState',{staticClass:"bg-white",attrs:{"captions":{
        thereAreNo: 'No tienes proveedores o clientes'
      }}})],1):_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"overflow-x-auto"},[_c('div',{staticClass:"py-2 align-middle inline-block min-w-full"},[_c('div',{staticClass:"shadow overflow-hidden border border-gray-200 sm:rounded-lg"},[_c('table',{staticClass:"min-w-full divide-y divide-gray-200"},[_c('thead',{staticClass:"bg-gray-50"},[_c('tr',_vm._l((_vm.headers),function(header,idx){return _c('th',{key:idx,staticClass:"px-4 py-2 text-left text-xs font-medium text-gray-500 tracking-wider select-none",class:{
                    'cursor-pointer hover:text-gray-700': header.name
                  },attrs:{"scope":"col"},on:{"click":function($event){return _vm.sortBy(header.name)}}},[_c('div',{staticClass:"flex items-center space-x-1 text-gray-500"},[_c('div',[_vm._v(" "+_vm._s(header.title)+" ")]),_c('div',{class:{
                        invisible:
                          !header.name || _vm.sortByColumn !== header.name
                      }},[(_vm.sortDirection === -1)?_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z","clip-rule":"evenodd"}})]):_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z","clip-rule":"evenodd"}})])])])])}),0)]),_c('tbody',{staticClass:"bg-white divide-y divide-gray-200"},_vm._l((_vm.sortedItems),function(link,idx){return _c('tr',{key:idx},[_c('td',{staticClass:"px-4 py-4 whitespace-nowrap"},[_c('div',{staticClass:"flex items-end"},[_c('div',{},[_c('div',{staticClass:"text-sm font-extrabold text-gray-900"},[_vm._v(" "+_vm._s(_vm.getWorkspace(link).taxId)+" ")]),_c('div',{staticClass:"text-sm text-gray-500 flex items-center space-x-1 truncate"},[_vm._v(" "+_vm._s(_vm.getWorkspace(link).legalName)+" ")])])])]),_c('td',{staticClass:"px-4 py-4 whitespace-nowrap"},[(_vm.whoAmI(link) !== 0)?_c('span',{staticClass:"flex-shrink-0 inline-block px-2 py-0.5 text-gray-800 text-sm font-medium rounded-sm border-teal-300"},[_vm._v(" "+_vm._s(_vm.$t("models.provider.object"))+" ")]):_c('span',{staticClass:"flex-shrink-0 inline-block px-2 py-0.5 text-gray-800 text-sm font-medium rounded-sm border-purple-300"},[_vm._v(" "+_vm._s(_vm.$t("models.client.object"))+" ")])]),_c('td',{staticClass:" w-20 px-2 py-4 whitespace-nowrap text-sm text-gray-600"},[_c('div',{staticClass:" flex items-center space-x-2"},[(_vm.whoAmI(link) === 0)?_c('router-link',{staticClass:"inline-flex space-x-2 items-center px-3 py-2 underline hover:text-theme-600",attrs:{"to":{
                        name: 'app.contracts.new',
                        query: { l: link.id }
                      }}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("shared.new"))+" "),_c('span',{staticClass:" lowercase"},[_vm._v(_vm._s(_vm.$t("models.contract.object")))])])]):_c('router-link',{staticClass:"inline-flex space-x-2 items-center px-3 py-2 underline hover:text-theme-600",attrs:{"to":{
                        name: 'app.providers.documentation',
                        params: { id: link.id }
                      }}},[_vm._v(" "+_vm._s(_vm.$t("app.providers.viewDocumentation"))+" ")])],1)])])}),0)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }