

























































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import services from "@/services";
import ContractClock from "@/assets/contracts/ContractClock.vue";
import ContractCheck from "@/assets/contracts/ContractCheck.vue";
import ContractArchived from "@/assets/contracts/ContractArchived.vue";
import { EmployeeDto } from "@/application/dtos/app/v3/employees/EmployeeDto";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import DateUtils from "@/utils/shared/DateUtils";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  components: {
    Breadcrumb,
    ContractClock,
    ContractCheck,
    ContractArchived,
    ConfirmModal,
    ErrorModal,
    SuccessModal,
    Loading
  }
})
export default class EmployeeProfile extends Vue {
  $refs!: {
    confirmSave: ConfirmModal;
    confirmDeleteEmployee: ConfirmModal;
    errorModal: ErrorModal;
    successModal: SuccessModal;
    successModalDeleted: SuccessModal;
    firstNameInput: HTMLInputElement;
  };
  id = "";
  year = 0;
  loading = false;
  loadingContracts = false;
  item: EmployeeDto = {} as EmployeeDto;
  // contracts: ContractDto[] = [];
  firstName = "";
  lastName1 = "";
  lastName2 = "";
  taxId = "";
  socialSecurityId = "";
  populationRegistryId = "";
  email = "";

  editing = false;
  created() {
    this.year = new Date().getFullYear();
    this.id = this.$route.params.id;
  }
  mounted() {
    this.reload();
    // this.reloadContracts();
  }
  reload() {
    this.loading = true;
    services.employees
      .get(this.id)
      .then(response => {
        this.item = response;
        this.loadProfile();
      })
      .finally(() => {
        this.loading = false;
      });
  }
  loadProfile() {
    this.firstName = this.item.firstName;
    this.lastName1 = this.item.lastName1;
    this.lastName2 = this.item.lastName2;
    this.taxId = this.item.taxId;
    this.socialSecurityId = this.item.socialSecurityId;
    this.populationRegistryId = this.item.populationRegistryId;
    this.email = this.item.email ?? "";
  }
  // reloadContracts() {
  //   this.loadingContracts = true;
  //   services.contracts
  //     .getEmployeeContracts(this.id)
  //     .then(response => {
  //       this.contracts = response;
  //     })
  //     .finally(() => {
  //       this.loadingContracts = false;
  //     });
  // }
  edit() {
    this.editing = true;
    this.$nextTick(() => {
      this.$refs.firstNameInput.focus();
      this.$refs.firstNameInput.select();
    });
  }
  save() {
    if (this.thereAreNoChanges) {
      this.$refs.errorModal.show("Error", "No hay cambios");
    } else {
      this.$refs.confirmSave.show("Guardar cambios", "Sí", "Cancelar");
    }
  }
  deleteEmployee() {
    this.$refs.confirmDeleteEmployee.show(
      "¿Eliminar colaborador?",
      "Sí",
      "Cancelar",
      "ADVERTENCIA: No se podrá eliminar si tiene contratos relacionados. No se puede recuperar un colaborador eliminado."
    );
  }
  successModalDeletedClosed() {
    this.$router.push({ name: "app.employees" });
  }
  confirmedDeleteEmployee() {
    this.loading = true;
    services.employees
      .delete(this.id)
      .then(() => {
        this.$refs.successModalDeleted.show("Colaborador eliminado");
      })
      .catch(error => {
        this.$refs.errorModal.show("Error eliminando", this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  saveConfirm() {
    this.loading = true;
    services.employees
      .update(this.id, {
        firstName: this.firstName,
        lastName1: this.lastName1,
        lastName2: this.lastName2,
        taxId: this.taxId,
        socialSecurityId: this.socialSecurityId,
        populationRegistryId: this.populationRegistryId,
        email: this.email
      })
      .then(response => {
        this.$refs.successModal.show("Cambios guardados");
        this.item = response;
        this.loadProfile();
        this.editing = false;
      })
      .catch(error => {
        this.$refs.errorModal.show("Error guardando", this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  cancel() {
    this.loadProfile();
    this.editing = false;
  }
  dateMonthDayYear(value: Date | undefined) {
    return DateUtils.dateMonthDayYear(value);
  }
  get thereAreNoChanges() {
    if (this.item) {
      return (
        this.firstName === this.item.firstName &&
        this.lastName1 === this.item.lastName1 &&
        this.lastName2 === this.item.lastName2 &&
        this.taxId === this.item.taxId &&
        this.socialSecurityId === this.item.socialSecurityId &&
        this.populationRegistryId === this.item.populationRegistryId &&
        this.email === this.item.email
      );
    }
    return true;
  }
}
