


























































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal2.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import services from "@/services";
import pdf from "vue-pdf";
import Sign from "@/assets/contracts/Sign.vue";
import SideModal from "@/components/shared/modals/SideModal.vue";
import DropDocument from "@/components/app/v3/contracts/uploads/DropDocument.vue";
import CfdisListAndTable from "@/components/app/v3/cfdis/list/CfdisListAndTable.vue";
import VisitsListAndTable from "@/components/app/v3/employees/visits/VisitsListAndTable.vue";
import DropCfdis from "@/components/app/uploads/DropCfdis.vue";
import { FileBase64 } from "@/application/dtos/shared/FileBase64";
import { ContractEmployeeVisitDto } from "@/application/dtos/app/v3/contracts/ContractEmployeeVisitDto";
import { Prop } from "vue-property-decorator";
import { EmployeeDto } from "@/application/dtos/app/v3/employees/EmployeeDto";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  metaInfo: {
    title: "Agregar colaboradores",
    titleTemplate: "%s | Délega"
  },
  components: {
    Breadcrumb,
    pdf,
    Sign,
    SideModal,
    DropDocument,
    CfdisListAndTable,
    ErrorModal,
    SuccessModal,
    ConfirmModal,
    DropCfdis,
    VisitsListAndTable,
    Loading
  }
})
export default class NewEmployees extends Vue {
  @Prop({}) id!: string;
  $refs!: {
    errorModal: ErrorModal;
    successModal: SuccessModal;
    confirmCreate: ConfirmModal;
    modalSign: SideModal;
    buttonSave: HTMLButtonElement;
    dropCfdis: DropCfdis;
  };

  employees: EmployeeDto[] = [];
  employeesFile = "";

  showImportEmployees = true;
  importingEmployees = false;

  error = "";
  loading = false;
  employeesVisiting: ContractEmployeeVisitDto[] = [];

  searchInput = "";

  created() {
    this.addEmployee();
  }
  removeEmployee(index) {
    this.employees = this.employees.filter((x, i) => i !== index);
    if (this.employees.length === 0) {
      this.employeesFile = "";
    }
  }
  save() {
    const confirmText =
      this.employees.length === 1
        ? "¿Guardar colabodador?"
        : `¿Guardar ${this.employees.length} colabodadores?`;
    this.$refs.confirmCreate.show(
      confirmText,
      "Confirmar",
      "Atrás",
      "En los contratos podrás seleccionar qué colaboradores trabajarán en el proyecto."
    );
  }
  cancel() {
    this.$router.push({ name: "app.employees" });
  }
  confirmSave() {
    this.loading = true;
    if (this.employees.length > 0) {
      services.employees
        .createMultiple({
          employees: this.employees
        })
        .then(response => {
          if (response.length === 1) {
            this.$refs.successModal.show("Agregado", "Colaborador agregado");
          } else if (response.length > 1) {
            this.$refs.successModal.show(
              "Agregados",
              response.length + " colaboradores agregados"
            );
          }
        })
        .catch(error => {
          this.$refs.errorModal.show("Error", this.$t(error));
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  goToProfile() {
    this.$router.push({
      name: "app.employees"
    });
  }
  addEmployee() {
    this.employees.push({
      id: undefined,
      firstName: "",
      lastName1: "",
      lastName2: "",
      taxId: "",
      socialSecurityId: "",
      populationRegistryId: "",
      email: "",
      cfdis: []
    });
    if (this.employees.length > 1) {
      this.$nextTick(() => {
        this.$refs[
          "employee-full-name-" + (this.employees.length - 1)
        ][0].focus();
      });
    }
  }
  downloadEmployeesFileTemplate() {
    const rows = [
      [
        "NOMBRE",
        "APELLIDO PATERNO",
        "APELLIDO MATERNO",
        "RFC",
        "NSS",
        "CURP",
        "CORREO"
      ]
    ];

    const csvContent =
      "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "plantilla.csv");
    document.body.appendChild(link);

    link.click();
  }
  droppedEmployeesFile(filesBase64: FileBase64[], files: File[]) {
    if (files.length > 0) {
      this.employeesFile = filesBase64[0].base64;
      const employeesFile = files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const employees = this.csvToArray(e.target.result);
        employees.forEach(employeeObject => {
          const employee: string[] = Object.values(employeeObject);
          // console.log(typeof employee);
          // console.log("employee.length", employee.length);
          const firstName =
            employee.length > 0 ? employee[0].toString().replace("\r", "") : "";
          if (firstName === "NOMBRE") {
            return;
          }
          const lastName1 =
            employee.length > 1 ? employee[1].toString().replace("\r", "") : "";
          const lastName2 =
            employee.length > 2 ? employee[2].toString().replace("\r", "") : "";
          const taxId =
            employee.length > 3 ? employee[3].toString().replace("\r", "") : "";
          const socialSecurityId =
            employee.length > 4 ? employee[4].toString().replace("\r", "") : "";
          const populationRegistryId =
            employee.length > 5 ? employee[5].toString().replace("\r", "") : "";
          const email =
            employee.length > 6 ? employee[6].toString().replace("\r", "") : "";
          this.employees.push({
            id: undefined,
            firstName,
            lastName1,
            lastName2,
            taxId,
            socialSecurityId,
            populationRegistryId,
            email,
            cfdis: []
          });
        });
        this.employees = this.employees.filter(f => f.firstName && f.lastName1);
        this.clearEmptyEmployees();
      };
      reader.readAsText(employeesFile);
    }
  }
  clearEmptyEmployees() {
    this.employees.forEach((employee, index) => {
      if (!employee.firstName || employee.firstName.trim() === "") {
        this.employees = this.employees.filter((x, i) => i !== index);
      }
    });
  }
  csvToArray(str, delimiter = ",") {
    const headers: string[] = str.slice(0, str.indexOf("\n")).split(delimiter);

    let rows = str.slice(str.indexOf("\n") + 1).split("\n");
    if (headers[0].trim().toUpperCase() !== "NOMBRE") {
      rows = str.split("\n");
    }
    const arr = rows.map(row => {
      const values = row.split(delimiter);
      const el = headers.reduce((object, header, index) => {
        object[index] = values[index];
        return object;
      }, {});
      return el;
    });
    return arr;
  }
  get filteredItems(): ContractEmployeeVisitDto[] {
    if (!this.employeesVisiting) {
      return [];
    }
    const items = this.employeesVisiting.filter(
      f =>
        f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) ||
        f.employee?.employee?.firstName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.employee?.employee?.lastName1
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.employee?.employee?.lastName2
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.employee?.employee?.email
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.employee?.employee?.taxId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.employee?.employee?.socialSecurityId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.employee?.employee?.populationRegistryId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase())
    );

    return items;
  }
  get sortedItems(): ContractEmployeeVisitDto[] {
    this.$forceUpdate();
    return this.filteredItems;
  }
}
