







































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import NewContractVisit from "@/components/app/v3/visits/NewContractVisit.vue";

@Component({
  metaInfo: {
    title: "Registrar visita",
    titleTemplate: "%s | Délega"
  },
  components: {
    Breadcrumb,
    NewContractVisit
  }
})
export default class NewVisit extends Vue {
  $refs!: {
    newContractVisit: NewContractVisit;
  };
  error = "";
  loading = false;
  id = "";
  created() {
    this.id = this.$route.params.id;
  }
  save() {
    this.$refs.newContractVisit.saveVisit();
  }
  cancel() {
    this.$router.push({ name: "app.visits.list", params: { id: this.id } });
  }
}
