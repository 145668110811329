<template>
  <div
    class="relative overflow-hidden bg-white dark:bg-gray-900 text-coolGray-800 dark:text-blueGray-200"
  >
    <Hero />
    <!-- <FeaturesV2 class="relative z-10" /> -->
    <FeaturesV3 class="relative z-10" />
    <FeaturesV4 />
    <JoinNow />
    <Footer />
  </div>
</template>

<script>
import Hero from "@/components/marketing/Hero.vue";
import FeaturesV2 from "@/components/marketing/FeaturesV2.vue";
import FeaturesV3 from "@/components/marketing/FeaturesV3.vue";
import FeaturesV4 from "@/components/marketing/FeaturesV4.vue";
import JoinNow from "@/components/marketing/JoinNow.vue";
import Footer from "@/components/marketing/Footer.vue";
export default {
  components: {
    Hero,
    FeaturesV2,
    FeaturesV3,
    FeaturesV4,
    JoinNow,
    Footer
  }
};
</script>
