































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import SvgResultsEmpty from "@/components/shared/icons/ResultsEmptyIcon.vue";
import ButtonTertiary from "../buttons/ButtonTertiary.vue";

@Component({
  components: {
    SvgResultsEmpty,
    ButtonTertiary
  }
})
export default class EmptyStateSimple extends Vue {
  @Prop({}) icon!: string;
  @Prop({}) captions!: any;
  @Prop({}) to!: any;
}
