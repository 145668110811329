
































































































import Component from "vue-class-component";
import Vue from "vue";
import GoogleSignInButton from "vue-google-signin-button-directive";
import ErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import Logo from "@/components/marketing/Logo.vue";
import { UserVerifyRequest } from "../../application/contracts/master/users/UserVerifyRequest";
import services from "@/services";

@Component({
  components: { ErrorModal, Logo },
  directives: {
    GoogleSignInButton
  }
})
export default class Verify extends Vue {
  $refs!: {
    errorModal: ErrorModal;
  };
  loading = false;
  droppedDown = false;
  user = {} as UserVerifyRequest;
  created() {
    this.user.email = this.$route.query.e ? this.$route.query.e.toString() : "";
    this.user.token = this.$route.query.t ? this.$route.query.t.toString() : "";
  }
  reset() {
    this.loading = true;
    if (this.user.password !== this.user.passwordConfirm) {
      return;
    }
    services.authentication.verify(this.user).catch(error => {
      this.$refs.errorModal.show(error);
    });
  }
  closeDropdown() {
    this.droppedDown = false;
  }
}
