




import Vue from "vue";
import Component from "vue-class-component";
import CfdisList from "@/components/app/v3/cfdis/list/CfdisList.vue";

@Component({
  metaInfo: {
    title: "CFDIs",
    titleTemplate: "%s | Délega"
  },
  components: {
    CfdisList
  }
})
export default class CfdisPayroll extends Vue {
  $refs!: {
    cfdiList: CfdisList;
  };
}
