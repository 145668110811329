














































































































































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import store from "@/store";
import AllDocumentation from "@/components/app/v3/documentation/AllDocumentation.vue";
import services from "@/services";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal2.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import { LinkDto } from "@/application/dtos/master/v3/links/LinkDto";

@Component({
  metaInfo: {
    title: "Documentación de proveedor",
    titleTemplate: "%s | Délega"
  },
  components: {
    Breadcrumb,
    AllDocumentation,
    ConfirmModal,
    SuccessModal,
    ErrorModal
  }
})
export default class LinkDocumentation extends Vue {
  $refs!: {
    allDocumentation: AllDocumentation;
  };
  year = 0;
  open = false;
  link: LinkDto | null = null;
  loading = false;
  years: number[] = []
  created() {
    for (let year = 2021; year <= new Date().getFullYear(); year++) {
      this.years.push(year);
    }

    const currentYear = new Date().getFullYear();
    this.year =
      Number(this.$route.query.ejercicio) ?? Number(this.$route.query.year);
    if (isNaN(this.year) || this.year === 0) {
      this.year = currentYear;
    }
    // this.workspaceId =
    //   this.$route.query.p?.toString() ??
    //   store.state.tenant.currentWorkspace?.id ??
    //   "";
  }
  mounted() {
    this.loading = true;
    services.links
      .get(this.$route.params.id)
      .then(link => {
        this.link = link;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  closeOptions() {
    this.open = false;
  }
  toggleOptions() {
    this.open = !this.open;
  }
  reload() {
    this.closeOptions();
    this.$refs.allDocumentation.reload();
  }
  sendDocumentationStatus() {
    this.$refs.allDocumentation.sendDocumentationStatus();
  }
  downloadAll() {
    this.$refs.allDocumentation.downloadAll();
  }
  uploadDocument() {
    this.$refs.allDocumentation.uploadDocument();
  }
  get isCurrentWorkspaceId(): boolean {
    return this.$route.params.id === store.state.tenant.currentWorkspace?.id;
  }
  // get year(): number {
  //   return Number(this.$route.params.year);
  // }
}
