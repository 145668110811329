import { UserType } from "@/application/enums/master/users/UserType";

export const adminRoutes = {
  name: "admin",
  path: "/admin",
  component: require("@/views/admin/Index.vue").default,
  meta: {
    requiresAuth: true,
    userTypes: [UserType.Admin],
  },
  redirect: "admin/tenants",
  children: [
    {
      name: "admin.tenants",
      path: "organizaciones",
      alias: "tenants",
      component: require("@/views/admin/tenants/Tenants.vue").default,
    },
    {
      name: "admin.tenant",
      path: "organizacion",
      alias: "tenant",
      component: require("@/views/admin/tenants/Tenant.vue").default,
      children: [
        {
          name: "admin.tenants.profile",
          path: "perfil/:id",
          alias: "profile/:id",
          component: require("@/components/admin/tenants/TenantProfile.vue").default,
        },
        {
          name: "admin.tenants.subscription",
          path: "subscripcion/:id",
          alias: "subscription/:id",
          component: require("@/components/admin/tenants/TenantSubscription.vue").default,
        },
      ],
    },
    {
      name: "admin.users",
      path: "users",
      alias: "usuarios",
      component: require("@/views/admin/Users.vue").default,
    },
    {
      name: "admin.pricing",
      path: "products",
      alias: "productos",
      component: require("@/views/admin/pricing/SubscriptionProductsList.vue").default,

      children: [
        {
          name: "admin.pricing.new",
          path: "new",
          alias: "nuevo",
          component: require("@/views/admin/pricing/SubscriptionProductsForm.vue").default,
        },
      ],
    },
    {
      name: "admin.product",
      path: "products/:id",
      alias: "productos/:id",
      component: require("@/views/admin/pricing/SubscriptionProduct.vue").default,
      children: [
        {
          name: "admin.product.overview",
          path: "overview",
          alias: "resumen",
          component: require("@/views/admin/pricing/SubscriptionProductOverview.vue").default,
        },
        {
          name: "admin.product.edit",
          path: ":id/edit",
          alias: ":id/editar",
          component: require("@/views/admin/pricing/SubscriptionProductsForm.vue").default,
        },
        {
          name: "admin.product.prices",
          path: "prices",
          alias: "precios",
          component: require("@/views/admin/pricing/SubscriptionPrices.vue").default,
          children: [
            {
              name: "admin.product.prices.edit",
              path: ":price/edit",
              alias: ":price/editar",
              component: require("@/views/admin/pricing/SubscriptionPricesForm.vue").default,
            },
          ],
        },
        {
          name: "admin.product.features",
          path: "features",
          alias: "caracteristicas",
          component: require("@/views/admin/pricing/SubscriptionFeatures.vue").default,
        },
      ],
    },
  ],
};
