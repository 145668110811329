import { SideBarItem } from "./SidebarItem";
import { i18n } from "@/plugins/vue-i18n";
import { UserType } from "@/application/enums/master/users/UserType";

export const AdminSidebar: SideBarItem[] = [
  {
    title: i18n.t("app.sidebar.admin"),
    items: [
      {
        title: i18n.t("admin.tenants.title"),
        pathName: "admin.tenants",
        icon: "users",
        items: [],
      },
      {
        title: i18n.t("admin.users"),
        pathName: "admin.users",
        icon: "user",
        items: [],
      },
      {
        title: i18n.t("admin.pricing.title"),
        pathName: "admin.pricing",
        icon: "tags",
        items: [],
      },
    ],
  },
  {
    title: "App",
    items: [
      {
        title: i18n.t("admin.switchToApp"),
        pathName: "app",
        icon: "external-link-alt",
        userTypes: [UserType.Admin],
        items: [],
      },
    ],
  },
];
