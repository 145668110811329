





































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import services from "@/services";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import Sign from "@/assets/contracts/Sign.vue";
import { ContractMemberRole } from "@/application/enums/app/v3/contracts/ContractMemberRole";
import { WorkspaceUserDto } from "@/application/dtos/app/workspaces/WorkspaceUserDto";
import { AddContractMemberDto } from "@/application/contracts/app/v3/contracts/AddContractMemberDto";
import { LinkDto } from "@/application/dtos/master/v3/links/LinkDto";
import { TranslateResult } from "vue-i18n";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  components: {
    EmptyState,
    ErrorModal,
    Sign,
    Loading
  }
})
export default class SelectContractMembers extends Vue {
  @Prop({ default: "sm:max-w-lg" }) maxSize!: string;
  $refs!: {
    errorModal: ErrorModal;
  };
  title!: string;
  closeText!: TranslateResult | string;
  showing = false;
  errorDescription!: string;
  searchInput = "";
  items: WorkspaceUserDto[] = [];
  loading = false;
  selected: string[] = [];
  link: LinkDto | null = null;
  linkId = "";
  mounted() {
    this.closeText = this.$t("shared.close").toString();
  }
  show(link: LinkDto, linkId: string, selected: string[]) {
    this.link = link;
    this.linkId = linkId;
    this.selected = selected;
    this.showing = true;

    // this.$nextTick(() => {
    //   window.addEventListener("keyup", this.keyup);
    // });

    this.reload();
  }
  reload() {
    this.loading = true;
    services.links
      .getLinkUsers(this.linkId)
      .then(response => {
        this.items = response;
      })
      .catch(error => {
        this.$refs.errorModal.show("Error", this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  keyup(event: KeyboardEvent) {
    console.log(event.keyCode);
    if (event.keyCode === 27) {
      this.close();
    }
  }
  close() {
    this.$emit("closed");
    this.showing = false;
  }
  toggle(item: WorkspaceUserDto) {
    if (this.isSelected(item)) {
      this.selected = this.selected.filter(f => f !== item.userId);
    } else {
      this.selected.push(item.userId);
    }
  }
  isSelected(item: WorkspaceUserDto) {
    return this.selected.find(f => f === item.userId);
  }
  accept() {
    const selected: AddContractMemberDto[] = [];
    this.items.forEach(element => {
      if (this.isSelected(element)) {
        const contractMember: AddContractMemberDto = {
          userId: element.userId,
          role: ContractMemberRole.SIGNATORY,
          name: (element.user?.firstName + " " + element.user?.lastName).trim(),
          email: element.user?.email ?? ""
        };
        selected.push(contractMember);
      }
    });
    this.$emit("selected", selected);
    this.close();
  }
  getNoMembers() {
    if (this.link?.providerWorkspace && this.link.clientWorkspace) {
      return `No se encontraron miembros de ${this.link?.providerWorkspace.taxId} o de ${this.link?.clientWorkspace.taxId}`;
    }
    return `No se encontraron miembros`;
  }
  get filteredItems(): WorkspaceUserDto[] {
    if (!this.items) {
      return [];
    }
    const items = this.items.filter(
      f =>
        f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) ||
        f.workspace?.name
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.workspace?.taxId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.workspace?.legalName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.user?.firstName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.user?.lastName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.user?.email
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase())
    );

    return items;
  }
}
