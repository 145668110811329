const fakeCompanies = [
  {
    taxId: "BEV450119V58",
    legalName: "BABEL, S.A. DE C.V.",
  },
  {
    taxId: "CFO4578213A1",
    legalName: "COMERCIALIZADORA FOBZ ASOR S.A.DE C.V.",
  },
  {
    taxId: "EMR540716Z66",
    legalName: "EXCLUSIVAS RENTA, S.A.DE C.V.",
  },
  {
    taxId: "DMX740218YX4",
    legalName: "DISENOS MAXIMUM S.A. DE C.V.",
  },
  {
    taxId: "IMG950010243",
    legalName: "INDUSTRIAS MARTINEZ GARCIA",
  },
  {
    taxId: "DLS8202283K5",
    legalName: "DEPORTES LOGOR SA DE CV",
  },
  {
    taxId: "AST700653DLB",
    legalName: "EL ASTILLERO S.A.",
  },
  {
    taxId: "IMJ940134SY1",
    legalName: "INNOVACIONES Y SERVICIOS DE JALISCO S.A. DE C.V. INNOVACIONES Y SERVICIOS DE JALISCO S.A. DE C.V.",
  },
  {
    taxId: "GIR1505418Z2",
    legalName: "GILATER.S.A.DE C.V.",
  },
  {
    taxId: "ABS980102VE5",
    legalName: "ABSYS S.C.",
  },
  {
    taxId: "EMP151616TR9",
    legalName: "EMPACADOS S.A. DE C.V.",
  },
  {
    taxId: "EMP151616TR9",
    legalName: "EMPACADOS S.A. DE C.V.",
  },
];

export default fakeCompanies;
