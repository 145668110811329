











































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { TenantFeaturesDto } from "../../../application/contracts/master/tenants/TenantFeaturesDto";
import ErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import store from "@/store";
import { i18n } from "@/plugins/vue-i18n";
import { WorkspaceType } from "@/application/enums/master/tenants/WorkspaceType";
import tinyEventBus from "@/plugins/tiny-eventbus";
import services from "@/services";
import Loading from "@/components/shared/loaders/Loading.vue";
import WarningBanner from "@/components/shared/banners/WarningBanner.vue";
import { FiscalCredentialType } from "@/application/enums/app/v3/cfdis/FiscalCredentialType";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";

@Component({
  components: {
    ErrorModal,
    Loading,
    WarningBanner,
    EmptyState
  }
})
export default class WorkspacesList extends Vue {
  $refs!: {
    errorModal: ErrorModal;
  };
  loading = false;
  headers = [
    {
      title: i18n.t("models.workspace.object")
    },
    {
      title: i18n.t("models.workspace.type")
    },
    {
      title: i18n.t("models.user.plural")
    },
    {
      title: "Credenciales en SAT"
    },
    {
      title: i18n.t("shared.actions")
    }
  ];

  searchInput = "";
  mounted() {
    tinyEventBus().emitter.on("workspace-deleted", () => this.reload());
    tinyEventBus().emitter.on("workspace-added", () => this.reload());
    tinyEventBus().emitter.on("workspace-saved", () => this.reload());

    services.tenants.getFeatures();
    this.reload();
  }
  beforeDestroy() {
    tinyEventBus().emitter.off("workspace-deleted");
    tinyEventBus().emitter.off("workspace-added");
    tinyEventBus().emitter.off("workspace-saved");
  }
  async reload() {
    // this.items = [];
    this.loading = true;
    services.workspaces
      .getAllWorkspaces(true)
      .catch(error => {
        this.$refs.errorModal.show(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  deleted() {
    this.reload();
  }
  // canceled() {
  // }
  added() {
    this.reload();
  }
  saved() {
    this.reload();
  }
  workspaceType(item: WorkspaceDto) {
    return this.$t("app.workspaces.types." + WorkspaceType[item.type]);
  }
  userEmails(item: WorkspaceDto) {
    return item.users.map(f => f.user?.firstName).join(", ");
  }
  fiscalTypeName(type: FiscalCredentialType) {
    return FiscalCredentialType[type];
  }
  get features(): TenantFeaturesDto {
    return store.state.app.features;
  }
  get maxWorkspacesReached() {
    if (!this.features) {
      return true;
    } else {
      if (
        this.features.maxWorkspaces > 0 &&
        this.workspaces.length >= this.features.maxWorkspaces
      ) {
        return true;
      }
      return false;
    }
  }
  get workspaces() {
    return store.state.tenant.workspaces;
  }
  get filteredItems(): WorkspaceDto[] {
    if (!this.workspaces) {
      return [];
    }
    const items = this.workspaces.filter(
      f =>
        f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) ||
        f.name
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.taxId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.legalName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase())
    );

    return items;
  }
  get sortedItems(): WorkspaceDto[] {
    this.$forceUpdate();
    return this.filteredItems;
  }
}
