














































































import Vue from "vue";
import Component from "vue-class-component";
import services from "@/services";
import { ContractDto } from "@/application/dtos/app/v3/contracts/ContractDto";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import SignedContract from "@/assets/contracts/SignedContract4.vue";
import PendingContract from "@/assets/contracts/PendingContract2.vue";
import ArchivedContract from "@/assets/contracts/ArchivedContract.vue";
import Contract from "@/assets/contracts/Contract.vue";
import ContractClock from "@/assets/contracts/ContractClock.vue";
import ContractCheck from "@/assets/contracts/ContractCheck.vue";
import ContractArchived from "@/assets/contracts/ContractArchived.vue";
import { ContractMemberDto } from "@/application/dtos/app/v3/contracts/ContractMemberDto";
import NewModal from "@/components/shared/modals/NewModal.vue";
import ContractsListAndTable from "./ContractsListAndTable.vue";
import CheckboxSelect from "@/components/shared/checkboxes/CheckboxSelect.vue";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import { DocumentationStatusFilter } from "@/application/contracts/app/v3/documentation/DocumentationStatusFilter";
import { ContractStatusFilter } from "@/application/contracts/app/v3/contracts/ContractStatusFilter";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  components: {
    TableRowSkeleton,
    Contract,
    PendingContract,
    SignedContract,
    ArchivedContract,
    ContractClock,
    ContractCheck,
    ContractArchived,
    NewModal,
    ContractsListAndTable,
    CheckboxSelect,
    Loading
  }
})
export default class ContractsList extends Vue {
  // @Prop({})
  filter: ContractStatusFilter = ContractStatusFilter.ALL;

  loading = false;
  items: ContractDto[] = [];
  members: ContractMemberDto[] = [];

  providers: WorkspaceDto[] = [];
  clients: WorkspaceDto[] = [];
  selectedProviders: string[] = [];
  selectedClients: string[] = [];

  searchInput = "";

  mounted() {
    if (!this.$route.params.status || this.$route.params.status === "todos") {
      this.filter = ContractStatusFilter.ALL;
    } else if (this.$route.params.status === "pendientes") {
      this.filter = ContractStatusFilter.PENDING;
    } else if (this.$route.params.status === "firmados") {
      this.filter = ContractStatusFilter.SIGNED;
    } else if (this.$route.params.status === "archivados") {
      this.filter = ContractStatusFilter.ARCHIVED;
    }
    if (this.$route.query.proveedor) {
      this.selectedProviders = [this.$route.query.proveedor.toString()];
    }
    if (this.$route.query.cliente) {
      this.selectedClients = [this.$route.query.cliente.toString()];
    }
    this.reload();
    // this.loadProviders();
    // this.loadClients();
  }
  loadProviders() {
    services.links
      .getAllProviders(DocumentationStatusFilter.All)
      .then(response => {
        this.providers = response.map(f => f.providerWorkspace);
      });
  }
  loadClients() {
    services.links.getAllClients().then(response => {
      this.clients = response.map(f => f.clientWorkspace);
    });
  }
  reload() {
    this.loading = true;
    services.contracts
      .getAllByStatusFilter(this.filter)
      .then(response => {
        this.items = response;
        this.items.forEach(contract => {
          if (contract.employeeLists.length > 0) {
            contract.employeeLists = contract.employeeLists.sort((x, y) => {
              if (x.number && y.number) {
                return x.number > y.number ? -1 : 1;
              }
              return -1;
            });
            contract.currentEmployeeList = contract.employeeLists[0];
          }
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }
  // loadProvidersAndClients() {
  //   this.providers = [];
  //   this.clients = [];
  //   this.items.forEach(contract => {
  //     if (
  //       !this.providers.find(
  //         f => f.providerWorkspace.id === contract.providerWorkspaceId
  //       )
  //     ) {
  //       this.providers.push({
  //         id: "",
  //         providerWorkspaceId: "",
  //         providerWorkspace: {} as WorkspaceDto,
  //         clientWorkspaceId: "",
  //         clientWorkspace: {} as WorkspaceDto,
  //         contracts: []
  //       });
  //     }
  //     if (!this.clients.find(f => f.taxId === contract.clientTaxId)) {
  //       this.clients.push({
  //         id: "",
  //         providerWorkspaceId: "",
  //         providerWorkspace: {} as WorkspaceDto,
  //         clientWorkspaceId: "",
  //         clientWorkspace: {} as WorkspaceDto,
  //         contracts: []
  //       });
  //     }
  //   });
  // }
  //   membersPending(contract: ContractDto) {
  //     return contract.members.filter(f => !f.signDate);
  //   }
  //   membersSigned(contract: ContractDto) {
  //     return contract.members.filter(f => f.signDate);
  //   }
  //   showMembers(members: ContractMemberDto[]) {
  //     if (members.length > 0) {
  //       this.members = members;
  //       this.$refs.modalMembers.show();
  //     }
  //   }
  get filteredItems(): ContractDto[] {
    if (!this.items) {
      return [];
    }
    let items = this.items.filter(
      f =>
        f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) ||
        f.name
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.description
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.link?.providerWorkspace.name
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.link?.providerWorkspace.taxId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.link?.providerWorkspace.legalName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.link?.clientWorkspace.name
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.link?.clientWorkspace.taxId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.link?.clientWorkspace.legalName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.createdByUser?.email
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase())
    );

    if (this.selectedProviders.length > 0) {
      items = items.filter(f =>
        this.selectedProviders.includes(f.link?.providerWorkspace.taxId)
      );
    }

    if (this.selectedClients.length > 0) {
      items = items.filter(f =>
        this.selectedClients.includes(f.link?.clientWorkspace.taxId)
      );
    }

    // if (this.filters?.includes("onlyWithStock")) {
    //   items = items.filter(f => f.totalStockQuantity > 0);
    // }
    // if (this.filters?.includes("onlyWithUnitPrice")) {
    //   items = items.filter(f => f.unitPrice > 0);
    // }
    // if (this.filters?.includes("onlyActive")) {
    //   items = items.filter(f => f.active);
    // }

    return items;
  }
  get sortedItems(): ContractDto[] {
    this.$forceUpdate();
    return this.filteredItems;
    // if (this.sortBy.includes(".")) {
    //   const objectProperty = this.sortBy.split(".")[0];
    //   const valueProperty = this.sortBy.split(".")[1];

    //   return this.filteredItems.sort((x, y) => {
    //     if (x[objectProperty] && y[objectProperty]) {
    //       if (
    //         x[objectProperty][valueProperty] &&
    //         y[objectProperty][valueProperty]
    //       ) {
    //         return (
    //           (x[objectProperty][valueProperty] >
    //           y[objectProperty][valueProperty]
    //             ? this.sortDirection
    //             : this.sortDirection * -1) ?? this.sortDirection
    //         );
    //       }
    //     }
    //     return 1;
    //   });
    // }
    // return this.filteredItems.sort((x, y) => {
    //   if (x[this.sortBy] && y[this.sortBy]) {
    //     return (
    //       (x[this.sortBy] > y[this.sortBy]
    //         ? this.sortDirection
    //         : this.sortDirection * -1) ?? this.sortDirection
    //     );
    //   }
    //   return 1;
    // });
  }
}
