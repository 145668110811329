import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";

export const appSettingsRoutes = {
  name: "settings",
  path: "settings",
  alias: "ajustes",
  component: require("@/views/app/settings/Settings.vue").default,
  redirect: "settings/profile",
  children: [
    {
      name: "settings.profile",
      path: "profile",
      alias: "perfil",
      component: require("@/views/app/settings/profile/Profile.vue").default,
    },
    {
      name: "settings.tenant",
      path: "organization",
      alias: "organizacion",
      component: require("@/views/app/settings/tenant/TenantCurrent.vue").default,
      redirect: "organization/general",
      children: [
        {
          path: "general",
          name: "settings.tenant.general",
          component: require("@/components/app/settings/tenant/TenantSettings.vue").default,
          meta: {
            roles: [TenantUserRole.OWNER],
          },
        },
        {
          name: "settings.tenant.subscription",
          path: "subscription",
          alias: "subscripcion",
          component: require("@/components/app/settings/tenant/subscription/TenantSubscription.vue").default,
          meta: {
            roles: [TenantUserRole.OWNER],
          },
        },
        {
          name: "settings.tenant.workspaces",
          path: "empresas",
          alias: "companies",
          component: require("@/components/app/workspaces/WorkspacesList.vue").default,
          meta: {
            requiresAuth: true,
            roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN],
          },
          children: [
            {
              name: "settings.tenant.workspaces.new",
              alias: "new",
              path: "nueva",
              component: require("@/components/app/workspaces/NewWorkspace.vue").default,
              meta: {
                roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN],
              },
            },
            {
              name: "settings.tenant.workspaces.edit",
              alias: "edit/:id",
              path: "editar/:id",
              component: require("@/components/app/workspaces/EditWorkspace.vue").default,
              meta: {
                roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN],
              },
            },
            {
              name: "settings.tenant.workspaces.credentials.new",
              alias: "new-credential/:id",
              path: "nueva-credencial/:id",
              component: require("@/components/app/workspaces/NewWorkspaceCredential.vue").default,
              meta: {
                roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN],
              },
            },
            {
              name: "settings.tenant.workspaces.credentials.edit",
              alias: "edit-credential/:id",
              path: "editar-credencial/:id",
              component: require("@/components/app/workspaces/EditWorkspaceCredential.vue").default,
              meta: {
                roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN],
              },
            },
          ],
        },
        {
          name: "settings.tenant.members",
          path: "members",
          alias: "miembros",
          component: require("@/components/app/settings/tenant/TenantMembers.vue").default,
          meta: {
            roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN],
          },
          children: [
            {
              name: "settings.tenant.members.new",
              path: "new",
              alias: "nuevo",
              component: require("@/components/app/settings/members/NewMember.vue").default,
              meta: {
                roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN],
              },
            },
            {
              name: "settings.tenant.members.edit",
              path: "edit/:id",
              alias: "editar/:id",
              component: require("@/components/app/settings/members/EditMember.vue").default,
              meta: {
                roles: [TenantUserRole.OWNER, TenantUserRole.ADMIN],
              },
            },
          ],
        },
      ],
    },
  ],
};
