






























































































import Vue from "vue";
import Component from "vue-class-component";
import { TranslateResult } from "vue-i18n";

@Component
export default class SuccessModal extends Vue {
  $refs!: {
    buttonClose: HTMLButtonElement;
  };
  title!: string;
  description!: string;
  closeText!: TranslateResult | string;
  showing = false;
  mounted() {
    this.title = this.$t("shared.success").toString();
    this.closeText = this.$t("shared.close").toString();
  }
  show(title: any, description?: any) {
    this.title = title;
    this.description = description;
    this.showing = true;
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.$refs.buttonClose) {
          this.$refs.buttonClose.focus();
        }
      }, 1000);
    });
  }
  close() {
    this.$emit("closed");
    this.showing = false;
  }
  keyup(event: KeyboardEvent) {
    // console.log({ event, showing: this.showing });
    if (event.keyCode === 13) {
      this.close();
    }
  }
}
