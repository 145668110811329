import { Module } from "vuex";
import { AppState, RootState } from "@/store/types";
import { AppUsageSummaryDto } from "@/application/contracts/app/tenants/AppUsageSummaryDto";
import { AppUsageType } from "@/application/enums/app/usages/AppUsageType";
import { TenantFeaturesDto } from "@/application/contracts/master/tenants/TenantFeaturesDto";

export const state: AppState = {
  usage: {
    type: 0,
    providers: 0,
    providersInCompliance: 0,
    clients: 0,
    contracts: 0,
    cfdis: 0,
    employees: 0,
    storage: 0,
    pendingInvitations: 0,
  },
  features: {
    maxUsers: 1,
    maxWorkspaces: 1,
    maxLinks: 1,
    maxStorage: 1,
    monthlyContracts: 1,
  },
  showTips: true,
};

export const app: Module<AppState, RootState> = {
  namespaced: true,
  state,
  mutations: {
    reset(state) {
      state.usage = {
        type: 0,
        providers: 0,
        providersInCompliance: 0,
        clients: 0,
        contracts: 0,
        cfdis: 0,
        employees: 0,
        storage: 0,
        pendingInvitations: 0,
      };
      state.features = {
        maxUsers: 0,
        maxWorkspaces: 0,
        maxLinks: 0,
        maxStorage: 0,
        monthlyContracts: 0,
      };
    },
    showTips(state, payload: boolean) {
      state.showTips = payload;
    },
    usage(state, payload: AppUsageSummaryDto) {
      if (payload.type === AppUsageType.ALL) {
        state.usage = payload;
      } else if (payload.type === AppUsageType.PROVIDERS) {
        state.usage.providers = payload.providers;
        state.usage.providersInCompliance = payload.providersInCompliance;
      } else if (payload.type === AppUsageType.CLIENTS) {
        state.usage.clients = payload.clients;
      } else if (payload.type === AppUsageType.EMPLOYEES) {
        state.usage.employees = payload.employees;
      } else if (payload.type === AppUsageType.CFDIS) {
        state.usage.cfdis = payload.cfdis;
      } else if (payload.type === AppUsageType.CONTRACTS) {
        state.usage.contracts = payload.contracts;
      } else if (payload.type === AppUsageType.STORAGE) {
        state.usage.storage = payload.storage;
      } else if (payload.type === AppUsageType.PENDING_INVITATIONS) {
        state.usage.pendingInvitations = payload.pendingInvitations;
      }
    },
    features(state, payload: TenantFeaturesDto) {
      state.features = payload;
    },
  },
};
