/* eslint-disable */
import { CreateCredentialCiecRequest } from "@/application/contracts/master/workspaces/CreateCredentialCiecRequest";
import { CreateCredentialEfirmaRequest } from "@/application/contracts/master/workspaces/CreateCredentialEfirmaRequest";
import { CreateWorkspaceRequest } from "@/application/contracts/master/workspaces/CreateWorkspaceRequest";
import { UpdateWorkspaceRequest } from "@/application/contracts/master/workspaces/UpdateWorkspaceRequest";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import { WorkspaceUserDto } from "@/application/dtos/app/workspaces/WorkspaceUserDto";
import { UserDto } from "@/application/dtos/master/users/UserDto";
import { Role } from "@/application/enums/app/common/Role";
import { WorkspaceType } from "@/application/enums/master/tenants/WorkspaceType";
import { i18n } from "@/plugins/vue-i18n";
import store from "@/store";
import fakeCompanies from "../v3/providers/FakeCompanies";
import { IWorkspaceService } from "./IWorkspaceService";

const fakeUser = {
  id: "",
  email: "user@email.com",
};
const workspaces: WorkspaceDto[] = [
  // {
  //   id: "1",
  //   tenant: undefined,
  //   name: "ABSYS SC",
  //   taxId: "ABS12345XYZ",
  //   legalName: "ABSYS SC",
  //   isProvider: true,
  //   public: true,
  //   businessMainActivity: "Business Main Activity",
  //   type: WorkspaceType.SpecializedProvider,
  //   addressStreet: undefined,
  //   addressExterior: undefined,
  //   addressInterior: undefined,
  //   addressNeighborhood: undefined,
  //   addressCity: undefined,
  //   addressZip: undefined,
  //   addressState: undefined,
  //   addressCountry: undefined,
  //   default: false,
  //   users: [
  //     {
  //       workspaceId: "1",
  //       userId: "e9b2b8da-c72b-4b89-872b-81bc5b36a28f",
  //       role: Role.ADMINISTRATOR,
  //       default: false,
  //       id: "1c8a666d-ea0d-4a65-8d0c-d71e8b3c4879",
  //       user: fakeUser as UserDto,
  //     },
  //   ],
  //   projects: [],
  //   documentation: [],
  // },
  // {
  //   id: "2",
  //   tenant: undefined,
  //   name: "COMPANY 2",
  //   taxId: "XYZ12345ABS",
  //   legalName: "COMPANY 2 LEGAL NAME",
  //   isProvider: false,
  //   public: true,
  //   businessMainActivity: "Business Main Activity",
  //   type: WorkspaceType.Regular,
  //   addressStreet: undefined,
  //   addressExterior: undefined,
  //   addressInterior: undefined,
  //   addressNeighborhood: undefined,
  //   addressCity: undefined,
  //   addressZip: undefined,
  //   addressState: undefined,
  //   addressCountry: undefined,
  //   default: false,
  //   users: [
  //     {
  //       workspaceId: "2",
  //       userId: "e9b2b8da-c72b-4b89-872b-81bc5b36a28f",
  //       role: Role.ADMINISTRATOR,
  //       default: false,
  //       id: "1c8a666d-ea0d-4a65-8d0c-d71e8b3c4879",
  //     },
  //   ],
  //   projects: [],
  //   documentation: [],
  // },
];

for (let index = 0; index < fakeCompanies.length; index++) {
  workspaces.push({
    id: (index + 1).toString(),
    tenant: undefined,
    name: fakeCompanies[index].legalName,
    taxId: fakeCompanies[index].taxId,
    legalName: fakeCompanies[index].legalName,
    businessMainActivity: "Servicios de consultoría en computación y desarrollo de software de aplicaciones empresariales.",
    type: WorkspaceType.SPECIALIZED_PROVIDER,
    registrationNumber: "",
    registrationDate: new Date(),
    addressStreet: undefined,
    addressExterior: undefined,
    addressInterior: undefined,
    addressNeighborhood: undefined,
    addressCity: undefined,
    addressZip: undefined,
    addressState: undefined,
    addressCountry: undefined,
    default: false,
    users: [
      {
        workspaceId: "1",
        userId: "e9b2b8da-c72b-4b89-872b-81bc5b36a28f",
        role: Role.ADMINISTRATOR,
        default: false,
        id: "1c8a666d-ea0d-4a65-8d0c-d71e8b3c4879",
        user: fakeUser as UserDto,
      },
    ],
    documentation: [],
  });
}

export class FakeWorkspaceService implements IWorkspaceService {
  workspaces = workspaces;
  adminGetAll(): Promise<WorkspaceDto[]> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const workspaces = this.workspaces.filter((f) => f.type === WorkspaceType.SPECIALIZED_PROVIDER).slice(0, 2);
        resolve(workspaces);
      }, 500);
    });
  }
  getAllPublicProviders(): Promise<WorkspaceDto[]> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const workspaces = this.workspaces.filter((f) => f.type === WorkspaceType.SPECIALIZED_PROVIDER).slice(0, 2);
        resolve(workspaces);
      }, 500);
    });
  }
  getAllWorkspaces(saveInStore: boolean): Promise<WorkspaceDto[]> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const workspaces = this.workspaces.filter((f) => f.type === WorkspaceType.SPECIALIZED_PROVIDER).slice(0, 2);
        if (saveInStore) {
          store.commit("tenant/workspaces", workspaces);
        }
        resolve(workspaces);
      }, 500);
    });
  }
  search(taxId: string, email: string): Promise<WorkspaceDto> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const workspace = this.workspaces.find((f) => f.taxId === taxId);
        if (workspace) {
          const user = workspace.users.map((f) => f.user).find((f) => f?.email === email);
          if (user) {
            console.log("found");
            resolve(workspace);
          }
        }
        console.log("not found");
        reject(i18n.t("shared.notFound"));
      }, 500);
    });
  }
  get(id: string): Promise<WorkspaceDto> {
    const workspace = this.workspaces.find((f) => f.id === id);
    if (workspace) {
      return Promise.resolve(workspace);
    } else {
      return Promise.reject(i18n.t("shared.notFound"));
    }
  }
  create(data: CreateWorkspaceRequest): Promise<WorkspaceDto> {
    throw new Error("Method not implemented.");
  }
  createCredentialCiec(data: CreateCredentialCiecRequest): Promise<void> {
    throw new Error("Method not implemented.");
  }
  createCredentialEfirma(data: CreateCredentialEfirmaRequest): Promise<void> {
    throw new Error("Method not implemented.");
  }
  addUser(id: string, data: WorkspaceUserDto): Promise<WorkspaceUserDto> {
    throw new Error("Method not implemented.");
  }
  update(id: string, data: UpdateWorkspaceRequest): Promise<WorkspaceDto> {
    const workspace = this.workspaces.find((f) => f.id === id);
    if (workspace) {
      workspace.name = data.name;
      workspace.legalName = data.legalName;
      return Promise.resolve(workspace);
    } else {
      return Promise.reject(i18n.t("shared.notFound"));
    }
  }
  updateType(id: string, type: WorkspaceType): Promise<void> {
    throw new Error("Method not implemented.");
  }
  removeUser(id: string): Promise<any> {
    throw new Error("Method not implemented.");
  }
  delete(id: string): Promise<any> {
    throw new Error("Method not implemented.");
  }
  deleteCredential(id: string): Promise<any> {
    throw new Error("Method not implemented.");
  }
  adminDelete(id: string): Promise<any> {
    throw new Error("Method not implemented.");
  }
}
