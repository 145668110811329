





















import Vue from "vue";
import Component from "vue-class-component";
import services from "@/services";
import { AppUsageType } from "../../../application/enums/app/usages/AppUsageType";
import store from "@/store";

@Component({
  components: {}
})
export default class ProvidersUsage extends Vue {
  loading = false;
  mounted() {
    this.loading = true;
    services.tenants.getCurrentUsage(AppUsageType.CLIENTS).finally(() => {
      this.loading = false;
    });
  }
  get clients() {
    return store.state.app.usage.clients;
  }
}
