




























































































































































































import Vue from "vue";
import Component from "vue-class-component";
import TenantMembers from "@/components/app/settings/tenant/TenantMembers.vue";
import { UserDto } from "../../application/dtos/master/users/UserDto";
import EntityTableList from "@/components/shared/entities/EntityTableList.vue";
import { UserType } from "@/application/enums/master/users/UserType";
import { i18n } from "@/plugins/vue-i18n";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";
import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import services from "@/services";
import SuccessModal from "@/components/shared/modals/SuccessModal2.vue";
import Loading from "@/components/shared/loaders/Loading.vue";
import DateUtils from "@/utils/shared/DateUtils";

@Component({
  components: {
    TenantMembers,
    EntityTableList,
    EmptyState,
    ConfirmModal,
    SuccessModal,
    ErrorModal,
    Loading
  }
})
export default class Users extends Vue {
  $refs!: {
    confirmDelete: ConfirmModal;
    errorModal: ErrorModal;
    successModal: SuccessModal;
  };
  items: UserDto[] = [];
  loading = false;
  searchInput = "";
  headers = [
    {
      title: i18n.t("models.user.object")
    },
    {
      title: i18n.t("models.user.tenants")
    },
    {
      title: i18n.t("shared.created")
    },
    {
      title: i18n.t("shared.actions")
    }
  ];
  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    services.users
      .adminGetAll()
      .then((response: UserDto[]) => {
        this.items = response;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  userType(user: UserDto) {
    return UserType[user.type];
  }
  impersonate(user: UserDto) {
    services.authentication.impersonate(user.id);
  }
  changePassword(user: UserDto) {
    const password = prompt(
      this.$t("settings.profile.changePassword") + " - " + user.email
    );
    if (!password || password.length < 8) {
      alert("Set a password with 8 characters minimum");
    } else if (user.type === 0) {
      alert("You cannot change password for admin user");
    } else {
      if (
        confirm("[ADMINISTRATOR] Update password for user " + user.email + "?")
      ) {
        services.users
          .adminUpdatePassword(user.id, password)
          .then(() => {
            alert("Updated");
          })
          .catch(error => {
            alert("Error: " + this.$t(error));
          });
      }
    }
  }
  getUserTenants(user: UserDto) {
    return user.tenants
      .map(
        f =>
          `${f.tenant.name} (${i18n.t(
            "settings.profile.roles." + TenantUserRole[f.role]
          )})`
      )
      .join(", ");
  }
  deleteUser(item: UserDto) {
    this.$refs.confirmDelete.value = item;
    this.$refs.confirmDelete.show(
      `¿Eliminar a ${item.email}?`,
      "Eliminar",
      "Cancelar",
      "ADVERTENCIA: Si el usuario es propietario de organizaciones, se cancelará su subscripción y se eliminarán todas sus empresas. No se puede recuperar un usuario eliminado."
    );
  }
  confirmDeleteUser(item) {
    this.loading = true;
    services.users
      .adminDelete(item.id)
      .then(() => {
        this.reload();
        this.$refs.successModal.show("Eliminado", "Usuario eliminado");
      })
      .catch(error => {
        this.$refs.errorModal.show("Error", this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  dateAgo(value) {
    return DateUtils.dateAgo(value)
  }
  get filteredItems(): UserDto[] {
    if (!this.items) {
      return [];
    }
    const items = this.items.filter(
      f =>
        f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) ||
        f.email
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.firstName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.lastName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.tenants
          ?.map(x => x.tenant?.name)
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase())
    );

    return items.sort((x, y) => {
      if (x.createdAt && y.createdAt) {
        return (x.createdAt > y.createdAt ? -1 : 1) ?? -1;
      }
      return -1;
    });
  }
}
