<template>
  <router-link :to="{ name: 'landing' }">
    <img
      v-if="$store.state.theme.theme === 0"
      class="h-12 w-auto mx-auto"
      src="@/assets/img/logo-light.png"
      alt="Logo"
    />
    <img
      v-else
      class="h-12 w-auto mx-auto"
      src="@/assets/img/logo-dark.png"
      alt="Logo"
    />
  </router-link>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
