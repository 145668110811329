/* eslint-disable */
import { CreateDocumentRequest } from "@/application/contracts/app/v3/documentation/CreateDocumentRequest";
import { SendDocumentationStatusRequest } from "@/application/contracts/app/v3/documentation/SendDocumentationStatusRequest";
import { UpdateDocumentPeriodRequest } from "@/application/contracts/app/v3/documentation/UpdateDocumentPeriodRequest";
import { DocumentationDto } from "@/application/dtos/app/v3/documentation/DocumentationDto";
import { DocumentationStatusDto } from "@/application/dtos/app/v3/documentation/DocumentationStatusDto";
import { Periodicity } from "@/application/enums/app/common/Periodicity";
import { DocumentationType } from "@/application/enums/app/v3/documentation/DocumentationType";
import { i18n } from "@/plugins/vue-i18n";
import FakePdfBase64 from "../contracts/FakePdfBase64";
import { IDocumentationService } from "./IDocumentationService";

const items: DocumentationDto[] = [
  {
    id: "1",
    year: 2021,
    period: 9,
    type: DocumentationType.SAT_COMPLIANCE,
    periodicity: Periodicity.MONTHLY,
    createdAt: new Date(2021, 8, 1),
    hasFile: true,
    public: true,
  },
  {
    id: "2",
    year: 2021,
    period: 9,
    type: DocumentationType.SAT_MONTHLY_ISR_STATEMENT,
    periodicity: Periodicity.MONTHLY,
    createdAt: new Date(2021, 8, 2),
    hasFile: false,
    public: true,
  },
  {
    id: "3",
    year: 2021,
    period: 9,
    type: DocumentationType.IMSS_COMPLIANCE,
    periodicity: Periodicity.MONTHLY,
    createdAt: new Date(2021, 7, 3),
    hasFile: false,
    public: true,
  },
  {
    id: "4",
    year: 2021,
    period: 9,
    type: DocumentationType.INFONAVIT_COMPLIANCE,
    periodicity: Periodicity.MONTHLY,
    createdAt: new Date(2021, 7, 4),
    hasFile: false,
    public: true,
  },
  {
    id: "4",
    year: 2021,
    period: 10,
    type: DocumentationType.INFONAVIT_COMPLIANCE,
    periodicity: Periodicity.MONTHLY,
    createdAt: new Date(2021, 7, 4),
    hasFile: false,
    public: true,
  },
  // {
  //   id: "4",
  //   year: 2021,
  //   number: 1,
  //   type: DocumentationType.TEST_BIMONTHLY,
  //   createdAt: new Date(2021, 7, 4),
  //   hasFile: false,
  //   public: true,
  // },
  {
    id: "4",
    year: 2021,
    period: 3,
    type: DocumentationType.INFONAVIT_SISUB,
    periodicity: Periodicity.QUATERLY,
    createdAt: new Date(2021, 7, 4),
    hasFile: false,
    public: true,
  },
  {
    id: "4",
    year: 2021,
    period: 10,
    type: DocumentationType.STPS_REPSE,
    periodicity: Periodicity.MONTHLY,
    createdAt: new Date(2021, 7, 4),
    hasFile: false,
    public: true,
  },
  {
    id: "5",
    year: 2021,
    period: 1,
    type: DocumentationType.SAT_YEARLY_STATEMENT,
    periodicity: Periodicity.YEARLY,
    createdAt: new Date(2021, 7, 4),
    hasFile: false,
    public: true,
  },
  {
    id: "5",
    year: 2021,
    period: 9,
    type: DocumentationType.IMSS_SUA_EMPLOYEES_FEES,
    periodicity: Periodicity.MONTHLY,
    createdAt: new Date(2021, 7, 4),
    hasFile: false,
    public: true,
  },
  {
    id: "5",
    year: 2021,
    period: 9,
    type: DocumentationType.IMSS_SIPARE_SUA_PAYMENT,
    periodicity: Periodicity.MONTHLY,
    createdAt: new Date(2021, 7, 4),
    hasFile: false,
    public: true,
  },
  {
    id: "5",
    year: 2021,
    period: 9,
    type: DocumentationType.IMSS_SIPARE_SUA,
    periodicity: Periodicity.MONTHLY,
    createdAt: new Date(2021, 7, 4),
    hasFile: false,
    public: true,
  },
  {
    id: "5",
    year: 2021,
    period: 9,
    type: DocumentationType.SAT_MONTHLY_PAYMENT,
    periodicity: Periodicity.MONTHLY,
    createdAt: new Date(2021, 7, 4),
    hasFile: false,
    public: true,
  },
  {
    id: "5",
    year: 2021,
    period: 9,
    type: DocumentationType.SAT_MONTHLY_TAX_STATEMENT,
    periodicity: Periodicity.MONTHLY,
    createdAt: new Date(2021, 7, 4),
    hasFile: false,
    public: true,
  },
  {
    id: "5",
    year: 2021,
    period: 9,
    type: DocumentationType.SAT_MONTHLY_ISR_STATEMENT,
    periodicity: Periodicity.MONTHLY,
    createdAt: new Date(2021, 7, 4),
    hasFile: false,
    public: true,
  },
  {
    id: "5",
    year: 2021,
    period: 9,
    type: DocumentationType.SAT_COMPLIANCE,
    periodicity: Periodicity.MONTHLY,
    createdAt: new Date(2021, 9, 4),
    hasFile: false,
    public: true,
  },
];
// const items: CompanyDocumentationDto[] = [];
export class FakeDocumentationService implements IDocumentationService {
  items = items;
  getMyDocumentationStatus(year: number, validatePreivousPeriods: boolean): Promise<DocumentationStatusDto> {
    const items = this.items.filter((f) => f.year === year);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({} as DocumentationStatusDto);
      }, 500);
    });
  }
  // tslint:disable-next-line: max-line-length
  getDocumentationStatus(linkId: string, year: number, validatePreivousPeriods: boolean): Promise<DocumentationStatusDto> {
    const items = this.items.filter((f) => f.year === year);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({} as DocumentationStatusDto);
      }, 500);
    });
  }
  // tslint:disable-next-line: max-line-length
  sendDocumentationStatus(linkId: string, year: number, validatePreivousPeriods: boolean, payload: SendDocumentationStatusRequest): Promise<void> {
    return Promise.resolve();
  }
  get(id: string): Promise<DocumentationDto> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const item = this.items.find((f) => f.id === id);
        if (item) {
          resolve(item);
        }
        reject(i18n.t("shared.notFound"));
      }, 500);
    });
  }
  downloadDocument(linkId: string, id: string): Promise<string> {
    const item = items.find((f) => f.id === id);
    if (item) {
      return Promise.resolve(FakePdfBase64);
    } else {
      return Promise.reject(i18n.t("shared.notFound"));
    }
  }
  downloadMyDocument(id: string): Promise<string> {
    const item = items.find((f) => f.id === id);
    if (item) {
      return Promise.resolve(FakePdfBase64);
    } else {
      return Promise.reject(i18n.t("shared.notFound"));
    }
  }
  downloadAll(linkId: string, year: number): Promise<any> {
    throw new Error("Method not implemented.");
  }
  downloadAllMyDocuments(year: number): Promise<any> {
    throw new Error("Method not implemented.");
  }
  downloadAllMyProvidersDocuments(year: number): Promise<any> {
    throw new Error("Method not implemented.");
  }
  // downloadDocument(workspaceId: string, year: number, month: number, type: DocumentationType): Promise<any> {
  //   const item = items.find((f) => f.year === year && f.number === month && f.type === type);
  //   if (item) {
  //     const response = { data: item.file };
  //     return Promise.resolve(response);
  //   } else {
  //     return Promise.reject(i18n.t("shared.notFound"));
  //   }
  // }
  create(payload: CreateDocumentRequest): Promise<void> {
    throw new Error("Method not implemented.");
  }
  updateDocumentPeriod(id: string, payload: UpdateDocumentPeriodRequest): Promise<void> {
    throw new Error("Method not implemented.");
  }
  delete(id: string): Promise<void> {
    const item = items.find((f) => f.id === id);
    if (item) {
      this.items = this.items.filter((f) => f.id !== id);
      return Promise.resolve();
    } else {
      return Promise.reject(i18n.t("shared.notFound"));
    }
  }
}
