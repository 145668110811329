




























































































































import Vue from "vue";
import Component from "vue-class-component";
import { DocumentTypeDto } from "@/application/dtos/app/v3/documentation/DocumentTypeDto";
import { DocumentationStatusDto } from "@/application/dtos/app/v3/documentation/DocumentationStatusDto";
import { Prop } from "vue-property-decorator";
import { PeriodStatusDto } from "@/application/dtos/app/v3/documentation/PeriodStatusDto";
import { DocumentationInstitution } from "@/application/enums/app/v3/documentation/DocumentationInstitution";
import { ComplianceStatus } from "@/application/enums/app/v3/documentation/ComplianceStatus";
import { DocumentStatusDto } from "@/application/dtos/app/v3/documentation/DocumentStatusDto";
import { FileBase64 } from "@/application/dtos/shared/FileBase64";
import { Periodicity } from "@/application/enums/app/common/Periodicity";

@Component({
  components: {}
})
export default class DocumentTableRow extends Vue {
  @Prop({}) documentType!: DocumentTypeDto;
  @Prop({}) documentation!: DocumentationStatusDto;
  @Prop({}) canUpload!: boolean;

  isDragging = false;
  imageSources: string[] = [];
  loading = false;
  draggingPeriod: PeriodStatusDto | null = null;

  getDocument(period: PeriodStatusDto): DocumentStatusDto | undefined {
    const documentStatus = this.periods
      .find(f => f.period === period.period)
      ?.documents?.find(f => f.documentType.type === this.documentType.type);
    return documentStatus;
  }
  getStatus(period: PeriodStatusDto): ComplianceStatus {
    const documentStatus = this.getDocument(period);
    if (documentStatus?.documentation?.hasFile) {
      return ComplianceStatus.Compliant;
    }
    if (period.year === 2021 && period.period === 1) {
      console.log({ documentStatus })
    }
    // if (period.periodicity === Periodicity.YEARLY) {
    //   return documentStatus?.status ?? ComplianceStatus.NonCompliant;  
    // }
    return documentStatus?.status ?? ComplianceStatus.NotRequired;
  }
  dragOver(period) {
    if (!this.loading) {
      this.draggingPeriod = period;
      this.isDragging = true;
    }
  }
  dragLeave(period) {
    console.log("leaved: " + period);
    this.draggingPeriod = null;
    this.isDragging = false;
  }
  async compressFileNotImage(imageFile: File): Promise<any> {
    return Promise.resolve(imageFile);
  }
  async drop(e, period: PeriodStatusDto) {
    if (!this.canUpload) {
      return;
    }
    let files: any[] = [...e.dataTransfer.files];
    // console.log({ files });
    const newImagesPromises: any[] = [];
    await files.forEach((element: File) => {
      newImagesPromises.push(this.compressFileNotImage(element));
    });
    files = await Promise.all(newImagesPromises);
    const filesArray: FileBase64[] = [];
    const promises: any[] = [];

    files.forEach(file => {
      const promise = this.getBase64(file);
      promises.push(promise);
      promise.then(response => {
        filesArray.push({
          file,
          base64: response
        });
        this.$emit("dropped", this.getDocument(period), period, response);
      });
    });
    // const all = await Promise.all(promises);
    // if (this.loadImages) {
    //   this.imageSources = this.imageSources.concat(
    //     filesArray.map(f => f.base64)
    //   );
    // }
    this.$emit("droppedFiles", filesArray, files);
    this.isDragging = false;
  }
  getBase64(file) {
    const reader = new FileReader();
    return new Promise(resolve => {
      reader.onload = ev => {
        resolve(ev?.target?.result);
      };
      reader.readAsDataURL(file);
    });
  }
  getClasses(period: PeriodStatusDto) {
    if (this.canUpload) {
      if (this.draggingPeriod === period) {
        return this.isDragging && !this.loading
          ? "bg-theme-100 border-2 border-dashed border-theme-800"
          : "";
      }
    }
  }
  get institutionName() {
    return DocumentationInstitution[this.documentType.institution];
  }
  get periods(): PeriodStatusDto[] {
    return (
      this.documentation.periods.filter(f =>
        f.documents.find(x => x.documentType.type === this.documentType.type)
      ) ?? []
    );
  }
  get colSpan() {
    return 12 / this.periods.length;
  }
}
