/* eslint-disable */
import { TenantInvitationResponse } from "@/application/contracts/master/tenants/TenantInvitationResponse";
import { TenantDto } from "@/application/dtos/master/tenants/TenantDto";
import { UserInviteRequest } from "@/application/contracts/master/users/UserInviteRequest";
import { TenantJoinSettingsDto } from "@/application/dtos/master/tenants/TenantJoinSettingsDto";
import { TenantUserDto } from "@/application/dtos/master/tenants/TenantUserDto";
import { UserVerifyRequest } from "@/application/contracts/master/users/UserVerifyRequest";
import { UserLoggedResponse } from "@/application/contracts/master/users/UserLoggedResponse";
// tslint:disable-next-line: max-line-length
import { TenantUpdateJoinSettingsRequest } from "@/application/contracts/master/tenants/TenantUpdateJoinSettingsRequest";
import { ITenantUserInvitationService } from "./ITenantUserInvitationService";
import { FakeTenantUsersService } from "./FakeTenantUsersService";
import { FakeTenantService } from "./FakeTenantService";

const fakeTenantUsersService = new FakeTenantUsersService();
const fakeTenantService = new FakeTenantService();
const invitation: TenantInvitationResponse = {
  invitation: fakeTenantUsersService.tenantUsers[0],
  tenant: fakeTenantService.tenants[0],
  requiresVerify: true,
};

export class FakeTenantUserInvitationService implements ITenantUserInvitationService {
  getInvitation(tenantUserId: string): Promise<TenantInvitationResponse> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(invitation);
      }, 500);
    });
  }
  getInviteURL(linkUuid: string): Promise<TenantDto> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(invitation.tenant);
      }, 500);
    });
  }
  getInvitationSettings(tenantId?: string): Promise<TenantJoinSettingsDto> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          id: "",
          tenantId: "",
          tenant: {} as TenantDto,
          link: "",
          linkActive: false,
          publicUrl: false,
          requireAcceptance: false,
        });
      }, 500);
    });
  }
  inviteUser(invitation: UserInviteRequest): Promise<TenantUserDto> {
    throw new Error("Method not implemented.");
  }
  requestAccess(linkUuid: string, payload: UserVerifyRequest): Promise<TenantUserDto> {
    throw new Error("Method not implemented.");
  }
  acceptUser(payload: TenantUserDto): Promise<void> {
    throw new Error("Method not implemented.");
  }
  acceptInvitation(tenantUserId: string, payload: UserVerifyRequest): Promise<UserLoggedResponse> {
    throw new Error("Method not implemented.");
  }
  updateInvitationSettings(payload: TenantUpdateJoinSettingsRequest): Promise<TenantJoinSettingsDto> {
    throw new Error("Method not implemented.");
  }
}
