





































































import Vue from "vue";
import Component from "vue-class-component";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import { Prop } from "vue-property-decorator";
import { Period } from "@/application/enums/app/common/Period";
import DropdownOptions from "@/components/shared/dropdowns/DropdownOptions.vue";
import UserMultiselect from "@/components/shared/selectors/UserMultiselect.vue";
import { WorkspaceUserDto } from "@/application/dtos/app/workspaces/WorkspaceUserDto";
import { Colors } from "@/application/enums/app/common/Colors";
import { Role } from "@/application/enums/app/common/Role";
import * as ColorUtils from "@/utils/shared/ColorUtils";
import services from "@/services";
import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";
import store from "@/store";

@Component({
  components: {
    DropdownOptions,
    UserMultiselect,
    ConfirmModal
  }
})
export default class WorkspaceItem extends Vue {
  $refs!: {
    options: DropdownOptions;
    addModal: ConfirmModal;
    removeModal: ConfirmModal;
  };
  @Prop({ default: "" }) id!: string;
  @Prop({ default: Colors.UNDEFINED }) color!: Colors;
  @Prop({ default: "" }) name!: string;
  @Prop({ default: "" }) taxId!: string;
  @Prop({ default: "" }) legalName!: string;
  @Prop({ default: "" }) cfdiUse!: string;
  @Prop({ default: "" }) addressStreet!: string;
  @Prop({ default: "" }) addressExterior!: string;
  @Prop({ default: "" }) addressInterior!: string;
  @Prop({ default: "" }) addressNeighborhood!: string;
  @Prop({ default: "" }) addressCity!: string;
  @Prop({ default: "" }) addressZip!: string;
  @Prop({ default: "" }) addressState!: string;
  @Prop({ default: "" }) addressCountry!: string;
  @Prop({ default: false }) default!: boolean;
  @Prop({ default: [] }) users!: WorkspaceUserDto[];
  @Prop({ default: "" }) createdByUserId!: string;
  @Prop({ default: 0 }) budget!: number;
  @Prop({ default: Period.ALL }) initialPeriod!: Period;
  period: Period = Period.ALL;
  loadingSummary = false;
  userId = "";
  mounted() {
    // this.loadSummary(this.initialPeriod);
  }
  // loadSummary(period: Period) {
  //   this.period = period;
  //   this.loadingSummary = true;
  //   services.dashboard
  //     .getSummaryCountTotal(
  //       SummaryCountTotalType.WORKSPACE,
  //       { period, withDetails: false },
  //       this.id
  //     )
  //     .then((summary: SummaryCountTotalResponse[]) => {
  //       if (summary && summary.length > 0) {
  //         this.summary = summary[0];
  //       }
  //     })
  //     .finally(() => {
  //       this.loadingSummary = false;
  //     });
  // }
  closeOptions() {
    this.$refs.options.close();
  }
  yesAdd() {
    services.workspaces
      .addUser(this.id, {
        id: undefined,
        userId: this.userId,
        workspaceId: this.id,
        role: Role.MEMBER
      })
      .then((response: WorkspaceUserDto) => {
        this.users.push(response);
      });
  }
  yesRemove() {
    const removeUserId = this.userId;
    const user = this.users.find(f => f.userId === removeUserId);
    if (user) {
      services.workspaces.removeUser(user.id).then(() => {
        this.users = this.users.filter(f => f.userId !== removeUserId);
      });
    }
  }
  addedUser(userId: string, email: string, firstName: string) {
    this.userId = userId;
    let userAdded = firstName;
    if (!userAdded || userAdded.toString().trim() === "") {
      userAdded = email;
    }
    this.$refs.addModal.show(
      this.$t("shared.addTo?", [userAdded, this.taxId]).toString(),
      this.$t("shared.add").toString(),
      this.$t("shared.no").toString()
    );
  }
  removedUser(userId: string, email: string, firstName: string) {
    this.userId = userId;
    let userRemoved = firstName;
    if (!userRemoved || userRemoved.toString().trim() === "") {
      userRemoved = email;
    }
    this.$refs.removeModal.show(
      this.$t("shared.removeFrom?", [userRemoved, this.taxId]).toString(),
      this.$t("shared.remove").toString(),
      this.$t("shared.no").toString()
    );
  }
  get userEmails() {
    return this.users.map(f => f.user?.email).join(", ");
  }
  get isProyectOwnerOrAdmin() {
    if (this.isOwnerOrAdmin) {
      return true;
    }
    if (this.createdByUserId === store.state.account.user?.id) {
      return true;
    }
    const usuario = this.users.find(
      f => f.userId === store.state.account.user?.id
    );
    return usuario && usuario.role === Role.ADMINISTRATOR;
  }
  get isProyectOwnerOrAdmins() {
    const ids: string[] = [];
    ids.push(this.createdByUserId);
    const admins = this.users.filter(f => f.role === Role.ADMINISTRATOR);
    admins.forEach(element => {
      ids.push(element.userId);
    });
    return ids;
  }
  get isOwnerOrAdmin(): boolean {
    return (
      this.currentRole === TenantUserRole.OWNER ||
      this.currentRole === TenantUserRole.ADMIN
    );
  }
  get currentRole(): TenantUserRole {
    return store.state.tenant.current?.currentUser.role ?? TenantUserRole.GUEST;
  }
  get idsUsers(): string[] {
    return this.users.map(f => f.userId);
  }
  get companyColor() {
    return ColorUtils.getTailwindColor(Colors.COOL_GRAY, 600, 100, 300);
  }
}
