var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"space-y-2"},[_c('div',{staticClass:" flex items-center space-x-2 justify-between"},[(!_vm.showValidatePreviousPeriods)?_c('h2',{staticClass:" text-base  font-medium"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_c('h2',{staticClass:"text-base font-medium"},[_vm._v(" Estatus de Cumplimiento "),(!_vm.validatePreviousPeriods && _vm.currentPeriod)?_c('span',[_vm._v(" de "+_vm._s(_vm.currentPeriod.periodName)+" ")]):_c('span',[_vm._v("del "+_vm._s(_vm.year)+" ")])]),(_vm.specialized && _vm.canUpload)?_c('div',[(_vm.loading)?_c('span',{staticClass:" italic text-sm text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t("shared.updating"))+"... ")]):_c('button',{staticClass:"flex items-center space-x-3 focus:outline-none",attrs:{"type":"button"},on:{"click":_vm.toggleWorkspaceType}},[_vm._m(0),_c('div',{staticClass:"bg-gray-200 relative inline-flex flex-shrink-0 h-5 w-9 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-theme-500",class:{
            'bg-theme-600': _vm.workspaceType === 1,
            'bg-gray-200': _vm.workspaceType === 0
          },attrs:{"role":"switch","aria-":"","checked":"false"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Proveedor especializado")]),_c('span',{staticClass:"translate-x-0 pointer-events-none relative inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",class:{
              'translate-x-4': _vm.workspaceType === 1,
              'translate-x-0': _vm.workspaceType === 0
            }},[_c('span',{staticClass:"opacity-100 ease-in duration-200 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity",class:{
                'hidden opacity-0 ease-out duration-100': _vm.workspaceType === 1,
                'opacity-100 ease-in duration-200': _vm.workspaceType === 0
              },attrs:{"aria-hidden":"true"}},[_c('svg',{staticClass:"h-3 w-3 text-gray-400",attrs:{"fill":"none","viewBox":"0 0 12 12"}},[_c('path',{attrs:{"d":"M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('span',{staticClass:"opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity",class:{
                'opacity-100 ease-in duration-200': _vm.workspaceType === 1,
                'hidden opacity-0 ease-out duration-100': _vm.workspaceType === 0
              },attrs:{"aria-hidden":"true"}},[_c('svg',{staticClass:"h-3 w-3 text-theme-600",attrs:{"fill":"currentColor","viewBox":"0 0 12 12"}},[_c('path',{attrs:{"d":"M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"}})])])])])])]):(_vm.showValidatePreviousPeriods)?_c('button',{staticClass:" flex-shrink-0 flex items-center space-x-3 focus:outline-none",attrs:{"type":"button","disabled":_vm.loading},on:{"click":_vm.toggleValidatePreviousPeriods}},[_vm._m(1),_c('div',{staticClass:"bg-gray-200 inline-flex flex-shrink-0 h-5 w-9 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-theme-500",class:{
          'bg-theme-600': _vm.validatePreviousPeriods,
          'bg-gray-200': !_vm.validatePreviousPeriods
        },attrs:{"role":"switch","aria-":"","checked":"false"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Validate previous periods")]),_c('span',{staticClass:"translate-x-0 pointer-events-none relative inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",class:{
            'translate-x-4': _vm.validatePreviousPeriods,
            'translate-x-0': !_vm.validatePreviousPeriods
          }},[_c('span',{staticClass:"opacity-100 ease-in duration-200 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity",class:{
              'hidden opacity-0 ease-out duration-100': _vm.validatePreviousPeriods,
              'opacity-100 ease-in duration-200': !_vm.validatePreviousPeriods
            },attrs:{"aria-hidden":"true"}},[_c('svg',{staticClass:"h-3 w-3 text-gray-400",attrs:{"fill":"none","viewBox":"0 0 12 12"}},[_c('path',{attrs:{"d":"M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('span',{staticClass:"opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity",class:{
              'opacity-100 ease-in duration-200': _vm.validatePreviousPeriods,
              'hidden opacity-0 ease-out duration-100': !_vm.validatePreviousPeriods
            },attrs:{"aria-hidden":"true"}},[_c('svg',{staticClass:"h-3 w-3 text-theme-600",attrs:{"fill":"currentColor","viewBox":"0 0 12 12"}},[_c('path',{attrs:{"d":"M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"}})])])])])]):_vm._e()]),_c('div',{staticClass:" overscroll-x-scroll"},[_c('table',{staticClass:"min-w-full divide-y divide-gray-200"},[_c('thead',{staticClass:"bg-gray-50"},[_c('tr',[_c('th',{staticClass:"w-64 px-2 py-2 text-left text-xs text-gray-500 tracking-wider select-none truncate border border-gray-200",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t("models.documentation.plural"))+" ")]),_vm._l((_vm.months),function(month,idxMonth){return _c('th',{key:idxMonth,staticClass:"px-2 py-2 text-left text-xs text-gray-500 select-none truncate border border-gray-200",attrs:{"scope":"col"}},[_c('div',{staticClass:"flex items-center justify-center space-x-1 text-gray-500",class:{
                'font-extrabold text-gray-800': _vm.currentMonth(month)
              }},[_vm._v(" "+_vm._s(month.shortTitle)+" "),(_vm.currentMonth(month))?_c('span',{staticClass:"ml-1"},[_vm._v(" (actual) ")]):_vm._e()])])})],2)]),_c('tbody',{staticClass:"bg-white divide-y divide-gray-200"},_vm._l((_vm.documentTypes),function(document,idxDocument){return _c('DocumentTableRow',{key:idxDocument,attrs:{"documentType":document,"documentation":_vm.documentation,"canUpload":_vm.canUpload},on:{"clickWithinDeadline":_vm.clickWithinDeadline,"clickCompliant":_vm.clickCompliant,"clickNonCompliant":_vm.clickNonCompliant,"dropped":_vm.dropped}})}),1)])]),_c('document-upload',{ref:"uploadDocument",on:{"uploaded":function($event){return _vm.$emit('uploaded')},"updated":function($event){return _vm.$emit('updated')},"deleted":function($event){return _vm.$emit('deleted')}}}),_c('document-view',{ref:"viewDocument",attrs:{"canDelete":_vm.canUpload},on:{"deleted":function($event){return _vm.$emit('deleted')}}}),_c('ErrorModal',{ref:"errorModal",staticClass:"z-30"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"flex-grow flex flex-col"},[_c('span',{staticClass:"text-sm font-normal truncate",attrs:{"id":"availability-label"}},[_vm._v("Estoy registrado en el REPSE")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"flex-grow flex flex-col"},[_c('span',{staticClass:"font-normal text-sm ",attrs:{"id":"availability-label"}},[_vm._v("Validar todo el año")])])}]

export { render, staticRenderFns }