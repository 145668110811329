/* eslint-disable */
import { SyncCfdisStatusMessage } from "@/application/contracts/app/cfdis/SyncCfdisStatusMessage";
import { UploadCfdisRequest } from "@/application/contracts/app/cfdis/UploadCfdisRequest";
import { CfdiDto } from "@/application/dtos/app/v3/cfdis/CfdiDto";
import { CfdiXmlPdfDto } from "@/application/dtos/app/v3/cfdis/CfdiXmlPdfDto";
import { InvoiceExtractionDto } from "@/application/dtos/app/v3/cfdis/sat/InvoiceExtractionDto";
import { CfdiType } from "@/application/enums/app/v3/cfdis/CfdiType";
import { i18n } from "@/plugins/vue-i18n";
import FakePdfBase64 from "../contracts/FakePdfBase64";
import FakeXmlBase64 from "../contracts/FakeXmlBase64";
import { FakeEmployeeService } from "../employees/FakeEmployeeService";
import fakeCompanies from "../providers/FakeCompanies";
import { ICfdiService } from "./ICfdiService";

const fakeEmployeeService = new FakeEmployeeService();
const cfdis: CfdiDto[] = [];

const createCfdi = (index): CfdiDto => {
  const employee = fakeEmployeeService.employees[index];
  const cfdi: CfdiDto = {
    id: index + 1 + "",
    version: "3.3",
    fecha: "2021-09-25T12:00:00",
    sello: "",
    noCertificado: "",
    certificado: "",
    subTotal: (index + 1) * (index + 1),
    moneda: "",
    total: (index + 1) * (index + 1) * 10.16,
    tipoDeComprobante: "N",
    lugarExpedicion: "",
    emisorRfc: fakeCompanies[index].taxId,
    emisorNombre: fakeCompanies[index].legalName,
    emisorRegimenFiscal: "",
    receptorRfc: employee.taxId,
    receptorNombre: employee.firstName + " " + employee.lastName1 + " " + employee.lastName2,
    receptorResidenciaFiscal: "",
    receptorNumRegIdTrib: "",
    receptorUsoCfdi: "P01",
    serie: "",
    folio: "",
    formaPago: "",
    condicionesDePago: "",
    descuento: 0,
    tipoCambio: "",
    metodoPago: "",
    confirmacion: "",
    xmlText: "",
    xml: "data:application/xml;base64," + FakeXmlBase64,
    pdf: "data:application/pdf;base64," + FakePdfBase64,
    fileName: "",
    cfdisRelacionados: [],
    conceptos: [],
    impuestos: "",
    complemento: "",
    addenda: "",
    hasXml: false,
    hasPdf: false,
    employeeId: employee.id,
    employee,
    paymentDate: new Date(),
    startPeriod: new Date(),
    endPeriod: new Date(),
    riskPrime: "",
  };
  if (index % 2 === 0) {
    const contract = { id: index + 1 + "", name: "Contrato " + (index + 1) };
    cfdi.contractEmployeeCfdi = {
      id: "",
      contractId: "",
      contract: contract as any,
      contractEmployeeId: employee.id,
      contractEmployee: {} as any,
      cfdiId: cfdi.id,
      cfdi,
    };
  }
  return cfdi;
};

for (let index = 0; index < fakeEmployeeService.employees.length; index++) {
  const cfdi: CfdiDto = createCfdi(index);
  cfdis.push(cfdi);
}

export class FakeCfdiService implements ICfdiService {
  cfdis: CfdiDto[] = cfdis;
  adminGetAll(): Promise<CfdiDto[]> {
    return new Promise((resolve, reject) => {
      resolve(cfdis);
    });
  }
  getAllCfdis(type: CfdiType): Promise<CfdiDto[]> {
    return new Promise((resolve, reject) => {
      resolve(this.cfdis);
    });
  }
  get(id: string): Promise<CfdiDto> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const cfdi = cfdis.find((f) => f.id?.toString() === id);
        if (cfdi) {
          resolve(cfdi);
        } else {
          reject(i18n.t("shared.notFound").toString());
        }
      }, 500);
    });
  }
  getAllExtractions(): Promise<InvoiceExtractionDto[]> {
    throw new Error("Method not implemented.");
  }
  create(data: CfdiDto): Promise<CfdiDto> {
    throw new Error("Method not implemented.");
  }
  sync(): Promise<SyncCfdisStatusMessage> {
    throw new Error("Method not implemented.");
  }
  download(id: string): Promise<any> {
    throw new Error("Method not implemented.");
  }
  update(id: string, data: CfdiDto): Promise<CfdiDto> {
    throw new Error("Method not implemented.");
  }
  delete(id: string): Promise<any> {
    throw new Error("Method not implemented.");
  }
  readCfdis(data: UploadCfdisRequest): Promise<CfdiXmlPdfDto[]> {
    const read: CfdiXmlPdfDto[] = [];
    this.cfdis.forEach((element) => {
      read.push({
        id: "",
        fileName: element.receptorRfc + " " + element.fecha,
        xml: element.xml,
        pdf: element.pdf,
        cfdi: element,
      });
    });
    return Promise.resolve(read);
  }
  uploadCfdis(data: UploadCfdisRequest): Promise<any> {
    for (let index = 0; index < data.cfdis.length; index++) {
      this.cfdis.push(createCfdi(index));
    }
    return Promise.resolve();
  }
  adminDelete(id: string): Promise<any> {
    throw new Error("Method not implemented.");
  }
}
