
























































import Vue from "vue";
import Component from "vue-class-component";
import store from "@/store";
import { Theme } from "@/application/enums/shared/Theme";

@Component({})
export default class DarkModeToggle extends Vue {
  toggle() {
    if (this.currentTheme === 1) {
      store.commit("theme/theme", Theme.Light);
    } else {
      store.commit("theme/theme", Theme.Dark);
    }
    const htmlClasses = document.querySelector("html")?.classList;
    if (this.currentTheme === 0) {
      htmlClasses?.remove("dark");
    } else {
      htmlClasses?.add("dark");
    }
  }
  get currentTheme() {
    return store.state.theme.theme;
  }
}
