










































































































































import Vue from "vue";
import Component from "vue-class-component";
import services from "@/services";
import { MifielDocument } from "@/application/dtos/app/v3/mifiel/MifielDocument";
import { MifielSigner } from "@/application/dtos/app/v3/mifiel/MifielSigner";
import store from "@/store";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import { ContractMemberDto } from "@/application/dtos/app/v3/contracts/ContractMemberDto";
import SuccessModal from "@/components/shared/modals/SuccessModal2.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import DateUtils from "@/utils/shared/DateUtils";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  components: {
    ErrorModal,
    SuccessModal,
    Loading
  }
})
export default class ContractSign extends Vue {
  $refs!: {
    errorModal: ErrorModal;
    successModal: SuccessModal;
  };
  item: MifielDocument | null = null;
  signer: MifielSigner | null = null;
  loading = false;
  savingSignature = false;
  currentMember: ContractMemberDto | null = null;
  widgetOpened = false;
  showingSignedModal = false;
  widgetSuccess = false;
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute("type", "text/javascript");
    const url = process.env.VUE_APP_MIFIEL_API_URL?.toString() ?? "";
    if (url) {
      plugin.text = `window.mifiel=window.mifiel||[],function(){"use strict";for(var e=["widget"],i=function(e){return function(){window.mifiel.push([e].concat(Array.prototype.slice.call(arguments,0)))}},t=0;t<e.length;t++){var n=e[t];window.mifiel[n]||(window.mifiel[n]=i(n))}if(!document.getElementById("mifiel-js")){var r=document.createElement("script"),o=document.getElementsByTagName("script")[0];r.type="text/javascript",r.id="mifiel-js",r.async=!0,r.src="${url}/sign-widget-v1.0.0.js",o.parentNode.insertBefore(r,o)}}();`;
      plugin.async = true;
      document.head.appendChild(plugin);
    }
    this.reload();
  }
  beforeDestroy() {
    // @ts-ignore
    window.removeEventListener("message", this.mifielMessage);
  }
  reload() {
    this.loading = true;
    services.contracts
      .getContract(this.$route.params.id, false)
      .then(response => {
        this.currentMember =
          response.members.find(
            f => f.user.email === store.state.account.user?.email
          ) ?? null;
        if (this.currentMember && !this.currentMember.signDate) {
          services.contracts
            .getDigitalDocument(this.$route.params.id)
            .then(response => {
              this.item = response;
              this.signer =
                this.item?.signers.find(
                  f => f.email === store.state.account.user?.email
                ) ?? null;

              if (this.signer?.signed) {
                this.signed("signed");
              } else {
                this.setupSignWidget();
              }
              this.loading = false;
            })
            .catch(error => {
              console.error(error);
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      })
      .catch(error => {
        console.error(error);
        this.loading = false;
      });
  }
  setupSignWidget() {
    if (!this.signer || this.signer.signed) {
      return;
    }
    this.widgetOpened = true;
    // @ts-ignore
    window.mifiel.widget({
      widgetId: this.signer.widget_id,
      appendTo: "mifielwidget",
      successBtnText: "Clic aquí para cerrar"
    });

    window.addEventListener("message", this.mifielMessage, false);
  }
  mifielMessage(e) {
    if (!e.origin.includes(".mifiel.com")) {
      return;
    }
    if (typeof e.data !== "object") {
      return;
    }
    console.log("e.data.eventType", e.data.eventType);
    if (e.data.eventType === "mifiel.widget.successStep") {
      e.stopImmediatePropagation();
      this.widgetSuccess = true;
      this.signed("signed");
    } else if (e.data.eventType.includes("mifiel.widget.error")) {
      const error = e.data.error;
      this.$refs.errorModal.show("Error", error.message);
    }
  }
  signed(signature: string) {
    if (this.savingSignature) {
      console.log("return saving signature");
      return;
    }
    this.savingSignature = true;
    console.log("saving signature");
    this.loading = true;
    services.contracts
      .signed(this.$route.params.id, signature)
      .then(() => {
        tinyEventBus().emitter.emit("contract-reload");
        this.showingSignedModal = true;
        if (this.$refs.successModal) {
          this.$refs.successModal.show(
            "Contrato firmado",
            "Has firmado el contrato correctamente."
          );
        } else {
          this.back();
        }
      })
      .catch(error => {
        this.$refs.errorModal.show("Error", this.$t(error));
      })
      .finally(() => {
        this.loading = false;
        this.savingSignature = false;
      });
  }
  back() {
    this.$router.push({
      name: "app.contracts.view",
      params: { id: this.$route.params.id }
    });
  }
  dateDM(value: Date | undefined) {
    return DateUtils.dateDM(value);
  }
}
