

























































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import services from "@/services";
import { TenantUserDto } from "@/application/dtos/master/tenants/TenantUserDto";

@Component({
  components: {}
})
export default class UserMultiselect extends Vue {
  @Prop() initialSelected!: string[];
  @Prop() exclude!: string[];
  mostrar = false;
  loading = false;
  selected: string[] = [];
  tenantUsers: TenantUserDto[] = [];
  mounted() {
    this.loading = true;
    this.selected = this.initialSelected;
    if (!this.selected) {
      this.selected = [];
    }
    services.tenantUsers
      .getAll("")
      .then((response: TenantUserDto[]) => {
        this.tenantUsers = response;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  close() {
    this.mostrar = false;
  }
  select(userId: string) {
    const user = this.tenantUsers.find(f => f.userId === userId);
    if (user) {
      if (this.selected.includes(userId)) {
        this.selected = this.selected.filter(f => f !== userId);
        console.log("removed: " + JSON.stringify(user));
        this.$emit(
          "removed",
          userId,
          user.email,
          user.firstName,
          user.lastName
        );
        this.$emit("updatedSelected", this.selected);
        this.close();
      } else {
        this.selected.push(userId);
        console.log("added: " + JSON.stringify(user));
        this.$emit("added", userId, user.email, user.firstName, user.lastName);
        this.$emit("updatedSelected", this.selected);
        this.close();
      }
    }
  }
  get users() {
    if (!this.tenantUsers || this.tenantUsers.length === 0) {
      return [];
    }
    return this.tenantUsers;
    // const tenantUsers: TenantUserDto[] = [];
    // // this.tenantUsers.forEach((element) => {
    // //   if (!this.exclude || !this.exclude.includes(element.userId)) {
    // //     tenantUsers.push(element);
    // //   }
    // // });
    // return tenantUsers;
  }
}
