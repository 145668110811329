<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="50"
    height="50"
    viewBox="0 0 172 172"
    fill="currentColor"
  >
    <g transform="translate(0.86,0.86) scale(0.99,0.99)">
      <g
        fill="none"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style="mix-blend-mode: normal"
      >
        <path d="M0,172v-172h172v172z" fill="none" stroke="none"></path>
        <g fill="currentColor" stroke="none">
          <path
            d="M86,6.88c-43.65603,0 -79.12,35.46397 -79.12,79.12c0,43.65603 35.46397,79.12 79.12,79.12c43.65603,0 79.12,-35.46397 79.12,-79.12c0,-43.65603 -35.46397,-79.12 -79.12,-79.12zM86,13.76c39.93779,0 72.24,32.30221 72.24,72.24c0,39.93779 -32.30221,72.24 -72.24,72.24c-39.93779,0 -72.24,-32.30221 -72.24,-72.24c0,-39.93779 32.30221,-72.24 72.24,-72.24zM85.94625,24.03297c-1.89722,0.02966 -3.41223,1.58976 -3.38625,3.48703v48.75797c-4.12064,1.45686 -6.87671,5.35144 -6.88,9.72203c0.00314,1.53746 0.34976,3.05478 1.01453,4.4411l-20.64672,20.64672c-0.89867,0.86281 -1.26068,2.14404 -0.94641,3.34956c0.31427,1.20552 1.2557,2.14696 2.46122,2.46122c1.20552,0.31427 2.48675,-0.04774 3.34956,-0.94641l20.64672,-20.64672c1.38631,0.66477 2.90364,1.01139 4.4411,1.01453c5.69958,0 10.32,-4.62042 10.32,-10.32c-0.00613,-4.36813 -2.76169,-8.25927 -6.88,-9.71531v-48.76469c0.01273,-0.92983 -0.35149,-1.82522 -1.00967,-2.48214c-0.65819,-0.65692 -1.55427,-1.01942 -2.48408,-1.00489z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>