












































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import ClientsList from "@/components/app/v3/contacts/clients/list/ClientsList.vue";
import LinkForm from "@/components/app/v3/contacts/LinkForm.vue";
import ButtonPrimary from "@/components/shared/buttons/ButtonPrimary.vue";

@Component({
  metaInfo: {
    title: "Mis clientes",
    titleTemplate: "%s | Délega"
  },
  components: {
    Breadcrumb,
    ClientsList,
    LinkForm,
    ButtonPrimary
  }
})
export default class Clients extends Vue {
  $refs!: {
    newLinkForm: LinkForm;
    list: ClientsList;
  };
  newLink() {
    this.$refs.newLinkForm.show();
  }
  reload() {
    this.$refs.list.reload();
  }
}
