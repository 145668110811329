


















































import Vue from "vue";
import Component from "vue-class-component";
import services from "@/services";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import NewModal from "@/components/shared/modals/NewModal.vue";
import ClientsListAndTable from "./ClientsListAndTable.vue";
import { LinkDto } from "@/application/dtos/master/v3/links/LinkDto";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  components: {
    TableRowSkeleton,
    NewModal,
    ClientsListAndTable,
    Loading
  }
})
export default class ClientsList extends Vue {
  loading = false;
  items: LinkDto[] = [];
  // pending: LinkDto[] = [];

  searchInput = "";

  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    services.links
      .getAllClients()
      .then(response => {
        this.items = response;
      })
      .finally(() => {
        this.loading = false;
      });
    // services.clients.getAllPending().then(response => {
    //   this.pending = response;
    // });
  }
  get filteredItems(): LinkDto[] {
    if (!this.items) {
      return [];
    }
    const items = this.items.filter(
      f =>
        f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) ||
        f.clientWorkspace.taxId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.clientWorkspace.legalName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase())
    );

    return items;
  }
  get sortedItems(): LinkDto[] {
    this.$forceUpdate();
    return this.filteredItems;
  }
}
