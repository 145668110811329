




import Vue from "vue";
import Component from "vue-class-component";
import InvoiceExtractionsList from "@/components/app/v3/cfdis/extractions/InvoiceExtractionsList.vue";

@Component({
  metaInfo: {
    title: "Extracciones",
    titleTemplate: "%s | Délega"
  },
  components: {
    InvoiceExtractionsList
  }
})
export default class CfdisSync extends Vue {
  $refs!: {
    list: InvoiceExtractionsList;
  };
}
