var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close($event)}}},[_c('div',{staticClass:"absolute inset-0 bg-gray-900 bg-opacity-50 transition-opacity"}),_c('div',{staticClass:"fixed inset-0 overflow-hidden z-10"},[_c('div',{staticClass:"absolute inset-0 overflow-hidden"},[_c('section',{staticClass:"absolute inset-y-0 right-0 pl-10 max-w-full flex"},[(false)?_c('div',{staticClass:"flex h-screen justify-center pt-20 w-1"},[_c('div',{staticClass:"text-center"},[(!_vm.title)?_c('button',{staticClass:"focus:outline-none hover:opacity-95 transition duration-300 ease-in-out transform hover:-translate-x-2 hover:scale-x-105 h-16 w-14 pr-5 flex justify-center rounded-lg bg-gray-800 items-center",attrs:{"type":"button"},on:{"click":_vm.close}},[_c('svg',{staticClass:"h-4 text-gray-200",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M6 18L18 6M6 6l12 12"}})])]):_vm._e()])]):_vm._e(),_c('transition',{attrs:{"enter-active-class":"transform transition ease-in-out duration-500 sm:duration-700","enter-class":"-translate-x-full","enter-to-class":"translate-x-0","leave-active-class":"transform transition ease-in-out duration-500 sm:duration-700","leave-class":"translate-x-0","leave-to-class":"-translate-x-full"}},[_c('div',{staticClass:"w-screen max-w-sm",class:{
              'md:max-w-md': _vm.size === 'md',
              'md:max-w-lg': _vm.size === 'lg',
              'md:max-w-2xl': _vm.size === '2xl',
              'md:max-w-3xl': _vm.size === '3xl',
              'md:max-w-4xl': _vm.size === '4xl',
              'md:max-w-full': _vm.size === 'full'
            }},[_c('div',{staticClass:"h-full divide-y divide-gray-200 flex flex-col bg-white shadow-2xl"},[_c('div',{staticClass:"h-0 flex-1 space-y-6 overflow-y-scroll",class:_vm.paddingY},[(_vm.title)?_c('header',{staticClass:"px-4 sm:px-6"},[_c('div',{staticClass:"flex items-start justify-between space-x-3"},[_c('h2',{staticClass:"text-lg leading-7 font-medium text-gray-900"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"h-7 flex items-center"},[_c('button',{staticClass:"text-gray-400 hover:text-gray-500 transition ease-in-out duration-150",attrs:{"aria-label":"Close panel"},on:{"click":_vm.close}},[_c('svg',{staticClass:"h-6 w-6",attrs:{"fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M6 18L18 6M6 6l12 12"}})])])])])]):_vm._e(),_c('div',{staticClass:"relative flex-1",class:_vm.paddingContent},[_c('div',[_vm._v(_vm._s(_vm.showingDocument.documentation.id))])])])])])])],1)]),_c('div',{staticClass:"absolute top-4 right-10"},[(!_vm.title)?_c('button',{staticClass:"flex items-center space-x-1 py-1 px-2 text-xs uppercase hover:bg-gray-200 rounded-md shadow-lg bg-white border border-gray-300 text-gray-800 font-extrabold",on:{"click":_vm.close}},[_c('div',[_vm._v(_vm._s(_vm.$t("shared.close")))]),_c('svg',{staticClass:"h-3.5",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M6 18L18 6M6 6l12 12"}})])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }