/* eslint-disable */
import { LinkDto } from "@/application/dtos/master/v3/links/LinkDto";
import { LinkStatus } from "@/application/enums/app/v3/links/LinkStatus";
import { ILinkService } from "./ILinkService";
import { i18n } from "@/plugins/vue-i18n";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import { FakeWorkspaceService } from "@/services/api/app/workspaces/FakeWorkspaceService";
import fakeNamesAndEmails from "../../tenants/FakeNamesAndEmails";
import { WorkspaceUserDto } from "@/application/dtos/app/workspaces/WorkspaceUserDto";
import { FakeContractService } from "@/services/api/app/v3/contracts/FakeContractService";
import { DocumentationDto } from "@/application/dtos/app/v3/documentation/DocumentationDto";
import documentTypes from "@/application/dtos/app/v3/documentation/DocumentationTypes";
import { FakeUserService } from "../../users/FakeUserService";
import { DocumentationStatusFilter } from "@/application/contracts/app/v3/documentation/DocumentationStatusFilter";
import fakeCompanies from "@/services/api/app/v3/providers/FakeCompanies";
import { WorkspaceType } from "@/application/enums/master/tenants/WorkspaceType";
import { LinkInvitationDto } from "@/application/dtos/master/v3/links/LinkInvitationDto";
import { UserDto } from "@/application/dtos/master/users/UserDto";
import { CreateLinkRequest } from "@/application/contracts/master/v3/links/CreateLinkRequest";
import { UpdateLinkRequest } from "@/application/contracts/master/v3/links/UpdateLinkRequest";

const fakeContractService = new FakeContractService();
const fakeWorkspaceService = new FakeWorkspaceService();
const fakeUserService = new FakeUserService();

const providers: LinkDto[] = [];
for (let index = 1; index <= 10; index++) {
  const documentation: DocumentationDto[] = [];
  const periods = [
    {
      year: 2021,
      month: 9,
    },
    {
      year: 2021,
      month: 10,
    },
  ];

  let documentNumber = 0;
  if (index > 1 && index <= 5) {
    documentNumber = 4;
  } else {
    documentNumber = documentTypes.length;
  }
  // tslint:disable-next-line: prefer-for-of
  for (let periodIdx = 0; periodIdx < periods.length; periodIdx++) {
    const period = periods[periodIdx];
    if (index >= 3 && index <= 7 && period.month === 9) {
      continue;
    }
    const date = new Date(period.year, period.month, 1);
    for (let index = 0; index < documentNumber; index++) {
      const documentType = documentTypes[index];
      documentation.push({
        id: "",
        year: date.getFullYear(),
        period: date.getMonth(),
        periodicity: documentType.periodicity,
        type: documentType.type,
        hasFile: true,
        public: true,
      });
    }
  }

  const provider: LinkDto = {
    createdAt: new Date(),
    id: (index + 100).toString(),
    createdByUserId: fakeUserService.users[0].id,
    createdByUser: fakeUserService.users[0],
    createdByWorkspaceId: index === 1 ? "2" : "1",
    createdByWorkspace: {} as WorkspaceDto,
    providerWorkspaceId: (index - 1).toString(),
    providerWorkspace: {
      id: index.toString(),
      name: fakeCompanies[index - 1].legalName,
      legalName: fakeCompanies[index - 1].legalName,
      taxId: fakeCompanies[index - 1].taxId,
      businessMainActivity: "Servicios de consultoría en computación y desarrollo de software de aplicaciones empresariales.",
      type: WorkspaceType.SPECIALIZED_PROVIDER,
      registrationNumber: "",
      registrationDate: undefined,
      users: [],
      documentation,
    },
    clientWorkspaceId: index.toString(),
    clientWorkspace: {
      id: (index + 1).toString(),
      name: fakeCompanies[index].legalName,
      legalName: fakeCompanies[index].legalName,
      taxId: fakeCompanies[index].taxId,
      businessMainActivity: "Servicios de consultoría en computación y desarrollo de software de aplicaciones empresariales.",
      type: WorkspaceType.REGULAR,
      registrationNumber: "",
      registrationDate: undefined,
      users: [],
      documentation: [],
    },
    contracts: [],
    status: index <= 2 ? LinkStatus.PENDING : LinkStatus.LINKED,
  };
  // tslint:disable-next-line: max-line-length
  const contracts = fakeContractService.contracts.filter((f) => f.workspace?.taxId === provider.providerWorkspace.taxId);
  provider.contracts = contracts;
  providers.push(provider);
}

const clients: LinkDto[] = [];
for (let index = 1; index <= 10; index++) {
  const client: LinkDto = {
    id: (index + 200).toString(),
    createdAt: new Date(),
    createdByUserId: fakeUserService.users[0].id,
    createdByUser: fakeUserService.users[0],
    createdByWorkspaceId: index === 1 ? "1" : "2",
    createdByWorkspace: {} as WorkspaceDto,
    providerWorkspaceId: "1",
    providerWorkspace: {
      id: "1",
      name: fakeCompanies[index - 1].legalName,
      legalName: fakeCompanies[index - 1].legalName,
      taxId: fakeCompanies[index - 1].taxId,
      businessMainActivity: "Servicios de consultoría en computación y desarrollo de software de aplicaciones empresariales.",
      type: WorkspaceType.SPECIALIZED_PROVIDER,
      registrationNumber: "",
      registrationDate: new Date(),
      users: [],
      documentation: [],
    },
    clientWorkspaceId: "2",
    clientWorkspace: {
      id: "2",
      name: fakeCompanies[index - 1].legalName,
      legalName: fakeCompanies[index - 1].legalName,
      taxId: fakeCompanies[index - 1].taxId,
      businessMainActivity: "Servicios de consultoría en computación y desarrollo de software de aplicaciones empresariales.",
      type: WorkspaceType.REGULAR,
      registrationNumber: "",
      registrationDate: new Date(),
      users: [],
      documentation: [],
    },
    contracts: [],
    status: index <= 2 ? LinkStatus.PENDING : LinkStatus.LINKED,
  };
  // tslint:disable-next-line: max-line-length
  const contracts = fakeContractService.contracts.filter((f) => f.linkId === client.id);
  client.contracts = contracts;
  clients.push(client);
}

const links: LinkDto[] = [...providers, ...clients];

const invitations: LinkInvitationDto[] = [
  {
    id: "1",
    createdByUser: {
      email: "alex@delega.com.mx",
      firstName: "Alexandro",
      lastName: "Martinez",
    },
    createdByWorkspace: {
      name: "Absys",
      taxId: "ABS990201VE4",
      legalName: "ABSYS SC",
    },
    taxId: "RFC123456789",
    legalName: "RAZÓN SOCIAL",
    email: "pedro@delega.com.mx",
    message: "Favor de crear su cuenta para creación de contrato #ABC123.",
    status: LinkStatus.PENDING,
    inviteeIsProvider: true,
  } as LinkInvitationDto,
  {
    id: "2",
    createdByUser: {
      email: "alex@delega.com.mx",
      firstName: "Alexandro",
      lastName: "Martinez",
    },
    createdByWorkspace: {
      name: "Absys",
      taxId: "ABS990201VE4",
      legalName: "ABSYS SC",
    },
    taxId: "RFC123456789",
    legalName: "RAZÓN SOCIAL",
    email: "pedro@delega.com.mx",
    message: "Favor de crear su cuenta para creación de contrato #ABC123.",
    status: LinkStatus.PENDING,
    inviteeIsProvider: false,
  } as LinkInvitationDto,
];

export class FakeLinkService implements ILinkService {
  links: LinkDto[] = links;
  providers: LinkDto[] = providers;
  clients: LinkDto[] = clients;
  invitations: LinkInvitationDto[] = invitations;
  getAllLinked(): Promise<LinkDto[]> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.links.filter((f) => f.status === 1));
      }, 500);
    });
  }
  getAllPending(): Promise<LinkDto[]> {
    return new Promise((resolve, reject) => {
      const links = this.links.filter((f) => f.status === 0);
      setTimeout(() => {
        resolve(links);
      }, 500);
    });
  }
  getAllProviders(filter: DocumentationStatusFilter): Promise<LinkDto[]> {
    return new Promise((resolve, reject) => {
      let providers = this.providers.filter((f) => f.status === 1);
      if (filter === DocumentationStatusFilter.NonCompliant) {
        providers = providers.filter((f) => f.providerWorkspace.documentation.length !== documentTypes.length);
      } else if (filter === DocumentationStatusFilter.Compliant) {
        providers = providers.filter((f) => f.providerWorkspace.documentation.length === documentTypes.length);
      }
      setTimeout(() => {
        resolve(providers.filter((f) => f.status === 1));
      }, 500);
    });
  }
  getAllClients(): Promise<LinkDto[]> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.clients);
      }, 500);
    });
  }
  getLinkUsers(linkId: string): Promise<WorkspaceUserDto[]> {
    throw new Error("Method not implemented.");
  }
  getInvitation(id: string): Promise<LinkInvitationDto> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const invitation = this.invitations.find((f) => f.id === id);
        if (invitation) {
          resolve(invitation);
        }
        reject(i18n.t("shared.notFound"));
      }, 500);
    });
  }
  createInvitation(payload: LinkInvitationDto): Promise<LinkInvitationDto> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.invitations.push(payload);
        resolve(payload);
      }, 500);
    });
  }
  rejectInvitation(id: string): Promise<void> {
    console.log("rejected");
    return Promise.resolve();
  }
  getWorkspace(linkId: string): Promise<WorkspaceDto> {
    const link = this.links.find((f) => f.id === linkId);
    // tslint:disable-next-line: max-line-length
    const workspace = fakeWorkspaceService.workspaces.find((f) => f.id === link?.clientWorkspaceId || f.id === link?.providerWorkspaceId);
    if (workspace) {
      return Promise.resolve(workspace);
    } else {
      return Promise.reject(i18n.t("shared.notFound"));
    }
  }
  searchUser(email: string): Promise<UserDto> {
    const fakeUsers: any[] = [];
    fakeNamesAndEmails.forEach((fakeEmail) => {
      fakeUsers.push({
        email: fakeEmail.email,
        firstName: fakeEmail.firstName,
        lastName: fakeEmail.lastName,
      });
    });
    const user = fakeUsers.find((f) => f.email === email);
    if (user) {
      return Promise.resolve(user);
    }
    return Promise.reject(i18n.t("shared.notFound"));
  }
  searchMember(taxId: string, email: string): Promise<WorkspaceUserDto> {
    const fakeUsers: any[] = [];
    fakeNamesAndEmails.forEach((fakeEmail) => {
      fakeUsers.push({
        email: fakeEmail.email,
        firstName: fakeEmail.firstName,
        lastName: fakeEmail.lastName,
      });
    });
    const user = fakeUsers.find((f) => f.email === email);
    if (user) {
      return Promise.resolve(user);
    }
    return Promise.reject(i18n.t("shared.notFound"));
  }
  getMember(linkId: string, email: string): Promise<WorkspaceUserDto> {
    const fakeUsers: any[] = [];
    fakeNamesAndEmails.forEach((fakeEmail) => {
      fakeUsers.push({
        email: fakeEmail.email,
        firstName: fakeEmail.firstName,
        lastName: fakeEmail.lastName,
      });
    });
    const user = fakeUsers.find((f) => f.email === email);
    if (user) {
      return Promise.resolve(user);
    }
    return Promise.reject(i18n.t("shared.notFound"));
  }
  get(id: string): Promise<LinkDto> {
    const link = this.links.find((f) => f.id === id);
    if (link) {
      return Promise.resolve(link);
    } else {
      return Promise.reject(i18n.t("shared.notFound"));
    }
  }
  create(data: CreateLinkRequest): Promise<LinkDto> {
    return Promise.resolve(this.links[0]);
  }
  acceptOrReject(id: string, data: UpdateLinkRequest): Promise<LinkDto> {
    const link = this.links.find((f) => f.id === id);
    if (link) {
      link.status = data.accepted ? LinkStatus.LINKED : LinkStatus.REJECTED;

      return Promise.resolve(link);
    }
    return Promise.reject(i18n.t("shared.notFound"));
  }
  delete(id: string): Promise<void> {
    const link = this.links.find((f) => f.id === id);
    if (link) {
      this.links = this.links.filter((f) => f.id !== id);
      return Promise.resolve();
    } else {
      return Promise.reject(i18n.t("shared.notFound"));
    }
  }
}
