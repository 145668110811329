















































































































import Vue from "vue";
import Component from "vue-class-component";
import LinksPendingList from "./LinksPendingList.vue";

@Component({
  components: {
    LinksPendingList
  }
})
export default class PendingInvitationsSideModal extends Vue {
  // @Prop({})

  showing = false;
  show() {
    this.showing = true;
  }
  close() {
    this.showing = false;
  }
}
