/* eslint-disable */
import { ContractDto } from "@/application/dtos/app/v3/contracts/ContractDto";
import { i18n } from "@/plugins/vue-i18n";
import { IContractService } from "./IContractService";
import { CreateContractRequest } from "@/application/contracts/app/v3/contracts/CreateContractRequest";
import { ContractStatus } from "@/application/enums/app/v3/contracts/ContractStatus";
import { SendContractRequest } from "@/application/contracts/app/v3/contracts/SendContractRequest";
import { ContractActivityDto } from "@/application/dtos/app/v3/contracts/ContractActivityDto";
import { ContractActivityType } from "@/application/enums/app/v3/contracts/ContractActivityType";
import { FakeWorkspaceService } from "../../workspaces/FakeWorkspaceService";
import { ContractMemberDto } from "@/application/dtos/app/v3/contracts/ContractMemberDto";
import { ContractMemberRole } from "@/application/enums/app/v3/contracts/ContractMemberRole";
import { EmployeeDto } from "@/application/dtos/app/v3/employees/EmployeeDto";
import { ContractEmployeeDto } from "@/application/dtos/app/v3/contracts/ContractEmployeeDto";
import FakePdfBase64 from "./FakePdfBase64";
import { UserDto } from "@/application/dtos/master/users/UserDto";
import { FakeUserService } from "../../../master/users/FakeUserService";
import { ContractEmployeeListDto } from "@/application/dtos/app/v3/contracts/ContractEmployeeListDto";
import { UpdateContractRequest } from "@/application/contracts/app/v3/contracts/UpdateContractRequest";
import { ContractInvitationDto } from "@/application/dtos/app/v3/contracts/ContractInvitationDto";
import { ContractInvitationStatus } from "@/application/enums/app/v3/contracts/ContractInvitationStatus";
import { ContractEmployeeCfdiDto } from "@/application/dtos/app/v3/contracts/ContractEmployeeCfdiDto";
import { FakeCfdiService } from "../cfdis/FakeCfdiService";
import store from "@/store";
import { ContractVisitDto } from "@/application/dtos/app/v3/contracts/ContractVisitDto";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import { WorkspaceType } from "@/application/enums/master/tenants/WorkspaceType";
import { FakeEmployeeService } from "../employees/FakeEmployeeService";
import fakeCompanies from "../providers/FakeCompanies";
import { ContractStatusFilter } from "@/application/contracts/app/v3/contracts/ContractStatusFilter";
import { LinkDto } from "@/application/dtos/master/v3/links/LinkDto";
import { LinkStatus } from "@/application/enums/app/v3/links/LinkStatus";
import { CreateVisitRequest } from "@/application/contracts/app/v3/contracts/CreateVisitRequest";
import { CreateEmployeeListRequest } from "@/application/contracts/app/v3/contracts/CreateEmployeeListRequest";
import { MifielDocument } from "@/application/dtos/app/v3/mifiel/MifielDocument";
import { ContractFileType } from "@/application/enums/app/v3/contracts/ContractFileType";

const userService = new FakeUserService();
const cfdisService = new FakeCfdiService();
const fakeEmployeeService = new FakeEmployeeService();

const users: UserDto[] = userService.users;
const contracts: ContractDto[] = [];
const invitations: ContractInvitationDto[] = [];

// MEMBERS
const members: ContractMemberDto[] = [
  {
    id: "",
    userId: userService.users[0].id,
    user: userService.users[0],
    role: ContractMemberRole.SIGNATORY,
    contractId: "",
    contract: {} as ContractDto,
    signDate: new Date(),
  },
  {
    id: "",
    userId: userService.users[1].id,
    user: userService.users[1],
    role: ContractMemberRole.SIGNATORY,
    contractId: "",
    contract: {} as ContractDto,
  },
  {
    id: "",
    userId: userService.users[2].id,
    user: userService.users[2],
    role: ContractMemberRole.SPECTATOR,
    contractId: "",
    contract: {} as ContractDto,
  },
];

// EMPLOYEES
const employees: ContractEmployeeDto[] = [];
for (let index = 1; index <= 10; index++) {
  const employee: ContractEmployeeDto = {
    id: (index + 1).toString(),
    createdAt: new Date(),
    listId: "",
    list: {} as ContractEmployeeListDto,
    employeeId: "",
    employee: {
      id: "",
      firstName: fakeEmployeeService.employees[index - 1].firstName,
      lastName1: fakeEmployeeService.employees[index - 1].lastName1,
      lastName2: fakeEmployeeService.employees[index - 1].lastName2,
      taxId: fakeEmployeeService.employees[index - 1].taxId,
      socialSecurityId: fakeEmployeeService.employees[index - 1].socialSecurityId,
      populationRegistryId: fakeEmployeeService.employees[index - 1].populationRegistryId,
      email: fakeEmployeeService.employees[index - 1].email,
      cfdis: [],
    }, // cfdis: [],
  };
  employees.push(employee);
  employee.employee.cfdis?.push(cfdisService.cfdis[index - 1]);
  // for (let idxCfdi = 1; idxCfdi <= 3; idxCfdi++) {
  //   const cfdi = cfdisService.cfdis[index - 1];
  // }
}
const employeeLists: ContractEmployeeListDto[] = [
  {
    id: "1",
    number: 1,
    employees,
    createdByUser: users[0],
    hasFile: false,
    digitalSignatureId: "",
  },
];

// CONTRACTS
const contractStatus = [ContractStatus.PENDING, ContractStatus.SIGNED, ContractStatus.SIGNED, ContractStatus.ARCHIVED];
// tslint:disable-next-line: max-line-length
const activityTypes = [
  ContractActivityType.CREATED,
  ContractActivityType.SIGNED_PARTIAL,
  ContractActivityType.SIGNED_PARTIAL,
  ContractActivityType.SIGNED_FULL,
  ContractActivityType.UPDATED_EMPLOYEES,
  ContractActivityType.SIGNED_PARTIAL,
  ContractActivityType.SIGNED_PARTIAL,
  ContractActivityType.SIGNED_FULL,
];
for (let idxContract = 0; idxContract < contractStatus.length; idxContract++) {
  const status = contractStatus[idxContract];
  const activity: ContractActivityDto[] = [];
  for (let idxActivity = 0; idxActivity < activityTypes.length; idxActivity++) {
    const type = activityTypes[idxActivity];
    const today = new Date();
    const createdAt = new Date(today.setDate(today.getMonth() + idxActivity));
    activity.push({
      id: idxActivity,
      contractId: "",
      contract: {} as ContractDto,
      type,
      createdAt,
      createdByUser: users[0],
    });
  }
  const today = new Date();
  const createdAt = new Date(today.setDate(today.getDate() + idxContract));
  const estimatedStartDate = new Date(createdAt.setMonth(createdAt.getMonth() + 1));
  const realStartDate = new Date(createdAt.setMonth(createdAt.getMonth() + 2));
  const estimatedTerminationDate = new Date(createdAt.setMonth(createdAt.getMonth() + 2));
  const realTerminationDate = new Date(createdAt.setMonth(createdAt.getMonth() + 3));

  let providerEmail = "provider" + (idxContract + 1) + "@company.com";
  let providerTaxId = "PRO12345XYZ" + (idxContract + 1);
  let providerLegalName = "PROVIDER LEGAL NAME " + (idxContract + 1);
  let clientEmail = "client" + (idxContract + 1) + "@company.com";
  let clientTaxId = "CLI12345XYZ" + (idxContract + 1);
  let clientLegalName = "CLIENT LEGAL NAME " + (idxContract + 1);
  if (idxContract === 0) {
    providerEmail = "admin@delega.com.mx";
    providerTaxId = "ABS12345XYZ";
    providerLegalName = "COMPANY 1 LEGAL NAME";
  } else if (idxContract === 1) {
    clientEmail = "admin@delega.com.mx";
    clientTaxId = "ABS12345XYZ";
    clientLegalName = "COMPANY 1 LEGAL NAME";
  }
  const cfdis: ContractEmployeeCfdiDto[] = [];
  employeeLists.forEach((element) => {
    element.employees.forEach((employee) => {
      employee.employee.cfdis?.forEach((employeeCfdi) => {
        cfdis.push({
          id: "",
          contractId: "",
          contract: {} as any,
          contractEmployeeId: "",
          contractEmployee: {} as any,
          cfdiId: employeeCfdi.id,
          cfdi: employeeCfdi,
        });
      });
    });
  });
  const contract: ContractDto = {
    id: (idxContract + 1).toString(),
    createdAt,
    createdByUser: users[0],
    name: "Contrato " + (idxContract + 1),
    digitalSignatureId: "",
    linkId: (idxContract + 100).toString(),
    link: {
      id: (idxContract + 100).toString(),
      createdAt: new Date(),
      createdByUserId: "",
      createdByUser: {} as UserDto,
      createdByWorkspaceId: "",
      createdByWorkspace: {} as WorkspaceDto,
      status: LinkStatus.LINKED,
      providerWorkspaceId: "1",
      providerWorkspace: {
        id: "1",
        name: fakeCompanies[idxContract].legalName,
        legalName: fakeCompanies[idxContract].legalName,
        taxId: fakeCompanies[idxContract].taxId,
        businessMainActivity: "",
        type: WorkspaceType.SPECIALIZED_PROVIDER,
        registrationNumber: "",
        registrationDate: new Date(),
        users: [],
        documentation: [],
      },
      clientWorkspaceId: "2",
      clientWorkspace: {
        id: "2",
        name: "COMERCIALIZADORA FOBZ ASOR S.A.DE C.V.",
        taxId: "CFO4578213A1",
        legalName: "COMERCIALIZADORA FOBZ ASOR S.A.DE C.V.",
        businessMainActivity: "",
        type: WorkspaceType.REGULAR,
        registrationNumber: "",
        registrationDate: new Date(),
        users: [],
        documentation: [],
      },
    },
    hasFile: true,
    estimatedStartDate,
    realStartDate,
    estimatedTerminationDate,
    realTerminationDate,
    status,
    description:
      "CONTRATO DE PRESTACIÓN DE SERVICIOS DE IMPLEMENTACIÓN, SOFTWARE Y HARDWARE, QUE CELEBRAN POR UNA PARTE ABSYS SC, REPRESENTADA POR EL ING. ALEXANDRO MARTÍNEZ GARCÍA EN LO SUCESIVO DENOMINADO “EL PROVEEDOR” Y POR LA OTRA PARTE “BABEL, S.A. DE C.V.”, REPRESENTADA POR EL ING. LUIS GONZALEZ EN LO SUCESIVO DENOMINADO “EL CLIENTE”, AL AMPARO DE LAS SIGUIENTES DECLARACIONES Y CLAUSULAS...",
    employeeLists,
    members,
    activity,
    cfdis,
    visits: [],
  };
  contracts.push(contract);
  invitations.push({
    id: "",
    contractId: contract.id,
    contract,
    link: "fake-contract-link",
    public: true,
    status: ContractInvitationStatus.PENDING,
  });

  const visits: ContractVisitDto[] = [];
  for (let idxVisits = 1; idxVisits <= 1; idxVisits++) {
    const visit: ContractVisitDto = {
      createdAt: new Date(),
      id: idxVisits,
      comments: "Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum",
      contractId: contract.id,
      contract,
      employees: [],
    };
    visits.push(visit);
  }

  contract.visits = visits;
}

export class FakeContractService implements IContractService {
  contracts: ContractDto[] = contracts;
  invitations: ContractInvitationDto[] = invitations;
  getAllByStatusFilter(status: ContractStatusFilter): Promise<ContractDto[]> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let contracts = this.contracts;
        if (status !== ContractStatusFilter.ALL) {
          contracts = this.contracts.filter((f) => Number(f.status) === Number(status));
        }
        resolve(contracts);
      }, 500);
    });
  }
  getAllByLink(linkId: string): Promise<ContractDto[]> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const contracts = this.contracts.filter((f) => f.linkId === linkId);
        resolve(contracts);
      }, 500);
    });
  }
  getEmployeeContracts(linkId: string): Promise<ContractDto[]> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.contracts);
      }, 500);
    });
  }
  getContract(id: string, employees: boolean): Promise<ContractDto> {
    console.log("contracts: " + this.contracts.length);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const contract = this.contracts.find((f) => f.id?.toString() === id);
        if (contract) {
          resolve(contract);
        } else {
          reject(i18n.t("shared.notFound").toString());
        }
      }, 500);
    });
  }
  getDigitalDocument(id: string): Promise<MifielDocument> {
    throw new Error("Method not implemented.");
  }
  getEmployeeListDigitalDocument(id: string, listId: string): Promise<MifielDocument> {
    throw new Error("Method not implemented.");
  }
  getInvitation(link: string): Promise<ContractInvitationDto> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const invitation = this.invitations.find((f) => f.link.toString() === link);
        if (invitation) {
          resolve(invitation);
        } else {
          reject(i18n.t("shared.notFound").toString());
        }
      }, 500);
    });
  }
  create(data: CreateContractRequest): Promise<ContractDto> {
    const contract: ContractDto = {
      id: this.contracts.length + 1 + "",
      name: data.name,
      description: data.description,
      linkId: data.linkId,
      link: {} as LinkDto,
      hasFile: true,
      status: ContractStatus.PENDING,
      estimatedStartDate: data.estimatedStartDate,
      realStartDate: data.realStartDate,
      estimatedTerminationDate: data.estimatedTerminationDate,
      realTerminationDate: data.realTerminationDate,
      members: [],
      employeeLists: [],
      cfdis: [],
      activity: [],
      visits: [],
      createdAt: new Date(),
      createdByUserId: store.state.account.user?.id ?? "",
      createdByUser: store.state.account.user ?? undefined,
      digitalSignatureId: "",
    };
    contract.members = [];
    data.members.forEach((addMember) => {
      contract.members.push({
        id: "",
        role: addMember.role,
        contractId: contract.id,
        contract,
        userId: users[0].id,
        user: users[0],
        signDate: undefined,
      });
    });

    const employeeList: ContractEmployeeListDto = {
      id: "1",
      number: 1,
      employees: [],
      hasFile: false,
      digitalSignatureId: "",
    };
    contract.employeeLists.push(employeeList);

    employeeList.employees = [];
    data.employees.forEach((employee) => {
      // const employee: EmployeeDto = {
      //   id: "",
      //   firstName: addEmployee.fullName,
      //   taxId: addEmployee.taxId,
      //   socialSecurityId: addEmployee.socialSecurityId,
      //   populationRegistryId: addEmployee.populationRegistryId,
      //   cfdis: [],
      // };
      employeeList.employees.push({
        id: "",
        listId: employeeList.id,
        list: employeeList,
        employeeId: employee.id,
        employee,
      });
    });

    contract.activity.push({
      id: "",
      contractId: contract.id,
      contract,
      type: ContractActivityType.CREATED,
      createdAt: new Date(),
      createdByUserId: store.state.account.user?.id ?? "",
      createdByUser: store.state.account.user ?? undefined,
    });

    this.contracts.push(contract);
    console.log("contracts: " + this.contracts.length);
    return Promise.resolve(contract);
  }
  signed(id: string, signature: string): Promise<any> {
    throw new Error("Method not implemented.");
  }
  updateEmployeeList(id: string, data: CreateEmployeeListRequest): Promise<ContractEmployeeListDto> {
    return new Promise((resolve, reject) => {
      const contract = this.contracts.find((f) => f.id?.toString() === id);
      if (contract) {
        contract.members.forEach((element) => {
          element.signDate = undefined;
        });
        contract.activity.push({
          id: "",
          contractId: contract.id,
          contract,
          type: ContractActivityType.UPDATED_EMPLOYEES,
          createdAt: new Date(),
          createdByUserId: store.state.account.user?.id ?? "",
          createdByUser: store.state.account.user ?? undefined,
        });
      }
      // resolve(data);
    });
  }
  createVisit(id: string, data: CreateVisitRequest): Promise<ContractVisitDto> {
    const contract = this.contracts.find((f) => f.id?.toString() === id);
    if (contract) {
      const visit: ContractVisitDto = {
        createdAt: new Date(),
        id: "",
        comments: data.comments,
        contractId: id,
        contract,
        employees: data.employees,
      };
      contract.visits.push(visit);
      return Promise.resolve(visit);
    }
    return Promise.reject(i18n.t("shared.notFound"));
  }
  downloadFile(id: string, type: ContractFileType): Promise<any> {
    const contract = this.contracts.find((f) => f.id?.toString() === id);
    if (contract) {
      return Promise.resolve(FakePdfBase64);
    } else {
      return Promise.reject(i18n.t("shared.notFound").toString());
    }
  }
  downloadAddendum(id: string, listId: string): Promise<string> {
    throw new Error("Method not implemented.");
  }
  send(id: string, request: SendContractRequest): Promise<any> {
    return Promise.resolve(true);
  }
  update(id: string, data: UpdateContractRequest): Promise<ContractDto> {
    const contract = this.contracts.find((f) => f.id?.toString() === id);
    if (contract) {
      if (data.name) {
        contract.name = data.name;
      }
      if (data.description) {
        contract.description = data.description;
      }
      if (data.file) {
        contract.hasFile = true;
      }
      return Promise.resolve(contract);
    } else {
      return Promise.reject(i18n.t("shared.notFound").toString());
    }
  }
  delete(id: string): Promise<any> {
    this.contracts = this.contracts.filter((f) => f.id !== id);
    return Promise.resolve(this.contracts);
  }
}
