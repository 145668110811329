



























import Component from "vue-class-component";
import { SubscriptionProductDto } from "../../../application/dtos/master/subscriptions/SubscriptionProductDto";
import Vue from "vue";
import store from "@/store";

@Component
export default class BillingPeriodToggle extends Vue {
  changeCurrency(currency) {
    store.commit("pricing/currency", currency);
    this.$emit("selected");
  }
  get products(): SubscriptionProductDto[] {
    return store.state.pricing.products as SubscriptionProductDto[];
  }
  get currencies() {
    const allCurrencies: string[] = [];
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    this.products.forEach(element => {
      element.prices.forEach(price => {
        allCurrencies.push(price.currency.toLowerCase());
      });
    });
    return allCurrencies.filter(onlyUnique);
  }
}
