











































































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import { ContractDto } from "@/application/dtos/app/v3/contracts/ContractDto";
import services from "@/services";
import pdf from "vue-pdf";
import { ContractMemberDto } from "@/application/dtos/app/v3/contracts/ContractMemberDto";
import Sign from "@/assets/contracts/Sign.vue";
import store from "@/store";
import SideModal from "@/components/shared/modals/SideModal.vue";
import DropDocument from "@/components/app/v3/contracts/uploads/DropDocument.vue";
import Tabs, { TabItem } from "@/components/shared/tabs/Tabs.vue";
import { FileBase64 } from "@/application/dtos/shared/FileBase64";
import { ContractEmployeeListDto } from "@/application/dtos/app/v3/contracts/ContractEmployeeListDto";
import EmployeesListAndTable from "@/components/app/v3/employees/list/EmployeesListAndTable.vue";
import { EmployeeDto } from "@/application/dtos/app/v3/employees/EmployeeDto";
import SelectEmployees from "../employees/SelectEmployees.vue";
import DateUtils from "@/utils/shared/DateUtils";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  metaInfo: {
    title: "Colaboradores de contrato",
    titleTemplate: "%s | Délega"
  },
  components: {
    Breadcrumb,
    pdf,
    Sign,
    SideModal,
    DropDocument,
    Tabs,
    EmployeesListAndTable,
    ErrorModal,
    SuccessModal,
    ConfirmModal,
    SelectEmployees,
    Loading
  }
})
export default class ContractEmployees extends Vue {
  $refs!: {
    errorModal: ErrorModal;
    successModal: SuccessModal;
    confirmCreate: ConfirmModal;
    confirmSign: ConfirmModal;
    modalSign: SideModal;
    buttonSave: HTMLButtonElement;
    selectEmployees: SelectEmployees;
  };
  id = "";
  name = "";

  tabs: TabItem[] = [];
  employees: EmployeeDto[] = [];
  employeesFile = "";

  importingEmployees = false;
  withLabels = true;

  item?: ContractDto | null = null;
  error = "";
  loading = false;
  loadingPdf = false;
  addendumFile = "";

  currentList: ContractEmployeeListDto | null = null;

  currentPage = 1;
  pageCount = 0;

  created() {
    this.id = this.$route.params.id;
  }
  mounted() {
    this.tabs = [
      {
        name: this.$t("app.contracts.details.title"),
        routeName: "app.contracts.view"
        // current: false
      },
      {
        name: this.$t("app.contracts.employees.title"),
        routeName: "app.contracts.employees"
        // current: false
      },
      {
        name: this.$t("app.contracts.visits.title"),
        routeName: "app.contracts.visits"
        // current: false
      }
    ];
    // this.tabs.forEach(tab => {
    //   tab.current = tab.routeName === this.$route.name;
    // });
    this.reload();
  }
  reload() {
    this.loading = true;
    services.contracts
      .getContract(this.id, true)
      .then(response => {
        this.item = response;
        this.name = response.name;
        if (this.item.employeeLists.length > 0) {
          this.item.employeeLists = this.item.employeeLists.sort((x, y) => {
            if (x.number && y.number) {
              return x.number > y.number ? -1 : 1;
            }
            return -1;
          });
          this.item.currentEmployeeList = this.item.employeeLists[0];
        }
        if (response.currentEmployeeList) {
          this.showList(response.currentEmployeeList);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
  loadPdf() {
    if (this.currentList?.id) {
      this.loadingPdf = true;
      services.contracts
        .downloadAddendum(this.id, this.currentList.id)
        .then(response => {
          if (this.currentList?.id) {
            this.addendumFile = response;
          }
        })
        .finally(() => {
          this.loadingPdf = false;
        });
    }
  }
  showList(list: ContractEmployeeListDto) {
    this.currentList = list;
    this.addendumFile = "";
    this.employees = list.employees.map(f => f.employee);
    if (list.hasFile) {
      this.loadPdf();
    }
  }
  newList() {
    this.currentList = {
      id: undefined,
      number: this.item?.employeeLists.length ?? 0 + 1,
      employees: [],
      hasFile: false,
      digitalSignatureId: ""
    };
    this.addendumFile = "";
    this.employees = [];
    // this.addEmployee();
  }
  saveList() {
    if (this.currentList) {
      if (this.employees.length === 0) {
        this.$refs.errorModal.show(
          "Faltan campos",
          this.$t("app.employees.errors.atLeastOne")
        );
      } else if (!this.addendumFile) {
        this.$refs.errorModal.show(
          "Faltan campos",
          this.$t("app.employees.errors.noAddendum")
        );
      } else {
        this.$refs.confirmCreate.show(
          "¿Crear nueva lista?",
          "Crear y solicitar firmas",
          "Cancelar",
          "ADVERTENCIA: Al crear esta nueva lista de empleados, se requerirá que los integrantes del contrato nuevamente firmen con su FIEL el presente anexo."
        );
      }
    }
  }
  confirmSaveList() {
    if (this.item && this.currentList) {
      this.loading = true;
      services.contracts
        .updateEmployeeList(this.item.id, {
          addendum: this.addendumFile ?? "",
          employees: this.employees
        })
        .then(() => {
          if (
            this.item?.members.find(
              f =>
                f.role === 0 && f.user.email === store.state.account.user?.email
            )
          ) {
            this.$refs.confirmSign.show(
              "Nueva lista agregada",
              "Firmar ahora",
              "Atrás",
              "Se ha notificado a los miembros del contrato para que vuelvan a firmar con su FIEL."
            );
          } else {
            this.$refs.successModal.show(
              "Nueva lista agregada",
              "Se ha notificado a los miembros del contrato para que vuelvan a firmar con su FIEL."
            );
          }
        })
        .catch(error => {
          this.$refs.errorModal.show("No se pudo actualizar", this.$t(error));
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  confirmedSign() {
    this.$router.push({ name: "app.contracts.sign", params: { id: this.id } });
  }
  deleteContract() {
    this.$router.push({ name: "app.contracts" });
  }
  droppedAddendumFile(files: FileBase64[]) {
    if (this.currentList) {
      if (files.length > 0) {
        const mb = files[0].file.size / 1048576;
        console.log("mb", mb);
        if (mb >= 40) {
          this.$refs.errorModal.show(
            "Límite excedido",
            this.$t("app.contracts.errors.maxFileReached")
          );
        } else {
          this.addendumFile = files[0].base64;
        }
      }
    }
  }
  nextPage(pages) {
    this.currentPage += pages;
  }
  removeFile() {
    if (this.currentList) {
      this.addendumFile = "";
    }
  }
  addEmployee() {
    if (this.currentList) {
      this.$refs.selectEmployees.show(this.employees.map(f => f.id));
      // this.currentList.employees.push({
      //   id: "",
      //   listId: "",
      //   list: this.currentList,
      //   employeeId: "",
      //   employee: {
      //     id: "",
      //     firstName: "",
      //     lastName1: "",
      //     lastName2: "",
      //     taxId: "",
      //     socialSecurityId: "",
      //     populationRegistryId: "",
      //     email: ""
      //   }
      // });
    }
  }
  downloadEmployeesFileTemplate() {
    const rows = [["NOMBRE", "RFC", "NSS", "CURP"]];

    const csvContent =
      "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "plantilla.csv");
    document.body.appendChild(link);

    link.click();
  }
  removeEmployee(index) {
    if (this.currentList) {
      this.currentList.employees = this.currentList.employees.filter(
        (x, i) => i !== index
      );
      if (this.currentList.employees.length === 0) {
        this.employeesFile = "";
      }
    }
  }
  droppedEmployeesFile(filesBase64: FileBase64[], files: File[]) {
    if (files.length > 0) {
      // this.employeesFile = filesBase64[0].base64;
      // const employeesFile = files[0];
      // const reader = new FileReader();
      // reader.onload = (e: any) => {
      //   const employees = this.csvToArray(e.target.result);
      //   employees.forEach(employee => {
      //     this.employees.push({
      //       fullName: employee[0],
      //       taxId: employee[1],
      //       socialSecurityId: employee[2],
      //       populationRegistryId: employee[3]
      //     });
      //   });
      //   this.clearEmptyEmployees();
      // };
      // reader.readAsText(employeesFile);
    }
  }
  creatingList() {
    return !this.currentList || !this.currentList.id;
  }
  selectedEmployees(items: EmployeeDto[]) {
    this.employees = items;
  }
  dateYMDHMS(value: Date | undefined) {
    return DateUtils.dateYMDHMS(value);
  }
  get isProvider() {
    return (
      store.state.tenant.currentWorkspace?.id ===
      this.item?.link.providerWorkspaceId
    );
  }
  get addingNewList() {
    return !this.currentList || !this.currentList.id;
  }
  get canSign() {
    return true;
    // return this.item?.members.find(
    //   f => f.role === 0 && f.email === store.state.account.user?.email
    // );
  }
  get canEdit() {
    return this.item?.createdByUserId === store.state.account.user?.id || true;
  }
  get signers(): ContractMemberDto[] {
    if (this.item) {
      return this.item.members.filter(f => f.role === 0);
    }
    return [];
  }
  get sortedItems(): ContractEmployeeListDto[] {
    if (!this.item) {
      return [];
    }
    // return this.item.employeeLists;
    return this.item.employeeLists.sort((x, y) => {
      if (x.createdAt && y.createdAt) {
        return (x.createdAt > y.createdAt ? -1 : 1) ?? -1;
      }
      return -1;
    });
  }
}
