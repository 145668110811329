<template>
  <div>
    <div class="mx-auto max-w-screen-xl px-6 pt-16">
      <!-- <h2
        class="pb-4 text-3xl md:text-6xl font-black tracking-snug leading-10 md:leading-18 md:text-center"
      >
        Te hacemos la vida laboral menos laboriosa.
      </h2>
      <p
        class="pb-6 text-gray-600 dark:text-gray-400 dark:text-gray-400 text-lg md:text-2xl md:text-center leading-normal md:leading-normal"
      >
        Explora el potencial de nuestro producto creando
        <router-link
          :to="{ name: 'prices' }"
          class=" hover:text-teal-600 font-extrabold text-teal-500 underline"
          >tu cuenta gratis</router-link
        >.
      </p> -->

      <div>
        <div v-for="(feature, idx) in features" :key="idx">
          <div v-if="idx % 2 === 0" class="mx-auto max-w-screen-xl">
            <div class="mx-auto md:flex items-center px-6 pt-20">
              <div class="md:w-1/2 md:pr-24 pb-8">
                <h2
                  class="pb-4 text-xl md:text-3xl font-bold tracking-snug leading-tight md:leading-14"
                >
                  {{ feature.title }}
                </h2>
                <p
                  class="pb-6 text-gray-600 dark:text-gray-400 text-lg md:text-xl leading-normal md:leading-normal"
                >
                  {{ feature.description }}
                </p>
                <dl class="mt-10 space-y-10">
                  <div
                    v-for="(subfeature, idxSub) in feature.features"
                    :key="idxSub"
                    class="flex items-baseline"
                  >
                    <div class="flex-shrink-0">
                      <div
                        class="flex items-center justify-center h-4 w-4 rounded-full bg-theme-600 text-white"
                      >
                        <!-- Heroicon name: globe-alt -->
                        <svg
                          class="h-3 w-3"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                    </div>
                    <div class="ml-4">
                      <dt
                        v-if="subfeature.title"
                        class="mb-2 text-lg leading-6 font-medium text-gray-900 dark:text-blueGray-300"
                      >
                        {{ subfeature.title }}
                      </dt>
                      <dd class="text-base text-gray-600 dark:text-gray-400">
                        {{ subfeature.description }}
                      </dd>
                    </div>
                  </div>
                </dl>
              </div>
              <div class="relative flex md:w-1/2">
                <div
                  class="absolute inset-0 bg-gradient-to-bl from-blueGray-900 to-theme-500 rounded-lg transform rotate-3 origin-bottom-right"
                ></div>
                <div class="rounded-lg shadow z-10">
                  <div class="bg-white rounded-lg shadow-lg">
                    <div class="rounded-md overflow-hidden">
                      <img
                        v-lazy="feature.image"
                        width="1092"
                        height="878"
                        :alt="feature.title"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="mx-auto max-w-screen-xl">
            <div
              class="mx-auto md:flex flex-row-reverse items-center px-6 pt-20 md:pt-36"
            >
              <div class="md:w-1/2 md:pl-24 pb-8">
                <h2
                  class="pb-4 text-xl md:text-3xl font-bold tracking-snug leading-tight md:leading-14"
                >
                  {{ feature.title }}
                </h2>
                <p
                  class="pb-6 text-gray-600 dark:text-gray-400 text-lg md:text-xl leading-normal md:leading-normal"
                >
                  {{ feature.description }}
                </p>
                <dl class="mt-10 space-y-10">
                  <div
                    v-for="(subfeature, idxSub) in feature.features"
                    :key="idxSub"
                    class="flex items-baseline"
                  >
                    <div class="flex-shrink-0">
                      <div
                        class="flex items-center justify-center h-4 w-4 rounded-full bg-theme-600 text-white"
                      >
                        <!-- Heroicon name: globe-alt -->
                        <svg
                          class="h-3 w-3"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                    </div>
                    <div class="ml-4">
                      <dt
                        v-if="subfeature.title"
                        class="mb-2 text-lg leading-6 font-medium text-gray-900 dark:text-blueGray-300"
                      >
                        {{ subfeature.title }}
                      </dt>
                      <dd class="text-base text-gray-600 dark:text-gray-400">
                        {{ subfeature.description }}
                      </dd>
                    </div>
                  </div>
                </dl>
              </div>
              <div class="relative flex md:w-1/2">
                <div
                  class="absolute inset-0 bg-gradient-to-tr from-blueGray-900 to-theme-500 rounded-lg transform -rotate-3 origin-bottom-left"
                ></div>
                <div class="rounded-lg shadow z-10">
                  <div class="bg-white rounded-lg shadow-lg">
                    <div class="rounded-md overflow-hidden">
                      <img
                        v-lazy="feature.image"
                        width="1334"
                        height="880"
                        :alt="feature.title"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload);

export default {
  data() {
    return {
      features: [
        {
          title: "Enlaces entre cliente y proveedores",
          features: [
            {
              description:
                "Es por eso que trabajamos por medio de enlaces de perfiles donde basta con agregar el correo y RFC de tu cliente/proveedor para crear un vínculo de trabajo."
            },
            {
              description:
                "Descuida, solamente tendrá acceso a la información la parte identificada como cliente."
            },
            {
              description:
                "Realiza acciones automáticas y manuales de atención."
            },
            {
              description:
                "Basta con tener actualizado tu perfil y documentación para que todos tus clientes puedan acceder a ella."
            }
          ],
          image:
            "https://yahooder.sirv.com/Delega/Landing/4.%20Enlaces%20-%20%232%20Confirmacio%CC%81n.png"
        },
        {
          title: "Visualiza el cumplimiento de tus proveedores",
          features: [
            {
              description:
                "Obtén una vista general del estatus de tus proveedores por medio de nuestra tabla de cumplimiento."
            },
            {
              description:
                "Podrás saber quien subió documentación, quien no ha subido nada y quien ha subido solamente una parte de la documentación."
            },
            {
              description:
                "Busca a cualquier proveedor en caso de que desees ver su estatus de cumplimiento."
            },
            {
              description:
                "Descarga la información de dicho proveedor de forma rápida para poder cumplir con alguna verificación o solicitud de información de las autoridades."
            }
          ],
          image:
            "https://yahooder.sirv.com/Delega/Landing/1.%20Mis%20proveedores.png"
        },
        {
          title: "¿Eres proveedor?",
          features: [
            {
              description:
                "Sube tu documentación reglamentaria según su recurrencia: Mensual, Cuatrimestral o Anual."
            },
            // tslint:disable-next-line: max-line-length
            {
              description:
                "Todos tus clientes podrán tener acceso a la documentación sin que tengas que enviarla a cada uno de ellos."
            },
            {
              description:
                "La información se visualiza a forma de calendario para que puedas cumplir de forma fácil y que no se te pasen las fechas."
            }
          ],
          image:
            "https://yahooder.sirv.com/Delega/Landing/5.%20Mi%20documentacio%CC%81n.png"
        },
        {
          title: "Le damos fecha cierta a tus contratos",
          description:
            "Si te ha tocado estar en una revisión por parte de la autoridad sabrás lo importante que es que tus documentos tengan fecha cierta.",
          features: [
            {
              description:
                "Aprovechamos tu FIEL y la de tus clientes/proveedores para firmar los documentos dentro de la plataforma."
            },
            {
              description:
                "Utilizamos servicios que cumplen con la NOM 151 para garantizar fecha cierta."
            },
            {
              description:
                "Da seguimiento de las personas que faltan de firmar un documento y obtén trazabilidad de las firmas."
            }
          ],
          image:
            "https://yahooder.sirv.com/Delega/Landing/2.%20Fecha%20cierta.png"
        },
        {
          title: "Control de acceso de personal",
          features: [
            {
              description:
                "Creamos un perfil de vigilante de acceso a las instalaciones del cliente."
            },
            {
              description:
                "Dicho perfil podrá validar que los empleados que asisten a las instalaciones sean los indicados por el proveedor."
            },
            {
              description:
                "De esta forma se tiene un control de entrada y salida del persona."
            },
            {
              description:
                "Podrás buscar la información por proveedor y por contrato."
            },
            {
              description:
                "La información generada será utilizada para filtrado de los CFDIs de nómina."
            }
          ],
          image:
            "https://yahooder.sirv.com/Delega/Landing/3.%20Control%20de%20acceso%20-%20%233%20Nueva%20visita.png"
        },
        {
          title: "El océano de los CFDIs de nómina",
          features: [
            {
              description:
                "Si eres proveedor, nosotros nos encargamos de mostrar los CFDIs correctos a tus clientes."
            },
            {
              description:
                "Con base a nuestros registros de entradas y salidas de personal en las instalaciones de tu cliente y/o del listado de empleados, filtramos los CFDIs de nóminas para mostrar solamente los correctos cada mes."
            },
            {
              description:
                "Evita tener que subir los documentos de forma individual."
            },
            {
              description:
                "Como cliente, basta con buscar los CFDIs de cierto proveedor para obtener los documentos ya fitrados."
            }
          ],
          image: "https://yahooder.sirv.com/Delega/Landing/7.%20CFDIs.png"
        }
      ]
    };
  }
};
</script>

<style>
</style>