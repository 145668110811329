export default [
  {
    name: "landing",
    path: "/",
    component: require("@/views/marketing/Product.vue").default,
  },
  {
    name: "prices",
    path: "/precios",
    alias: "/prices",
    component: require("@/views/marketing/Pricing.vue").default,
  },
  {
    name: "contact",
    path: "/contacto",
    alias: "/contact",
    component: require("@/views/marketing/Contact.vue").default,
  },
  {
    name: "terms",
    path: "/terminos-y-condiciones",
    alias: "/terms-and-conditions",
    component: require("@/views/marketing/Terms.vue").default,
  },
  {
    name: "privacy",
    path: "/politica-de-privacidad",
    alias: "/privacy-policy",
    component: require("@/views/marketing/Privacy.vue").default,
  },
];
