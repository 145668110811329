
























































import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import Tabs, { TabItem } from "@/components/shared/tabs/Tabs.vue";
import ContractsList from "@/components/app/v3/contracts/list/ContractsList.vue";
import { ContractStatus } from "@/application/enums/app/v3/contracts/ContractStatus";
import ButtonPrimary from "@/components/shared/buttons/ButtonPrimary.vue";

@Component({
  metaInfo: {
    title: "Contratos",
    titleTemplate: "%s | Délega"
  },
  components: {
    Breadcrumb,
    Tabs,
    ContractsList,
    ButtonPrimary
  }
})
export default class Contracts extends Vue {
  tabs: TabItem[] = [];
  currentStatus: ContractStatus | null = null;
  created() {
    this.changeContractsStatus();
  }
  mounted() {
    this.tabs = [
      {
        name: this.$t("shared.all"),
        routeName: "app.contracts",
        routeParams: { status: "todos" }
      },
      {
        name: this.$t("app.contracts.pending.title"),
        routeName: "app.contracts",
        routeParams: { status: "pendientes" }
      },
      {
        name: this.$t("app.contracts.signed.title"),
        routeName: "app.contracts",
        routeParams: { status: "firmados" }
      },
      {
        name: this.$t("app.contracts.archived.title"),
        routeName: "app.contracts",
        routeParams: { status: "archivados" }
      }
    ];
    // this.tabs.forEach(tab => {
    //   tab.current = tab.routePath === this.$route.path;
    // });
    this.changeContractsStatus();
  }
  @Watch("$route.path")
  changeContractsStatus() {
    if (!this.$route.params.status) {
      this.$router.push({ path: "/app/contratos/pendientes" });
    } else {
      switch (this.$route.params.status) {
        case "pendientes":
          this.currentStatus = ContractStatus.PENDING;
          break;
        case "firmados":
          this.currentStatus = ContractStatus.SIGNED;
          break;
        case "archivados":
          this.currentStatus = ContractStatus.ARCHIVED;
          break;
        case "todos":
          this.currentStatus = null;
          break;
      }
    }
  }
}
