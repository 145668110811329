

























































































































































import Vue from "vue";
import Component from "vue-class-component";
import store from "@/store";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal2.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import services from "@/services";
import { Prop } from "vue-property-decorator";
import { DocumentationStatusDto } from "@/application/dtos/app/v3/documentation/DocumentationStatusDto";
import documentTypes from "@/application/dtos/app/v3/documentation/DocumentationTypes";
import { Periodicity } from "@/application/enums/app/common/Periodicity";
import { WorkspaceType } from "@/application/enums/master/tenants/WorkspaceType";
import { DocumentTypeDto } from "@/application/dtos/app/v3/documentation/DocumentTypeDto";
import DocumentsTable from "./DocumentsTable.vue";
import DocumentUpload from "./DocumentUpload.vue";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  components: {
    ConfirmModal,
    SuccessModal,
    ErrorModal,
    DocumentsTable,
    DocumentUpload,
    Loading
  }
})
export default class AllDocumentation extends Vue {
  @Prop({ default: 0 }) year!: number;
  @Prop({ default: "" }) linkId!: string;
  @Prop({}) workspace!: WorkspaceDto;
  $refs!: {
    confirmModalSend: ConfirmModal;
    successModal: SuccessModal;
    errorModal: ErrorModal;
    uploadDocument: DocumentUpload;
  };
  loading = true;

  documentation: DocumentationStatusDto | null = null;
  documentTypes = documentTypes;
  validatePreviousPeriods = false;
  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    if (!this.linkId) {
      services.documentation
        .getMyDocumentationStatus(this.year, this.validatePreviousPeriods)
        .then(response => {
          this.documentation = response;
        })
        .catch(error => {
          this.$refs.errorModal.show(this.$t(error));
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      services.documentation
        .getDocumentationStatus(
          this.linkId,
          this.year,
          this.validatePreviousPeriods
        )
        .then(response => {
          this.documentation = response;
        })
        .catch(error => {
          this.$refs.errorModal.show(this.$t(error));
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  downloadAll() {
    if (this.linkId) {
      this.loading = true;
      services.documentation
        .downloadAll(this.linkId, this.year)
        .then(response => {
          this.downloadData(
            `${this.year} - ${this.workspace.legalName}.zip`,
            response
          );
        })
        .catch(error => {
          this.$refs.errorModal.show("Error descargando", this.$t(error));
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = true;
      services.documentation
        .downloadAllMyDocuments(this.year)
        .then(response => {
          this.downloadData(
            `${this.year} - ${store.state.tenant.currentWorkspace?.legalName}.zip`,
            response
          );
        })
        .catch(error => {
          this.$refs.errorModal.show("Error descargando", this.$t(error));
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  downloadData(fileName, response) {
    const type = "application/zip";

    const fileUrl = window.URL.createObjectURL(
      new Blob([response.data], { type })
    );
    const fileLink = document.createElement("a");
    fileLink.href = fileUrl;
    fileLink.setAttribute("download", fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
  }
  uploadDocument() {
    this.$refs.uploadDocument.upload();
  }
  sendDocumentationStatus() {
    this.$refs.confirmModalSend.show(
      "Enviar informe",
      "Enviar",
      "Atrás",
      "Ingresa los correos para enviar el estatus de la documentación",
      store.state.account.user?.email ?? ""
    );
  }
  confirmSendDocumentationStatus(email: string) {
    const emails: string[] = email.split(",");
    services.documentation
      .sendDocumentationStatus(
        this.$route.params.id,
        this.year,
        this.validatePreviousPeriods,
        {
          emails
        }
      )
      .then(() => {
        this.$refs.successModal.show(
          "Enviada",
          "Estatus de documentación enviada"
        );
      })
      .catch(error => {
        this.$refs.errorModal.show("Error", this.$t(error));
      });
  }
  validatePreviousPeriodsChanged(value) {
    this.validatePreviousPeriods = value;
  }
  get oneTimeDocuments(): DocumentTypeDto[] {
    return this.documentTypes.filter(f => f.periodicity === Periodicity.ONCE);
  }
  get specializedDocuments(): DocumentTypeDto[] {
    // return this.documentTypes;
    return this.documentTypes
      .filter(
        f =>
          f.periodicity !== Periodicity.ONCE &&
          f.requiredFor === WorkspaceType.SPECIALIZED_PROVIDER
      )
      .sort((x, y) => {
        if (x.periodicity && y.periodicity) {
          return (x.periodicity > y.periodicity ? 1 : -1) ?? 1;
        }
        return 1;
      });
  }
  get regularDocuments(): DocumentTypeDto[] {
    // return this.documentTypes;
    return this.documentTypes
      .filter(
        f =>
          f.periodicity !== Periodicity.ONCE &&
          f.requiredFor !== WorkspaceType.SPECIALIZED_PROVIDER
      )
      .sort((x, y) => {
        if (x.periodicity && y.periodicity) {
          return (x.periodicity > y.periodicity ? 1 : -1) ?? 1;
        }
        return 1;
      })
      .sort((x, y) => {
        if (x.institution && y.institution) {
          return (x.institution > y.institution ? -1 : 1) ?? -1;
        }
        return 1;
      });
  }
}
