
























































































































import Vue from "vue";
import Component from "vue-class-component";
import Icon from "@/assets/contracts/WorkerMale.vue";
import DateUtils from "@/utils/shared/DateUtils";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import { InvoiceExtractionDto } from "@/application/dtos/app/v3/cfdis/sat/InvoiceExtractionDto";
import { ExtractionStatus } from "@/application/enums/app/v3/cfdis/ExtractionStatus";
import { Prop } from "vue-property-decorator";
import store from "@/store";

@Component({
  components: {
    Icon,
    EmptyState,
    ConfirmModal
  }
})
export default class InvoiceExtractionsListAndTable extends Vue {
  @Prop({}) items!: InvoiceExtractionDto[];
  headers: any[] = [];
  mounted() {
    this.headers = [
      // {
      //   name: "id",
      //   title: "ID"
      // },
      {
        name: "status",
        title: "Estatus"
      },
      {
        title: "Periodo"
      },
      // {
      //   name: "startedAt",
      //   title: "Comenzó"
      // },
      // {
      //   name: "finishedAt",
      //   title: "Terminó"
      // },
      {
        name: "invoices",
        title: "CFDIs"
      }
    ];
  }
  dateYMDHMS(value: Date | undefined) {
    return DateUtils.dateYMDHMS(value);
  }
  status(item: InvoiceExtractionDto) {
    if (item.processed) {
      return "Sincronizado";
    } else if (item.status == ExtractionStatus.PENDING) {
      return "Extrayendo del SAT...";
    } else if (item.status == ExtractionStatus.RUNNING) {
      return "Extrayendo del SAT...";
    } else if (item.status == ExtractionStatus.FINISHED) {
      return "Pendiente por sincronizar";
    } else if (item.status == ExtractionStatus.FAILED) {
      return "Error: " + item.errorCode;
    }
  }
  period(item: InvoiceExtractionDto) {
    if (!item.periodFrom && !item.periodTo) {
      return "";
    }
    if (item.periodFrom && item.periodTo) {
      return `${this.dateYMDHMS(item.periodFrom)} - ${this.dateYMDHMS(
        item.periodTo
      )}`;
    } else if (item.periodFrom) {
      return `Desde ${this.dateYMDHMS(item.periodFrom)}`;
    } else if (item.periodTo) {
      return `Hasta ${this.dateYMDHMS(item.periodTo)}`;
    }
  }
  // type(item: InvoiceExtractionDto) {
  //   let type = ExtractionType.INVOICE;
  //   return this.$t("app.cfdis.extractions.types." + ExtractionType[item.type]);
  // }
  get workspaceWithoutFiscalId(): string {
    if (!store.state.tenant.currentWorkspace?.fiscalWorkspace)
      return store.state.tenant.currentWorkspace?.id ?? "";
    return "";
  }
}
