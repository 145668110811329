







































































































































import Vue from "vue";
import Component from "vue-class-component";
import services from "@/services";
import { TenantDto } from "@/application/dtos/master/tenants/TenantDto";
import store from "@/store";

@Component({
  components: {}
})
export default class TenantSelector extends Vue {
  $refs!: {
    inputSearch: HTMLInputElement;
  };
  loading = false;
  dropdown = false;
  addingTenant = false;
  search = "";
  closeDropdownTenant() {
    this.dropdown = false;
  }
  toggleDropDown() {
    this.dropdown = !this.dropdown;
    if (this.dropdown) {
      this.$nextTick(() => {
        this.$refs.inputSearch.focus();
        this.$refs.inputSearch.select();
      });
    }
  }
  enter() {
    if (this.myTenants.length === 1) {
      this.changeTenant(this.myTenants[0]);
    }
  }
  changeTenant(tenant: TenantDto) {
    this.search = "";
    this.closeDropdownTenant();
    this.loading = true;
    services.users
      .updateDefaultTenant(tenant.id)
      .then(() => {
        this.$router.go(0);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  addTenant() {
    store.commit("layout/sidebarOpen", false);
    this.closeDropdownTenant();
    this.$emit("add");
  }
  getPlanFromTenant(tenant: TenantDto): string {
    if (
      tenant.products &&
      tenant.products.length > 0 &&
      tenant.products[0].subscriptionProduct
    ) {
      return tenant.products[0].subscriptionProduct.title;
    } else {
      if (tenant.subdomain === "admin") {
        return "Admin";
      } else {
        return this.$t("settings.tenant.subscription.notSubscribed").toString();
      }
    }
  }
  get currentTenant(): TenantDto {
    return store.state.tenant.current ?? ({ name: "Undefinded" } as TenantDto);
  }
  get myTenants(): TenantDto[] {
    return store.state.tenant.tenants
      .filter(
        f =>
          !this.search ||
          f.name.toLowerCase().includes(this.search.toLowerCase())
      )
      .sort((x, y) => {
        if (x.name && y.name) {
          return (x.name > y.name ? 1 : -1) ?? 1;
        }
        return 1;
      });
  }
}
