































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import imageCompression from "browser-image-compression";
import { FileBase64 } from "@/application/dtos/shared/FileBase64";

@Component({})
export default class DropDocument extends Vue {
  @Prop({ default: false, type: Boolean })
  loadImages!: boolean;
  @Prop({ default: "Arrastra el documento aquí", type: String })
  title!: boolean;
  @Prop({ default: ".pdf", type: String })
  accept!: string;
  @Prop({ default: false, type: Boolean })
  multiple!: boolean;
  @Prop({ default: "", type: String })
  description!: boolean;
  @Prop({ default: 0, type: Number })
  imageStyle!: number;
  $refs!: {
    fileInput: HTMLInputElement;
  };

  isDragging = false;
  imageSources: string[] = [];
  loading = false;

  dragOver() {
    if (!this.loading) {
      this.isDragging = true;
    }
  }
  dragLeave() {
    this.isDragging = false;
  }
  async compressFile(imageFile: File): Promise<any> {
    // console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920 / 2,
      useWebWorker: true
    };
    try {
      const file = await imageCompression(imageFile, options);
      // console.log("compressedFile instanceof Blob", file instanceof Blob); // true
      // console.log(`compressedFile size ${file.size / 1024 / 1024} MB`); // smaller than maxSizeMB
      return Promise.resolve(file);
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async compressFileNotImage(imageFile: File): Promise<any> {
    return Promise.resolve(imageFile);
  }
  async drop(e) {
    let files: any[] = [...e.dataTransfer.files];
    // console.log({ files });
    const newImagesPromises: any[] = [];
    await files.forEach((element: File) => {
      if (element.type.includes("image")) {
        newImagesPromises.push(this.compressFile(element));
      } else {
        newImagesPromises.push(this.compressFileNotImage(element));
      }
    });
    files = await Promise.all(newImagesPromises);
    const filesArray: FileBase64[] = [];
    const promises: any[] = [];

    files.forEach(file => {
      const promise = this.getBase64(file);
      promises.push(promise);
      promise
        .then(response => {
          filesArray.push({
            file,
            base64: response
          });
          this.$emit("dropped", response);
        })
        .catch(e => {
          console.error(e);
        });
    });
    await Promise.all(promises).then(() => {
      this.$emit("droppedFiles", filesArray, files);
    });
    if (this.loadImages) {
      this.imageSources = this.imageSources.concat(
        filesArray.map(f => f.base64)
      );
    }
    this.isDragging = false;
  }
  remove(img) {
    this.imageSources = this.imageSources.filter(f => f !== img);
  }
  requestUploadFile() {
    const src = this.$el.querySelector("#uploadmyfile");
    this.drop({ dataTransfer: src });
  }
  getBase64(file) {
    const reader = new FileReader();
    return new Promise(resolve => {
      reader.onload = ev => {
        resolve(ev?.target?.result);
      };
      reader.readAsDataURL(file);
    });
  }
  get getImageSources(): string[] {
    return this.imageSources.filter(f => f && f !== "");
  }
  get getClasses() {
    return this.isDragging && !this.loading
      ? "bg-theme-200 border-2 border-dashed border-theme-800"
      : "";
  }
  get getGridCols() {
    if (this.imageSources.length > 0 && this.imageSources.length <= 12) {
      return "grid-cols-" + this.imageSources.length;
    } else if (this.imageSources.length > 12) {
      return "grid-cols-12";
    }
    return "grid-cols-1";
  }
}
