/* eslint-disable */
import { UserDto } from "@/application/dtos/master/users/UserDto";
import { TenantUserDto } from "@/application/dtos/master/tenants/TenantUserDto";
import { TenantUserUpdateRequest } from "@/application/contracts/master/tenants/TenantUserUpdateRequest";
import { ITenantUsersService } from "./ITenantUsersService";
import { FakeUserService } from "../users/FakeUserService";
import { FakeTenantService } from "./FakeTenantService";
import { i18n } from "@/plugins/vue-i18n";

const fakeTenantService = new FakeTenantService();
const fakeUserService = new FakeUserService();
const users = fakeUserService.users;
const tenantUsers: TenantUserDto[] = [];
fakeTenantService.tenants.forEach((element) => {
  element.users.forEach((user) => {
    tenantUsers.push(user);
  });
});

export class FakeTenantUsersService implements ITenantUsersService {
  tenantUsers: TenantUserDto[] = tenantUsers;
  adminGetAll(): Promise<UserDto[]> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(users);
      }, 500);
    });
  }
  getAll(tenantId = ""): Promise<TenantUserDto[]> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.tenantUsers);
      }, 500);
    });
  }
  get(tenantUserId: string): Promise<TenantUserDto> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const user = this.tenantUsers.find((f) => f.id === tenantUserId);
        if (user) {
          resolve(user);
        }
        reject(i18n.t("shared.notFound"));
      }, 500);
    });
  }
  update(tenantUserId: string, payload: TenantUserUpdateRequest): Promise<TenantUserDto> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const user = this.tenantUsers.find((f) => f.id === tenantUserId);
        if (user) {
          user.role = payload.role;
          user.phone = payload.phone;
          resolve(user);
        }
        reject(i18n.t("shared.notFound"));
      }, 500);
    });
  }
  delete(tenantUserId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const user = this.tenantUsers.find((f) => f.id === tenantUserId);
        if (user) {
          this.tenantUsers = this.tenantUsers.filter((f) => f.id !== user.id);
        }
        reject(i18n.t("shared.notFound"));
      }, 500);
    });
  }
  adminDelete(tenantUserId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const user = this.tenantUsers.find((f) => f.id === tenantUserId);
        if (user) {
          this.tenantUsers = this.tenantUsers.filter((f) => f.id !== user.id);
        }
        reject(i18n.t("shared.notFound"));
      }, 500);
    });
  }
}
