<template>
  <div>
    <div class="doodles-light mt-16 md:mt-24">
      <div class="mx-auto max-w-screen-xl px-6 md:px-10 py-16 md:py-24">
        <h2
          class="pb-4 text-3xl md:text-6xl font-black tracking-snug leading-10 md:leading-18 md:text-center"
        >
          Documentos previstos
        </h2>
        <p
          class="pb-6 text-gray-500 text-lg md:text-2xl md:text-center leading-normal md:leading-normal"
        >
          Sube tu documentación o audita la de tus proveedores para cumplir con
          la ley.
        </p>

        <div class="pt-6 sm:pt-12 grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <div
            v-for="(document, idx) in documents"
            :key="idx"
            class="p-6 md:p-10 rounded-xl bg-blueGray-100 dark:bg-blueGray-800 transform origin-center hover:scale-105 hover:bg-blueGray-200 dark:hover:bg-blueGray-900 transition duration-150 ease-in-out"
          >
            <div
              class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium mb-2"
              :class="document.classes"
            >
              {{ document.institution }}
            </div>
            <div>
              <h3
                class="pb-1 md:pb-2 text-gray-600 dark:text-blueGray-400 font-semibold text-lg md:text-2xl leading-6"
              >
                {{ document.title }}
              </h3>
              <p class="text-gray-400 md:text-lg">
                {{ document.description }}
              </p>
            </div>
          </div>
          <div
            class="p-6 md:p-10 rounded-xl bg-theme-100 dark:bg-theme-800 transform origin-center hover:scale-105 hover:bg-theme-200 dark:hover:bg-theme-900 transition duration-150 ease-in-out"
          >
            <div
              class="bg-theme-50 text-theme-800 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium mb-2"
            >
              PRÓXIMAMENTE
            </div>
            <div>
              <h3
                class="pb-1 md:pb-2 text-gray-600 dark:text-blueGray-400 font-semibold text-lg md:text-2xl leading-6"
              >
                Los que se agreguen al Artículo 32-D
              </h3>
              <p class="text-gray-400 md:text-lg"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DocumentationInstitution } from "@/application/enums/app/v3/documentation/DocumentationInstitution";
export default {
  data() {
    return {
      documents: [
        {
          institution: DocumentationInstitution[DocumentationInstitution.SAT],
          classes: "bg-blue-100 text-blue-800",
          title: "Opinión de cumplimiento",
          description: ""
        },
        {
          institution:
            DocumentationInstitution[DocumentationInstitution.INFONATIV],
          classes: "bg-red-100 text-red-800",
          title: "Opinión de cumplimiento",
          description: ""
        },
        {
          institution: DocumentationInstitution[DocumentationInstitution.IMSS],
          classes: "bg-orange-100 text-orange-800",
          title: "Opinión de cumplimiento",
          description: ""
        },

        {
          institution: DocumentationInstitution[DocumentationInstitution.IMSS],
          classes: "bg-orange-100 text-orange-800",
          title: "SIPARE",
          description: ""
        },
        {
          institution: DocumentationInstitution[DocumentationInstitution.IMSS],
          classes: "bg-orange-100 text-orange-800",
          title: "SUA detalle cuotas obrero patronales",
          description: ""
        },
        {
          institution: DocumentationInstitution[DocumentationInstitution.IMSS],
          classes: "bg-orange-100 text-orange-800",
          title: "Comprobante de pago SUA/SIPARE",
          description: ""
        },
        {
          institution: DocumentationInstitution[DocumentationInstitution.IMSS],
          classes: "bg-orange-100 text-orange-800",
          title: "ICSOE",
          description: ""
        },
        {
          institution: DocumentationInstitution[DocumentationInstitution.SAT],
          classes: "bg-blue-100 text-blue-800",
          title: "Declaración mensual de ISR por sueldos y salarios",
          description: ""
        },
        {
          institution: DocumentationInstitution[DocumentationInstitution.SAT],
          classes: "bg-blue-100 text-blue-800",
          title: "Declaración mensual de IVA",
          description: ""
        },
        {
          institution: DocumentationInstitution[DocumentationInstitution.SAT],
          classes: "bg-blue-100 text-blue-800",
          title: "Comprobante de pago de la declaración mensual",
          description: ""
        },
        {
          institution: DocumentationInstitution[DocumentationInstitution.SAT],
          classes: "bg-blue-100 text-blue-800",
          title: "Declaración anual de impuestos",
          description: ""
        },
        {
          institution: DocumentationInstitution[DocumentationInstitution.SAT],
          classes: "bg-blue-100 text-blue-800",
          title: "Comprobante de pago de la declaración anual",
          description: ""
        },
        {
          institution:
            DocumentationInstitution[DocumentationInstitution.INFONATIV],
          classes: "bg-red-100 text-red-800",
          title: "SISUB",
          description: ""
        },
        {
          institution: DocumentationInstitution[DocumentationInstitution.STPS],
          classes: "bg-green-100 text-green-800",
          title: "Registro en el REPSE",
          description: ""
        }
      ]
    };
  }
};
</script>

<style>
</style>