export const accountRoutes = [
  {
    name: "account.login",
    path: "/entrar",
    alias: "/login",
    component: require("@/views/account/Login.vue").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "account.register",
    path: "/registro",
    alias: "/register",
    component: require("@/views/account/Register.vue").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "account.verify",
    path: "/verificar",
    alias: "/verify",
    component: require("@/views/account/Verify.vue").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "account.reset",
    path: "/restablecer",
    alias: "/reset",
    component: require("@/views/account/Reset.vue").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "account.forgot",
    path: "/olvide-contrasena",
    alias: "/forgot-password",
    component: require("@/views/account/Forgot.vue").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "account.member.invitation",
    path: "/invitacion",
    alias: "/invitation",
    component: require("@/views/account/TenantMemberInvitation.vue").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "account.join",
    path: "/unirse/:linkUrl",
    alias: "/join/:linkUrl",
    component: require("@/views/account/InviteURL.vue").default,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: "logout",
    path: "logout",
    component: require("@/views/account/Logout.vue").default,
  },
];
