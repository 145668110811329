import { RouteConfig } from "vue-router";
import { accountRoutes } from "./accountRoutes";
import { adminRoutes } from "./adminRoutes";
import { appRoutes } from "./appRoutes";
import marketingRoutes from "./marketingRoutes";
const routes: RouteConfig[] = [
  {
    path: "*",
    redirect: "/",
  },
  ...marketingRoutes,
  ...accountRoutes,
  appRoutes,
  adminRoutes,
];
export default routes;
