




















































import Vue from "vue";
import Component from "vue-class-component";
import { TranslateResult } from "vue-i18n";

@Component
export default class Modal extends Vue {
  title!: string;
  closeText!: TranslateResult | string;
  showing = false;
  errorDescription!: string;
  mounted() {
    this.closeText = this.$t("shared.close").toString();
  }
  show(title?: any, closeText?: any) {
    this.title = title;
    if (closeText !== "") {
      this.closeText = closeText;
    }
    this.showing = true;
  }
  close() {
    this.showing = false;
  }
}
