





























import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import { ContractDto } from "@/application/dtos/app/v3/contracts/ContractDto";
import services from "@/services";
import ActiveContractsListAndTable from "@/components/app/v3/visits/ActiveContractsListAndTable.vue";
import { ContractStatusFilter } from "@/application/contracts/app/v3/contracts/ContractStatusFilter";
import ButtonSecondary from "@/components/shared/buttons/ButtonSecondary.vue";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  metaInfo: {
    title: "Visitas",
    titleTemplate: "%s | Délega"
  },
  components: {
    Breadcrumb,
    ActiveContractsListAndTable,
    ButtonSecondary,
    Loading
  }
})
export default class Visits extends Vue {
  contracts: ContractDto[] = [];
  error = "";
  loading = false;
  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    services.contracts
      .getAllByStatusFilter(ContractStatusFilter.SIGNED)
      .then(response => {
        this.contracts = response;
      })
      .catch(error => {
        this.error = this.$t(error).toString();
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
