



























































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import ErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import Modal from "../../../components/shared/modals/Modal.vue";
import Products from "../../../components/marketing/pricing/Products.vue";
import { SubscriptionProductDto } from "../../../application/dtos/master/subscriptions/SubscriptionProductDto";
import services from "@/services";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  components: {
    Products,
    TableRowSkeleton,
    Modal,
    Loading
  }
})
export default class SubscriptionProduct extends Vue {
  $refs!: {
    errorModal: ErrorModal;
  };
  loading = false;
  items = [] as SubscriptionProductDto[];
  fromServer = true;
  mounted() {
    this.reload(true);
  }
  async reload(fromServer: boolean) {
    this.fromServer = fromServer;
    this.items = [];
    this.loading = true;
    services.subscriptionProducts
      .getProducts(fromServer, false)
      .then((response: SubscriptionProductDto[]) => {
        this.items = [];
        response.forEach(product => {
          this.items.push(product);
        });
        if (response.length === 0 && this.fromServer) {
          this.reload(false);
        }
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  added(data: SubscriptionProductDto) {
    this.items.push(data);
  }
  saved(data: SubscriptionProductDto) {
    const idx = this.items.findIndex(f => f.id === data.id);
    this.items[idx] = data;
  }
  deleted(data: SubscriptionProductDto) {
    this.items = this.items.filter(f => f.id !== data.id);
  }
  async generateFromFiles() {
    this.loading = true;
    this.myAsyncLoopFunction().finally(() => {
      this.reload(true);
    });
  }
  async myAsyncLoopFunction() {
    const allAsyncResults: any[] = [];

    for (const product of this.items) {
      const asyncResult = await services.subscriptionProducts
        .createProduct(product)
        .catch(error => {
          this.$refs.errorModal.show(error);
        });
      allAsyncResults.push(asyncResult);
    }

    return allAsyncResults;
  }
  get pricingFile() {
    return "ClientApp/src/store/modules/pricing/default-pricing.ts";
  }
  get orderedItems() {
    if (!this.items) {
      return [];
    }
    return this.items.sort((x, y) => {
      return x?.tier > y?.tier ? 1 : -1;
    });
  }
}
