
































































































































































































import Header from "@/components/marketing/Header.vue";
import Footer from "@/components/marketing/Footer.vue";

export default {
  metaInfo: {
    title: "Términos y condiciones",
    titleTemplate: "%s | Délega",
  },
  components: { Header, Footer },
};
