

















import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {}
})
export default class ColorSwitcher extends Vue {
  colors = [
    // "red",
    // "orange",
    // "amber",
    // // "yellow",
    // // "lime",
    // "green",
    // "emerald",
    // "teal",
    // "cyan",
    // "lightBlue",
    // "blue",
    // "indigo",
    // "violet",
    // "purple",
    // "fuchsia",
    // // "pink",
    // "rose",
    // // "gray",
    // "blueGray",
    // "coolGray"
    // // "trueGray",
    // // "warmGray"
  ];
}
