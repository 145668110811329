































import Vue from "vue";
import Component from "vue-class-component";
import services from "@/services";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import NewModal from "@/components/shared/modals/NewModal.vue";
import InvoiceExtractionsListAndTable from "./InvoiceExtractionsListAndTable.vue";
import Loading from "@/components/shared/loaders/Loading.vue";
import { InvoiceExtractionDto } from "@/application/dtos/app/v3/cfdis/sat/InvoiceExtractionDto";
import ButtonPrimary from "../../../../shared/buttons/ButtonPrimary.vue";
import { ExtractionStatus } from "@/application/enums/app/v3/cfdis/ExtractionStatus";
import SuccessModal from "@/components/shared/modals/SuccessModal2.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import { SyncCfdisStatus } from "@/application/enums/app/v3/cfdis/SyncCfdisStatus";
import store from "@/store";

@Component({
  components: {
    TableRowSkeleton,
    NewModal,
    InvoiceExtractionsListAndTable,
    Loading,
    SuccessModal,
    ButtonPrimary,
    ErrorModal,
    ConfirmModal
  }
})
export default class InvoiceExtractionsList extends Vue {
  $refs!: {
    successModal: SuccessModal;
    errorModal: ErrorModal;
    confirmModal: ConfirmModal;
  };
  loading = false;
  items: InvoiceExtractionDto[] = [];

  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    services.cfdis
      .getAllExtractions()
      .then(response => {
        this.items = response;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  syncPending() {
    if (this.workspaceWithoutFiscalId) {
      this.$refs.errorModal.show(
        "Faltan credenciales",
        "Ve a Ajustes -> Empresas -> Agregar credenciales para poder sincronizar tus CFDIs del SAT."
      );
      return;
    }
    // if (this.items.length > 0 && !this.hasReady) {
    //   const now = new Date();
    //   if (this.items[0].periodTo !== undefined) {
    //     const lastPeriodTo = new Date(this.items[0].periodTo);
    //     const diffMs = Math.abs(
    //       lastPeriodTo.getTime() - new Date(now).getTime()
    //     );
    //     const diffDays = Math.floor(diffMs / 86400000); // days
    //     const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    //     const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    //     console.log("lastPeriodTo", lastPeriodTo);
    //     console.log("now", now);
    //     console.log("diffDays", diffDays);
    //     console.log("diffHrs", diffHrs);
    //     console.log("diffMins", diffMins);
    //     if (diffHrs < 1) {
    //       this.$refs.errorModal.show(
    //         "Límite excedido",
    //         "Espera una hora para sincronizar de nuevo."
    //       );
    //       return;
    //     }
    //   }
    // }
    this.confirmedSync();
    // this.$refs.confirmModal.show(
    //   "¿Sincronizar?",
    //   "Sincronizar",
    //   "Atrás",
    //   "Se utilizarán tus credenciales del SAT para obtener los CFDIs hasta la hora actual."
    // );
  }
  confirmedSync() {
    this.loading = true;
    services.cfdis
      .sync()
      .then(response => {
        this.reload();
        if (response.status === SyncCfdisStatus.NothingToSync) {
          this.$refs.errorModal.show("Nada pendiente", response.message);
        } else if (response.status === SyncCfdisStatus.Persisted) {
          this.$refs.successModal.show("CFDIs extraídos", response.message);
        } else if (response.status === SyncCfdisStatus.Running) {
          this.$refs.errorModal.show("Ejecución en proceso", response.message);
        } else if (response.status === SyncCfdisStatus.Extracted) {
          this.$refs.successModal.show(
            "Extracción solicitada",
            response.message
          );
        } else if (response.status === SyncCfdisStatus.LimitReached) {
          this.$refs.errorModal.show("Límite alcanzado", response.message);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
  get workspaceWithoutFiscalId(): string {
    if (!store.state.tenant.currentWorkspace?.fiscalWorkspace)
      return store.state.tenant.currentWorkspace?.id ?? "";
    return "";
  }
  get hasReady() {
    return (
      this.items.filter(
        f => !f.processed && f.status === ExtractionStatus.FINISHED
      ).length > 0
    );
  }
}
