




































































































import Component from "vue-class-component";
import Vue from "vue";
import LoadingButton from "../../components/shared/buttons/LoadingButton.vue";
import GoogleSignInButton from "vue-google-signin-button-directive";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import axios from "axios";
import Logo from "@/components/marketing/Logo.vue";
import { UserLoginRequest } from "../../application/contracts/master/users/UserLoginRequest";
import { UserLoginType } from "../../application/enums/master/users/UserLoginType";
import services from "@/services";

@Component({
  metaInfo: {
    title: "Entra a tu cuenta",
    titleTemplate: "%s | Délega"
  },
  components: {
    ErrorModal,
    Logo,
    LoadingButton
  },
  directives: {
    GoogleSignInButton
  }
})
export default class Login extends Vue {
  $refs!: {
    errorModal: ErrorModal;
    loadingButton: LoadingButton;
  };
  loading = false;
  googleClientId = "";
  user = {} as UserLoginRequest;
  created() {
    this.googleClientId = process.env.VUE_APP_GOOGLE_CLIENT_ID_OAUTH2 ?? "";
  }
  OnGoogleAuthSuccess(idToken) {
    axios
      .get("https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=" + idToken)
      .then(response => {
        this.user.email = response.data.email;
        this.login(UserLoginType.GMAIL);
      });
  }
  OnGoogleAuthFail(error) {
    console.error("Error: " + error);
  }
  login(type: UserLoginType = UserLoginType.PASSWORD) {
    this.$refs.loadingButton.start();
    this.loading = true;
    this.user.loginType = type;
    services.authentication
      .login(this.user)
      .catch(error => {
        this.$refs.loadingButton.stop();
        console.error(error);
        this.$refs.errorModal.show("Error", error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
