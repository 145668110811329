import { ContractDto } from "@/application/dtos/app/v3/contracts/ContractDto";
import { ApiService } from "../../../ApiService";
import { IContractService } from "./IContractService";
import { CreateContractRequest } from "@/application/contracts/app/v3/contracts/CreateContractRequest";
import { SendContractRequest } from "@/application/contracts/app/v3/contracts/SendContractRequest";
import { UpdateContractRequest } from "@/application/contracts/app/v3/contracts/UpdateContractRequest";
import { ContractEmployeeListDto } from "@/application/dtos/app/v3/contracts/ContractEmployeeListDto";
import { ContractVisitDto } from "@/application/dtos/app/v3/contracts/ContractVisitDto";
import { ContractStatusFilter } from "@/application/contracts/app/v3/contracts/ContractStatusFilter";
import { CreateVisitRequest } from "@/application/contracts/app/v3/contracts/CreateVisitRequest";
import { CreateEmployeeListRequest } from "@/application/contracts/app/v3/contracts/CreateEmployeeListRequest";
import { MifielDocument } from "@/application/dtos/app/v3/mifiel/MifielDocument";
import { ContractFileType } from "@/application/enums/app/v3/contracts/ContractFileType";

export class ContractService extends ApiService implements IContractService {
  constructor() {
    super("Contract");
  }
  getAllByStatusFilter(filter: ContractStatusFilter): Promise<ContractDto[]> {
    return super.getAll("GetAllByStatusFilter/" + filter);
  }
  getAllByLink(linkId: string): Promise<ContractDto[]> {
    return super.getAll("GetAllByLink/" + linkId);
  }
  getContract(id: string, employees: boolean): Promise<ContractDto> {
    return super.get(`Get/${id}/${employees}`);
  }
  getDigitalDocument(id: string): Promise<MifielDocument> {
    return super.get("GetDigitalDocument", id);
  }
  getEmployeeListDigitalDocument(id: string, listId: string): Promise<MifielDocument> {
    return super.get(`GetEmployeeListDigitalDocument/${id}/${listId}`, id);
  }
  create(data: CreateContractRequest): Promise<ContractDto> {
    return super.post(data, "Create");
  }
  signed(id: string, signature: string): Promise<any> {
    return super.post(undefined, `Signed/${id}/${signature}`);
  }
  updateEmployeeList(id: string, data: CreateEmployeeListRequest): Promise<ContractEmployeeListDto> {
    return super.post(data, "UpdateEmployeeList/" + id);
  }
  createVisit(id: string, data: CreateVisitRequest): Promise<ContractVisitDto> {
    return super.post(data, "CreateVisit/" + id);
  }
  downloadFile(id: string, format: ContractFileType): Promise<any> {
    return super.post(undefined, `Download/${id}/${format}`);
  }
  downloadAddendum(id: string, listId: string): Promise<any> {
    return super.post(undefined, `DownloadAddendum/${id}/${listId}`);
  }
  send(id: string, request: SendContractRequest): Promise<any> {
    return super.post(request, `Send/${id}`);
  }
  update(id: string, data: UpdateContractRequest): Promise<ContractDto> {
    return super.put(id, data, "Update");
  }
  delete(id: string): Promise<any> {
    return super.delete(id);
  }
}
