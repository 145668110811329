



















































































































import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import DocumentUpload from "./DocumentUpload.vue";
import { DocumentStatusDto } from "@/application/dtos/app/v3/documentation/DocumentStatusDto";

@Component({
  components: {
    DocumentUpload
  }
})
export default class SideDocumentUpload extends Vue {
  $refs!: {
    uploadDocument: DocumentUpload
  }
  @Prop()
  showingDocument!: DocumentStatusDto;
  @Prop()
  title!: string;
  @Prop({ default: "md" })
  size!: string;
  @Prop({ default: "px-4 sm:px-6" })
  paddingContent!: string;
  @Prop({ default: "py-6" })
  paddingY!: string;
  created() {
    document.addEventListener("keyup", evt => {
      if (evt.keyCode === 27) {
        this.close();
      }
    });
  }
  close() {
    this.$emit("close");
  }
}
