









































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import services from "@/services";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal2.vue";
import WarningBannner from "@/components/shared/banners/WarningBanner.vue";
import Loading from "@/components/shared/loaders/Loading.vue";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import { FiscalCredentialType } from "@/application/enums/app/v3/cfdis/FiscalCredentialType";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";

@Component({
  components: {
    ErrorModal,
    SuccessModal,
    WarningBannner,
    Loading,
    ConfirmModal
  }
})
export default class EditWorkspaceCredential extends Vue {
  @Prop({ default: "sm:max-w-lg" }) maxSize!: string;
  $refs!: {
    errorModal: ErrorModal;
    confirmModal: ConfirmModal;
    successModal: SuccessModal;
    inputName: HTMLInputElement;
  };
  loading = false;
  item: WorkspaceDto | null = null;
  type: FiscalCredentialType = FiscalCredentialType.CIEC;
  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    services.workspaces
      .get(this.$route.params.id)
      .then(response => {
        this.item = response;
      })
      .finally(() => {
        this.loading = false;
        this.$nextTick(() => {
          if (this.$refs.inputName) {
            this.$refs.inputName.focus();
            this.$refs.inputName.select();
          }
        });
      });
  }
  keyup(event: KeyboardEvent) {
    console.log(event.keyCode);
    if (event.keyCode === 27) {
      this.close();
    }
  }
  close() {
    this.$router.push({ name: "settings.tenant.workspaces" });
  }
  deleteCredentials() {
    this.$refs.confirmModal.show(
      "¿Eliminar credencial?",
      "Eliminar",
      "Atrás",
      "ADVERTENCIA: Ya no podrás obtener automáticamente información del SAT como la opinión de cumplimiento y tus CFDIs."
    );
  }
  confirmedDelete() {
    this.loading = true;
    services.workspaces
      .deleteCredential(this.item?.fiscalWorkspace?.id)
      .then(() => {
        this.$refs.successModal.show(
          "Credencial eliminada",
          "Se ha eliminado correctamente."
        );
      })
      .catch(error => {
        this.$refs.errorModal.show("Error al eliminar", this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
