






























































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import NewEmployees from "@/components/app/v3/employees/profile/NewEmployees.vue";

@Component({
  components: {
    Breadcrumb,
    NewEmployees
  }
})
export default class NewEmployee extends Vue {
  $refs!: {
    newEmployees: NewEmployees;
  };
  error = "";
  loading = false;
  save() {
    this.$refs.newEmployees.save();
  }
  cancel() {
    this.$router.push({ name: "app.employees" });
  }
}
