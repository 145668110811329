













import Vue from "vue";
import Component from "vue-class-component";
import Header from "@/components/marketing/Header.vue";
import Products from "../../components/marketing/pricing/Products2.vue";
import TableRowSkeleton from "../../components/shared/skeletons/TableRowSkeleton.vue";
import Faq from "../../components/marketing/Faq.vue";
import Footer from "../../components/marketing/Footer.vue";
import { SubscriptionBillingPeriod } from "../../application/enums/master/subscriptions/SubscriptionBillingPeriod";
import store from "@/store";

@Component({
  metaInfo: {
    title: "Planes y precios",
    titleTemplate: "%s | Délega"
  },
  components: {
    Header,
    Products,
    TableRowSkeleton,
    Faq,
    Footer
  }
})
export default class Pricing extends Vue {
  mounted() {
    store.commit("pricing/billingPeriod", SubscriptionBillingPeriod.MONTHLY);
    store.commit("pricing/currency", "mxn");
  }
}
