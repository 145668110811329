







































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { i18n } from "@/plugins/vue-i18n";
import store from "@/store";
import Icon from "@/assets/contracts/WorkerMale.vue";
import { ContractVisitDto } from "@/application/dtos/app/v3/contracts/ContractVisitDto";
import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";
import DateUtils from "@/utils/shared/DateUtils";

@Component({
  components: {
    Icon
  }
})
export default class VisitsListAndTable extends Vue {
  @Prop({}) items!: ContractVisitDto[];
  sortByColumn = "";
  sortDirection = -1;
  headers = [
    {
      name: "",
      title: i18n.t("models.visit.date")
    },
    {
      title: i18n.t("app.contracts.visits.attended")
    },
    {
      title: i18n.t("models.cfdi.plural")
    }
    // {
    //   title: i18n.t("app.visits.visits")
    // }
  ];

  sortBy(column) {
    if (column) {
      this.sortDirection = this.sortDirection === -1 ? 1 : -1;
      this.sortByColumn = column;
    }
  }
  currentWorkspaceTaxIdIs(taxId) {
    return store.state.tenant.currentWorkspace?.taxId === taxId;
  }
  downloadCfdis(visit: ContractVisitDto) {
    alert("not implemented: " + visit.id);
  }
  dateMonthName(value: Date | undefined) {
    return DateUtils.dateMonthName(value);
  }
  dateDM(value: Date | undefined) {
    return DateUtils.dateDM(value);
  }
  dateHMS(value: Date | undefined) {
    return DateUtils.dateHMS(value);
  }
  get role(): TenantUserRole {
    return store.state.tenant.current?.currentUser.role ?? TenantUserRole.GUEST;
  }
  get sortedItems(): ContractVisitDto[] {
    const column = this.sortByColumn;
    const sortDirection = this.sortDirection;
    if (!column || column === "") {
      return this.items;
    }
    return this.items.sort((x, y) => {
      if (x[column] && y[column]) {
        if (sortDirection === -1) {
          return (x[column] > y[column] ? 1 : -1) ?? 1;
        } else {
          return (x[column] < y[column] ? 1 : -1) ?? 1;
        }
      }
      return 1;
    });
  }
}
