
















































































































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import ErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import { ContractDto } from "@/application/dtos/app/v3/contracts/ContractDto";
import services from "@/services";
import pdf from "vue-pdf";
import Sign from "@/assets/contracts/Sign.vue";
import store from "@/store";
import SideModal from "@/components/shared/modals/SideModal.vue";
import DropDocument from "@/components/app/v3/contracts/uploads/DropDocument.vue";
import CfdisListAndTable from "@/components/app/v3/cfdis/list/CfdisListAndTable.vue";
import { CfdiXmlPdfDto } from "@/application/dtos/app/v3/cfdis/CfdiXmlPdfDto";
import DropCfdis from "@/components/app/uploads/DropCfdis.vue";
import { FileBase64 } from "@/application/dtos/shared/FileBase64";
import { UploadCfdisRequest } from "@/application/contracts/app/cfdis/UploadCfdisRequest";
import { CfdiDto } from "@/application/dtos/app/v3/cfdis/CfdiDto";
import NumberUtils from "@/utils/shared/NumberUtils";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  metaInfo: {
    title: "CFDIs de contrato",
    titleTemplate: "%s | Délega"
  },
  components: {
    Breadcrumb,
    pdf,
    Sign,
    SideModal,
    DropDocument,
    CfdisListAndTable,
    ErrorModal,
    SuccessModal,
    ConfirmModal,
    DropCfdis,
    Loading
  }
})
export default class ContractCfdis extends Vue {
  $refs!: {
    errorModal: ErrorModal;
    successModal: SuccessModal;
    confirmCreate: ConfirmModal;
    modalSign: SideModal;
    buttonSave: HTMLButtonElement;
    dropCfdis: DropCfdis;
  };
  id = "";

  item: ContractDto | null = null;
  error = "";
  loading = false;
  uploading = false;
  cfdiXmlAndPdfs: CfdiXmlPdfDto[] = [];
  searchInput = "";

  created() {
    this.id = this.$route.params.id;
  }
  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    services.contracts
      .getContract(this.id, false)
      .then(response => {
        this.item = response;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  toggleUpload() {
    this.uploading = !this.uploading;
    if (this.uploading) {
      // this.$nextTick(() => {
      //   this.$refs.buttonSave.focus();
      // });
    }
  }
  droppedFiles(files: FileBase64[]) {
    const cfdis: CfdiXmlPdfDto[] = [];
    this.loading = true;
    files.forEach(file => {
      const name = file.file.name
        .toUpperCase()
        .replace(".XML", "")
        .replace(".PDF", "");
      const xml = file.file.name.toUpperCase().includes(".XML")
        ? file.base64
        : "";
      const pdf = file.file.name.toUpperCase().includes(".PDF")
        ? file.base64
        : "";
      let cfdi = cfdis.find(f => f.fileName === name);
      if (!cfdi) {
        cfdi = {
          id: undefined,
          fileName: name,
          xml,
          pdf
        };
        cfdis.push(cfdi);
      } else {
        if (xml) {
          cfdi.xml = xml;
        }
        if (pdf) {
          cfdi.pdf = pdf;
        }
      }
    });
    const uploadCfdisRequest: UploadCfdisRequest = {
      cfdis
    };
    this.loading = true;
    services.cfdis
      .readCfdis(uploadCfdisRequest)
      .then((response: CfdiXmlPdfDto[]) => {
        this.cfdiXmlAndPdfs = response;
      })
      .catch(error => {
        this.$refs.errorModal.show(this.$t(error).toString());
      })
      .finally(() => {
        this.loading = false;
        // self.$refs.dropCfdis.loading = false;
      });
  }
  removeCfdiXmlPdf(index) {
    this.cfdiXmlAndPdfs = this.cfdiXmlAndPdfs.filter((x, i) => i !== index);
  }
  decimalFormat(value: number) {
    return NumberUtils.decimalFormat(value);
  }
  get canEdit() {
    return this.item?.createdByUserId === store.state.account.user?.id || true;
  }
  get filteredItems(): CfdiDto[] {
    if (this.item?.cfdis.length === 0) {
      return [];
    }
    const items =
      this.item?.cfdis
        .map(f => f.cfdi)
        .filter(
          f =>
            f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) ||
            f.receptorNombre
              ?.toString()
              .toUpperCase()
              .includes(this.searchInput.toUpperCase()) ||
            f.receptorRfc
              ?.toString()
              .toUpperCase()
              .includes(this.searchInput.toUpperCase())
        ) ?? [];

    return items;
  }
}
