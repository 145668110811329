



































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { TabItem } from "./Tabs.vue";

@Component({})
export default class TabsInPills extends Vue {
  @Prop({ default: "sm" }) breakpoint!: string;
  @Prop({}) tabs!: TabItem[];
}
