


































































import Component from "vue-class-component";
import Vue from "vue";
import { SubscriptionInvoiceDto } from "@/application/dtos/master/subscriptions/SubscriptionInvoiceDto";
import store from "@/store";
import tinyEventBus from "@/plugins/tiny-eventbus";
import services from "@/services";
import DateUtils from "@/utils/shared/DateUtils";

@Component({
  components: {}
})
export default class TenantSubscriptionInvoices extends Vue {
  upcomingInvoice = {} as SubscriptionInvoiceDto;
  created() {
    tinyEventBus().emitter.on("updated-plan", () => this.getUpcomingInvoice());
  }
  beforeDestroy() {
    tinyEventBus().emitter.off("updatedPlan");
  }
  mounted() {
    this.getUpcomingInvoice();
  }
  getUpcomingInvoice() {
    services.subscriptionManager
      .getUpcomingInvoice()
      .then((response: SubscriptionInvoiceDto) => {
        this.upcomingInvoice = response;
      });
  }
  invoiceDescription(invoice: SubscriptionInvoiceDto) {
    if (invoice && invoice.created) {
      const date = new Date(invoice.created);
      return DateUtils.dateLL(date);
    }
    return "";
  }
  get invoices(): SubscriptionInvoiceDto[] | undefined {
    return store.state.tenant?.subscription?.invoices ?? [];
  }
}
