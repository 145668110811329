export enum DocumentationType {
  SAT_ARTICLES_OF_INCORPORATION_DOCS,
  SAT_COMPLIANCE,
  SAT_MONTHLY_ISR_STATEMENT,
  SAT_MONTHLY_TAX_STATEMENT,
  SAT_MONTHLY_PAYMENT,
  SAT_YEARLY_STATEMENT,
  SAT_YEARLY_PAYMENT,
  IMSS_COMPLIANCE,
  IMSS_SIPARE_SUA,
  IMSS_SUA_EMPLOYEES_FEES,
  IMSS_SIPARE_SUA_PAYMENT,
  INFONAVIT_COMPLIANCE,
  IMSS_ICSOE,
  INFONAVIT_SISUB,
  STPS_REPSE,
  SAT_CFDIS,
}
