


























































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import { ContractDto } from "@/application/dtos/app/v3/contracts/ContractDto";
import services from "@/services";
import ActiveContractsListAndTable from "@/components/app/v3/visits/ActiveContractsListAndTable.vue";
import VisitsListAndTable from "@/components/app/v3/employees/visits/VisitsListAndTable.vue";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  metaInfo: {
    title: "Visitas de contrato",
    titleTemplate: "%s | Délega"
  },
  components: {
    Breadcrumb,
    ActiveContractsListAndTable,
    VisitsListAndTable,
    EmptyState,
    Loading
  }
})
export default class VisitsList extends Vue {
  id = "";
  contract: ContractDto = {} as ContractDto;
  error = "";
  loading = false;
  created() {
    this.id = this.$route.params.id;
  }
  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    services.contracts
      .getContract(this.id, true)
      .then(response => {
        this.contract = response;
      })
      .catch(error => {
        this.error = this.$t(error).toString();
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
