











































import Vue from "vue";
import Component from "vue-class-component";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";

import Modal from "../../../components/shared/modals/Modal.vue";
import Products from "../../../components/marketing/pricing/Products.vue";

@Component({
  components: {
    Products,
    TableRowSkeleton,
    Modal
  }
})
export default class SubscriptionProductOverview extends Vue {
  columns = [
    { name: "serviceId", type: typeof String },
    { name: "title", type: typeof String },
    { name: "description", type: typeof String },
    { name: "badge", type: typeof String },
    { name: "active", type: typeof Number },
    { name: "image", type: typeof Image }
  ];
  get product() {
    // @ts-ignore
    return this.$parent.product;
  }
}
