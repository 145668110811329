










































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import ErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import { Prop } from "vue-property-decorator";
import { TenantUserDto } from "@/application/dtos/master/tenants/TenantUserDto";
import { TenantJoinSettingsDto } from "@/application/dtos/master/tenants/TenantJoinSettingsDto";
import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";
import { TenantUserStatus } from "@/application/enums/master/tenants/TenantUserStatus";
import { TenantUserJoined } from "@/application/enums/master/tenants/TenantUserJoined";
import { i18n } from "@/plugins/vue-i18n";
import tinyEventBus from "@/plugins/tiny-eventbus";
import store from "@/store";
import WarningBannner from "@/components/shared/banners/WarningBanner.vue";
import services from "@/services";
import { UserType } from "@/application/enums/master/users/UserType";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  metaInfo: {
    title: "Miembros",
    titleTemplate: "%s | Délega"
  },
  components: {
    TableRowSkeleton,
    ConfirmModal,
    ErrorModal,
    WarningBannner,
    Loading
  }
})
export default class TenantMembers extends Vue {
  $refs!: {
    confirmarAcceptUser: ConfirmModal;
    errorModal: ErrorModal;
    confirmUpgrade: ConfirmModal;
  };
  @Prop({ default: "Miembros" }) readonly title!: string;
  loading = false;
  sortByColumn = "";
  sortDirection = -1;
  searchInput = "";

  headers = [
    {
      name: "role",
      title: i18n.t("settings.profile.role")
    },
    {
      name: "email",
      title: i18n.t("account.shared.email")
    },
    {
      name: "firstName",
      title: i18n.t("settings.profile.name")
    },
    {
      title: i18n.t("settings.profile.phone")
    },
    {
      title: i18n.t("shared.status")
    },
    {
      title: i18n.t("settings.profile.joinedMethod")
    },
    {
      title: i18n.t("models.user.companyRole")
    }
  ];

  items = [] as TenantUserDto[];
  tenantJoinSettings: any = {};
  enableLink = false;
  enablePublicUrl = false;
  requireAcceptance = false;
  copiedUrlLink = false;
  acceptedUser!: TenantUserDto;
  acceptUserEmail = "";
  created() {
    tinyEventBus().emitter.on("user-canceled", () => this.canceled());
    tinyEventBus().emitter.on("user-added", () => this.reload());
    tinyEventBus().emitter.on("user-saved", () => this.reload());
    tinyEventBus().emitter.on("user-deleted", () => this.reload());

    this.acceptUserEmail = this.$route.query.au?.toString() ?? "";
  }
  destroy() {
    tinyEventBus().emitter.off("user-canceled");
    tinyEventBus().emitter.off("user-deleted");
    tinyEventBus().emitter.off("user-added");
    tinyEventBus().emitter.off("user-saved");
  }
  mounted() {
    services.tenants.getFeatures();
    // SignalService.on("UserAdded", (user: User) => {
    //   this.items.push(user);
    // });
    services.tenantUserInvitations
      .getInvitationSettings()
      .then((response: TenantJoinSettingsDto) => {
        this.tenantJoinSettings = response;
        this.enableLink = this.tenantJoinSettings.linkActive;
        this.enablePublicUrl = this.tenantJoinSettings.publicUrl;
        this.requireAcceptance = this.tenantJoinSettings.requireAcceptance;
      });
    this.reload();
  }
  avatarText(user: TenantUserDto) {
    if (user) {
      // return "xx";
      if (user.firstName && user.lastName) {
        if (user.firstName.length > 0 && user.lastName.length > 0) {
          return (user.firstName[0] + user.lastName[0]).toUpperCase();
        } else if (user.firstName.length > 1) {
          return user.firstName.substring(0, 2).toUpperCase();
        } else if (user.email.length > 1) {
          return user.email.substring(0, 2).toUpperCase();
        }
      } else {
        return user.email.substring(0, 2).toUpperCase();
      }
    }
  }
  async reload() {
    this.loading = true;
    services.tenantUsers
      .getAll("")
      .then((response: TenantUserDto[]) => {
        this.items = [];
        response.forEach(element => {
          this.items.push(element);
        });

        if (this.acceptUserEmail) {
          const user = this.items.find(f => f.email === this.acceptUserEmail);
          if (user && user.status === TenantUserStatus.PENDING_ACCEPTANCE) {
            this.acceptUser(user, true);
          }
        }
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  canceled() {
    console.log("canceled");
  }
  added() {
    this.reload();
    // console.log("added data:" + JSON.stringify(data));
    // this.items.push(data);
    // // SignalService.invoke(
    //   "AddUser",
    //   store.state.tenant.current.id,
    //   data
    // );
  }
  saved(data) {
    const idx = this.items.findIndex(f => f.id === data.id);
    // console.log("found: " + JSON.stringify(idx));
    this.items[idx] = data;
  }
  deleted(data) {
    this.items = this.items.filter(f => f.id !== data.id);
  }
  updateTenantJoinSettings(reset = false) {
    services.tenantUserInvitations
      .updateInvitationSettings({
        enableLink: this.enableLink,
        resetLink: reset,
        enablePublicUrl: this.enablePublicUrl,
        requireAcceptance: this.requireAcceptance
      })
      .then((response: TenantJoinSettingsDto) => {
        this.tenantJoinSettings = response;
        this.enableLink = this.tenantJoinSettings.linkActive;
        this.enablePublicUrl = this.tenantJoinSettings.publicUrl;
        this.requireAcceptance = this.tenantJoinSettings.requireAcceptance;
      });
  }
  changeLinkSettings() {
    this.updateTenantJoinSettings();
  }
  copyInviteLink() {
    if (this.maxUsersReached) {
      this.$refs.confirmUpgrade.show(
        this.$t("settings.tenant.members.maxUsers") +
          " (" +
          this.maxUsers +
          "). " +
          this.$t("shared.upgrade?")
      );
    } else {
      // @ts-ignore
      this.$clipboard(this.urlLink);
      this.copiedUrlLink = true;
    }
  }
  yesUpdateSubscription() {
    this.$router.push({ name: "settings.tenant.subscription" });
  }
  getUserRole(item: TenantUserDto) {
    return this.$t("settings.profile.roles." + TenantUserRole[item.role]);
  }
  getUserJoined(item: TenantUserDto) {
    if (
      item.status === TenantUserStatus.ACTIVE &&
      item.joined !== TenantUserJoined.CREATOR
    ) {
      return this.$t(
        "settings.profile.joined." + TenantUserJoined[item.joined]
      );
    }
  }
  getUserStatus(item: TenantUserDto) {
    return this.$t("settings.profile.status." + TenantUserStatus[item.status]);
  }
  getUserRoleClass(item: TenantUserDto) {
    switch (item.role as TenantUserRole) {
      case TenantUserRole.OWNER:
        return "bg-blueGray-50 text-gray-800 border border-blueGray-300";
      case TenantUserRole.ADMIN:
        return "bg-rose-50 border border-rose-200";
      case TenantUserRole.MEMBER:
        return "bg-blue-50 border border-blue-200";
      case TenantUserRole.GUEST:
        return "bg-gray-50 border border-gray-200";
    }
  }
  getUserJoinedClass(item: TenantUserDto) {
    if (item.status === TenantUserStatus.ACTIVE) {
      switch (item.joined as TenantUserJoined) {
        case TenantUserJoined.CREATOR:
          return "bg-gray-800 text-gray-100";
        case TenantUserJoined.JOINED_BY_INVITATION:
          return "bg-blue-50 border border-blue-200";
        case TenantUserJoined.JOINED_BY_LINK:
          return "bg-teal-50 border border-teal-200";
        case TenantUserJoined.JOINED_BY_PUBLIC_URL:
          return "bg-orange-50 border border-orange-200";
      }
    }
  }
  getUserStatusClass(item: TenantUserDto) {
    switch (item.status as TenantUserStatus) {
      case TenantUserStatus.PENDING_INVITATION:
        return "bg-yellow-50 border border-yellow-300";
      case TenantUserStatus.PENDING_ACCEPTANCE:
        return "bg-yellow-50 border border-yellow-300";
      case TenantUserStatus.ACTIVE:
        return "bg-teal-50 border border-teal-200";
      case TenantUserStatus.INACTIVE:
        return "bg-red-50 border border-red-300";
    }
  }
  pendingAcceptance(item: TenantUserDto) {
    return item.status === TenantUserStatus.PENDING_ACCEPTANCE;
  }
  yesAcceptUser() {
    if (this.isOwnerOrAdmin && this.acceptedUser) {
      services.tenantUserInvitations
        .acceptUser(this.acceptedUser)
        .then(() => {
          this.reload();
        })
        .catch(error => {
          this.$refs.errorModal.show(error);
        });
    }
  }
  acceptUser(item: TenantUserDto, accept: boolean) {
    item.accepted = accept;
    this.acceptedUser = item;
    this.$refs.confirmarAcceptUser.show(
      this.$t("shared.accept?", [item.email]).toString(),
      this.$t("shared.accept").toString(),
      this.$t("shared.cancel").toString()
    );
  }
  sortBy(column) {
    if (column) {
      this.sortDirection = this.sortDirection === -1 ? 1 : -1;
      this.sortByColumn = column;
    }
  }
  get isOwnerOrAdmin(): boolean {
    return (
      this.currentRole === TenantUserRole.OWNER ||
      this.currentRole === TenantUserRole.ADMIN
    );
  }
  get currentRole(): TenantUserRole {
    return store.state.tenant.current?.currentUser.role ?? TenantUserRole.GUEST;
  }
  get maxUsers(): number {
    if (store.state.account.user?.type === UserType.Admin) {
      return 0;
    }
    return store.state.app.features.maxUsers;
  }
  get maxUsersReached() {
    if (
      this.maxUsers > 0 &&
      store.state.tenant.members.length >= this.maxUsers
    ) {
      return true;
    }
    return false;
  }

  get urlLink(): string {
    if (this.tenantJoinSettings) {
      return location.origin + "/join/" + this.tenantJoinSettings.link;
    }
    return "";
  }
  get filteredItems(): TenantUserDto[] {
    if (!this.items) {
      return [];
    }
    const items = this.items.filter(
      f =>
        f.firstName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.lastName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.email
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.phone
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase())
    );

    return items;
  }
  get sortedItems() {
    if (!this.items) {
      return [];
    }
    const items = this.filteredItems.sort((x, y) => {
      return x.role > y.role ? -1 : 1;
    });
    return items.sort((x, y) => {
      return x.role > y.role ? 1 : -1;
    });
  }
}
