var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:[_vm.breakpoint + ':hidden']},[_c('label',{staticClass:"sr-only",attrs:{"for":"tabs"}},[_vm._v("Select a tab")]),_c('select',{staticClass:"block w-full focus:ring-theme-500 focus:border-theme-500 border-gray-300 rounded-sm",attrs:{"id":"tabs","name":"tabs"},on:{"change":function($event){return _vm.selectTab($event.target.value)}}},_vm._l((_vm.tabs),function(tab){return _c('option',{key:tab.name,domProps:{"selected":_vm.isCurrent(tab)}},[_vm._v(_vm._s(tab.name))])}),0)]),_c('div',{staticClass:"hidden",class:[_vm.breakpoint + ':block']},[_c('nav',{staticClass:"flex space-x-4",attrs:{"aria-label":"Tabs"}},_vm._l((_vm.tabs),function(tab){return _c('router-link',{key:tab.name,staticClass:"truncate",class:[
          _vm.isCurrent(tab)
            ? 'bg-theme-100 text-theme-700'
            : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50',
          'px-3 py-2 font-medium text-sm rounded-sm '
        ],attrs:{"to":{
          name: tab.routeName,
          params: tab.routeParams
        },"aria-current":_vm.isCurrent(tab) ? 'page' : undefined}},[_vm._v(" "+_vm._s(tab.name)+" ")])}),1)])])}
var staticRenderFns = []

export { render, staticRenderFns }