














import Component from "vue-class-component";
import Vue from "vue";
import { CfdiXmlPdfDto } from "@/application/dtos/app/v3/cfdis/CfdiXmlPdfDto";

@Component({
  components: {}
})
export default class CfdisUpload extends Vue {
  uploaded(cfdis: CfdiXmlPdfDto[]) {
    console.log("uploaded cfdis: " + cfdis.length);
  }
}
