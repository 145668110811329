















































































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import services from "@/services";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal2.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import store from "@/store";
import { WorkspaceType } from "@/application/enums/master/tenants/WorkspaceType";
import SelectUsers from "../../../views/app/users/SelectUsers.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import { UserDto } from "@/application/dtos/master/users/UserDto";
import DateUtils from "@/utils/shared/DateUtils";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  components: {
    ErrorModal,
    SuccessModal,
    SelectUsers,
    ConfirmModal,
    Loading
  }
})
export default class EditWorkspace extends Vue {
  @Prop({ default: "sm:max-w-lg" }) maxSize!: string;
  $refs!: {
    errorModal: ErrorModal;
    successModal: SuccessModal;
    selectUsers: SelectUsers;
    confirmRemove: ConfirmModal;
  };
  id = "";
  item: WorkspaceDto | null = null;
  loading = false;
  name = "";
  taxId = "";
  legalName = "";
  type: WorkspaceType = WorkspaceType.REGULAR;
  businessMainActivity = "";
  registrationNumber = "";
  registrationDate: Date | null = null;
  users: UserDto[] = [];
  created() {
    this.id = this.$route.params.id;
  }
  mounted() {
    this.loading = true;
    services.workspaces
      .get(this.id)
      .then(response => {
        this.item = response;
        this.name = response.name;
        this.taxId = response.taxId;
        this.legalName = response.legalName;
        this.type = response.type;
        this.businessMainActivity = response.businessMainActivity;
        this.registrationNumber = response.registrationNumber;
        if (response.registrationDate) {
          this.registrationDate = new Date(
            DateUtils.dateYMD(response.registrationDate)
          );
        }
        this.users = [];
        response.users.forEach(element => {
          if (element.user) {
            this.users.push(element.user);
          }
        });
      })
      .catch(error => {
        this.$refs.errorModal.show("Error", this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  keyup(event: KeyboardEvent) {
    console.log(event.keyCode);
    if (event.keyCode === 27) {
      this.close();
    }
  }
  close() {
    this.$router.push({ name: "settings.tenant.workspaces" });
  }
  save() {
    if (this.users.length === 0) {
      this.$refs.errorModal.show(
        "Error",
        this.$t("account.tenant.workspaces.errors.atLeastOneUser")
      );
      return;
    }
    this.loading = true;
    services.workspaces
      .update(this.id, {
        name: this.name,
        legalName: this.legalName,
        type: this.type,
        businessMainActivity: this.businessMainActivity,
        registrationNumber: this.registrationNumber,
        registrationDate: this.registrationDate,
        users: this.users
      })
      .then(() => {
        tinyEventBus().emitter.emit("workspace-saved");
        this.$refs.successModal.show(
          "Empresa actualizada",
          "Se ha actualizado correctamente."
        );
      })
      .catch(error => {
        this.$refs.errorModal.show("Error", this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  remove() {
    this.$refs.confirmRemove.show(
      this.$t("shared.delete?"),
      "Eliminar",
      "Cancelar",
      "ADVERTENCIA: No se puede recuperar una empresa eliminada."
    );
  }
  yesRemove() {
    if (
      this.currentRole === TenantUserRole.MEMBER ||
      this.currentRole === TenantUserRole.GUEST
    ) {
      this.$refs.errorModal.show(this.$t("account.tenant.onlyAdmin"));
    } else {
      this.loading = true;
      services.workspaces
        .delete(this.id)
        .then(() => {
          tinyEventBus().emitter.emit("workspace-deleted", this.item);
          this.$router.push({ name: "settings.tenant.workspaces" });
        })
        .catch(error => {
          this.$refs.errorModal.show(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  selectUsers() {
    this.$refs.selectUsers.show(this.users.map(f => f.id));
  }
  selectedUsers(items: UserDto[]) {
    this.users = items;
  }
  changedType(e) {
    const type: WorkspaceType = Number(e.target.value);
    this.type = type;
  }
  get currentRole(): TenantUserRole {
    return store.state.tenant.current?.currentUser.role ?? TenantUserRole.GUEST;
  }
  get currentUsersDescription() {
    if (this.users.length === 0) {
      return "No has seleccionado empresas del usuario";
    }
    return this.users.map(f => `${f.firstName} (${f.email})`).join(", ");
  }
  get maxWorkspaces(): number {
    if (store.state.account.user?.type === 0) {
      return 0;
    }
    return store.state.app.features.maxWorkspaces;
  }
  get maxWorkspacesReached() {
    if (
      this.maxWorkspaces > 0 &&
      store.state.tenant.workspaces.length >= this.maxWorkspaces
    ) {
      return true;
    }
    return false;
  }
}
