













































































































import Vue from "vue";
import Component from "vue-class-component";
import Header from "@/components/marketing/Header.vue";

@Component({
  components: {
    Header
  }
})
export default class Hero extends Vue { }
