




















































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import Selector from "@/components/shared/forms/Selector.vue";
import ColorSwitcher from "./selectors/ColorSwitcher.vue";
import DarkModeToggle from "./toggles/DarkModeToggle.vue";
import FlagSelector from "./selectors/FlagSelector.vue";
import store from "@/store";

@Component({
  components: {
    Selector,
    DarkModeToggle,
    ColorSwitcher,
    FlagSelector
  }
})
export default class Header extends Vue {
  droppedDown = false;
  links: any[] = [
    { name: "landing", title: this.$t("marketing.navbar.product") },
    // { name: "providers", title: this.$t("marketing.navbar.providers") },
    { name: "prices", title: this.$t("marketing.navbar.pricing") },
    { name: "contact", title: this.$t("marketing.navbar.contact") }
    // { name: "blog", title: this.$t("marketing.navbar.blog") }
  ];
  isCurrent(pathName: string): boolean {
    return (
      this.$route.name?.includes(pathName) ||
      this.$route.path.includes(pathName)
    );
  }
  get headerColor(): string {
    const color =
      store.state.theme.theme === 1
        ? "bg-blueGray-50 dark:bg-blueGray-800"
        : "bg-blueGray-50 dark:bg-blueGray-800";
    return color;
  }
  get headerColorMobile() {
    const color =
      store.state.theme.theme === 1
        ? "bg-blueGray-50 dark:bg-blueGray-800"
        : "bg-blueGray-100 dark:bg-coolGray-900";
    return color;
  }
}
