




































































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import services from "@/services";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal2.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import store from "@/store";
import WarningBannner from "@/components/shared/banners/WarningBanner.vue";
import { WorkspaceType } from "@/application/enums/master/tenants/WorkspaceType";
import SelectUsers from "../../../views/app/users/SelectUsers.vue";
import { UserDto } from "@/application/dtos/master/users/UserDto";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  components: {
    ErrorModal,
    SuccessModal,
    WarningBannner,
    SelectUsers,
    Loading
  }
})
export default class NewWorkspace extends Vue {
  @Prop({ default: "sm:max-w-lg" }) maxSize!: string;
  $refs!: {
    errorModal: ErrorModal;
    successModal: SuccessModal;
    selectUsers: SelectUsers;
    inputName: HTMLInputElement;
  };
  loading = false;
  name = "";
  taxId = "";
  legalName = "";
  type: WorkspaceType = WorkspaceType.REGULAR;
  businessMainActivity = "";
  registrationNumber = "";
  registrationDate: Date | null = null;
  users: UserDto[] = [];
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.inputName) {
        this.$refs.inputName.focus();
        this.$refs.inputName.select();
      }
    });
  }
  keyup(event: KeyboardEvent) {
    console.log(event.keyCode);
    if (event.keyCode === 27) {
      this.close();
    }
  }
  close() {
    this.$router.push({ name: "settings.tenant.workspaces" });
  }
  save() {
    if (this.users.length === 0) {
      this.$refs.errorModal.show(
        "Error",
        this.$t("account.tenant.workspaces.errors.atLeastOneUser")
      );
      return;
    }
    this.loading = true;
    services.workspaces
      .create({
        name: this.name,
        taxId: this.taxId,
        legalName: this.legalName,
        type: this.type,
        businessMainActivity: this.businessMainActivity,
        registrationNumber: this.registrationNumber,
        registrationDate: this.registrationDate,
        users: this.users
      })
      .then(response => {
        tinyEventBus().emitter.emit("workspace-added", response);
        this.$refs.successModal.show(
          "Empresa agregada",
          "Se ha creado correctamente."
        );
      })
      .catch(error => {
        this.$refs.errorModal.show("Error", this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  selectUsers() {
    this.$refs.selectUsers.show([]);
  }
  selectedUsers(items: UserDto[]) {
    this.users = items;
  }
  changedType(e) {
    const type: WorkspaceType = Number(e.target.value);
    this.type = type;
  }
  get currentUsersDescription() {
    if (this.users.length === 0) {
      return "No has seleccionado empresas del usuario";
    }
    return this.users.map(f => `${f.firstName} (${f.email})`).join(", ");
  }
  get maxWorkspaces(): number {
    if (store.state.account.user?.type === 0) {
      return 0;
    }
    return store.state.app.features.maxWorkspaces;
  }
  get maxWorkspacesReached() {
    if (
      this.maxWorkspaces > 0 &&
      store.state.tenant.workspaces.length >= this.maxWorkspaces
    ) {
      return true;
    }
    return false;
  }
}
