


































































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import TenantNew from "@/components/app/settings/tenant/TenantNew.vue";
import TenantSubscriptionStatus from "@/components/app/settings/tenant/subscription/TenantSubscriptionStatus.vue";
import Breadcrumb from "../Breadcrumb.vue";
import { SignalService } from "@/plugins/SignalR";
import { Prop, Watch } from "vue-property-decorator";
import { RouteConfig } from "vue-router";
import { SideBarItem } from "@/application/sidebar/SidebarItem";
import { SidebarGroup } from "@/application/sidebar/SidebarGroup";
import { AdminSidebar } from "@/application/sidebar/AdminSidebar";
import { AppSidebar } from "@/application/sidebar/AppSidebar";
import services from "../../../services";
import { TenantDto } from "@/application/dtos/master/tenants/TenantDto";
import { SubscriptionProductDto } from "@/application/dtos/master/subscriptions/SubscriptionProductDto";
import { UserType } from "@/application/enums/master/users/UserType";
import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";
import WorkspaceSelector from "@/components/app/workspaces/WorkspaceSelector.vue";
import TenantSelector from "@/components/app/selectors/TenantSelector.vue";
import store from "@/store";
import LinksInformationModal from "../../app/v3/contacts/LinksInformationModal.vue";
import PendingInvitationsSideModal from "../../app/v3/contacts/PendingInvitationsSideModal.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import { ContractDto } from "@/application/dtos/app/v3/contracts/ContractDto";
import LinksInformationButton from "../shared/LinksInformationButton.vue";
import PendingInvitationsButton from "../shared/PendingInvitationsButton.vue";
import QuickActionsButton from "../shared/QuickActionsButton.vue";
import ProfileButton from "../shared/ProfileButton.vue";
import ChatSupportButton from "../shared/ChatSupportButton.vue";
import LinkForm from "@/components/app/v3/contacts/LinkForm.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";

@Component({
  metaInfo: {
    title: "App",
    titleTemplate: "%s | Délega"
  },
  components: {
    TenantNew,
    TenantSubscriptionStatus,
    Breadcrumb,
    WorkspaceSelector,
    TenantSelector,
    LinksInformationModal,
    PendingInvitationsSideModal,
    LinksInformationButton,
    PendingInvitationsButton,
    QuickActionsButton,
    ProfileButton,
    ChatSupportButton,
    LinkForm,
    ErrorModal
  }
})
export default class SidebarLayout extends Vue {
  $refs!: {
    mainElement: HTMLElement;
    sideModalPendingInvitations: PendingInvitationsSideModal;
    modalLinksInformation: LinksInformationModal;
    newProviderLinkForm: LinkForm;
    newClientLinkForm: LinkForm;
    newLinkForm: LinkForm;
    errorModal: ErrorModal;
  };
  @Prop()
  layout!: string;
  sidebarOpen = false;
  dropDownTenantMobile = false;
  dropDownTenantDesktop = false;
  menu: SideBarItem[] = [];
  expanded: number[] = [];
  addingTenant = false;
  whatsAppLink = "";
  phone = "";
  token = "";
  isSandbox = false;
  activeContracts: ContractDto[] = [];
  mounted() {
    // @ts-ignore
    $crisp.push(["do", "chat:hide"]);
    this.isSandbox = process.env.VUE_APP_SERVICE === "sandbox" ?? false;
    // store.commit("theme/theme", Theme.Light);
    if (this.layout === "admin") {
      this.menu = AdminSidebar;
    } else {
      this.menu = AppSidebar;
      this.whatsAppLink = "";
      this.phone = process.env.VUE_APP_PHONE?.toString() ?? "";
      if (this.phone) {
        this.whatsAppLink += `https://wa.me/${this.phone}`;
      }
      if (this.token) {
        this.whatsAppLink += `?text=${this.token}`;
      }
    }
    this.menu.forEach(group => {
      group.items?.forEach((element, index) => {
        if (element.open) {
          this.expanded.push(index);
        } else {
          this.expanded = this.expanded.filter(f => f !== index);
        }
      });
    });
    this.showConversations();

    services.tenants.getAll();
    services.workspaces.getAllWorkspaces(true).then(response => {
      if (response.length === 0) {
        this.$refs.errorModal.show(
          this.$t("app.workspaces.errors.noWorkspaces"),
          this.$t("app.workspaces.errors.addAtLeastOneWorkspace")
        );
        this.$router.push({ name: "settings.tenant.workspaces" });
      }
    });

    SignalService.on("UserConnected", message => {
      console.log("[Message received] " + message);
    });
    tinyEventBus().emitter.on("view-pending-invitations", () => {
      this.$router.push({ name: "app.links.pending" });
    });
    tinyEventBus().emitter.on("view-links-information", () => {
      this.$refs.modalLinksInformation.show();
    });
    tinyEventBus().emitter.on("new-provider", () => {
      this.$refs.newProviderLinkForm.show();
    });
    tinyEventBus().emitter.on("new-client", () => {
      this.$refs.newClientLinkForm.show();
    });
    tinyEventBus().emitter.on("new-link", () => {
      this.$refs.newLinkForm.show();
    });
  }
  beforeDestroy() {
    tinyEventBus().emitter.off("view-pending-invitations");
    tinyEventBus().emitter.off("view-pending-links");
    tinyEventBus().emitter.off("view-links-information");
    tinyEventBus().emitter.off("new-provider");
    tinyEventBus().emitter.off("new-client");
    tinyEventBus().emitter.off("new-link");
  }
  showConversations() {
    // @ts-ignore
    if (this.$intercom) {
      // @ts-ignore
      // this.$intercom.boot({
      //   user_id: store.state.account.user.id,
      //   name: store.state.account.user.firstName,
      //   email: store.state.account.user.email,
      // });
      // this.$intercom.show();
    }
    // @ts-ignore
    if (this.$drift) {
      // @ts-ignore
      // this.$drift.identify(store.state.account.user.id, {
      //   name: store.state.account.user.firstName,
      //   email: store.state.account.user.email,
      // });
      // this.$drift.show();
    }
  }
  changeTenant(tenant: TenantDto) {
    this.closeDropdownTenant();
    services.users.updateDefaultTenant(tenant.id).then(() => {
      this.$router.go(0);
    });
  }
  closeSidebar() {
    if (this.sidebarOpen) {
      this.sidebarOpen = false;
    }
  }
  closeDropdownTenant() {
    this.dropDownTenantMobile = false;
  }
  closeDropdownTenantDesktop() {
    this.dropDownTenantDesktop = false;
  }
  toggleMenuItem(index) {
    if (this.expanded.includes(index)) {
      this.expanded = this.expanded.filter(item => item !== index);
    } else {
      this.expanded.push(index);
    }
  }
  menuItemIsExpanded(index: number) {
    return this.expanded.includes(index);
  }
  translate(title) {
    return this.$t(title.trim());
  }
  addTenant() {
    this.sidebarOpen = false;
    this.closeDropdownTenantDesktop();
    this.addingTenant = true;
  }
  createdTenant() {
    this.addingTenant = false;
  }
  // addPricesToAdminSidebar(prices: SideBarItem) {
  //   prices.items = [];
  //   if (prices && this.products) {
  //     this.products.forEach((element: SubscriptionProductDto) => {
  //       const price: SideBarItem = {
  //         title: element.title,
  //         pathName: "/admin/products/" + element.id,
  //         items: []
  //       };
  //       if (element.id && price) {
  //         prices.items?.push(price);
  //       }
  //     });
  //     if (prices.items.length > 0) {
  //       prices.items.unshift({
  //         title: this.$t("shared.all"),
  //         name: "admin.products",
  //         items: []
  //       });
  //       // prices.open = true;
  //     }
  //   }
  // }
  addToBreadcrumb(items: any, route: RouteConfig) {
    if (route.path !== this.$route.path) {
      if (!items.some(f => f.link === route.path)) {
        items.push({
          name: route.meta?.title ?? "Untitled",
          link: route.path
        });
      }
    }
  }
  getPlanFromTenant(tenant: TenantDto): string {
    if (
      tenant.products &&
      tenant.products.length > 0 &&
      tenant.products[0].subscriptionProduct
    ) {
      return tenant.products[0].subscriptionProduct.title;
    } else {
      if (tenant.subdomain === "admin") {
        return "Admin";
      } else {
        return this.$t("settings.tenant.subscription.notSubscribed").toString();
      }
    }
  }
  get products(): SubscriptionProductDto[] {
    return store.state.pricing.products as SubscriptionProductDto[];
  }
  get myTenants(): TenantDto[] {
    return store.state.tenant.tenants;
  }
  get currentTenant(): TenantDto {
    return store.state.tenant.current ?? ({ name: "Undefinded" } as TenantDto);
  }
  get currentUserType(): UserType {
    return store.state.account.user?.type ?? UserType.Tenant;
  }
  get getMenu(): SidebarGroup[] {
    // if (this.layout === "admin" && this.menu.length > 0 && this.menu[0].items) {
    //   const prices: SideBarItem | undefined = this.menu[0].items.find(
    //     f => f.pathName === "admin.pricing"
    //   );
    //   if (prices) {
    //     prices.items = [];
    //     this.addPricesToAdminSidebar(prices);
    //   }
    // }
    const menu: SidebarGroup[] = [];
    this.menu.forEach(({ title, items, userTypes, userRoles }) => {
      if (
        (!userTypes || userTypes.includes(this.currentUserType)) &&
        (!userRoles || userRoles.includes(this.currentRole))
      ) {
        const validItems: SideBarItem[] = [];
        items.forEach(f => {
          if (
            (!f.userTypes || f.userTypes.includes(this.currentUserType)) &&
            (!f.userRoles || f.userRoles.includes(this.currentRole))
          ) {
            validItems.push(f);
          }
        });
        const addGroup: SidebarGroup = {
          title: title.toString(),
          items: validItems
        };
        if (addGroup.items.length > 0) {
          menu.push(addGroup);
        }
      }
    });
    return menu;
  }
  get currentRole() {
    return (
      store.state.tenant?.current?.currentUser?.role ?? TenantUserRole.GUEST
    );
  }
  get currentWorkspaceId(): string {
    return store.state.tenant.currentWorkspace?.id ?? "";
  }
  @Watch("$route.path")
  scroll() {
    this.$refs.mainElement.scrollTo(0, 0);
  }
}
