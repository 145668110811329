







































































import Vue from "vue";
import Component from "vue-class-component";
import services from "@/services";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import NewModal from "@/components/shared/modals/NewModal.vue";
import ProvidersListAndTable from "./ProvidersListAndTable.vue";
import { LinkDto } from "@/application/dtos/master/v3/links/LinkDto";
import tinyEventBus from "@/plugins/tiny-eventbus";
import { Prop } from "vue-property-decorator";
import { DocumentationStatusFilter } from "@/application/contracts/app/v3/documentation/DocumentationStatusFilter";
import Loading from "@/components/shared/loaders/Loading.vue";
import { DocumentationDto } from "@/application/dtos/app/v3/documentation/DocumentationDto";

@Component({
  components: {
    TableRowSkeleton,
    NewModal,
    ProvidersListAndTable,
    Loading
  }
})
export default class ProvidersList extends Vue {
  @Prop({ default: DocumentationStatusFilter.NonCompliant })
  filter!: DocumentationStatusFilter;

  loading = false;
  items: LinkDto[] = [];

  searchInput = "";
  year = 0;
  //   currentStatus: ContractStatus | null = null;
  created() {
    this.year = new Date().getFullYear();
  }
  mounted() {
    this.reload();
  }
  reload() {
    this.items = [];
    this.loading = true;
    services.links
      .getAllProviders(this.filter)
      .then(response => {
        response.forEach(item => {
          item.lastDocument = this.getLastDocument(item);
        });
        this.items = response;
      })
      .finally(() => {
        this.loading = false;
      });
    // services.providers.getAll().then(response => {
    //   this.pending = response;
    // });
  }
  viewInvitations() {
    tinyEventBus().emitter.emit("view-pending-links");
    // this.$refs.invitations.show();
  }
  getLastDocument(provider: LinkDto): DocumentationDto | undefined {
    if (provider.documents) {
      const sorted = provider.documents.filter(f => f).slice().sort((x, y) => {
        if (x.createdAt && y.createdAt) {
          return x.createdAt > y.createdAt ? 1 : -1;
        }
        return 1
      })
      if (sorted.length > 0) {
        return sorted[0];
      }
    }
  }
  get filteredItems(): LinkDto[] {
    if (!this.items) {
      return [];
    }
    const items = this.items.filter(
      f =>
        f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) ||
        f.providerWorkspace.taxId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.providerWorkspace.legalName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.createdByUser.firstName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.createdByUser.lastName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        (f.createdByUser.firstName + " " + f.createdByUser.lastName)
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase())
    );

    return items.sort((a, b) => {
      if (a.createdAt && b.createdAt) {
        return a.createdAt > b.createdAt ? 1 : -1
      }
      return 1;
    });
  }
  get sortedItems(): LinkDto[] {
    this.$forceUpdate();
    return this.filteredItems;
  }
}
