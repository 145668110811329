
































import Component from "vue-class-component";
import { SubscriptionPriceDto } from "../../../application/dtos/master/subscriptions/SubscriptionPriceDto";
import { SubscriptionProductDto } from "../../../application/dtos/master/subscriptions/SubscriptionProductDto";
import { SubscriptionBillingPeriod } from "../../../application/enums/master/subscriptions/SubscriptionBillingPeriod";
import Vue from "vue";
import store from "@/store";

@Component
export default class BillingPeriodToggle extends Vue {
  changeInterval(billingPeriod: SubscriptionBillingPeriod) {
    store.commit("pricing/billingPeriod", billingPeriod);
    this.$emit("selected");
  }
  changeToMonthly() {
    this.changeInterval(SubscriptionBillingPeriod.MONTHLY);
  }
  changeToYearly() {
    this.changeInterval(SubscriptionBillingPeriod.YEARLY);
  }
  billingPeriodName(billingPeriod: SubscriptionBillingPeriod): string {
    return this.$t(
      "marketing.pricing." + SubscriptionBillingPeriod[billingPeriod].toString()
    ).toString();
  }
  isYearly(billingPeriod: SubscriptionBillingPeriod): boolean {
    return billingPeriod === SubscriptionBillingPeriod.YEARLY;
  }
  yearlyDiscount(): string | undefined {
    const priceYearly = this.getPriceWithInterval(
      SubscriptionBillingPeriod.YEARLY
    );
    const priceMonthly = this.getPriceWithInterval(
      SubscriptionBillingPeriod.MONTHLY
    );
    if (priceYearly && priceMonthly) {
      const discount =
        100 - (priceYearly.price * 100) / (priceMonthly.price * 12);
      if (discount !== 0) {
        return "-" + discount.toFixed(0) + "%";
      }
    }

    return undefined;
  }
  getPriceWithInterval(
    billingPeriod: SubscriptionBillingPeriod
  ): SubscriptionPriceDto | undefined {
    let price: SubscriptionPriceDto | undefined;
    if (this.products && this.products.length > 0) {
      this.products.forEach(product => {
        const prices = product.prices.find(
          f =>
            f.billingPeriod === billingPeriod &&
            f.currency === store.state.pricing.currency &&
            f.price > 0
        );
        if (prices) {
          price = prices;
        }
      });
    }
    return price;
  }
  get products(): SubscriptionProductDto[] {
    return store.state.pricing.products as SubscriptionProductDto[];
  }
  get billingPeriods() {
    const allBillingPeriods: SubscriptionBillingPeriod[] = [];
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    this.products.forEach(element => {
      element.prices.forEach(price => {
        if (
          store.state.pricing.currency === price.currency &&
          price.billingPeriod !== SubscriptionBillingPeriod.ONCE
        ) {
          allBillingPeriods.push(price.billingPeriod);
        }
      });
    });
    return allBillingPeriods.filter(onlyUnique);
  }
  get billingPeriod() {
    return store.state.pricing.billingPeriod as SubscriptionBillingPeriod;
  }
  get isMonthly() {
    return this.billingPeriod !== SubscriptionBillingPeriod.MONTHLY;
  }
}
