const supportedLocales = [
  {
    lang: "en-US",
    flag: "us",
    lang_short: "en",
  },
  {
    lang: "es-MX",
    flag: "mx",
    lang_short: "es",
  },
];
export default supportedLocales;
