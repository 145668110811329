

































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import store from "@/store";
import services from "@/services";
import { TenantProductDto } from "@/application/dtos/master/tenants/TenantProductDto";
import { SubscriptionGetCurrentResponse } from "@/application/contracts/master/subscriptions/SubscriptionGetCurrentResponse";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import { TenantUserDto } from "@/application/dtos/master/tenants/TenantUserDto";
import tinyEventBus from "@/plugins/tiny-eventbus";
import { Prop } from "vue-property-decorator";
import { AppUsageSummaryDto } from "@/application/contracts/app/tenants/AppUsageSummaryDto";
import DateUtils from "@/utils/shared/DateUtils";

@Component({
  components: {}
})
export default class TenantSubscriptionProducts extends Vue {
  @Prop({ default: false }) withCurrentPlan!: boolean;
  @Prop({ default: "grid-cols-2 sm:grid-cols-2 xl:grid-cols-4" })
  cols!: boolean;
  loading = false;
  subscription: SubscriptionGetCurrentResponse | null = null;
  workspaces: WorkspaceDto[] = [];
  users: TenantUserDto[] = [];
  mounted() {
    tinyEventBus().emitter.on("updated-plan", () => {
      this.reload();
    });
    this.reload();
  }
  beforeDestroy() {
    tinyEventBus().emitter.off("updated-plan");
  }
  reload() {
    this.loading = true;

    const promises: any[] = [];

    const loadDashboard = services.subscriptionManager
      .getCurrentSubscription()
      .then(response => {
        this.subscription = response;
      });
    // const loadFeatures = services.tenants.getCurrentUsage();
    const loadWorkspaces = services.workspaces
      .getAllWorkspaces(false)
      .then(response => {
        this.workspaces = response;
      });
    const loadUsers = services.tenantUsers.getAll("").then(response => {
      this.users = response;
    });
    promises.push(loadDashboard);
    promises.push(loadWorkspaces);
    // promises.push(loadFeatures);
    promises.push(loadUsers);

    this.loading = true;
    Promise.all(promises).finally(() => {
      this.loading = false;
    });
  }
  billableStatus(max: number): number {
    if (this.loading) {
      return 2;
    }
    if (!this.currentProduct) {
      return 0;
    }
    if (max === 0) {
      return 1;
    }
    if (max > 0) {
      return 2;
    }
    return 0;
  }
  dateAgo(value: Date) {
    return DateUtils.dateAgo(value);
  }
  get links(): number {
    return store.state.app.usage.providers + store.state.app.usage.clients;
  }
  get getLinksRoutName() {
    return "app.providers";
  }
  get currentProduct(): TenantProductDto | undefined {
    if (
      this.subscription?.myProducts &&
      this.subscription?.myProducts.length > 0
    ) {
      return this.subscription?.myProducts[0];
    }
    return undefined;
  }
  get maxLinksRemaining() {
    if (!this.currentProduct || !this.usage) {
      return 1;
    }
    const links = this.usage.providers + this.usage.clients;
    const remaining = this.currentProduct.maxLinks - links;
    return remaining;
  }
  get maxDocumentsRemaining() {
    if (!this.currentProduct || !this.usage) {
      return 1;
    }
    return this.currentProduct.monthlyContracts - this.usage.contracts;
  }
  get maxWorkspacesRemaining() {
    if (!this.currentProduct || !this.usage) {
      return 1;
    }
    return this.currentProduct.maxWorkspaces - this.workspaces.length;
  }
  get maxUsersRemaining() {
    if (!this.currentProduct || !this.usage) {
      return 1;
    }
    return this.currentProduct.maxUsers - this.users.length;
  }
  get usage(): AppUsageSummaryDto {
    return store.state.app.usage;
  }
  // get myProducts(): SubscriptionPriceDto[] {
  //   const products = store.state.tenant?.subscription?.myProducts.map(
  //     f => f.subscriptionPrice
  //   ) as SubscriptionPriceDto[];
  //   if (products && products.length > 0) {
  //     return products.filter(
  //       f => f.billingPeriod === SubscriptionBillingPeriod.ONCE
  //     );
  //   }
  //   return [];
  // }
}
