







































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import services from "@/services";
import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal2.vue";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import SelectWorkspaces from "@/components/app/workspaces/SelectWorkspaces.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import store from "@/store";
import WarningBanner from "@/components/shared/banners/WarningBanner.vue";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  components: {
    ErrorModal,
    SuccessModal,
    SelectWorkspaces,
    WarningBanner,
    Loading
  }
})
export default class NewMember extends Vue {
  @Prop({ default: "sm:max-w-lg" }) maxSize!: string;
  $refs!: {
    errorModal: ErrorModal;
    successModal: SuccessModal;
    selectWorkspaces: SelectWorkspaces;
    inputEmail: HTMLInputElement;
  };
  loading = false;
  email = "";
  firstName = "";
  lastName = "";
  phone = "";
  companyRole = "";
  role: TenantUserRole = TenantUserRole.MEMBER;
  workspaces: WorkspaceDto[] = [];
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.inputEmail) {
        this.$refs.inputEmail.focus();
        this.$refs.inputEmail.select();
      }
    });
  }
  keyup(event: KeyboardEvent) {
    console.log(event.keyCode);
    if (event.keyCode === 27) {
      this.close();
    }
  }
  close() {
    this.$router.push({ name: "settings.tenant.members" });
  }
  save() {
    if (this.workspaces.length === 0) {
      this.$refs.errorModal.show(
        "Error",
        this.$t("account.tenant.members.errors.atLeastOneWorkspace")
      );
      return;
    }
    this.loading = true;
    services.tenantUserInvitations
      .inviteUser({
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phone,
        role: this.role,
        workspaces: this.workspaces,
        companyRole: this.companyRole,
        sendEmail: process.env.NODE_ENV === "production"
      })
      .then(response => {
        tinyEventBus().emitter.emit("user-added", response);
        this.$refs.successModal.show(
          "Invitación enviada",
          "Se ha enviado un correo de invitación al usuario."
        );
      })
      .catch(error => {
        this.$refs.errorModal.show("Error", this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  selectWorkspaces() {
    this.$refs.selectWorkspaces.show([]);
  }
  selectedWorkspaces(items: WorkspaceDto[]) {
    this.workspaces = items;
  }
  changedRole(e) {
    const role: TenantUserRole = Number(e.target.value);
    this.role = role;
  }
  get currentWorkspacesDescription() {
    if (this.workspaces.length === 0) {
      return "No has seleccionado empresas del usuario";
    }
    return this.workspaces.map(f => f.taxId).join(", ");
  }
  get maxUsers(): number {
    if (store.state.account.user?.type === 0) {
      return 0;
    }
    return store.state.app.features.maxUsers;
  }
  get maxUsersReached() {
    if (
      this.maxUsers > 0 &&
      store.state.tenant.members.length >= this.maxUsers
    ) {
      return true;
    }
    return false;
  }
}
