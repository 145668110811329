







































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";
import { ContractDto } from "@/application/dtos/app/v3/contracts/ContractDto";
import services from "@/services";
import pdf from "vue-pdf";
import { ContractMemberDto } from "@/application/dtos/app/v3/contracts/ContractMemberDto";
import Sign from "@/assets/contracts/Sign.vue";
import store from "@/store";
import SideModal from "@/components/shared/modals/SideModal.vue";
import DropDocument from "@/components/app/v3/contracts/uploads/DropDocument.vue";
import Tabs, { TabItem } from "@/components/shared/tabs/Tabs.vue";
import { FileBase64 } from "@/application/dtos/shared/FileBase64";
import { ContractEmailFormat } from "@/application/enums/app/v3/contracts/ContractEmailFormat";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import { ContractActivityType } from "@/application/enums/app/v3/contracts/ContractActivityType";
import ContractMembers from "./ContractMembers.vue";
import { ContractActivityDto } from "@/application/dtos/app/v3/contracts/ContractActivityDto";
import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";
import { ContractFileType } from "@/application/enums/app/v3/contracts/ContractFileType";
import { ContractMemberRole } from "@/application/enums/app/v3/contracts/ContractMemberRole";
import DateUtils from "@/utils/shared/DateUtils";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  metaInfo: {
    title: "Detalles de contrato",
    titleTemplate: "%s | Délega"
  },
  components: {
    Breadcrumb,
    ErrorModal,
    SuccessModal,
    ConfirmModal,
    pdf,
    Sign,
    SideModal,
    DropDocument,
    Tabs,
    ContractMembers,
    Loading
  }
})
export default class ContractDetails extends Vue {
  $refs!: {
    modalSign: SideModal;
    buttonSave: HTMLButtonElement;
    confirmSendReminder: ConfirmModal;
    confirmSendContract: ConfirmModal;
    confirmDelete: ConfirmModal;
    errorModal: ErrorModal;
    successModal: SuccessModal;
    inputName: HTMLInputElement;
  };
  id = "";
  name = "";
  description = "";
  contractPdf = "";

  tabs: TabItem[] = [];

  editing = false;
  signing = false;
  openOptions = false;

  item?: ContractDto | null = null;
  error = "";
  loading = false;
  loadingPdf = false;
  currentPage = 1;
  pageCount = 0;
  created() {
    this.id = this.$route.params.id;
  }
  mounted() {
    this.tabs = [
      {
        name: this.$t("app.contracts.details.title"),
        routeName: "app.contracts.view"
        // current: false
      },
      {
        name: this.$t("app.contracts.employees.title"),
        routeName: "app.contracts.employees"
        // current: false
      },
      {
        name: this.$t("app.contracts.visits.title"),
        routeName: "app.contracts.visits"
        // current: false
      }
    ];
    // this.tabs.forEach(tab => {
    //   tab.current = tab.routeName === this.$route.name;
    // });
    tinyEventBus().emitter.on("contract-reload", () => {
      this.reload();
    });
    this.reload();
  }
  beforeDestroy() {
    tinyEventBus().emitter.off("contract-reload");
  }
  reload() {
    this.closeOptions();
    this.loading = true;
    this.item = undefined;
    services.contracts
      .getContract(this.id, false)
      .then(response => {
        this.item = response;
        this.name = response.name;
        this.description = response.description;
        this.loadPdf();
      })
      .catch(error => {
        this.error = error;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  nextPage(pages) {
    this.currentPage += pages;
  }
  removePdf() {
    if (this.item) {
      this.contractPdf = "";
    }
  }
  loadPdf() {
    let format = ContractFileType.PDF;
    if (this.item?.status === 1) {
      format = ContractFileType.SIGNED_PDF;
    }
    this.downloadFile(format, false);
  }
  downloadFile(format: ContractFileType, open: boolean) {
    this.closeOptions();
    this.loadingPdf = true;
    services.contracts
      .downloadFile(this.id, format)
      .then(response => {
        if (open) {
          const downloadLink = document.createElement("a");
          const fileName = this.item?.name + (format === 2 ? ".xml" : ".pdf");
          downloadLink.href = response;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          this.contractPdf = response;
        }
      })
      .catch(error => {
        this.$refs.errorModal.show("Error descargando PDF", this.$t(error));
      })
      .finally(() => {
        this.loadingPdf = false;
      });
  }
  downloadPdf() {
    this.closeOptions();
    if (this.loadingPdf) {
      this.$refs.errorModal.show(
        "Cargando...",
        "El PDF todavía se está descargando, espera un momento y vuelve a intentar."
      );
    } else if (this.item) {
      const downloadLink = document.createElement("a");
      const fileName = this.item?.name + ".pdf";
      downloadLink.href = this.contractPdf;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }
  toggleEdit() {
    this.closeOptions();
    this.editing = !this.editing;
    if (this.editing) {
      this.$nextTick(() => {
        this.$refs.inputName.focus();
        this.$refs.inputName.select();
      });
    }
  }
  deleteContract() {
    this.$refs.confirmDelete.show(
      `Eliminar contrato '${this.item?.name}'`,
      "Eliminar",
      "Cancelar",
      `Se eliminarán todas las firmas, listas de empleados y visitas registradas.`
    );
  }
  yesDelete() {
    this.loading = true;
    services.contracts
      .delete(this.item?.id)
      .then(() => {
        this.$router.push({ name: "app.contracts" });
      })
      .catch(error => {
        this.$refs.errorModal.show("Error eliminando", this.$t(error));
        this.loading = false;
      });
  }
  droppedContractFile(files: FileBase64[]) {
    if (this.item) {
      if (files.length > 0) {
        this.contractPdf = files[0].base64;
      }
    }
  }
  sign() {
    alert("sign");
  }
  sendReminder(member: ContractMemberDto) {
    this.$refs.confirmSendReminder.show(
      `Recordatorio`,
      "Enviar",
      "Cancelar",
      `Se enviará recordatorio para firmar contrato ${this.item?.name}.`,
      member.user.email
    );
  }
  yesSend(input: string) {
    if (!this.item) {
      this.$refs.errorModal.show("Error", "Contrato inválido");
      return;
    }
    const emails = input.split(",");
    // const email = prompt("Correo electrónico", store.state.account.user?.email);
    if (!emails || emails.length === 0) {
      return;
    }
    services.contracts
      .send(this.item.id, {
        format: ContractEmailFormat.REMINDER,
        emails
      })
      .then(() => {
        this.$refs.successModal.show("Recordatorio enviado");
      })
      .catch(error => {
        this.$refs.errorModal.show("Error enviando", this.$t(error));
      });
  }
  send() {
    this.closeOptions();
    this.$refs.confirmSendContract.show(
      `Enviar contrato`,
      "Enviar",
      "Cancelar",
      `Enviarás el contrato a los participantes: ${this.item?.members
        .map(
          f =>
            (f.user.firstName + " " + f.user.lastName).trim() +
            " (" +
            f.user.email +
            ")"
        )
        .join(", ")}.`
      // store.state.account.user?.email
    );
  }
  yesSendContract() {
    if (!this.item) {
      this.$refs.errorModal.show("Error enviando", "Contrato inválido");
      return;
    }
    let format = ContractEmailFormat.NEW;
    if (this.item?.signedDate) {
      format = ContractEmailFormat.SIGNED;
    }
    services.contracts
      .send(this.item.id, {
        format,
        emails: []
      })
      .then(() => {
        this.$refs.successModal.show("Contrato enviado");
      })
      .catch(error => {
        this.$refs.errorModal.show("Error enviando", this.$t(error));
      });
  }
  getActivityTitle(activity: ContractActivityDto) {
    return this.$t(
      "app.contracts.activity.types." + ContractActivityType[activity.type]
    );
  }
  save() {
    if (!this.name) {
      this.$refs.errorModal.show("Error", "El contrato debe de tener nombre.");
      return;
    } else if (!this.description) {
      this.$refs.errorModal.show(
        "Error",
        "El contrato debe de tener una descripción."
      );
      return;
    } else if (!this.contractPdf) {
      this.$refs.errorModal.show("Error", "Falta el archivo PDF.");
      return;
    } else {
      this.loading = true;
      services.contracts
        .update(this.id, {
          name: this.name,
          description: this.description,
          file: this.contractPdf
        })
        .then(() => {
          this.editing = false;
          this.$refs.successModal.show("Cambios guardados");
        })
        .catch(error => {
          this.$refs.errorModal.show("Error", this.$t(error));
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  closeOptions() {
    this.openOptions = false;
  }
  toggleOptions() {
    this.openOptions = !this.openOptions;
  }
  dateDM(value: Date | undefined) {
    return DateUtils.dateDM(value);
  }
  get clientFullName() {
    if (this.item && this.item.link?.providerWorkspace) {
      return `${this.item.link.clientWorkspace.taxId} - ${this.item.link.clientWorkspace.legalName}`;
    }
    return "";
  }
  get providerFullName() {
    if (this.item && this.item.link?.providerWorkspace) {
      return `${this.item.link.providerWorkspace.taxId} - ${this.item.link.providerWorkspace.legalName}`;
    }
    return "";
  }
  get canBeEdited() {
    return (
      this.item?.members.filter(f => f.role === 0 && f.signDate).length === 0
    );
  }
  get alreadySigned() {
    return (
      this.currentMember?.signDate !== null &&
      this.currentMember?.signDate !== undefined
    );
  }
  get currentMember() {
    return this.item?.members.find(
      f => f.user.email === store.state.account.user?.email
    );
  }
  get sortedActivity() {
    if (!this.item?.activity) {
      return [];
    }
    return this.item.activity.sort((x, y) => {
      if (x.createdAt && y.createdAt) {
        return x.createdAt > y.createdAt ? 1 : -1;
      }
      return 1;
    });
  }
  get canSign() {
    return this.currentMember?.role === ContractMemberRole.SIGNATORY;
    // return this.item?.members.find(
    //   f => f.role === 0 && f.email === store.state.account.user?.email
    // );
  }
  get isOwnerOrAdmin(): boolean {
    return (
      this.currentRole === TenantUserRole.OWNER ||
      this.currentRole === TenantUserRole.ADMIN
    );
  }
  get currentRole(): TenantUserRole {
    return store.state.tenant.current?.currentUser.role ?? TenantUserRole.GUEST;
  }
  get canEdit() {
    if (this.isOwnerOrAdmin) {
      return true;
    }
    return this.item?.createdByUserId === store.state.account.user?.id || true;
  }
}
