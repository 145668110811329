import { ApiService } from "../../ApiService";
import store from "@/store";
import { WorkspaceUserDto } from "@/application/dtos/app/workspaces/WorkspaceUserDto";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import { IWorkspaceService } from "./IWorkspaceService";
import { WorkspaceType } from "@/application/enums/master/tenants/WorkspaceType";
import { CreateWorkspaceRequest } from "@/application/contracts/master/workspaces/CreateWorkspaceRequest";
import { UpdateWorkspaceRequest } from "@/application/contracts/master/workspaces/UpdateWorkspaceRequest";
import { CreateCredentialCiecRequest } from "@/application/contracts/master/workspaces/CreateCredentialCiecRequest";
import { CreateCredentialEfirmaRequest } from "@/application/contracts/master/workspaces/CreateCredentialEfirmaRequest";

export class WorkspaceService extends ApiService implements IWorkspaceService {
  constructor() {
    super("Workspace");
  }
  adminGetAll(): Promise<WorkspaceDto[]> {
    return super.getAll(`Admin/GetAll`);
  }
  getAllPublicProviders(): Promise<WorkspaceDto[]> {
    throw new Error("Method not implemented.");
  }
  getAllWorkspaces(saveInStore = false): Promise<WorkspaceDto[]> {
    return new Promise((resolve, reject) => {
      super
        .getAll("GetAll")
        .then((response: WorkspaceDto[]) => {
          resolve(JSON.parse(JSON.stringify(response)));
          if (saveInStore) {
            store.commit("tenant/workspaces", response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  search(taxId: string, email: string): Promise<WorkspaceDto> {
    return super.get(`Search/${taxId}/${email}`);
  }
  get(id: string): Promise<WorkspaceDto> {
    return super.get("Get", id);
  }
  create(data: CreateWorkspaceRequest): Promise<WorkspaceDto> {
    return super.post(data);
  }
  createCredentialCiec(data: CreateCredentialCiecRequest): Promise<void> {
    return super.post(data, "CreateCredentialCiec");
  }
  createCredentialEfirma(data: CreateCredentialEfirmaRequest): Promise<void> {
    return super.post(data, "CreateCredentialEfirma");
  }
  addUser(id: string, data: WorkspaceUserDto): Promise<WorkspaceUserDto> {
    return super.post(data, "AddUser/" + id);
  }
  update(id: string, data: UpdateWorkspaceRequest): Promise<WorkspaceDto> {
    return super.put(id, data);
  }
  updateType(id: string, type: WorkspaceType): Promise<void> {
    return new Promise((resolve, reject) => {
      super
        .put("", undefined, `UpdateType/${id}/${type}`)
        .then(() => {
          store.commit("tenant/currentWorkspaceType", type);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  removeUser(id: string): Promise<any> {
    return super.putRaw("RemoveUser/" + id);
  }
  delete(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      super
        .delete(id)
        .then(() => {
          resolve(this.getAllWorkspaces(true));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  deleteCredential(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      super
        .delete(id, "DeleteCredential")
        .then(() => {
          resolve(this.getAllWorkspaces(true));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  adminDelete(id: string): Promise<any> {
    return super.delete(id, "Admin/Delete");
  }
}
