/* eslint-disable */
import { TenantDto } from "@/application/dtos/master/tenants/TenantDto";
import { TenantCreateRequest } from "@/application/contracts/master/tenants/TenantCreateRequest";
import { UserLoggedResponse } from "@/application/contracts/master/users/UserLoggedResponse";
import { TenantUpdateImageRequest } from "@/application/contracts/master/tenants/TenantUpdateImageRequest";
import { TenantFeaturesDto } from "../../../../application/contracts/master/tenants/TenantFeaturesDto";
import { ITenantService } from "./ITenantService";
import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";
import { TenantUserJoined } from "@/application/enums/master/tenants/TenantUserJoined";
import { TenantUserStatus } from "@/application/enums/master/tenants/TenantUserStatus";
import { i18n } from "@/plugins/vue-i18n";
import { TenantUserDto } from "@/application/dtos/master/tenants/TenantUserDto";
import fakeNamesAndEmails from "./FakeNamesAndEmails";
import { UserType } from "@/application/enums/master/users/UserType";
import { UserLoginType } from "@/application/enums/master/users/UserLoginType";
import store from "@/store";
import { FakeSubscriptionManagerService } from "../subscriptions/FakeSubscriptionManagerService";
import { TenantProductDto } from "@/application/dtos/master/tenants/TenantProductDto";
import { AppUsageSummaryDto } from "@/application/contracts/app/tenants/AppUsageSummaryDto";
import { AppUsageType } from "@/application/enums/app/usages/AppUsageType";

const fakeSubscriptionManagerService = new FakeSubscriptionManagerService();

const createTenant = (index: number): TenantDto => {
  const tenant: TenantDto = {
    id: index.toString(),
    uuid: "string",
    name: "Absys",
    domain: "",
    subdomain: "",
    icon: "",
    logo: "",
    logoDarkmode: "",
    subscriptionCustomerId: "",
    subscriptionPlanId: "",
    users: [],
    products: fakeSubscriptionManagerService.currentSubscription.activeProduct,
    currentUser: {} as TenantUserDto,
    workspaces: [],
  };
  return tenant;
};

const tenants: TenantDto[] = [createTenant(1)];

const types = [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER, TenantUserRole.GUEST];
const users: TenantUserDto[] = [];
for (let idxType = 0; idxType < types.length; idxType++) {
  const type = types[idxType];
  users.push({
    id: idxType + 1,
    tenantId: "",
    tenant: {} as TenantDto,
    userId: idxType.toString(),
    user: {
      id: idxType.toString(),
      type: UserType.Tenant,
      username: fakeNamesAndEmails[idxType].email.split("@")[0],
      email: fakeNamesAndEmails[idxType].email,
      firstName: fakeNamesAndEmails[idxType].firstName,
      lastName: fakeNamesAndEmails[idxType].lastName,
      phone: "",
      loginType: UserLoginType.PASSWORD,
      avatar: "",
      token: "",
      defaultTenantId: 1,
      defaultTenant: {} as TenantDto,
      tenants: [],
      currentTenant: {} as TenantDto,
      timezone: "",
      locale: "",
    },
    role: type,
    joined: TenantUserJoined.CREATOR,
    status: TenantUserStatus.ACTIVE,
    chatbotToken: "",
    uuid: "",
    accepted: true,
    email: fakeNamesAndEmails[idxType].email,
    firstName: fakeNamesAndEmails[idxType].firstName,
    lastName: fakeNamesAndEmails[idxType].lastName,
    phone: fakeNamesAndEmails[idxType].phone,
    avatar: fakeNamesAndEmails[idxType].avatar,
    companyRole: "",
  });
}

tenants.forEach((element) => {
  element.currentUser = users.find((f) => f.role === TenantUserRole.OWNER) ?? users[0];
  element.users = users;
});

export class FakeTenantService implements ITenantService {
  tenants: TenantDto[] = tenants;
  adminGetAll(): Promise<TenantDto[]> {
    return Promise.resolve(tenants);
  }
  adminGetProducts(id: string): Promise<TenantProductDto[]> {
    throw new Error("Method not implemented." + id);
  }
  adminGetFeatures(id: string): Promise<TenantFeaturesDto> {
    throw new Error("Method not implemented." + id);
  }
  getAll(): Promise<TenantDto[]> {
    return Promise.resolve(this.tenants);
  }
  get(id: string): Promise<TenantDto> {
    const tenant = this.tenants.find((f) => f.id === id);
    if (tenant) {
      return Promise.resolve(tenant);
    } else {
      return Promise.reject(i18n.t("shared.notFound"));
    }
  }
  getFeatures(): Promise<TenantFeaturesDto> {
    const currentSubcription = store.state.tenant.subscription?.activeProduct[0] ?? this.tenants[0].products[0];
    const features: TenantFeaturesDto = {
      maxWorkspaces: currentSubcription.maxWorkspaces,
      maxUsers: currentSubcription.maxUsers,
      maxLinks: currentSubcription.maxLinks,
      maxStorage: currentSubcription.maxStorage,
      monthlyContracts: currentSubcription.monthlyContracts,
    };
    store.commit("app/features", features);
    return Promise.resolve(features);
  }
  getCurrentUsage(type: AppUsageType): Promise<AppUsageSummaryDto> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const summary: AppUsageSummaryDto = {
          type: 0,
          providers: 10,
          providersInCompliance: 6,
          clients: 4,
          contracts: 3,
          cfdis: 10,
          employees: 20,
          storage: 0.1,
          pendingInvitations: 0, // fakeLinkService.links.filter((f) => f.status === 0).length,
        };
        store.commit("app/usage", summary);
        resolve(summary);
      }, 2000);
    });
  }
  getCurrent(): Promise<TenantDto[]> {
    throw new Error("Method not implemented.");
  }
  create(payload: TenantCreateRequest): Promise<UserLoggedResponse> {
    const newTenant = createTenant(this.tenants.length + 1);
    newTenant.name = payload.name;
    this.tenants.push(newTenant);
    return Promise.resolve({} as UserLoggedResponse);
  }
  update(payload: TenantDto): Promise<TenantDto> {
    return new Promise((resolve, reject) => {
      const tenant = this.tenants.find((f) => f.id === payload.id);
      if (tenant) {
        tenant.name = payload.name;
        store.commit("tenant/settings", payload);
        resolve(tenant);
      } else {
        reject(i18n.t("shared.notFound"));
      }
    });
  }
  updateImage(payload: TenantUpdateImageRequest): Promise<TenantDto> {
    throw new Error("Method not implemented." + payload);
  }
  delete(): Promise<void> {
    throw new Error("Method not implemented.");
  }
  adminDelete(id: string): Promise<void> {
    throw new Error("Method not implemented." + id);
  }
}
