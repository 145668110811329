import { SyncCfdisStatusMessage } from "@/application/contracts/app/cfdis/SyncCfdisStatusMessage";
import { UploadCfdisRequest } from "@/application/contracts/app/cfdis/UploadCfdisRequest";
import { CfdiDto } from "@/application/dtos/app/v3/cfdis/CfdiDto";
import { CfdiXmlPdfDto } from "@/application/dtos/app/v3/cfdis/CfdiXmlPdfDto";
import { InvoiceExtractionDto } from "@/application/dtos/app/v3/cfdis/sat/InvoiceExtractionDto";
import { CfdiType } from "@/application/enums/app/v3/cfdis/CfdiType";
import { ApiService } from "../../../ApiService";
import { ICfdiService } from "./ICfdiService";

export class CfdiService extends ApiService implements ICfdiService {
  constructor() {
    super("Cfdi");
  }
  adminGetAll(): Promise<CfdiDto[]> {
    return super.getAll("Admin/GetAll");
  }
  getAllCfdis(type: CfdiType): Promise<CfdiDto[]> {
    return super.getAll("GetAll?type=" + type);
  }
  get(id: string): Promise<CfdiDto> {
    return super.get("Get", id);
  }
  getAllExtractions(): Promise<InvoiceExtractionDto[]> {
    return super.get("GetAllExtractions");
  }
  create(data: CfdiDto): Promise<CfdiDto> {
    return super.post(data);
  }
  sync(): Promise<SyncCfdisStatusMessage> {
    return super.post(undefined, "Sync");
  }
  readCfdis(data: UploadCfdisRequest): Promise<CfdiXmlPdfDto[]> {
    return super.post(data, "ReadCfdis");
  }
  uploadCfdis(data: UploadCfdisRequest): Promise<any> {
    return super.post(data, "UploadCfdis");
  }
  download(id: string, fileType: string): Promise<CfdiDto> {
    return super.download(undefined, `Download/${id}/${fileType}`);
  }
  update(id: string, data: CfdiDto): Promise<CfdiDto> {
    return super.put(id, data);
  }
  delete(id: string): Promise<any> {
    return super.delete(id);
  }
  adminDelete(id: string): Promise<any> {
    return super.delete(id, "Admin/Delete");
  }
}
