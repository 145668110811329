import moment from "moment";
import store from "@/store";

const dateAgo = (value: Date | string | null | undefined): string => {
  moment.locale(store.state.locale.locale);
  const today = moment(new Date());
  const at = moment(value);
  const days = Math.abs(today.diff(at, "days"));
  // if (unitOfTime) {
  //   return moment(at)
  //     .startOf(unitOfTime)
  //     .fromNow();
  // }
  if (days <= 1) {
    return moment(at)
      .startOf("minute")
      .fromNow();
  } else if (days <= 7) {
    return moment(at)
      .startOf("day")
      .fromNow();
  } else if (days <= 30) {
    return moment(at)
      .startOf("week")
      .fromNow();
  } else if (days <= 30 * 12) {
    return moment(at)
      .startOf("month")
      .fromNow();
  } else if (days <= 30 * 12 * 2) {
    return moment(at)
      .startOf("year")
      .fromNow();
  } else {
    if (at.year() !== 1) {
      return moment(at).format("YYYY-MM-DD");
    }
    return "";
  }
};
const dateCustom = (value: Date | string | null | undefined, format: string): string => {
  moment.locale(store.state.locale.locale);
  return moment(value).format(format);
};
const dateYMD = (value: Date | string | null | undefined): string => {
  moment.locale(store.state.locale.locale);
  return moment(value).format("YYYY-MM-DD");
};
const dateDMY = (value: Date | string | null | undefined): string => {
  moment.locale(store.state.locale.locale);
  return moment(value).format("DD/MM/YYYY");
};
const dateLL = (value: Date | string | null | undefined): string => {
  moment.locale(store.state.locale.locale);
  return moment(value).format("YYYY-MM-DD");
};
const dateYMDHMS = (value: Date | string | null | undefined): string => {
  moment.locale(store.state.locale.locale);
  return moment(value).format("YYYY-MM-DD HH:mm:ss");
};
const dateMonthName = (value: Date | string | null | undefined): string => {
  moment.locale(store.state.locale.locale);
  return moment(value).format("MMMM YYYY");
};
const dateDM = (value: Date | string | null | undefined): string => {
  moment.locale(store.state.locale.locale);
  return moment(value).format("D MMM");
};
const dateMonthDayYear = (value: Date | string | null | undefined): string => {
  moment.locale(store.state.locale.locale);
  return moment(value).format("MMMM D, YYYY");
};
const dateHMS = (value: Date | string | null | undefined): string => {
  moment.locale(store.state.locale.locale);
  return moment(value).format("HH:mm:ss");
};

export default {
  dateAgo,
  dateYMD,
  dateCustom,
  dateDMY,
  dateLL,
  dateYMDHMS,
  dateMonthName,
  dateDM,
  dateHMS,
  dateMonthDayYear,
};
