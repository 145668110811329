




import Component from "vue-class-component";
import Vue from "vue";
import EntityForm from "@/components/shared/entities/EntityForm.vue";
import { ColumnType } from "../../../application/dtos/ColumnType";
import {
  columns,
  SubscriptionPriceDto
} from "../../../application/dtos/master/subscriptions/SubscriptionPriceDto";
import services from "@/services";

@Component({
  components: { EntityForm }
})
export default class Prices extends Vue {
  id!: string;
  columns: ColumnType[] = columns;
  mounted() {
    this.id = this.$route.params.price;
  }
  get() {
    return services.subscriptionProducts.getPrice(this.id);
  }
  post(model: SubscriptionPriceDto) {
    return model;
  }
  put(model: SubscriptionPriceDto) {
    return model;
  }
  delete() {
    return true;
  }
}
