import { i18n } from "../../plugins/vue-i18n";
import { SideBarItem } from "./SidebarItem";
import { TenantUserRole } from "@/application/enums/master/tenants/TenantUserRole";
import { UserType } from "@/application/enums/master/users/UserType";

export const AppSidebar: SideBarItem[] = [
  {
    title: i18n.t("app.sidebar.app"),
    items: [
      {
        title: i18n.t("shared.dashboard"),
        pathName: "app.dashboard",
        icon: "chart-line",
        userRoles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
        items: [],
      },
      {
        title: i18n.t("models.provider.plural"),
        pathName: "app.providers",
        icon: "address-book",
        userRoles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
        items: [],
      },
      {
        title: i18n.t("models.client.plural"),
        pathName: "app.clients",
        icon: "address-book",
        userRoles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
        items: [],
      },
      {
        title: "Contratos",
        pathName: "app.contracts",
        pathParams: { status: "pendientes" },
        icon: "file-signature",
        userRoles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
        items: [],
      },
      {
        title: i18n.t("app.documentation.my"),
        pathName: "app.documentation",
        icon: "clipboard-list",
        userRoles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
        items: [],
      },
      {
        title: i18n.t("models.employee.plural"),
        pathName: "app.employees",
        icon: "briefcase",
        userRoles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
        items: [],
      },
      {
        title: i18n.t("models.cfdi.plural"),
        pathName: "app.cfdis",
        icon: "file-invoice",
        userRoles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER],
        items: [],
      },
      {
        title: i18n.t("app.contracts.visits.title"),
        pathName: "app.visits",
        icon: "user-check",
        userRoles: [TenantUserRole.OWNER, TenantUserRole.ADMIN, TenantUserRole.MEMBER, TenantUserRole.GUEST],
        items: [],
      },
      {
        title: i18n.t("app.navbar.settings"),
        icon: "cog",
        userRoles: [TenantUserRole.OWNER, TenantUserRole.ADMIN],
        pathName: "settings",
        items: [
          {
            title: i18n.t("app.navbar.profile"),
            pathName: "settings.profile",
            items: [],
          },
          {
            title: i18n.t("settings.tenant.workspaces.title"),
            pathName: "settings.tenant.workspaces",
            userRoles: [TenantUserRole.OWNER, TenantUserRole.ADMIN],
            items: [],
          },
          {
            title: i18n.t("settings.tenant.members.title"),
            pathName: "settings.tenant.members",
            userRoles: [TenantUserRole.OWNER, TenantUserRole.ADMIN],
            items: [],
          },
          {
            title: i18n.t("settings.tenant.subscription.title"),
            pathName: "settings.tenant.subscription",
            userRoles: [TenantUserRole.OWNER],
            items: [],
          },
          {
            title: i18n.t("app.navbar.tenant"),
            pathName: "settings.tenant.general",
            userRoles: [TenantUserRole.OWNER],
            items: [],
          },
          {
            title: i18n.t("app.navbar.logout"),
            pathName: "logout",
            items: [],
          },
        ],
      },
    ],
  },
  {
    title: i18n.t("admin.title"),
    items: [
      {
        title: i18n.t("admin.switchToAdmin"),
        pathName: "admin.tenants",
        icon: "user-shield",
        userTypes: [UserType.Admin],
        items: [],
      },
    ],
  },
];
