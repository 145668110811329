




































































































































































import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { EntityDto } from "../../../application/dtos/EntityDto";
import { ColumnType, ValueType } from "../../../application/dtos/ColumnType";

@Component({})
export default class EntityTableList extends Vue {
  @Prop()
  modelName!: string;
  @Prop()
  items!: EntityDto[];
  @Prop()
  columns!: ColumnType[];
  @Prop({ default: true })
  allowEdit!: boolean;
  @Prop({ default: false })
  enableDownload!: boolean;
  @Prop({ default: false })
  enableUpload!: boolean;
  @Prop()
  routerLinks!: any[];

  files: FormData = new FormData();
  edit(item: EntityDto) {
    this.$emit("edit", item);
  }
  downloadFile(item: EntityDto) {
    this.$emit("downloadFile", item);
  }
  uploadFile(fileList: any, item) {
    this.$emit("uploadFile", item, fileList);
  }
  translateModelProperty(columnName: string) {
    const translated = this.$t(`models.${this.modelName}.${columnName}`);
    return translated ?? columnName;
  }
  convertedValue(item: EntityDto, column: ColumnType): string {
    return ColumnType.convertValue(item, column);
  }
  convertedArray(item: EntityDto, column: ColumnType): any {
    if (
      column.valueType === ValueType.TenantUserRoles ||
      column.valueType === ValueType.SubscriptionProducts
    ) {
      const values = ColumnType.getValues(column);
      const descriptions: any = [];
      values.forEach((element: any) => {
        if (item[column.name].includes(element.value)) {
          descriptions.push(element.name);
        }
      });
      const uniqueArray = [...new Set(descriptions)];
      return uniqueArray.join(", ");
    }
  }
  isBoolean(column: ColumnType): boolean {
    return column.valueType === ValueType.Bool;
  }
  isArray(column: ColumnType): boolean {
    return (
      column.valueType === ValueType.TenantUserRoles ||
      column.valueType === ValueType.SubscriptionProducts
    );
  }
  isFile(column: ColumnType): boolean {
    return column.valueType === ValueType.File;
  }
  getLinkTo(link: any, item: EntityDto): string {
    if (link.to.includes("{id}")) {
      return link.to.replace("{id}", item.id);
    }
    // else if (link.to.includes("{uuid}")) {
    //   return link.to.replace("{uuid}", item.id);
    // }
    return link.to;
  }
  get orderedItems() {
    if (!this.items) {
      return [];
    }
    return this.items.sort((x, y) => {
      if (x && y) {
        return x.id && y.id && x.id > y.id ? -1 : 1;
      }
      return 1;
    });
  }
}
