var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('breadcrumb',{attrs:{"menu":[
      {
        title: _vm.$t('app.contracts.visits.activeProjects'),
        routeName: 'app.visits'
      },
      {
        title: _vm.$t('app.contracts.visits.title'),
        routeName: 'app.visits.list',
        routeParams: { id: _vm.id }
      },
      { title: _vm.$t('app.contracts.visits.new'), routeName: '' }
    ]}}),_c('div',{staticClass:"bg-white shadow-sm border-b border-gray-300 w-full py-2"},[_c('div',{staticClass:"mx-auto max-w-5xl xl:max-w-7xl flex items-center justify-between px-4 sm:px-6 lg:px-8 space-x-2"},[_c('h2',{staticClass:"flex-1 font-bold flex items-center truncate"},[_c('span',{staticClass:" hidden sm:block"},[_vm._v(" "+_vm._s(_vm.$t("app.contracts.visits.attendace"))+" ")]),_c('span',{staticClass:" sm:hidden"},[_vm._v(" "+_vm._s(_vm.$t("app.contracts.visits.attendace"))+" ")])]),_c('div',{staticClass:"flex items-center space-x-2"},[(false)?_c('button',{staticClass:"inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm sm:text-sm font-medium sm:rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500",attrs:{"type":"button"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("shared.cancel"))+" ")]):_vm._e(),(false)?_c('button',{staticClass:"inline-flex items-center space-x-2 px-3 py-2 border border-transparent shadow-sm sm:text-sm font-medium sm:rounded-md text-white bg-theme-600 hover:bg-theme-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500",attrs:{"type":"button"},on:{"click":_vm.save}},[_c('svg',{staticClass:"h-5 w-5 sm:h-4 sm:w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M5 13l4 4L19 7"}})]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("shared.save"))+" ")])]):_vm._e()])])]),_c('div',{staticClass:" max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pt-4 pb-6"},[_c('new-contract-visit',{ref:"newContractVisit",attrs:{"id":_vm.id}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }