












































































































































































import Vue from "vue";
import Component from "vue-class-component";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import Modal from "@/components/shared/modals/Modal.vue";
import Plans from "@/components/marketing/pricing/PlansRadioButtons.vue";
import { Card, createToken } from "vue-stripe-elements-plus";
import CurrencyToggle from "@/components/marketing/toggles/CurrencyToggle.vue";
import { SubscriptionProductDto } from "@/application/dtos/master/subscriptions/SubscriptionProductDto";
import { SubscriptionPriceDto } from "@/application/dtos/master/subscriptions/SubscriptionPriceDto";
import { TenantCreateRequest } from "@/application/contracts/master/tenants/TenantCreateRequest";
import { SubscriptionBillingPeriod } from "@/application/enums/master/subscriptions/SubscriptionBillingPeriod";
import store from "@/store";
import { i18n } from "@/plugins/vue-i18n";
import services from "@/services";

@Component({
  components: {
    ErrorModal,
    Modal,
    ConfirmModal,
    Plans,
    Card,
    CurrencyToggle
  }
})
export default class TenantNew extends Vue {
  $refs!: {
    errorModal: ErrorModal;
    cardModal: Modal;
    confirmModal: ConfirmModal;
  };
  name = "";
  taxId = "";
  legalName = "";
  stripeKey = "";
  stripeOptions: any = {};
  complete = false;
  created() {
    this.stripeKey =
      process.env.VUE_APP_SUBSCRIPTION_PUBLIC_KEY?.toString() ?? "";
    this.stripeOptions = {
      showCardHolderName: true,
      hidePostalCode: false,
      iconStyle: "solid",
      elements: {
        locale: i18n.locale
      }
    };
    if (!this.selectedProduct) {
      store.commit("pricing/select", {
        product: store.state.pricing.products[0],
        billingPeriod: SubscriptionBillingPeriod.MONTHLY
      });
    }
  }
  mounted() {
    services.subscriptionProducts.getProducts(true, true);
  }
  close() {
    this.$emit("close");
  }
  createTenant() {
    if (!this.selectedPrice) {
      this.$refs.errorModal.show("Selecciona un plan");
    } else if (this.name === "") {
      this.$refs.errorModal.show("Escribe un nombre");
    } else if (this.taxId === "") {
      this.$refs.errorModal.show("Escribe el RFC");
    } else if (this.legalName === "") {
      this.$refs.errorModal.show("Escribe la razón social");
    } else {
      this.$refs.confirmModal.show(this.$t("settings.tenant.createConfirm"));
    }
  }
  tryRegister() {
    if (this.selectedPrice?.trialDays === 0 && this.selectedPrice?.price > 0) {
      this.$refs.cardModal.show(
        this.$t("account.register.providePaymentDetails").toString(),
        this.$t("shared.cancel")
      );
    } else {
      this.register();
    }
  }
  pay() {
    createToken()
      .then(data => {
        if (data.error) {
          this.$refs.errorModal.show(data.error.message);
        } else {
          this.register(data.token.id);
        }
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      })
      .finally(() => {
        this.$refs.cardModal.close();
      });
  }
  register(stripeCardToken = "") {
    const tenantCreateRequest: TenantCreateRequest = {
      name: this.name,
      workspaceTaxIdRfc: this.taxId,
      workspaceLegalName: this.legalName,
      selectedSubscription: {
        subscriptionPriceId: this.selectedPrice?.id ?? "",
        subscriptionCardToken: stripeCardToken,
        subscriptionCoupon: ""
      }
    };
    services.tenants
      .create(tenantCreateRequest)
      .then(() => {
        this.name = "";
        this.$emit("created");
        // this.$router.go(0);
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      });
  }
  get selectedProduct() {
    return store.state.pricing.selectedProduct as SubscriptionProductDto;
  }
  get getButtonText(): string {
    if (this.selectedPrice) {
      return (
        (this.selectedPrice.billingPeriod === SubscriptionBillingPeriod.ONCE
          ? this.$t("marketing.pricing.pay")
          : this.$t("marketing.pricing.subscribe")) +
        " " +
        this.priceDescription
      );
    }
    return "";
  }
  get selectedPrice(): SubscriptionPriceDto | undefined {
    if (this.selectedProduct) {
      return (
        this.selectedProduct.prices.find(
          f => f.billingPeriod === store.state.pricing.billingPeriod
        ) ?? this.selectedProduct.prices[0]
      );
    }
    return undefined;
  }
  get billingPeriod(): string {
    if (this.selectedPrice) {
      if (
        this.selectedPrice?.billingPeriod === SubscriptionBillingPeriod.ONCE
      ) {
        return this.$t("marketing.pricing.once").toString();
      } else {
        return (
          "/ " +
          this.$t(
            "marketing.pricing." +
              SubscriptionBillingPeriod[this.selectedPrice.billingPeriod] +
              "Short"
          )
        );
      }
    }
    return "";
  }
  get priceDescription() {
    return "$" + this.selectedPrice?.price + " " + this.billingPeriod;
  }
}
