





























































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import services from "@/services";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal2.vue";
import WarningBannner from "@/components/shared/banners/WarningBanner.vue";
import InfoBanner from "@/components/shared/banners/InfoBanner.vue";
import Loading from "@/components/shared/loaders/Loading.vue";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import { FiscalCredentialType } from "@/application/enums/app/v3/cfdis/FiscalCredentialType";
import DropDocument from "@/components/app/v3/contracts/uploads/DropDocument.vue";
import tinyEventBus from "@/plugins/tiny-eventbus";

@Component({
  components: {
    ErrorModal,
    SuccessModal,
    WarningBannner,
    InfoBanner,
    Loading,
    DropDocument
  }
})
export default class NewWorkspaceCredential extends Vue {
  @Prop({ default: "sm:max-w-lg" }) maxSize!: string;
  $refs!: {
    errorModal: ErrorModal;
    successModal: SuccessModal;
    inputName: HTMLInputElement;
  };
  loading = false;
  item: WorkspaceDto | null = null;
  type: FiscalCredentialType = FiscalCredentialType.CIEC;
  certificate = "";
  privateKey = "";
  password = "";
  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    services.workspaces
      .get(this.$route.params.id)
      .then(response => {
        this.item = response;
      })
      .finally(() => {
        this.loading = false;
        this.$nextTick(() => {
          if (this.$refs.inputName) {
            this.$refs.inputName.focus();
            this.$refs.inputName.select();
          }
        });
      });
  }
  keyup(event: KeyboardEvent) {
    console.log(event.keyCode);
    if (event.keyCode === 27) {
      this.close();
    }
  }
  close() {
    this.$router.push({ name: "settings.tenant.workspaces" });
  }
  save() {
    this.loading = true;
    if (this.type === FiscalCredentialType.CIEC) {
      services.workspaces
        .createCredentialCiec({
          id: this.item?.id,
          password: this.password
        })
        .then(() => {
          tinyEventBus().emitter.emit("workspace-added");
          this.$refs.successModal.show(
            "Credencial CIEC",
            "Se ha creado la credencial correctamente para extracciones como Estatus de Cumplimiento y CFDIs."
          );
        })
        .catch(error => {
          this.$refs.errorModal.show("Error al crear", this.$t(error));
        })
        .finally(() => {
          this.loading = false;
        });
    } else if (this.type === FiscalCredentialType.EFIRMA) {
      services.workspaces
        .createCredentialEfirma({
          id: this.item?.id,
          certificate: this.certificate,
          privateKey: this.privateKey,
          password: this.password
        })
        .then(() => {
          tinyEventBus().emitter.emit("workspace-added");
          this.$refs.successModal.show(
            "Credencial E.FIRMA",
            "Se ha creado la credencial correctamente para extracciones como Estatus de Cumplimiento y CFDIs."
          );
        })
        .catch(error => {
          this.$refs.errorModal.show("Error al crear", this.$t(error));
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  droppedCertificate(filesBase64: string) {
    if (filesBase64.length > 0) {
      this.certificate = filesBase64;
    }
  }
  droppedKey(filesBase64: string) {
    if (filesBase64.length > 0) {
      this.privateKey = filesBase64;
    }
  }
}
