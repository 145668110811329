































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { EmployeeDto } from "@/application/dtos/app/v3/employees/EmployeeDto";
import services from "@/services";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import Workers from "@/assets/contracts/Workers.vue";
import { TranslateResult } from "vue-i18n";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  components: {
    EmptyState,
    ErrorModal,
    Workers,
    Loading
  }
})
export default class SelectEmployees extends Vue {
  @Prop({ default: "sm:max-w-lg" }) maxSize!: string;
  $refs!: {
    errorModal: ErrorModal;
  };
  title!: string;
  closeText!: TranslateResult | string;
  showing = false;
  errorDescription!: string;
  searchInput = "";
  items: EmployeeDto[] = [];
  loading = false;
  selected: string[] = [];
  mounted() {
    this.closeText = this.$t("shared.close").toString();
  }
  show(selected: string[]) {
    this.selected = selected;
    this.showing = true;

    // this.$nextTick(() => {
    //   window.addEventListener("keyup", this.keyup);
    // });

    this.reload();
  }
  reload() {
    this.loading = true;
    services.employees
      .getAll()
      .then(response => {
        this.items = response;
      })
      .catch(error => {
        this.$refs.errorModal.show("Error", this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  keyup(event: KeyboardEvent) {
    console.log(event.keyCode);
    if (event.keyCode === 27) {
      this.close();
    }
  }
  close() {
    this.$emit("closed");
    this.showing = false;
  }
  toggle(item: EmployeeDto) {
    if (this.isSelected(item)) {
      this.selected = this.selected.filter(f => f !== item.id);
    } else {
      this.selected.push(item.id);
    }
  }
  isSelected(item: EmployeeDto) {
    return this.selected.find(f => f === item.id);
  }
  accept() {
    const selected: EmployeeDto[] = [];
    this.items.forEach(element => {
      if (this.isSelected(element)) {
        selected.push(element);
      }
    });
    this.$emit("selected", selected);
    this.close();
  }
  newEmployees() {
    const routeData = this.$router.resolve({ name: "app.employees.new" });
    window.open(routeData.href, "_blank");
    this.close();
  }
  get filteredItems(): EmployeeDto[] {
    if (!this.items) {
      return [];
    }
    const items = this.items.filter(
      f =>
        f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) ||
        f.firstName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.lastName1
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.lastName2
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.taxId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.socialSecurityId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.populationRegistryId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase())
    );

    return items;
  }
}
