import { i18n } from "@/plugins/vue-i18n";
import { WorkspaceType } from "../../../../enums/master/tenants/WorkspaceType";
import { Periodicity } from "../../../../enums/app/common/Periodicity";
import { DocumentationInstitution } from "../../../../enums/app/v3/documentation/DocumentationInstitution";
import { DocumentationType } from "../../../../enums/app/v3/documentation/DocumentationType";
import { DocumentTypeDto } from "./DocumentTypeDto";

const expirations = [
  {
    periodicity: Periodicity.MONTHLY,
    expiresMonths: 1,
    expiresDays: 17,
  },
  {
    periodicity: Periodicity.QUATERLY,
    expiresMonths: 1,
    expiresDays: 17,
  },
  {
    periodicity: Periodicity.YEARLY,
    expiresMonths: 3,
    expiresDays: 0,
  },
];
const institutions = [
  {
    institution: DocumentationInstitution.LEGAL,
    institutionClasses: "bg-gray-50 border border-gray-200 text-gray-900",
    documents: [
      {
        periodicity: Periodicity.ONCE,
        type: DocumentationType.SAT_ARTICLES_OF_INCORPORATION_DOCS,
        workspaceType: WorkspaceType.REGULAR,
      },
    ],
  },
  {
    institution: DocumentationInstitution.SAT,
    institutionClasses: "bg-blue-50 border border-blue-200 text-blue-900",
    documents: [
      {
        periodicity: Periodicity.MONTHLY,
        type: DocumentationType.SAT_COMPLIANCE,
        workspaceType: WorkspaceType.REGULAR,
      },
      {
        periodicity: Periodicity.MONTHLY,
        type: DocumentationType.SAT_MONTHLY_ISR_STATEMENT,
        workspaceType: WorkspaceType.REGULAR,
      },
      {
        periodicity: Periodicity.MONTHLY,
        type: DocumentationType.SAT_MONTHLY_TAX_STATEMENT,
        workspaceType: WorkspaceType.REGULAR,
      },
      {
        periodicity: Periodicity.MONTHLY,
        type: DocumentationType.SAT_MONTHLY_PAYMENT,
        workspaceType: WorkspaceType.REGULAR,
      },
      {
        periodicity: Periodicity.YEARLY,
        type: DocumentationType.SAT_YEARLY_STATEMENT,
        workspaceType: WorkspaceType.REGULAR,
      },
      {
        periodicity: Periodicity.YEARLY,
        type: DocumentationType.SAT_YEARLY_PAYMENT,
        workspaceType: WorkspaceType.REGULAR,
      },
    ],
  },
  {
    institution: DocumentationInstitution.IMSS,
    institutionClasses: "bg-teal-50 border border-teal-200 text-teal-900",
    documents: [
      {
        periodicity: Periodicity.MONTHLY,
        type: DocumentationType.IMSS_COMPLIANCE,
        workspaceType: WorkspaceType.REGULAR,
      },
      {
        periodicity: Periodicity.MONTHLY,
        type: DocumentationType.IMSS_SIPARE_SUA,
        workspaceType: WorkspaceType.REGULAR,
      },
      {
        periodicity: Periodicity.MONTHLY,
        type: DocumentationType.IMSS_SIPARE_SUA_PAYMENT,
        workspaceType: WorkspaceType.REGULAR,
      },
      {
        periodicity: Periodicity.MONTHLY,
        type: DocumentationType.IMSS_SUA_EMPLOYEES_FEES,
        workspaceType: WorkspaceType.REGULAR,
      },
      {
        periodicity: Periodicity.QUATERLY,
        type: DocumentationType.IMSS_ICSOE,
        workspaceType: WorkspaceType.SPECIALIZED_PROVIDER,
      },
    ],
  },
  {
    institution: DocumentationInstitution.INFONATIV,
    institutionClasses: "bg-red-50 border border-red-200 text-red-900",
    documents: [
      {
        periodicity: Periodicity.MONTHLY,
        type: DocumentationType.INFONAVIT_COMPLIANCE,
        workspaceType: WorkspaceType.REGULAR,
      },
      {
        periodicity: Periodicity.QUATERLY,
        type: DocumentationType.INFONAVIT_SISUB,
        workspaceType: WorkspaceType.SPECIALIZED_PROVIDER,
      },
    ],
  },
  {
    institution: DocumentationInstitution.STPS,
    institutionClasses: "bg-green-50 border border-green-200 text-green-900",
    documents: [
      {
        periodicity: Periodicity.MONTHLY,
        type: DocumentationType.STPS_REPSE,
        workspaceType: WorkspaceType.SPECIALIZED_PROVIDER,
      },
    ],
  },
];

const documentTypes: DocumentTypeDto[] = [];
for (const institution of institutions) {
  for (const document of institution.documents) {
    const expiration = expirations.find((f) => f.periodicity === document.periodicity);
    documentTypes.push({
      order: documentTypes.length + 1,
      institution: institution.institution,
      institutionClasses: institution.institutionClasses,
      type: document.type,
      name: i18n
        .t("models.documentation." + DocumentationType[document.type])
        .toString()
        .toString(),
      periodicity: document.periodicity,
      expiresMonths: expiration?.expiresMonths ?? 0,
      expiresDays: expiration?.expiresDays ?? 0,
      requiredFor: document.workspaceType,
    });
  }
}

export default documentTypes;
