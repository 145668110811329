













































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import EmployeesList from "@/components/app/v3/employees/list/EmployeesList.vue";
import ButtonPrimary from "@/components/shared/buttons/ButtonPrimary.vue";

@Component({
  metaInfo: {
    title: "Colaboradores",
    titleTemplate: "%s | Délega"
  },
  components: {
    Breadcrumb,
    EmployeesList,
    ButtonPrimary
  }
})
export default class Employees extends Vue {}
