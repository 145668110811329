export enum Period {
  ALL,
  YEAR,
  MONTH,
  WEEK,
  DAY,
  LAST_YEAR,
  LAST_MONTH,
  LAST_WEEK,
  LAST_DAY,
  LAST_30_DAYS,
  LAST_7_DAYS,
}
