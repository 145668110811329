





















































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class CheckboxSelect extends Vue {
  @Prop({}) title!: string;
  @Prop({}) value!: any[];
  @Prop({}) options!: any[];
  @Prop({ default: "left" }) direction!: string;
  @Prop({ default: false }) showId!: boolean;
  @Prop({ default: "id" }) idProp!: string;
  @Prop({ default: "name" }) nameProp!: string;
  @Prop({ default: "" }) descriptionProp!: string;
  @Prop({ default: false }) acceptWhenClose!: boolean;

  selected: any[] = [];
  temp: any[] = [];
  open = false;
  mounted() {
    this.selected = this.value;
    this.temp = this.selected;
  }
  close() {
    if (this.open) {
      this.open = false;
      if (this.acceptWhenClose) {
        this.model = this.temp;
      }
    }
    this.temp = this.selected;
  }
  accept() {
    this.model = this.temp;
    this.open = false;
  }
  get sorted() {
    return this.options.sort((x, y) => {
      if (x.id && y.id) {
        return (x.id > y.id ? 1 : -1) ?? 1;
      }
      return 1;
    });
  }
  get model() {
    return this.selected;
  }
  set model(value) {
    this.$emit("input", value);
  }
}
