



































































































































import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class SideModal extends Vue {
  @Prop()
  title!: string;
  @Prop({ default: "md" })
  size!: string;
  @Prop({ default: "px-4 sm:px-6" })
  paddingContent!: string;
  @Prop({ default: "py-6" })
  paddingY!: string;
  created() {
    document.addEventListener("keyup", evt => {
      if (evt.keyCode === 27) {
        this.close();
      }
    });
  }
  close() {
    this.$emit("close");
  }
}
