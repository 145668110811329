var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.items.length === 0)?_c('div',[(_vm.workspaceWithoutFiscalId)?_c('span',[_c('EmptyState',{staticClass:"bg-white",attrs:{"captions":{
          new: 'Agregar credenciales',
          thereAreNo:
            'No has agregado tus credenciales del SAT para obtener automáticamente los CFDIs y la Opinión de Cumplimiento'
        },"icon":"refresh"},on:{"new":function($event){return _vm.$router.push({
            name: 'settings.tenant.workspaces.credentials.new',
            params: { id: _vm.workspaceWithoutFiscalId }
          })}}})],1):_c('EmptyState',{staticClass:"bg-white",attrs:{"captions":{
        new: 'Sincronizar CFDIs del SAT',
        thereAreNo: 'No hay extracciones'
      },"icon":"refresh"},on:{"new":function($event){return _vm.$emit('sync')}}})],1):_c('div',[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"overflow-x-auto"},[_c('div',{staticClass:"py-2 align-middle inline-block min-w-full"},[_c('div',{staticClass:"shadow overflow-hidden border border-gray-200 sm:rounded-lg"},[_c('table',{staticClass:"min-w-full divide-y divide-gray-200"},[_c('thead',{staticClass:"bg-gray-50"},[_c('tr',_vm._l((_vm.headers),function(header,idx){return _c('th',{key:idx,staticClass:"px-6 py-2 text-left text-xs font-medium text-gray-500 tracking-wider select-none truncate",attrs:{"scope":"col"}},[_c('div',{staticClass:"flex items-center space-x-1 text-gray-500"},[_c('div',[_vm._v(" "+_vm._s(header.title)+" ")])])])}),0)]),_c('tbody',{staticClass:"bg-white divide-y divide-gray-200 text-sm"},_vm._l((_vm.items),function(extraction,idxExtraction){return _c('tr',{key:idxExtraction},[_c('td',{staticClass:"px-6 py-4 whitespace-nowrap"},[(extraction.processed)?_c('span',{staticClass:"inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-teal-50 text-teal-800 border border-teal-300"},[_vm._v(" "+_vm._s(_vm.status(extraction))+" ")]):_c('span',{staticClass:"inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-50 text-yellow-800 border border-yellow-300"},[_vm._v(" "+_vm._s(_vm.status(extraction))+" ")])]),_c('td',{staticClass:"px-6 py-4 whitespace-nowrap"},[_vm._v(" "+_vm._s(_vm.period(extraction))+" ")]),_c('td',{staticClass:"px-6 py-4 whitespace-nowrap"},[_vm._v(" "+_vm._s(extraction.invoices)+" ")])])}),0)])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }