



























import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import Tabs, { TabItem } from "@/components/shared/tabs/Tabs.vue";

@Component({
  metaInfo: {
    title: "Perfil de proveedor",
    titleTemplate: "%s | Délega"
  },
  components: {
    Breadcrumb,
    Tabs
  }
})
export default class Provider extends Vue {
  tabs: TabItem[] = [];
  mounted() {
    this.tabs = [
      {
        name: this.$t("app.providers.profile.title"),
        routeName: "app.providers.profile"
      },
      {
        name: this.$t("app.providers.documentation.title"),
        routeName: "app.providers.documentation"
      }
    ];
  }
}
