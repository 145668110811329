







































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { LinkDto } from "@/application/dtos/master/v3/links/LinkDto";
import services from "@/services";
import store from "@/store";
import { LinkStatus } from "@/application/enums/app/v3/links/LinkStatus";
import tinyEventBus from "@/plugins/tiny-eventbus";

@Component({
  components: {}
})
export default class LinkSelector extends Vue {
  $refs!: {
    inputSearch: HTMLInputElement;
  };
  opened = false;
  loading = false;
  searchInput = "";
  items: LinkDto[] = [];
  selected: LinkDto | null = null;
  mounted() {
    this.reload();
    tinyEventBus().emitter.on("reload-links", () => {
      this.reload();
    });
  }
  beforeDestroy() {
    tinyEventBus().emitter.off("reload-links");
  }
  reload() {
    this.selected = null;
    this.loading = true;
    services.links
      .getAllLinked()
      .then(response => {
        this.items = response;
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  select(link: LinkDto) {
    this.close();
    this.selected = link;
    this.$emit("selected", this.selected.id, link);
    this.searchInput = "";
  }
  toggle() {
    if (!this.opened) {
      this.open();
      this.$nextTick(() => {
        this.$refs.inputSearch.focus();
        this.$refs.inputSearch.select();
      });
    } else {
      this.close();
    }
  }
  open() {
    this.opened = true;
  }
  close() {
    this.opened = false;
  }
  trySelect() {
    if (this.filteredItems.length > 0) {
      this.select(this.filteredItems[0]);
    }
  }
  add() {
    tinyEventBus().emitter.emit("new-link");
  }
  linkCreated(link: LinkDto) {
    if (link.status === LinkStatus.LINKED) {
      this.items.push(link);
      this.select(link);
    }
  }
  get currentWorkspaceId() {
    return store.state.tenant.currentWorkspace?.id ?? "";
  }
  get filteredItems(): LinkDto[] {
    if (!this.items) {
      return [];
    }
    const items = this.items.filter(
      f =>
        f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) ||
        f.providerWorkspace?.taxId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.providerWorkspace?.legalName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.clientWorkspace?.taxId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.clientWorkspace?.legalName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase())
    );

    return items;
  }
}
