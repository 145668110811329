










































































































import Vue from "vue";
import Component from "vue-class-component";
import { WorkspaceDto } from "@/application/dtos/app/workspaces/WorkspaceDto";
import services from "@/services";
import store from "@/store";

@Component({
  components: {}
})
export default class WorkspaceSelector extends Vue {
  $refs!: {
    inputSearch: HTMLInputElement;
  };
  loading = false;
  dropDown = false;
  search = "";
  mounted() {
    if (
      !this.currentWorkspace &&
      this.workspaces &&
      this.workspaces.length > 0
    ) {
      const defaultWorkspace = this.workspaces.find(f => f.default);
      if (defaultWorkspace) {
        this.change(defaultWorkspace);
      } else {
        this.change(this.workspaces[0]);
      }
    }
    if (!this.workspaces) {
      services.workspaces.getAllWorkspaces(true);
    }
  }
  add() {
    this.$emit("add");
    this.closeDropdown();
    this.$router.push({ name: "settings.tenant.workspaces.new" });
  }
  closeDropdown() {
    this.dropDown = false;
  }
  toggleDropDown() {
    this.dropDown = !this.dropDown;
    if (this.dropDown) {
      this.$nextTick(() => {
        this.$refs.inputSearch.focus();
        this.$refs.inputSearch.select();
      });
    }
  }
  enter() {
    if (this.sortedItems.length === 1) {
      this.change(this.sortedItems[0]);
    }
  }
  change(workspace: WorkspaceDto) {
    this.search = "";
    this.$emit("selected");
    this.closeDropdown();
    store.commit("tenant/currentWorkspace", workspace);
    // this.$router.go(0);
  }
  get workspaces(): WorkspaceDto[] {
    return store.state.tenant.workspaces ?? [];
  }
  get currentWorkspace(): WorkspaceDto | null {
    return store.state.tenant.currentWorkspace;
  }
  get sortedItems(): WorkspaceDto[] {
    return this.workspaces
      .filter(
        f =>
          !this.search ||
          f.name.toLowerCase().includes(this.search.toLowerCase())
          || f.taxId.toLowerCase().includes(this.search.toLowerCase())

      )
      .sort((x, y) => {
        if (x.name && y.name) {
          return (x.name > y.name ? 1 : -1) ?? 1;
        }
        return 1;
      });
  }
}
