












































import Vue from "vue";
import Component from "vue-class-component";
import services from "@/services";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import NewModal from "@/components/shared/modals/NewModal.vue";
import EmployeesListAndTable from "./EmployeesListAndTable.vue";
import { EmployeeDto } from "@/application/dtos/app/v3/employees/EmployeeDto";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  components: {
    TableRowSkeleton,
    NewModal,
    EmployeesListAndTable,
    Loading
  }
})
export default class EmployeesList extends Vue {
  loading = false;
  items: EmployeeDto[] = [];

  searchInput = "";

  mounted() {
    this.reload();
  }
  reload() {
    this.loading = true;
    services.employees
      .getAll()
      .then(response => {
        this.items = response;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  initials(member: EmployeeDto) {
    let initials = "";
    const names = [member.firstName, member.lastName1];
    if (names.length === 1) {
      initials = member.firstName.substring(0, 2);
    } else {
      initials = names[0][0] + names[1][0];
    }
    return initials.toUpperCase();
  }
  get filteredItems(): EmployeeDto[] {
    if (!this.items) {
      return [];
    }
    const items = this.items.filter(
      f =>
        f.id?.toUpperCase().includes(this.searchInput.toUpperCase()) ||
        f.firstName
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.lastName1
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.lastName2
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.email
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.taxId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.socialSecurityId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase()) ||
        f.populationRegistryId
          ?.toString()
          .toUpperCase()
          .includes(this.searchInput.toUpperCase())
    );

    return items;
  }
  get sortedItems(): EmployeeDto[] {
    this.$forceUpdate();
    return this.filteredItems;
  }
}
