import { Module } from "vuex";
import { AccountState, RootState } from "@/store/types";
import { UserDto } from "@/application/dtos/master/users/UserDto";

export const state: AccountState = {
  user: null,
};
export const account: Module<AccountState, RootState> = {
  namespaced: true,
  state,
  actions: {
    logged({ commit }, user) {
      commit("logged", user);
    },
  },
  mutations: {
    reset(state: AccountState) {
      state.user = null;
    },
    logged(state: AccountState, user: UserDto) {
      state.user = user;
    },
  },
};
