var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('breadcrumb',{attrs:{"home":"app.visits","menu":[
      {
        title: _vm.$t('app.contracts.visits.activeProjects'),
        routeName: 'app.visits'
      },
      {
        title: _vm.$t('app.contracts.visits.title'),
        routeName: ''
      }
    ]}}),_c('div',{staticClass:"bg-white shadow-sm border-b border-gray-300 w-full py-2"},[_c('div',{staticClass:"mx-auto max-w-5xl xl:max-w-7xl flex items-center justify-between px-4 sm:px-6 lg:px-8 space-x-2"},[_c('div',[_c('h2',{staticClass:"flex-1 font-bold flex items-center truncate"},[_vm._v(" "+_vm._s(_vm.$t("app.contracts.visits.title"))+" ")])]),_c('div',{staticClass:"flex items-center space-x-2"},[_c('button',{staticClass:"inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm sm:text-sm font-medium sm:rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500",attrs:{"type":"button"},on:{"click":_vm.reload}},[_vm._v(" "+_vm._s(_vm.$t("shared.reload"))+" ")]),(_vm.contract.id)?_c('router-link',{staticClass:"inline-flex space-x-2 items-center px-3 py-2 border border-transparent shadow-sm sm:text-sm font-medium sm:rounded-md text-white bg-theme-600 hover:bg-theme-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500",attrs:{"to":{ name: 'app.visits.new', params: { id: _vm.contract.id } }}},[_c('svg',{staticClass:"-ml-0.5 mr-2 h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 6v6m0 0v6m0-6h6m-6 0H6"}})]),_vm._v(" "+_vm._s(_vm.$t("app.contracts.visits.new"))+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"pt-2 space-y-2 max-w-5xl xl:max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"},[(_vm.loading)?_c('Loading'):(_vm.contract.visits)?_c('div',[(_vm.contract.visits.length === 0)?_c('div',[_c('EmptyState',{staticClass:"bg-white",attrs:{"type":0,"captions":{
            new: 'Agregar',
            description: 'Registra la visita del contrato ' + _vm.contract.name,
            thereAreNo: 'No hay visitas',
            routeName: 'app.visits.new',
            routeParams: { id: _vm.contract.id }
          },"icon":"plus"}})],1):_c('div',[_c('visits-list-and-table',{attrs:{"items":_vm.contract.visits}})],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }