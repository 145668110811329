<template>
  <span>
    <button
      v-if="!to"
      :type="type"
      @click="$emit('click')"
      :disabled="disabled"
      class="inline-flex items-center space-x-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-theme-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
      :class="{
        ' cursor-not-allowed opacity-75': disabled,
        'hover:bg-theme-700': !disabled
      }"
    >
      <slot />
    </button>
    <router-link
      v-else
      :disabled="disabled"
      :class="{
        ' cursor-not-allowed opacity-75': disabled,
        'hover:bg-theme-700': !disabled
      }"
      class="inline-flex items-center space-x-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-theme-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
      :to="to"
    >
      <slot />
    </router-link>
  </span>
</template>

<script>
export default {
  props: ["type", "click", "to", "disabled"]
};
</script>

<style>
</style>