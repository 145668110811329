






























































































import Vue from "vue";
import Component from "vue-class-component";
import { TranslateResult } from "vue-i18n";

@Component
export default class ErrorModal extends Vue {
  $refs!: {
    buttonClose: HTMLButtonElement;
  };
  title!: string;
  description!: string;
  closeText!: TranslateResult | string;
  showing = false;
  mounted() {
    this.title = this.$t("shared.success").toString();
    this.closeText = this.$t("shared.close").toString();
  }
  show(title: any, description?: any) {
    this.title = title;
    this.description = description;
    // if (this.description && this.description.length > 300) {
    //   this.description = this.description.substring(0, 300);
    // }
    this.showing = true;
    // window.addEventListener("keyup", this.keyEvent);
    setTimeout(() => {
      this.$nextTick(() => {
        if (this.$refs.buttonClose) {
          this.$refs.buttonClose.focus();
        }
      });
    }, 500);
  }
  keyEvent(event) {
    if (event.ctrlKey && event.keyCode) {
      if (event.keyCode === 13) {
        this.close();
      }
    }
  }
  close() {
    window.removeEventListener("keyup", this.keyEvent);
    this.$emit("closed");
    this.showing = false;
  }
  keyup(event: KeyboardEvent) {
    // console.log({ event, showing: this.showing });
    if (event.keyCode === 13) {
      this.close();
    }
  }
}
