



































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ContractDto } from "@/application/dtos/app/v3/contracts/ContractDto";
import ContractClock from "@/assets/contracts/ContractClock.vue";
import ContractCheck from "@/assets/contracts/ContractCheck.vue";
import ContractArchived from "@/assets/contracts/ContractArchived.vue";
import { i18n } from "@/plugins/vue-i18n";
import store from "@/store";
import EmptyState from "@/components/shared/emptyState/EmptyState.vue";
import DateUtils from "@/utils/shared/DateUtils";

@Component({
  components: {
    ContractClock,
    ContractCheck,
    ContractArchived,
    EmptyState
  }
})
export default class ContractsListAndTable extends Vue {
  @Prop({}) items!: ContractDto[];
  sortByColumn = "";
  sortDirection = -1;
  headers = [
    {
      name: "providerWorkspace.taxId",
      title: i18n.t("models.provider.taxId")
    },
    {
      name: "name",
      title: i18n.t("models.contract.object")
    }
    // {
    //   name: "providerLegalName",
    //   title: i18n.t("models.provider.legalName")
    // },
    // {
    //   title: i18n.t("models.employee.plural")
    // }
    // {
    //   name: "clientLegalName",
    //   title: i18n.t("models.contract.clientLegalName"),
    //   sortable: true
    // },
    // {
    //   name: "status",
    //   title: i18n.t("models.contract.status")
    // },
    // {
    //   name: "createdAt",
    //   title: i18n.t("shared.created")
    // }
    // {
    //   title: i18n.t("app.contracts.visits")
    // }
  ];

  sortBy(column) {
    if (column) {
      this.sortDirection = this.sortDirection === -1 ? 1 : -1;
      this.sortByColumn = column;
    }
  }
  currentWorkspaceTaxIdIs(taxId) {
    return store.state.tenant.currentWorkspace?.taxId === taxId;
  }
  dateMonthName(value: Date | undefined) {
    return DateUtils.dateMonthName(value);
  }
  get sortedItems(): ContractDto[] {
    const column = this.sortByColumn;
    const sortDirection = this.sortDirection;
    if (!column || column === "") {
      return this.items;
    }
    return this.items.sort((x, y) => {
      if (x[column] && y[column]) {
        if (sortDirection === -1) {
          return (x[column] > y[column] ? 1 : -1) ?? 1;
        } else {
          return (x[column] < y[column] ? 1 : -1) ?? 1;
        }
      }
      return 1;
    });
  }
}
