







































































































































































































































































































































































































































































































































































































import Component from "vue-class-component";
import Vue from "vue";
import GoogleSignInButton from "vue-google-signin-button-directive";
import { Card, createToken } from "vue-stripe-elements-plus";
import LoadingButton from "@/components/shared/buttons/LoadingButton.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import NewModal from "@/components/shared/modals/NewModal.vue";
import BillingPeriodToggle from "../../components/marketing/toggles/BillingPeriodToggle.vue";
import Plans from "@/components/marketing/pricing/PlansRadioButtons.vue";
import Logo from "@/components/marketing/Logo.vue";
import { SubscriptionCouponDto } from "../../application/dtos/master/subscriptions/SubscriptionCouponDto";
import { UserLoginType } from "../../application/enums/master/users/UserLoginType";
import { SubscriptionBillingPeriod } from "../../application/enums/master/subscriptions/SubscriptionBillingPeriod";
import { SubscriptionProductDto } from "../../application/dtos/master/subscriptions/SubscriptionProductDto";
import { SubscriptionPriceDto } from "../../application/dtos/master/subscriptions/SubscriptionPriceDto";
import { UserRegisterRequest } from "../../application/contracts/master/users/UserRegisterRequest";
import LoadingButtonComponent from "@/components/shared/buttons/LoadingButton.vue";
import Modal from "@/components/shared/modals/Modal.vue";
import store from "@/store";
import { i18n } from "@/plugins/vue-i18n";
import { LinkStatus } from "@/application/enums/app/v3/links/LinkStatus";
import { WorkspaceType } from "@/application/enums/master/tenants/WorkspaceType";
import { LinkInvitationDto } from "@/application/dtos/master/v3/links/LinkInvitationDto";
import services from "@/services";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";

@Component({
  metaInfo: {
    title: "Crea tu cuenta",
    titleTemplate: "%s | Délega"
  },
  components: {
    BillingPeriodToggle,
    ErrorModal,
    NewModal,
    Card,
    Plans,
    Logo,
    LoadingButton,
    ConfirmModal
  },
  directives: {
    GoogleSignInButton
  }
})
export default class Register extends Vue {
  $refs!: {
    inputFirstName: HTMLInputElement;
    inputWorkspaceName: HTMLInputElement;
    inputTaxId: HTMLInputElement;
    inputLegalName: HTMLInputElement;
    loadingButton: LoadingButtonComponent;
    modalInvitation: NewModal;
    errorModal: ErrorModal;
    cardModal: Modal;
    buttonAcceptInvitation: HTMLButtonElement;
    confirmModalRegister: ConfirmModal;
  };
  loading = false;
  requireName = true;
  requirePhone = true;
  requirePassword = true;
  droppedDown = false;
  googleClientId = "";
  user = {} as UserRegisterRequest;
  email = "";
  // verifyToken: string = "";
  stripeKey = "";
  stripeOptions: any = {};
  cardCompleted = false;
  registered = false;
  haveCoupon = false;
  couponCode = "";
  couponDescription = "";
  coupon: SubscriptionCouponDto | null = null;
  linkInvitation: LinkInvitationDto | null = null;
  linkInvitationAccepted = false;

  emailEnabled = true;
  workspaceTaxIdEnabled = true;

  workspaceType: WorkspaceType = WorkspaceType.REGULAR;

  created() {
    if (this.$route.query.e) {
      this.user.email = this.$route.query.e.toString();
    }

    this.googleClientId =
      process.env.VUE_APP_GOOGLE_CLIENT_ID_OAUTH2?.toString() ?? "";
    this.stripeKey =
      process.env.VUE_APP_SUBSCRIPTION_PUBLIC_KEY?.toString() ?? "";
    this.stripeOptions = {
      showCardHolderName: true,
      hidePostalCode: false,
      iconStyle: "solid",
      elements: {
        locale: i18n.locale
      }
    };
  }
  mounted() {
    this.loading = true;
    services.subscriptionProducts
      .getProducts(true, true)
      .then(() => {
        if (this.$route.query.p) {
          const product = this.products.find(
            f => f.tier === Number(this.$route.query.p)
          );
          if (product) {
            store.commit("pricing/select", {
              product,
              billingPeriod: store.state.pricing.billingPeriod
            });
          }
        }
        if (!this.selectedProduct) {
          store.commit(
            "pricing/billingPeriod",
            SubscriptionBillingPeriod.MONTHLY
          );
          store.commit("pricing/currency", "mxn");
          store.commit("pricng/select", {
            product: this.products[0],
            billingPeriod: store.state.pricing.billingPeriod
          });
        }

        if (this.$route.params.coupon) {
          this.haveCoupon = true;
          this.couponCode = this.$route.params.coupon;
          this.searchCoupon(false);
        } else if (this.$route.query.coupon) {
          this.haveCoupon = true;
          this.couponCode = this.$route.query.coupon.toString();
          this.searchCoupon(false);
        }
        if (this.$route.query.i) {
          services.links
            .getInvitation(this.$route.query.i.toString())
            .then(response => {
              this.linkInvitation = response;
              this.loadLinkInvitation();
            });
        }
      })
      .finally(() => {
        this.loading = false;
      });
    this.$nextTick(() => {
      if (this.$route.query.i) {
        this.$refs.inputFirstName.focus();
        this.$refs.inputFirstName.select();
      } else {
        this.$refs.inputFirstName.focus();
        this.$refs.inputFirstName.select();
      }
    });
  }
  loadLinkInvitation() {
    if (this.linkInvitation) {
      if (this.linkInvitation.status === LinkStatus.PENDING) {
        this.$refs.modalInvitation.show();
        this.$nextTick(() => {
          this.$refs.buttonAcceptInvitation.focus();
        });
      }
    }
  }
  rejectInvitation() {
    this.$refs.modalInvitation.close();
    services.links.rejectInvitation(this.linkInvitation?.id);
    this.linkInvitation = null;
  }
  acceptInvitation() {
    this.$refs.modalInvitation.close();
    this.linkInvitationAccepted = true;
    this.user.joinedByLinkInvitation = this.linkInvitation?.id;
    this.emailEnabled = false;
    this.workspaceTaxIdEnabled = false;
    this.user.email = this.linkInvitation?.email ?? "";
    this.user.workspaceTaxId = this.linkInvitation?.taxId ?? "";
    this.user.workspaceName = this.linkInvitation?.legalName ?? "";
    this.user.workspaceLegalName = this.linkInvitation?.legalName ?? "";
    this.$nextTick(() => {
      if (this.$refs.inputFirstName) {
        this.$refs.inputFirstName.focus();
        this.$refs.inputFirstName.select();
      }
    });
  }
  searchCoupon(showError) {
    if (!this.selectedPrice) {
      return "";
    }
    if (!this.couponCode) {
      return;
    }
    services.subscriptionManager
      .getCoupon(this.couponCode, this.selectedPrice.currency)
      .then((response: SubscriptionCouponDto) => {
        this.coupon = response;
        if (this.coupon && this.coupon.name) {
          this.couponDescription = this.coupon.name.toString();
        }
      })
      .catch(error => {
        this.couponDescription = this.$t(
          "account.register.invalidCoupon"
        ).toString();
        if (showError) {
          this.$refs.errorModal.show("Error", this.$t(error));
        }
      });
  }
  tryRegisterWithPassword() {
    this.tryRegister(UserLoginType.PASSWORD);
  }
  tryRegister(loginType: UserLoginType = UserLoginType.PASSWORD) {
    this.email = this.user.email;
    this.user.loginType = loginType;
    if (!this.selectedPrice) {
      this.$refs.errorModal.show("Error", "Selecciona un plan");
      return;
    }
    if (this.selectedPrice.trialDays === 0 && this.selectedPrice.price > 0) {
      this.$refs.cardModal.show(
        this.$t("account.register.providePaymentDetails"),
        this.$t("shared.cancel")
      );
    } else {
      this.$refs.confirmModalRegister.show(
        this.$t("account.register.prompts.register.title"),
        this.$t("shared.confirm"),
        this.$t("shared.back"),
        this.$t("account.register.prompts.register.description", [
          this.selectedProduct?.title
        ])
      );
    }
  }
  confirmedRegister() {
    this.register();
  }
  pay() {
    createToken()
      .then(data => {
        if (data.error) {
          this.$refs.errorModal.show("Error", data.error.message);
        } else {
          this.register(data.token.id);
        }
      })
      .catch(error => {
        this.$refs.errorModal.show("Error", error);
      })
      .finally(() => {
        this.$refs.cardModal.close();
      });
  }
  register(cardToken = "") {
    if (!this.selectedPrice) {
      this.$refs.errorModal.show(
        "Error",
        this.$t("account.register.errors.priceNotSelected")
      );
      return;
    } else if (this.selectedPrice && !this.selectedPrice.id) {
      this.$refs.errorModal.show(
        "Error",
        this.$t("account.register.errors.priceNotInDatabase")
      );
      return;
    }
    this.user.selectedSubscription = {
      subscriptionPriceId: this.selectedPrice.id ?? "",
      subscriptionCardToken: cardToken,
      subscriptionCoupon: this.couponCode
    };
    this.user.workspaceType = this.workspaceType;
    this.$refs.loadingButton.start();
    services.authentication
      .register(this.user)
      .then(() => {
        this.registered = true;
      })
      .catch(error => {
        this.$refs.errorModal.show("Error", error);
      })
      .finally(() => {
        if (this.$refs.loadingButton) {
          this.$refs.loadingButton.stop();
        }
      });
  }
  // changeProduct(product) : void {
  //   this.closeDropdown();
  //   store.commit("pricing/select", product);
  // }
  closeDropdown() {
    this.droppedDown = false;
  }
  selectedPriceIsOneTimePrice(): boolean {
    return (
      !this.selectedPrice ||
      this.selectedPrice.billingPeriod === SubscriptionBillingPeriod.ONCE
    );
  }
  toggleWorkspaceType() {
    this.workspaceType =
      this.workspaceType === WorkspaceType.REGULAR
        ? WorkspaceType.SPECIALIZED_PROVIDER
        : WorkspaceType.REGULAR;
  }
  get phonePlaceHolder() {
    return (
      this.$t("settings.profile.phone") +
      " (" +
      this.$t("shared.optional")
        .toString()
        .toLowerCase() +
      ")"
    );
  }
  get planSupportsPhone() {
    return this.selectedProduct && this.selectedProduct.tier >= 3;
  }
  get products(): SubscriptionProductDto[] {
    return store.state.pricing.products;
  }
  // get verifyURL(): string {
  //   if (this.verifyToken && this.verifyToken !== "") {
  //     return "/cuenta/verificar?e=" + this.email + "&t=" + this.verifyToken;
  //   }
  //   return "";
  // }
  get getButtonText(): string {
    if (!this.selectedPrice) {
      return "";
    }
    return (
      (this.selectedPrice.billingPeriod === SubscriptionBillingPeriod.ONCE
        ? this.$t("marketing.pricing.pay")
        : this.$t("marketing.pricing.subscribe")) +
      " " +
      this.priceDescription
    );
  }
  get selectedPrice(): SubscriptionPriceDto | undefined {
    return (
      this.selectedProduct?.prices.find(
        f =>
          f.billingPeriod === store.state.pricing.billingPeriod &&
          f.currency === store.state.pricing.currency
      ) ??
      this.selectedProduct?.prices.filter(
        f => f.currency === store.state.pricing.currency
      )[0]
    );
  }
  get billingPeriod(): string {
    if (!this.selectedPrice) {
      return "";
    }
    if (this.selectedPrice.billingPeriod === SubscriptionBillingPeriod.ONCE) {
      return this.$t("marketing.pricing.once").toString();
    } else {
      return (
        "/ " +
        this.$t(
          "marketing.pricing." +
            SubscriptionBillingPeriod[this.selectedPrice.billingPeriod] +
            "Short"
        )
      );
    }
  }
  get discountedPrice(): number {
    if (!this.selectedPrice) {
      return 0;
    }
    let price = this.selectedPrice.price;
    // let couponApplied = "";
    if (this.coupon) {
      if (
        this.coupon.amountOff &&
        this.selectedPrice.currency === this.coupon.currency
      ) {
        // couponApplied =
        //   " -$" +
        //   this.coupon.amountOff / 100 +
        //   " " +
        //   this.coupon.currency.toUpperCase();
        price = price - this.coupon.amountOff / 100;
      } else if (this.coupon.percentOff) {
        // couponApplied = " -" + this.coupon.percentOff + "%";
        price = Number(
          (price * ((100 - this.coupon.percentOff) / 100)).toFixed(2)
        );
      }
    }
    return price;
  }

  get priceDescription(): string {
    if (!this.selectedPrice) {
      return "";
    }
    return (
      "$" +
      this.discountedPrice +
      " " +
      this.selectedPrice.currency +
      " " +
      this.billingPeriod
    );
  }
  get selectedProduct(): SubscriptionProductDto | null {
    return store.state.pricing.selectedProduct;
  }
}
