























































































































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/shared/breadcrumbs/Breadcrumb.vue";
import services from "@/services";
import ContractClock from "@/assets/contracts/ContractClock.vue";
import ContractCheck from "@/assets/contracts/ContractCheck.vue";
import ContractArchived from "@/assets/contracts/ContractArchived.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import Sign from "@/assets/contracts/Sign.vue";
import { TenantDto } from "@/application/dtos/master/tenants/TenantDto";
import DateUtils from "@/utils/shared/DateUtils";
import Loading from "@/components/shared/loaders/Loading.vue";

@Component({
  metaInfo: {
    title: "Perfil de Organización",
    titleTemplate: "%s | Délega"
  },
  components: {
    Breadcrumb,
    ContractClock,
    ContractCheck,
    ContractArchived,
    ConfirmModal,
    SuccessModal,
    ErrorModal,
    Sign,
    Loading
  }
})
export default class TenantProfile extends Vue {
  $refs!: {
    confirmDelete: ConfirmModal;
    successModalDeleted: SuccessModal;
    errorModal: ErrorModal;
  };
  id = "";
  year = 0;
  loading = false;
  loadingContracts = false;
  openOptions = false;

  item: TenantDto = {} as TenantDto;
  created() {
    this.id = this.$route.params.id;
  }
  mounted() {
    this.reload();
  }
  reload() {
    this.closeOptions();
    this.loading = true;
    services.tenants
      .get(this.id)
      .then(response => {
        this.item = response;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  deleteTenant() {
    this.closeOptions();
    this.$refs.confirmDelete.show(
      "¿Eliminar organización?",
      "Eliminar",
      "Cancelar",
      "ADVERTENCIA: Se eliminarán todas sus empresas, usuarios e información que haya subido."
    );
  }
  confirmedDelete() {
    this.loading = true;
    services.tenants
      .adminDelete(this.id)
      .then(() => {
        this.$refs.successModalDeleted.show("Organización eliminada");
      })
      .catch(error => {
        this.$refs.errorModal.show("Error eliminando", this.$t(error));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  successModalDeletedClosed() {
    this.$router.push({ name: "admin.clients" });
  }
  closeOptions() {
    this.openOptions = false;
  }
  toggleOptions() {
    this.openOptions = !this.openOptions;
  }
  dateMonthDayYear(value: Date | undefined) {
    return DateUtils.dateMonthDayYear(value);
  }
}
