





































































































import Vue from "vue";
import Component from "vue-class-component";
import LoadingButton from "../../components/shared/buttons/LoadingButton.vue";
import ErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import Logo from "@/components/marketing/Logo.vue";
import LoadingButtonComponent from "../../components/shared/buttons/LoadingButton.vue";
import services from "@/services";

@Component({
  metaInfo: {
    title: "Olvidé mi contraseña",
    titleTemplate: "%s | Délega"
  },
  components: {
    SuccessModal,
    ErrorModal,
    Logo,
    LoadingButton
  }
})
export default class Forgot extends Vue {
  $refs!: {
    loadingButton: LoadingButtonComponent;
    errorModal: ErrorModal;
  };
  emailSent = false;
  email = "";
  verifyToken = "";
  created() {
    this.email = this.$route.query.e ? this.$route.query.e.toString() : "";
  }
  reset() {
    this.$refs.loadingButton.start();
    services.authentication
      .reset(this.email)
      .then(response => {
        this.emailSent = true;
        this.verifyToken = response.verifyToken;
      })
      .catch(error => {
        this.$refs.errorModal.show(error);
      })
      .finally(() => {
        if (this.$refs.loadingButton) {
          this.$refs.loadingButton.stop();
        }
      });
  }
}
