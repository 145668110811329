





























































































































import Component from "vue-class-component";
import Vue from "vue";
import ErrorModal from "@/components/shared/modals/NewErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import { Watch } from "vue-property-decorator";
import { SubscriptionProductDto } from "../../../application/dtos/master/subscriptions/SubscriptionProductDto";
import { ColumnType, ValueType } from "../../../application/dtos/ColumnType";
import services from "@/services";
import PageTitle from "@/components/shared/navigation/PageTitle.vue";

@Component({
  components: {
    SuccessModal,
    ErrorModal,
    ConfirmModal,
    PageTitle
  }
})
export default class SubscriptionProduct extends Vue {
  $refs!: {
    errorModal: ErrorModal;
  };
  loading = false;
  product = {} as SubscriptionProductDto;
  hasChanges = false;
  columns: ColumnType[] = [
    { name: "serviceId" },
    { name: "title" },
    { name: "description" },
    { name: "tier", valueType: ValueType.Number },
    { name: "badge", valueType: ValueType.String },
    { name: "active", valueType: ValueType.Bool },
    { name: "maxUsers", valueType: ValueType.Number },
    { name: "image", valueType: ValueType.Image }
  ];
  created() {
    services.subscriptionProducts.getProducts(true, false);
  }
  added() {
    this.reload();
  }
  saved() {
    this.reload();
  }
  deleted() {
    this.pushToParent();
  }
  @Watch("$route.params.id")
  productChanged() {
    this.reload();
  }
  mounted() {
    this.reload();
    // this.$router.push({ name: "admin.product.overview" });
  }
  async reload() {
    this.loading = true;
    this.hasChanges = false;
    services.subscriptionProducts
      .getProduct(this.$route.params.id)
      .then((response: SubscriptionProductDto) => {
        this.product = response;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  saveChanges() {
    if (!this.product.id) {
      services.subscriptionProducts
        .createProduct(this.product)
        .then(() => {
          this.reload();
        })
        .catch(error => {
          this.$refs.errorModal.show(error);
        });
    } else {
      services.subscriptionProducts
        .updateProduct(this.product.id, {
          id: this.product.id,
          tier: this.product.tier,
          title: this.product.title,
          description: this.product.description,
          badge: this.product.badge,
          active: this.product.active,
          image: this.product.image,
          maxUsers: this.product.maxUsers,
          maxWorkspaces: this.product.maxWorkspaces,
          maxLinks: this.product.maxLinks,
          maxStorage: this.product.maxStorage,
          monthlyContracts: this.product.monthlyContracts
        })
        .then(() => {
          this.reload();
        })
        .catch(error => {
          this.$refs.errorModal.show(error);
        });
    }
  }
  isBool(column: ColumnType) {
    return column.valueType === ValueType.Bool;
  }
  hasImage() {
    return this.columns.includes(
      (element: ColumnType) => element.valueType === ValueType.Image
    );
  }
  getImage() {
    return this.product.image;
  }
  pushToParent() {
    const pathArray = this.$route.path.split("/");
    if (pathArray[pathArray.length - 1] === "") {
      pathArray.pop();
    }
    const routes: string[] = [];
    for (let index = 0; index < pathArray.length - 1; index++) {
      const element: string = pathArray[index];
      routes.push(element);
    }
    const parentRoute = routes.join("/");
    console.log("pushing; " + parentRoute);
    this.$router.push(parentRoute);
  }
  get item() {
    return this.product;
  }
  //   get columns() {
  //     let modelColumns: ColumnType[] = [];
  //     Object.keys(this.product).forEach((key) => {
  //       modelColumns.push({
  //         name: key,
  //       });
  //     });
  //     return modelColumns;
  //   }
}
