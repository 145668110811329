

















































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import store from "@/store";
import { TranslateResult } from "vue-i18n";

@Component
export default class ConfirmModal extends Vue {
  $refs!: {
    main: HTMLDivElement;
    yesButton: HTMLElement;
    inputEmail: HTMLInputElement;
  };
  @Prop({ default: "" }) inputType!: string;
  @Prop({ default: true }) validateInput!: boolean;
  title!: string;
  closeText!: TranslateResult | string;
  showing = false;
  value: any = undefined;
  question!: string;
  yesTitle: string | TranslateResult = "";
  noTitle: string | TranslateResult = "";
  subtitle: string | TranslateResult = "";
  inputString: string | TranslateResult = "";
  mounted() {
    this.title = this.$t("shared.confirm").toString();
    this.closeText = this.$t("shared.close").toString();
    if (this.inputType === "email") {
      this.inputString = store.state.account.user?.email ?? "";
    }
    // this.$refs.yesButton.focus();
  }
  accept() {
    this.yes();
  }
  show(
    question: any,
    yesTitle?: string | TranslateResult,
    noTitle?: string | TranslateResult,
    subtitle?: string | TranslateResult,
    inputString?: string | TranslateResult
  ) {
    this.question = question;
    this.showing = true;
    if (yesTitle) {
      this.yesTitle = yesTitle;
    }
    if (noTitle) {
      this.noTitle = noTitle;
    }
    if (subtitle) {
      this.subtitle = subtitle;
    }
    // window.addEventListener("keyup", this.keyEvent);
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.$refs.yesButton) {
          this.$refs.yesButton.focus();
        }
        if (this.inputType === "email" && this.$refs.inputEmail) {
          this.$refs.inputEmail.focus();
          this.$refs.inputEmail.select();
        }
      }, 1000);
    });
    if (inputString) {
      this.inputString = inputString;
    }
  }
  yes() {
    if (this.inputType) {
      this.value = this.inputString;
      if (
        this.validateInput &&
        (!this.inputString || this.inputString.toString().trim() === "")
      ) {
        return;
      }
    }
    this.$emit("yes", this.value);
    this.value = undefined;
    this.showing = false;
    window.removeEventListener("keyup", this.keyEvent);
  }
  no() {
    this.value = undefined;
    this.showing = false;
    this.$emit("no");
    window.removeEventListener("keyup", this.keyEvent);
  }
  keyEvent(event) {
    if (event.ctrlKey && event.keyCode) {
      if (event.keyCode === 13) {
        this.yes();
      }
    }
  }
}
